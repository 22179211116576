import React from 'react';

const NonActiveVehicle = () => {
  return (
    <div className="text-center">
      <div className="info-form-box">
         No Vehicle Found
      </div>
    </div>
  );
};
export default NonActiveVehicle;
