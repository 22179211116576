import React, { useContext, useState } from 'react';
import { Context } from '../../../../../utils/Context';
import Button from 'react-bootstrap/Button';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { PAYMENT_CHECKOUT } from '../../../../../api/endpoint';
import { putRequest } from '../../../../../api/api';
import { GetGuestUserToken } from '../../guestUserTokenUtils';
import { ToastManager } from '../../../../../ToastContainer/ToastContainer';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PaymentFull = () => {
  const { profileObject, userId } = useContext(Context);
  const [buttonLoading, setButtonLoading] = useState(false);
  const subscriptions = profileObject?.programs[0]?.subscriptions || [];
  const partial_payment =
    profileObject?.programs[0]?.payment_options?.partial_payment;
  const navigate = useNavigate();

  const handlePayment = async () => {
    // setLoading(true);
    setButtonLoading(true);
    const data = {
      profile_id: profileObject?.id,
      program_id: profileObject?.programs[0]?.id,
      pay_option: 'full',
    };

    const guestUserToken = GetGuestUserToken();
    const headers = {};
    headers.Authorization = guestUserToken;
    // Check if guestUserToken is not empty or null
    if (guestUserToken) {
      // If guestUserToken is not empty, include it in the headers
      headers.Authorization = guestUserToken;
    }

    const params = {
      id: userId,
      ...data,
    };

    const { result } = await putRequest(
      PAYMENT_CHECKOUT,
      params,
      data,
      headers
    );
    if (result?.public_url) {
      window.location.href = result.public_url;
      setButtonLoading(false);
    }
    if (result.public_url === null) {
      setButtonLoading(false);
      ToastManager.error(
        'We are currently unable to process your payment, possibly due to a temporary disruption in the payment gateway. Kindly consider trying the transaction again after some time. We apologize for any inconvenience this may have caused.'
      );
    }
    if (result?.error) {
      ToastManager.error(result?.error);
      setButtonLoading(false);
    }
    if (!result?.success) {
      setButtonLoading(false);
      ToastManager.error('Payment cannot be processed - ' + result?.message);
      setTimeout(() => {
        navigate('/quote/' + result?.navigation_key);
      }, 3000);
    }
  };

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            This option allows you to pay in full in one go for all your
            policies.
          </Tooltip>
        }
      >
        <span className="float-end cursor-pointer text-muted">
          <AiOutlineQuestionCircle size="20" />
        </span>
      </OverlayTrigger>

      <h4 className="font-20-sm fw-bold">
        {partial_payment ? (
          <>Pay in Full</>
        ) : (
          <>Make Payment for below Policies</>
        )}
      </h4>
      <hr />
      {subscriptions.length > 0 ? (
        <>
          {subscriptions.map((subscription, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between text-start">
                <div>
                  <p className="fs-5">
                    <b>{index + 1}. </b>
                    <i>
                      <strong>{subscription?.formal_name}</strong>
                    </i>{' '}
                    <span className="text-start fs-5">
                      {subscription?.coverage_type_code_name === 'OA' ||
                      subscription?.coverage_type_code_name === 'PA' ? (
                        <p>(Drivers: {profileObject?.number_of_drivers})</p>
                      ) : (
                        <p>
                          (Vehicles: {profileObject?.vehicle_details.length})
                        </p>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </div>
          ))}
          <br />
          <Button
            className="btn silo-btn-small silo-btn__blue"
            onClick={handlePayment}
          >
            {buttonLoading ? <Spinner size="sm" /> : <>Continue to Pay</>}
          </Button>
        </>
      ) : (
        <Spinner size="30" />
      )}
    </div>
  );
};

export default PaymentFull;
