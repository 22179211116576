import { GetLoggedInUserToken } from "../components/pages/LoggedIn-Area/loggedInUserTokenUtils";



export async function getLoggedinRequest(url, params = {}, headers = {}) {
    try {
      const userToken = await GetLoggedInUserToken();
      if (userToken != null) {
        headers.Authorization = userToken;
      }
      params = Object.keys(params)
        .map((key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        })
        .join('&');
      if (params) url += `?${params}`;
      let response = await fetch(url, {
        method: 'GET',
        headers: Object.assign({ Accept: 'application/json' }, headers),
      });
  
      if (response.status === 200) {
        let result = await response.json();
        return { result };
      } else if (response.status === 422) {
        let result = await response.json();
        return { result };
      } else if (response.status === 500) {
        return { error: 'Internal Server Error' };
      } else {
        throw new Error(
          `Error with status ${response.status}, url: ${response.url}`
        );
      }
    } catch (error) {
      console.log(error);
      return { error: error.message };
    }
  }