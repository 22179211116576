import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../../../../../../utils/Context';
import {
  formatDate_MMDDYY,
  getVehicleDetails,
} from '../../../../../../../utils/Commonutils';
// import { BsThreeDotsVertical } from 'react-icons/bs';
import '../../driverInfo.scss';
// import { Link } from 'react-router-dom';
import DeleteModal from '../../../../../Shared/ModalContainer/DeleteModal';
import { Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { getRequest } from '../../../../../../../api/api';
import { GET_STATE_LIST } from '../../../../../../../api/endpoint';
const ActiveDriver = () => {
  const [showModal, setShowModal] = useState(false);
  const { setUserDetails, userDetails } = useContext(UserContext);
  const [stateList, setStateList] = useState(null);

  const handleCloseModal = () => setShowModal(false);
  // const handleShowModal = () => setShowModal(true);

  // const CustomToggle = ({ onClick }) => (
  //   <BsThreeDotsVertical
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //     size={20}
  //   />
  // );

  useEffect(() => {
    getVehicleDetails(setUserDetails);
    LicenseState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LicenseState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST);
      setStateList(response.result);
    } catch (error) {
      console.log('err', error);
    }
  };

  return (
    <div className="row">
      {userDetails?.drivers?.length > 0 ? (
        userDetails?.drivers.map((item) => (
          <div className="col-md-6 mb-4" key={item.id}>
            <div className="info-form-box container_override-sm">
              <div className="d-flex justify-content-between">
                <h5 className="fw-bold">
                  {item.first_name} {item.last_name}
                </h5>
                <Dropdown className="cursor-pointer ellipsis">
                  {/* <Dropdown.Toggle as={CustomToggle} /> */}
                  <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item>Download Certificate</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="mb-4">
                <Row>
                  <Col md={6}>
                    <div className="my-2">
                      <div className="text-muted">Driver Type</div>
                      <div>Primary Driver</div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="my-2">
                      <div className="text-muted">Email</div>
                      <div>{item?.email}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="my-2">
                      <div className="text-muted">Date of Birth</div>
                      <div>{formatDate_MMDDYY(item?.date_of_birth)}</div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="my-2">
                      <div className="text-muted">
                        Original CDL License Issue Date
                      </div>
                      <div>{formatDate_MMDDYY(item?.date_of_hire)}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="my-2">
                      <div className="text-muted">Driver License No.</div>
                      <div>{item?.license_no}</div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="my-2">
                      <div className="text-muted">Driver License State</div>
                      <div>
                        {
                          stateList?.find(
                            (option) => option?.id === item?.license_state_id
                          )?.name
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                {item?.vehicle_detail?.vin_number && (
                  <Row>
                    <Col md={6}>
                      <div className="my-2">
                        <div className="text-muted">Associated Vehicle</div>
                        <div>{item?.vehicle_detail?.vin_number}</div>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              {/* <div className="d-flex align-items-center justify-content-start">
              <Link
                to={`/edit/${item.id}`}
                className="btn silo-btn-small silo-btn__blue btn-primary"
              >
                Edit
              </Link>
              <button
                className="btn silo-btn-small btn-danger btn-delete mx-3"
                onClick={handleShowModal}
              >
                Delete
              </button>
            </div> */}
              <DeleteModal show={showModal} handleClose={handleCloseModal} />
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">
          <div className="info-form-box">No Driver Found</div>
        </div>
      )}
    </div>
  );
};

export default ActiveDriver;
