import React from 'react';
import { Form } from 'react-bootstrap';

const EditProfile = ({ profileData, setProfileData, hanndleChange, profileFieldError, setProfileFieldError }) => {

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  const userProfileFields = [
    {
      label: 'First Name',
      id: 'firstName',
      name: 'firstName',
      value: profileData.firstName,
      type: 'text',
    },
    {
      label: 'Last Name',
      id: 'lastName',
      name: 'lastName',
      value: profileData.lastName,
      type: 'text',
    },
    {
      label: 'Email',
      id: 'email',
      name: 'email',
      value: profileData.email,
      type: 'email',
    },
    {
      label: 'Phone Number',
      id: 'phoneNumber',
      name: 'phoneNumber',
      value: profileData.phoneNumber,
      type: 'tel',
    },
    {
      label: 'Date of Birth',
      id: 'DOBpic',
      name: 'dob',
      value: profileData.dob,
      type: 'date',
      max: `${eighteenYearsAgo.getFullYear()}-${String(eighteenYearsAgo.getMonth() + 1).padStart(2, '0')}-${String(eighteenYearsAgo.getDate()).padStart(2, '0')}`
    },
  ];

  return (
    <>
      <div className="user-detail-form-blue silo_page_section-heading">
        <h4 className="fw-bold">Edit Profile</h4>
        <p className="m-0">
          Set your login preferences, help us personalize your experience and
          make big account changes here
        </p>
        <div className="row mt-2">
          {userProfileFields.map((field) => (
            <div key={field.id} className="col-md-6">
              <Form.Group controlId={field.id}>
                <Form.Label className="fw-bold mt-2">{field.label}</Form.Label>
                <Form.Control
                  type={field.type}
                  name={field.name}
                  value={field.value}
                  max={field.max} // Add the max attribute
                  onChange={hanndleChange}
                  className={`${profileFieldError[field.name] ? 'is-invalid' : ''
                      }`}
                />
                {profileFieldError[field.name] && (
                    <span className="text-danger">
                      {profileFieldError[field.name]}
                    </span>
                  )}
              </Form.Group>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditProfile;
