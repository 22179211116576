import React from 'react';
import Contact from './Section/Contact/Contact';
import CommonSection from './Section/CommonSection/CommonSection';
import Banner from './Section/Banner/Banner';
import HowItWorks from './Section/HowItWorks/HowItWorks';
import Featured from './Section/Featured/Featured';
import Reminder from './Section/Reminder/Reminder';

const Home = () => {
  return (
    <div className="content-layout">
      <main>
        <Contact />
        <Banner />
        <Featured />
        <HowItWorks />
        <Reminder />
        <CommonSection />
      </main>
    </div>
  );
};

export default Home;
