import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import facebook from '../../../../assets/images/SocialIcons/facebook.svg';
import linkedIn from '../../../../assets/images/SocialIcons/linkedin.svg';
import twitter from '../../../../assets/images/SocialIcons/twitter.svg';
import instagram from '../../../../assets/images/SocialIcons/instagram.svg';
import '../../footer.scss';
import './footerCopyright.scss';
import ScrollToTopLink from '../../../ScrollToTop/ScrollToTop';

const FooterCopyright = () => {
  return (
    <footer className="font-small backgroundLightBlue pb-lg-0 pb-sm-3 pb-md-3">
      <div className="text-center py-3 text-blue borderBlue">
        <Container>
          <Row className="align-items-center">
            <Col md={4} sm={12} className="d-flex justify-content-center">
              <h6 className="font-12 text-center-sm">Copyright © 2022 SILO Members. All Right Reserved.</h6>
            </Col>
            <Col md={3} sm={12}>
              <ul className="list-inline d-flex social-icons justify-content-center pt-sm-1">
                <li>
                  <Link to="/">
                    <div className="icon d-flex">
                      <img src={facebook} alt="facebook" />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="icon d-flex">
                      <img src={twitter} alt="twitter" />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="icon d-flex">
                      <img src={linkedIn} alt="linkedIn" />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="icon d-flex">
                      <img src={instagram} alt="instagram" />
                    </div>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={5} sm={12}>
              <ScrollToTopLink to="/termsandconditions">
                <span className="text-blue font-12 p-1">Terms & Conditions</span>
              </ScrollToTopLink>
              <ScrollToTopLink to="/surplus-lines-disclosure">
                <span className="text-blue font-12 p-1">| Surplus Lines Disclosure</span>
              </ScrollToTopLink>
              <ScrollToTopLink to="/privacyPolicy">
                <span className="text-blue font-12 p-1">| Privacy Policy |</span>
              </ScrollToTopLink>
              <ScrollToTopLink to="/contact">
                <span className="text-blue font-12 p-1">Contact Us</span>
              </ScrollToTopLink>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default FooterCopyright;
