import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../../../../../utils/Context';
import { getVehicleDetails } from '../../../../../../../utils/Commonutils';
// import { BsThreeDotsVertical } from 'react-icons/bs';
import '../../vehicleInfo.scss';
import { Row, Col } from 'react-bootstrap';
import DeleteModal from '../../../../../Shared/ModalContainer/DeleteModal';
import Dropdown from 'react-bootstrap/Dropdown';
import { formatInput } from '../../../../../../../utils/Commonutils';

const ActiveVehicle = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  // const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  //   <BsThreeDotsVertical
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //     size={20}
  //     ref={ref}
  //   />
  // ));

  const { userDetails, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    getVehicleDetails(setUserDetails);
  }, [setUserDetails]);

  return (
    <div className="row">
      {userDetails?.vehicle_details.map((item, index) => (
        <div className="col-md-6 mb-4" key={index}>
          <div className="info-form-box container_override-sm" key={index}>
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Vehicle {index + 1}</h5>
              <Dropdown className="cursor-pointer ellipsis">
                {/* <Dropdown.Toggle as={CustomToggle} /> */}
                <Dropdown.Menu size="sm" title="">
                  <Dropdown.Item>Download Certificate</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Row className="mb-4">
              <Col md={6}>
                <div className="my-2">
                  <div className="text-muted">Make</div>
                  <div>{item.vehicle_make?.name || 'N/A'}</div>
                </div>
                <div className="my-2">
                  <div className="text-muted">Model</div>
                  <div>{item.vehicle_model?.name || 'Not Available'}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="my-2">
                  <div className="text-muted">Year</div>
                  <div>{item.declared_year || 'Not Available'}</div>
                </div>
                <div className="my-2">
                  <div className="text-muted">Value</div>
                  <div>$ {formatInput(item.declared_value) || 'N/A'}</div>
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex align-items-center">
            <Link
              to="edit"
              className="btn silo-btn-small silo-btn__blue btn-primary"
            >
              Edit
            </Link>
            <button
              className="btn silo-btn-small btn-danger btn-delete mx-3"
              onClick={handleShowModal}
            >
              Delete
            </button>
          </div> */}
            <DeleteModal show={showModal} handleClose={handleCloseModal} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActiveVehicle;
