import React from 'react';
import './contact.scss';
import Heading from './Section/Heading/Heading';

const Contact = () => {
  return (
    <div>
      <div className="silo_page-home d-flex align-items-center">
        <div className="container position-relative">
          <div className="row flex-direction-CR">
            <Heading />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
