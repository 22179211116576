import React from 'react';

const CancelledSubscription = () => {
  return (
    <div className="text-center">
      <div className="info-form-box">Cancelled Subscription</div>
    </div>
  );
};

export default CancelledSubscription;
