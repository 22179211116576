import { LuUserCheck } from 'react-icons/lu';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { TbCertificate } from 'react-icons/tb';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';
import { TbFileDollar } from 'react-icons/tb';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { MdDirectionsCar } from 'react-icons/md';
import { TbSteeringWheel } from 'react-icons/tb';
import { FaPowerOff } from 'react-icons/fa6';

const BarContent = [
  {
    id: 0,
    text: 'Personal Information',
    link: 'info',
    icon: <LuUserCheck />,
  },
  {
    id: 1,
    text: 'Certificate of Insurance',
    link: '/account/certificate-of-insurance',
    icon: <TbCertificate />,
  },
  {
    id: 2,
    text: 'My Policies',
    link: 'policy',
    icon: <HiOutlineDocumentText />,
  },
  {
    id: 3,
    text: 'Billing',
    link: 'billing',
    icon: <HiOutlineCurrencyDollar />,
    subNav: [
      {
        title: 'Billing-Details',
        path: '/account/billing/details',
        icon: <TbFileDollar />,
      },
      {
        title: 'Billing-History',
        path: '/account/billing/history',
        icon: <LiaFileInvoiceDollarSolid />,
      },
      // {
      //   title: 'Manage Subscription',
      //   path: '/account/billing/subscription',
      // },
    ],
  },
  // {
  //   id: 3,
  //   text: 'Saved Quote',
  //   link: 'savedquote',
  // },
  {
    id: 4,
    text: 'Vehicle Info',
    link: '/account/vehicle-info',
    icon: <MdDirectionsCar />,
  },
  {
    id: 5,
    text: 'Driver Info',
    link: '/account/driver-info',
    icon: <TbSteeringWheel />,
  },
  {
    id: 6,
    text: 'Documents',
    link: '/account/documents',
    icon: <TbCertificate />,
  },
  {
    id: 7,
    text: 'Logout',
    link: 'logout',
    class: 'as',
    icon: <FaPowerOff className="text-danger" />,
  },
];

export default BarContent;
