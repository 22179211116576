import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PaymentPartial from '../PaymentType/PaymentPartial';
import PaymentFull from '../PaymentType/PaymentFull';

export const PaymentCards = ({partial_payment, showFullPayment, saveProfile}) => {
  return (
    <span>
      <>
        <h3 className="text-center fw-bold font-24-sm mb-3 mb-md-4">
          Let's Proceed for Payment...
        </h3>
        <Container className="plr-0-sm pb-3">
          <Row>
            {partial_payment && (
              <Col
                md={6}
                lg={6}
                sm={12}
                className={showFullPayment ? '' : 'm-auto'}
              >
                <div className="current-container mb-md-0 mb-4 partials_payments">
                  <PaymentPartial saveProfile={saveProfile} />
                </div>
              </Col>
            )}
            {showFullPayment && (
              <Col
                md={6}
                lg={6}
                sm={12}
                className={partial_payment ? '' : 'm-auto'}
              >
                <div className="current-container mb-md-0 mb-4">
                  <PaymentFull />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </>
    </span>
  );
};
