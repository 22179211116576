import { getLoggedinRequest } from '../api/logginInUserAPI';
import { GET_VEHICLE_DETAILS } from '../api/endpoint';
import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getRequest } from '../api/api';
import { GET_CITY_LIST } from '../api/endpoint';

export const formatInput = (value) => {
  const numericValue = parseFloat(value);

  if (!isNaN(numericValue)) {
    return numericValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return '';
  }
};

export const getVehicleDetails = async (setUserDetails) => {
  try {
    const response = await getLoggedinRequest(GET_VEHICLE_DETAILS);
    setUserDetails(response?.result?.profiles[0]);
    return response;
  } catch (error) {
    console.log('API error:', error);
  }
};

export const CommonCityApi = ({
  page,
  stateId,
  Setpage,
  CityData,
  SetCityOptions,
  handleCityChange,
  selectDriverCityError,
}) => {
  const [stateName, setStateName] = useState(null);

  useEffect(() => {
    setStateName(stateId);
  }, [stateId]);

  const billingCity = async (search) => {
    try {
      // Check if state_id is defined before making the API request
      if (stateName) {
        const response = await getRequest(
          `${GET_CITY_LIST}?name=${search}&state_id=${stateName}&page=${page}`
        );
        const cityData = response.result;

        // Filter out any empty or invalid options
        const cityMapped = cityData
          .filter((item) => item.name) // Filter out items with empty names
          .map((item) => ({
            value: item.name,
            label: item.name,
            id: item.id,
          }));

        SetCityOptions((prevOptions) => {
          if (search === '') {
            // Clear options for a new search
            return cityMapped;
          } else {
            // Concatenate options for paginated results
            return [...prevOptions, ...cityMapped];
          }
        });

        const lastElement = cityData[cityData.length - 1];
        const lastPageUrl = lastElement.links?.last_url;

        if (lastPageUrl) {
          const lastPageNumber = new URLSearchParams(lastPageUrl).get('page');

          return {
            options: cityMapped,
            hasMore: cityMapped.length > 0 && page < lastPageNumber,
            additional: {
              page: Setpage(page + 1),
            },
          };
        } else {
          // Handle the case when last_url is not available in the response
          return {
            options: [],
            hasMore: false,
          };
        }
      } else {
        // Handle the case when state_id is not defined
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log('err', error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <AsyncPaginate
      key={stateName} // Use stateName instead of stateId
      value={
        CityData?.city && Object.keys(CityData?.city).length !== 0
          ? {
              value: CityData?.city?.name,
              label: CityData?.city?.name,
            }
          : []
      }
      loadOptions={billingCity}
      isSearchable={true}
      placeholder="Select City"
      isClearable
      onChange={(selectedOption) => {
        handleCityChange(selectedOption);
      }}
      additional={{
        page: 1,
      }}
      className={`custom-select w-100 ${
        selectDriverCityError ? 'is-invalid' : ''
      }`}
    />
  );
};

// method to get date format in string

export const formatDate = (newdate) => {
  var date = new Date(newdate);
  // Get the individual components of the date
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
  var day = date.getDate().toString().padStart(2, '0');
  var year = date.getFullYear();
  // Format the date as YYYY/MM/DD
  var formattedDate = year + '-' + month + '-' + day;

  return formattedDate;
};

// method to get formatted US based number

export const formatUSBasedNumber = (value) => {
  // Remove non-numeric characters
  const numericInput = value.replace(/\D/g, '');

  // Limit to 10 digits
  const truncatedInput = numericInput.slice(0, 10);

  // Format the number with parentheses and hyphens
  let formattedNumber = '';
  for (let i = 0; i < truncatedInput.length; i++) {
    if (i === 0) {
      formattedNumber += `(${truncatedInput[i]}`;
    } else if (i === 3) {
      formattedNumber += `) ${truncatedInput[i]}`;
    } else if (i === 6) {
      formattedNumber += `-${truncatedInput[i]}`;
    } else {
      formattedNumber += truncatedInput[i];
    }
  }

  return formattedNumber;
};

export const formatZipCode = (value) => {
  // Remove non-numeric characters
  const numericInput = value.replace(/[^\d]/g, '');
  // Limit to 5 digits
  const truncatedInput = numericInput.slice(0, 5);
  return truncatedInput;
};

export const checkVehicleDetailsFilled = (vehicles) => {
  const filteredVehicles =
    vehicles &&
    vehicles.filter((value) => {
      return (
        value.vehicle_body_type !== null &&
        value.vehicle_type !== null &&
        value.vehicle_make !== null &&
        value.declared_year !== null &&
        value.cargo_commodities !== null &&
        value.license_plate_state !== null &&
        (value.dot_information_id !== 2
          ? value.dot_information_value !== null
          : true)
      );
    });
  if (filteredVehicles?.length !== vehicles?.length) {
    return false;
  } else {
    return true;
  }
};

export const formatDate_MMDDYY = (newdate) => {
  var date = new Date(newdate);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
};

