import { ToastManager } from '../ToastContainer/ToastContainer';
import { deleteRequest } from '../api/api';
import { DELETE_ATTACHMENTS } from '../api/endpoint';

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const base64Value = fileReader.result.split(',')[1];
      resolve(base64Value);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const handleDeleteDocument = async (
  documentType,
  profileId,
  headers
) => {
  const data = {
    type: documentType,
  };
  const deleteAttachment = DELETE_ATTACHMENTS(profileId);
  const response = await deleteRequest(deleteAttachment, data, headers);
  if (!response.result?.success) {
    ToastManager.error(response.result?.message);
  } else {
    return response;
  }
};
