import React, { useContext, useEffect, useState } from 'react';
import { getLoggedinRequest } from '../../../../../api/logginInUserAPI';
import { GET_VEHICLE_DETAILS } from '../../../../../api/endpoint';
import { OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsDownload } from 'react-icons/bs';
import { ToastManager } from '../../../../../ToastContainer/ToastContainer';
import NTDocument from '../../../../../assets/Documents/Association Member NTL Guidelines 23 - Signed.pdf';
import NTPolicyEndorsement from '../../../../../assets/Documents/Endorsement CA 7002.pdf';
import PersonalDocuments from './Documents/PersonalDocuments';
import { getVehicleDetails } from '../../../../../utils/Commonutils';
import { UserContext } from '../../../../../utils/Context';

function Documents() {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [programs, setPrograms] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await getVehicleDetails(setUserDetails);
      if (response?.result) {
        setIsLoading(false);
        setPrograms(response?.result?.profiles[0]?.programs[0]);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCOIDownload = async (item) => {
    if (item?.coi_url === null) {
      const response = await getLoggedinRequest(GET_VEHICLE_DETAILS);
      setUserDetails(response?.result?.profiles[0]);

      const vehicle = response?.result?.profiles[0].vehicle_details.find(
        (vehicle) => vehicle.id === item.id
      );
      if (vehicle?.coi_url) {
        window.open(vehicle?.coi_url, '_blank', 'noopener noreferrer');
      } else {
        ToastManager.info(
          'Your certificate of insurance is currently being generated. Please check back in a couple of minutes. Thank you for your patience!'
        );
      }
    } else {
      window.open(item?.coi_url, '_blank', 'noopener noreferrer');
    }
  };

  const handleOACertificateDownload = (subscription) => {
    if (subscription?.pdf_url !== null) {
      window.open(subscription.pdf_url, '_blank', 'noopener noreferrer');
    } else {
      ToastManager.info(
        'Your OA Policy Benefit Certificate will generate post payment completion. Please complete your Payment!'
      );
    }
  };

  const handlePDCGCertificateDownload = (pdLink) => {
    if (pdLink === null) {
      ToastManager.info(
        'Policy Document generation is in progress. Please try again after some time.'
      );
    } else {
      window.open(pdLink, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="min-height">
          <Spinner
            animation="border"
            variant="secondary"
            size="lg"
            className="mx-2 spinner-border-xl"
            style={{ width: '5rem', height: '5rem' }}
          />
        </div>
      ) : (
        <>
          <h3>
            <b>User Documents</b>
          </h3>
          <hr />
          <div className="row">
            {userDetails &&
              userDetails?.vehicle_details.map((item, index) => (
                <div className="col-md-6 mb-4" key={index}>
                  <div className="details-container" key={index}>
                    <div className="fs-5 fw-bold">
                      <span>Certificate of Insurance</span> :{' '}
                      <span>{item.vehicle_make?.name || ''}</span> -{' '}
                      <span>{item.vehicle_model?.name || ''}</span> -{' '}
                      <span>{item.declared_year || ''}</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip">
                            A certificate of insurance (COI) is issued by an
                            insurance company and it verifies the existence of
                            an insurance policy.
                          </Tooltip>
                        }
                      >
                        <span className="float-end cursor-pointer text-muted">
                          <AiOutlineQuestionCircle size="20" />
                        </span>
                      </OverlayTrigger>
                    </div>
                    <Row className="m-4">
                      <div>
                        <a
                          className="text-white text-decoration-none"
                          href={item?.coi_url && item?.coi_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => handleCOIDownload(item)}
                        >
                          <div className="d-flex flex-column align-items-center">
                            <BsDownload
                              size="50"
                              color="#444"
                              className="cursor-pointer"
                            />
                            <div
                              type="submit"
                              className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                            >
                              <strong>DOWNLOAD</strong>
                            </div>
                          </div>
                        </a>
                      </div>
                    </Row>
                  </div>
                </div>
              ))}
            {programs &&
              programs?.subscriptions?.map((subscription, index) =>
                (subscription?.coverage_type_code_name === 'CG' ||
                  subscription?.coverage_type_code_name === 'PD') &&
                programs?.pd_url ? (
                  <div className="col-md-6 mb-4">
                    <div className="details-container">
                      <div className="fs-5 fw-bold">
                        <span>Purchased Policy Details</span> :{' '}
                        <span>
                          {subscription?.coverage_type_code_name === 'CG'
                            ? 'Cargo Plan'
                            : 'Physical Damage Plan'}
                        </span>
                      </div>
                      <Row className="m-4">
                        <div>
                          <a
                            className="text-white text-decoration-none"
                            href={programs?.pd_url && programs?.pd_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              handlePDCGCertificateDownload(programs?.pd_url)
                            }
                          >
                            <div className="d-flex flex-column align-items-center">
                              <BsDownload
                                size="50"
                                color="#444"
                                className="cursor-pointer"
                              />
                              <div
                                type="submit"
                                className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                              >
                                <strong>DOWNLOAD</strong>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Row>
                    </div>
                  </div>
                ) : subscription?.pdf_url ? (
                  <div className="col-md-6 mb-4" key={index}>
                    <div className="details-container" key={index}>
                      <div className="fs-5 fw-bold">
                        <span>OA Benefits Summary PDF</span> :{' '}
                        <span>
                          {subscription?.formal_name ===
                          'Occupational Accident Gold + Contingent Liability Plan'
                            ? 'Occupational Accident Gold Plan'
                            : subscription?.formal_name}
                        </span>
                      </div>
                      <Row className="m-4">
                        <div>
                          <a
                            className="text-white text-decoration-none"
                            href={subscription?.coi_url && subscription?.coi_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              handleOACertificateDownload(subscription)
                            }
                          >
                            <div className="d-flex flex-column align-items-center">
                              <BsDownload
                                size="50"
                                color="#444"
                                className="cursor-pointer"
                              />
                              <div
                                type="submit"
                                className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                              >
                                <strong>DOWNLOAD</strong>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Row>
                    </div>
                  </div>
                ) : null
              )}
            {programs?.subscriptions.some(
              (subscription) =>
                subscription.coverage_type_code_name === 'NT' &&
                subscription.status === 'active'
            ) ? (
              <>
                <div className="col-md-6 mb-4">
                  <div className="details-container">
                    <div className="fs-5 fw-bold">
                      <span>
                        MVR Membership Guidelines for Non-Trucking Liability
                      </span>
                    </div>
                    <Row className="m-4">
                      <div>
                        <a
                          className="text-white text-decoration-none"
                          href={NTDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="d-flex flex-column align-items-center">
                            <BsDownload
                              size="50"
                              color="#444"
                              className="cursor-pointer"
                            />
                            <div
                              type="submit"
                              className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                            >
                              <strong>DOWNLOAD</strong>
                            </div>
                          </div>
                        </a>
                      </div>
                    </Row>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="details-container">
                    <div className="fs-5 fw-bold">
                      <span>
                        Non-Trucking Policy Endorsement
                      </span>
                    </div>
                    <Row className="m-4">
                      <div>
                        <a
                          className="text-white text-decoration-none"
                          href={NTPolicyEndorsement}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="d-flex flex-column align-items-center">
                            <BsDownload
                              size="50"
                              color="#444"
                              className="cursor-pointer"
                            />
                            <div
                              type="submit"
                              className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                            >
                              <strong>DOWNLOAD</strong>
                            </div>
                          </div>
                        </a>
                      </div>
                    </Row>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <br />
          <PersonalDocuments />
        </>
      )}
    </>
  );
}

export default Documents;
