import React, { useState, useEffect } from 'react';
// import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import VehiclePlanSelectionSummaryWithoutAccordion from './VehiclePlanSelectionSummaryWithoutAccordion';

const VehiclePlanSelectionSummary = ({
  saveProfile,
  isMemberDetail,
  isLoggedIn,
  userDetails,
}) => {
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());


  // const [selectedOption, setSelectedOption] = useState('Year');

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth < 991) {
      setIsFirstAccordionOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);

  return (
    <Accordion activeKey={isFirstAccordionOpen ? '0' : ''}>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={toggleFirstAccordion}>
          <div className="m-0">Vehicle Plan Selection Summary</div>
        </Accordion.Header>
        <Accordion.Body>
         <VehiclePlanSelectionSummaryWithoutAccordion
           saveProfile={saveProfile}
           isMemberDetail={isMemberDetail}
           isLoggedIn={isLoggedIn}
           userDetails={userDetails}
         />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default VehiclePlanSelectionSummary;
