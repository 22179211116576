import React, { useState, useContext } from 'react';
import { Context } from '../../../../utils/Context';
import ContractDetails from './Section/ContractDetails/ContractDetails';
import MembershipDetails from './Section/MembershipDetails/MembershipDetails';
import { Row, Col } from 'react-bootstrap';
import Arrowsvg from '../../../../assets/images/Form/Arrow.svg';
import NewGetQuote from '../NewGetQuote';
import { useNavigate } from 'react-router-dom';
import VehiclePlanSelectionSummary from '../ChoosePlan/Section/VehiclePlanSelectionSummary/VehiclePlanSelectionSummary';
import VehicleListing from '../ChoosePlan/Section/VehicleListing/VehicleListing';
import MultiDriver from '../ChoosePlan/Section/AddMultiDriver/MultiDriver';
import { ToastManager } from '../../../../ToastContainer/ToastContainer';
import { postRequest } from '../../../../api/api';
import { RESEND_OTP } from '../../../../api/endpoint';
import { GetGuestUserToken } from '../guestUserTokenUtils';
import UpdateMissingVIN from './VinModal/UpdateMissingVIN';

const MemberInformation = ({ saveProfile }) => {
  const navigate = useNavigate();
  // Use state to control the visibility of the ContractDetails component
  const [showContractDetails, setShowContractDetails] = useState(false);
  const [showUpdateVINSection, setShowUpdateVINSection] = useState(false);
  const [password, Setpassword] = useState();
  const { profileObject } = useContext(Context);

  const handleSendOTP = async () => {
    const guestUserToken = GetGuestUserToken();
    const headers = {};
    headers.Authorization = guestUserToken;
    if (guestUserToken) {
      headers.Authorization = guestUserToken;
    }
    const sendOtp = {
      user: profileObject,
    };

    const { result } = await postRequest(RESEND_OTP, sendOtp, headers);
    if (result?.message) {
      ToastManager.success(
        'An email has been sent to your email address. Kindly verify your email to proceed with registration.'
      );
    } else {
      ToastManager.error('OTP not sent, Please try again');
    }
  };

  const handleBackToMembership = () => {
    setShowContractDetails(false); // Hide ContractDetails
    setShowUpdateVINSection(false);
  };

  const handleBack = () => {
    navigate('/quote/choose-plan#NT');
  };

  return (
    <>
      <div className="container-fluid mt-md-0 mt-3 plr-0-sm">
        <Row>
          <div className="d-flex">
            <span
              className="back-btn-style cursor-pointer"
              onClick={handleBack}
            >
              <img src={Arrowsvg} alt="arrow" />
              &nbsp;&nbsp;
              <span className="mt-5 text-blue fs-6">Back to Choose Plan</span>
            </span>
          </div>
          <Col lg={8} className="my-md-3 my-3 ">
            {showUpdateVINSection ? (
              <UpdateMissingVIN
                onBackButtonClick={handleBackToMembership}
                saveProfile={saveProfile}
                setShowContractDetails={setShowContractDetails}
                setShowUpdateVINSection={setShowUpdateVINSection}
                handleSendOTP={handleSendOTP}
              />
            ) : showContractDetails ? (
              <ContractDetails
                password={password}
                setShowContractDetails={setShowContractDetails}
                onBackButtonClick={handleBackToMembership}
              />
            ) : (
              <MembershipDetails
                saveProfile={saveProfile}
                Setpassword={Setpassword}
                setShowUpdateVINSection={setShowUpdateVINSection}
              />
            )}
          </Col>
          <Col lg={4} className="my-md-3 my-2 ">
            <VehicleListing isMemberArea={true} />
            <MultiDriver isMemberArea={true} saveProfile={saveProfile} />
            <VehiclePlanSelectionSummary isMemberDetail={true} />
            <NewGetQuote />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MemberInformation;
