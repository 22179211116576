import React from 'react';
import { Link } from 'react-router-dom';
import './contactUs.scss';
import FAQ from '../../../assets/images/Form/FAQ.svg';
import SupportSvg from '../../../assets/images/Form/Support.svg';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';

const ContactUs = () => {
  return (
    <>
      <Breadcrumb title={'Contact Page'} />
        <div className="container">
      <div className="contact_inner">
          <h1>Welcome! How can we help you today?</h1>
          <div className="categories">
            <Link to={'/contact/faq'} className="category text-decoration-none">
              <span className="icon">
                <img src={FAQ} alt="password" />
              </span>
              <span className="title">FAQs</span>
              <span className="subtitle">
                Frequently asked questions and answers
              </span>
            </Link>

            <Link
              to={'/contact/support'}
              className="category text-decoration-none"
            >
              <span className="img">
                <img src={SupportSvg} alt="password" />
              </span>
              <span className="title">Support</span>
              <span className="subtitle">
                Contact us for more detailed support
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
