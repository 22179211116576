import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';
import '../policy.scss';
import { UserContext } from '../../../../../../utils/Context';

export const RenewPolicy = () => {
  const { userDetails } = useContext(UserContext);
  return (
    <div>
      {' '}
      <Row className="bg-danger p-2 bg-opacity-10 border-box">
        <span className="fw-bold">
          Renew Your Policy to Safeguard Your Vehicle:
        </span>{' '}
        <span className="text-danger fw-semibold">
          Your Vehicle Protection Has Expired. Ensure uninterrupted coverage
          against damage, theft, and more by renewing your policy today.
        </span>
      </Row>
      <div className="d-flex justify-content-center mt-4 mb-3">
        <a
          href={userDetails?.programs[0]?.revise_payment_url}
          className="btn btn-sm silo-btn silo-btn__white text-center"
          rel="noopener noreferrer"
        >
          <strong>Renew Policy</strong>
        </a>
      </div>
    </div>
  );
};
