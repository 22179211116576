import React from 'react';
import ModalContainer from './ModalContainer';

const DeleteModal = ({ show, handleClose, handleDelete, content }) => {
  return (
    <ModalContainer
      size="md silo_modal"
      show={show}
      handleClose={handleClose}
      title="Are you sure?"
      content={
        <div className="text-center">
          <div className="my-4 trash-icon">❌</div>
          <p>{content}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <button className="btn silo-btn-small mr-2 " onClick={handleClose}>
              Cancel
            </button>
            <br />
            <button
              className="btn silo-btn-small silo-btn__blue ml-20"
              onClick={handleDelete}
            >
              Okay
            </button>
          </div>
        </div>
      }
    />
  );
};
export default DeleteModal;
