import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './account.scss';
import Sidebar from './Sidebar/Sidebar';
import Display from './Display';
import { TfiMenu } from 'react-icons/tfi';
import { UserContext } from '../../../../utils/Context';
const Account = () => {
  const [menu, setMenu] = useState(window.innerWidth >= 1030); // Set initial menu state based on screen width
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1030);

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const { userDetails } = useContext(UserContext);

  // const CustomToggle = ({ onClick }) => (
  //   <MdNotifications
  //     onClick={(e) => {
  //       setMenu(false);
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //     size={30}
  //   />
  // );

  useEffect(() => {
    if (userDetails?.id) {
      // Hotjar Tracking Code
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3809789, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      // Hotjar User Identification and Custom Attributes
      window.hj('identify', userDetails.id, {
        'Signed up': userDetails.created_at,
        'HasFullyPaid': userDetails?.programs[0]?.is_fully_paid,
        'DeviceType:': isMobile ? 'mobile' : 'desktop',
        'NumberOfVehicles': userDetails?.vehicle_details.length,
        'UserDOB': userDetails?.date_of_birth,
        'UserName': userDetails?.first_name,
        'NumberOfDrivers': userDetails?.number_of_drivers,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.id]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;

      setIsMobile(newWidth < 1030);

      if (newWidth > 1030) {
        setMenu(true); // Set menu to true immediately when switching to mobile screen
      }
    };

    window.addEventListener('resize', handleResize);
    window.userId = userDetails?.id;

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid className="min-vh-100">
      {menu && <div className="display-screen"></div>}
      {isMobile && (
        <div className="mb-5 menu cursor-pointer" onClick={toggleMenu}>
          <TfiMenu size={30} />
        </div>
      )}
      {/*<div className="mb-5 notification cursor-pointer">
        <Dropdown className="cursor-pointer notification-icon backgroundBlue">
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="lg"
            title=""
            className="notification-content backgroundLightBlue"
          >
            <Dropdown.Item className="notification-title backgroundBlue ">
              <span className="fw-bold text-white d-flex justify-content-between align-items-center">
                Notification
                <MdPlaylistAddCheck size={30} />
              </span>
            </Dropdown.Item>
            <hr />
            <span className="activity fw-bold text-blue">
              See all recent Activity
            </span>
          </Dropdown.Menu>
        </Dropdown>
      </div>*/}
      <Row>
        {menu ? (
          <>
            <Col
              md={2}
              xs={4}
              className="sidebar-col1 bg-white vh-100 position-absolute"
            >
              <Sidebar toggleMenu={isMobile ? toggleMenu : undefined} />
            </Col>

            <Col md={2} xs={4} className="sidebar-col2 bg-white"></Col>
          </>
        ) : (
          ''
        )}
        <Col className="col">
          <Display Menu={menu} />
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
