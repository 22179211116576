import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './billingDetails.scss';
// import { Link } from 'react-router-dom';
import { getVehicleDetails } from '../../../../../../../utils/Commonutils';
import { UserContext } from '../../../../../../../utils/Context';
import VehiclePlanSelectionSummaryWithoutAccordion from '../../../../../GetQuote/ChoosePlan/Section/VehiclePlanSelectionSummary/VehiclePlanSelectionSummaryWithoutAccordion';

const BillingDetails = () => {
  const { userDetails, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    getVehicleDetails(setUserDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>
        <b>Billing Details</b>
      </h3>
      <hr />
      <Row>
        <Col md={6}>
          <div className="details-container mb-4">
            <div className="silo_page_section-heading">
              <h5 className="m-0 fw-bold">Address</h5>
              

              <div className="row mt-4">
                <div className="col-md-6">
                  <p>Address:</p>
                  <h6>{userDetails?.billing_address?.address_line_1}</h6>
                </div>
                <div className="col-md-6">
                  <p>City:</p>
                  <h6>{userDetails?.billing_address?.city?.name}</h6>
                </div>
                <div className="col-md-6">
                  <p>State:</p>
                  <h6>{userDetails?.billing_address?.state?.name}</h6>
                </div>
                <div className="col-md-6">
                  <p>Zip Code:</p>
                  <h6>{userDetails?.billing_address?.zip5}</h6>
                </div>
              </div>

            </div>
            {/* <Link to="edit">
            <button className="mx-auto btn silo-btn-small silo-btn__blue card-btn">
              Edit
            </button>
          </Link> */}
          </div>
        </Col>
        <Col lg={6}>
          <VehiclePlanSelectionSummaryWithoutAccordion
          isLoggedIn={true}
          userDetails={userDetails}
          />
        </Col>
      </Row>
    </>
  );
};

export default BillingDetails;
