import React from 'react';
import './heading.scss';
import dots from '../../../../../../../assets/images/Home/dots.png';
import ScrollToTopLink from '../../../../../../ScrollToTop/ScrollToTop';

const Heading = () => {
  return (
    <div className="col">
      <div className="silo_page_section-heading p-5">
        <div className="design_element1">
          <img src={dots} alt="dots" />
        </div>
        <small>SILO MEMBERS</small>
        <h1 className='fw-bold mt-2 font-28-sm'>
          Insurance As Independent&nbsp;
          <br />
          As You®
        </h1>
        <p>
          Independent Contractor Drivers are the heart of SILO Association. From
          long haul trucking to last mile, we are serving professional drivers.
        </p>
        <ScrollToTopLink to="/quote/vehicle-details">
            <span className="btn silo-btn silo-btn__blue my-5 mb-0-sm-imp mt-20-sm-imp">Get Started</span>
          </ScrollToTopLink>
      </div>
    </div>
  );
};

export default Heading;
