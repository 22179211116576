import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalContainer = ({
  show,
  handleClose,
  title,
  content,
  size,
  customClassName = '',
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size={size}
        className={`silo_modal ${customClassName}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {size === 'md' ? (
              <h3 className="">{title}</h3>
            ) : (
              <h4 className="">{title}</h4>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-6 text-muted">{content}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalContainer;
