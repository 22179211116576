import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import OccupationalAccident from '../../Section/OccupationalAccident/OccupationalAccident';
import PassengerAccident from '../../Section/PassengerAccident/PassengerAccident';
import { getRequest } from '../../../../../../api/api';
import { GetGuestUserToken } from '../../../guestUserTokenUtils';
import { GET_POLICY_DETAILS } from '../../../../../../api/endpoint';

const OAPA = ({
  setPaPolicyData,
  paPolicyData,
  setPolicyData,
  policyData,
  toggleFirstAccordion,
  openSecondAccordion,
  saveProfile,
  getSubscriptionFor,
  handleTabChange,
  activeTab,
  setActiveTab,
  handleTabChangeNTCGPD,
  handleModalShow,
  modalOpen,
  setModalOpen,
  isDirectCheckout,
  setCurrentAccordion,
}) => {
  const [prevActiveTab, setPrevActiveTab] = useState('occupationalAccident');
  const [PApolicyListingRepsonse, setPAPolicyListingRepsonse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [OApolicyListingRepsonse, setOAPolicyListingRepsonse] = useState(null);

  const guestUserToken = GetGuestUserToken();

  useEffect(() => {
    if (activeTab === 'occupationalAccident') {
      OAPolicyDetailsListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OAPolicyDetailsListing = async () => {
    setIsLoading(true);
    try {
      const coverage_type = {
        coverage_type: 'oa',
      };
      const headers = {
        Authorization: guestUserToken,
      };
      const response = await getRequest(
        GET_POLICY_DETAILS,
        coverage_type,
        headers
      );
      setOAPolicyListingRepsonse(response.result);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const PAPolicyDetailsListing = async () => {
    setIsLoading(true);
    try {
      const coverage_type = {
        coverage_type: 'pa',
      };
      const headers = {
        Authorization: guestUserToken,
      };
      const response = await getRequest(
        GET_POLICY_DETAILS,
        coverage_type,
        headers
      );
      setPAPolicyListingRepsonse(response.result);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      prevActiveTab === 'occupationalAccident' &&
      activeTab === 'passengerAccident'
    ) {
      OAPolicyDetailsListing().then(() => {
        PAPolicyDetailsListing();
      });
    } else {
      OAPolicyDetailsListing();
    }
    setPrevActiveTab(activeTab);
    setCurrentAccordion('Accordion1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, prevActiveTab]);

  useEffect(() => {
    if (PApolicyListingRepsonse) {
      const paPolicyData = PApolicyListingRepsonse.map((item) => {
        return {
          ...item,
          label: item.plan_type.name,
          type: 'radio',
          name: 'policy',
        };
      });

      setPaPolicyData(paPolicyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PApolicyListingRepsonse]);

  useEffect(() => {
    if (OApolicyListingRepsonse) {
      const policyData = OApolicyListingRepsonse.map((item) => {
        return {
          ...item,
          label: item.plan_type.name,
          type: 'radio',
          name: 'policy',
        };
      });

      setPolicyData(policyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OApolicyListingRepsonse]);

  return (
    <div>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={toggleFirstAccordion}>
          Choose Occupational Accident & Passenger Accident Coverage Types :
        </Accordion.Header>
        <Accordion.Body>
          {' '}
          <Tab.Container activeKey={activeTab}>
            <Nav variant="pills" className="">
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="occupationalAccident"
                  onClick={() => handleTabChange('occupationalAccident')}
                >
                  Occupational Accident
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="passengerAccident"
                  onClick={() => handleTabChange('passengerAccident')}
                  // disabled
                >
                  Passenger Accident
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="occupationalAccident">
                <OccupationalAccident
                  policyData={policyData}
                  handleTabChange={setActiveTab}
                  saveProfile={saveProfile}
                  OApolicyListingRepsonse={OApolicyListingRepsonse}
                  getSubscriptionFor={getSubscriptionFor}
                  OAPolicyDetailsListing={OAPolicyDetailsListing}
                  PAPolicyDetailsListing={PAPolicyDetailsListing}
                  isLoading={isLoading}
                  handleModalShow={handleModalShow}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  isDirectCheckout={isDirectCheckout}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="passengerAccident">
                <PassengerAccident
                  paPolicyData={paPolicyData}
                  setPaPolicyData={setPaPolicyData}
                  handleTabChange={setActiveTab}
                  openSecondAccordion={openSecondAccordion}
                  saveProfile={saveProfile}
                  getSubscriptionFor={getSubscriptionFor}
                  PAPolicyDetailsListing={PAPolicyDetailsListing}
                  isLoading={isLoading}
                  handleTabChangeNTCGPD={handleTabChangeNTCGPD}
                  handleModalShow={handleModalShow}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  isDirectCheckout={isDirectCheckout}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

export default OAPA;
