import React, { useContext, useState } from 'react';
import { Context } from '../../../../../../utils/Context';
import Arrowsvg from '../../../../../../assets/images/Form/Arrow.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './nonMandatoryVehicleDetails.scss';
import VehicleSafety from '../VehicleSafety/VehicleSafety';
import ELDProvider from '../ELDProvider/ELDProvider';
import Value from '../../Section/Value/Value';
import ScrollToTopLink from '../../../../../ScrollToTop/ScrollToTop';
import { Spinner } from 'react-bootstrap';
import LienHolder from '../LienHolder/LienHolder';

const NonMandatoryVehicleDetails = ({
  setShowSection1,
  setShowSection2,
  setshowDotInfo,
  vehicleIndex,
  saveProfile,
}) => {
  const { vehicles } = useContext(Context);
  const [buttonLoading, setButtonLoading] = useState(false);

  let category_type = vehicles[vehicleIndex]?.vehicle_type?.category_type;
  if (category_type === 'non power unit') {
    category_type = true;
  } else {
    category_type = false;
  }
  const handleSave = () => {
    setButtonLoading(true);
    const vehicleSafetyIds = vehicles[
      vehicleIndex
    ]?.vehicle_safety_systems?.map((safetyItem) => safetyItem.id);
    const data = {
      vehicle_details_attributes: [
        {
          eld_provider_id: vehicles[vehicleIndex]?.eld_provider?.id,
          declared_value: vehicles[vehicleIndex]?.declared_value,
          vehicle_safety_system_ids: vehicleSafetyIds,
          lienholder_1_id: vehicles[vehicleIndex]?.lienholder_1_id,
          lienholder_2_id: vehicles[vehicleIndex]?.lienholder_2_id,
        },
      ],
    };
    saveProfile(data, vehicleIndex).then((success) => {
      if (success) {
        setshowDotInfo(true);
        setShowSection2(false);
      }
      setButtonLoading(false);
    });
  };
  // const handleSkip = () => {
  //   setshowDotInfo(true);
  //   setShowSection2(false);
  // };
  const handleBack = () => {
    setShowSection1(true);
    setShowSection2(false);
  };
  return (
    <div className="text-start">
      <div className="py-3">
        <div className="text-start">
          <span className="cursor-pointer" onClick={handleBack}>
            <img src={Arrowsvg} alt="arrow" />
            &nbsp;&nbsp;
            <span className="mt-5 text-blue fs-6">Back</span>
          </span>{' '}
        </div>
        {/* <Col md={6} className="text-end">
          <Button className="skip-button" onClick={handleSkip}>
            Skip
          </Button>{' '}
        </Col> */}
      </div>
      <Form className="user-detail-form-blue">
        {!category_type && (
          <Row className="">
            <Form.Group as={Col} md={6} controlId="formGridSafety">
              {/* Vehicle Safety Dropdown */}
              <VehicleSafety vehicleIndex={vehicleIndex} />
            </Form.Group>

            <Form.Group
              as={Col}
              md={6}
              controlId="formGridELD"
              className="mb-3"
            >
              {/* ELD Provider Dropdown */}
              <ELDProvider vehicleIndex={vehicleIndex} />
            </Form.Group>
          </Row>
        )}

        <Row>
          <Form.Group as={Col} md={6} controlId="formGridValue">
            <Value vehicleIndex={vehicleIndex} />
          </Form.Group>

          <Form.Group as={Col} md={6} controlId="formGridELD" className="mb-3">
            <LienHolder
              vehicleIndex={vehicleIndex}
              holderId={1}
              saveProfile={saveProfile}
            />
          </Form.Group>
        </Row>
        {vehicles[vehicleIndex]?.lienholder_1_id ||
        vehicles[vehicleIndex]?.lienholder_2_id ? (
          <Row>
            <Form.Group
              as={Col}
              md={6}
              controlId="formGridELD"
              className="mb-3"
            >
              <LienHolder
                vehicleIndex={vehicleIndex}
                holderId={2}
                saveProfile={saveProfile}
              />
            </Form.Group>
          </Row>
        ) : null}
        <span className="navigation_bottom">
          <ScrollToTopLink>
            <Button
              className="btn silo-btn-small silo-btn__blue my-3"
              onClick={handleSave}
            >
              {buttonLoading ? <Spinner size="sm" /> : <>Next</>}
            </Button>
          </ScrollToTopLink>
        </span>
      </Form>
    </div>
  );
};

export default NonMandatoryVehicleDetails;
