import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import AutoCompleteAddress from '../../../../../../Shared/AutoCompleteAddress/AutoCompleteAddress';

const EditAddress = ({ addressData, setAddressData, addressfieldErrors, setAddressFieldErrors, handleChange, handleCityChange, handleStateChange, stateOption, setStateOption, cityOption, setCityOption, handleAutoCompleteSelect, selectCityError, setSelectCityError, selectStateError, setSelectStateError }) => {

  const addressFields = [
    {
      label: 'Address',
      id: 'address',
      name: 'address',
      value: '',
      type: 'text',
    },
    {
      label: 'Zip',
      id: 'zip',
      name: 'zip',
      value: '',
      type: 'number',
    },
    {
      label: 'State',
      id: 'state',
      name: 'state',
      value: '',
      type: 'text',
    },
    {
      label: 'City',
      id: 'city',
      name: 'city',
      value: '',
      type: 'text',
    },
  ];

  return (
    <div className="user-detail-form-blue mt-5 silo_page_section-heading">
      <h4 className="fw-bold">Edit Address</h4>
      <p>Home and Garaging Address</p>
      <div className="row mt-2">
        <div className="col-md-12">
          <AutoCompleteAddress onAddressSelect={handleAutoCompleteSelect} />
        </div>
        {addressFields.map((field) => (
          <div className="col-md-6">
            <Form.Group controlId={field.id} className="d-flex flex-column">
              <Form.Label className="fw-bold mt-2">
                {field.label}
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              {field.name === 'state' ? (
                <>
                  <Select
                    className={`custom-select w-100 ${selectStateError ? 'is-invalid' : ''
                      }`}
                    name={field.name}
                    value={stateOption.find(
                      (option) => option.value === addressData[field.name]
                    )}
                    onChange={(selectedOption) => {
                      handleStateChange(selectedOption);
                      setSelectStateError('');
                    }}
                    options={stateOption}
                    placeholder={
                      <div className="select-placeholder-text text-start">
                        Select State
                      </div>
                    }
                  />
                  {selectStateError && (
                    <div className="text-danger">{selectStateError}</div>
                  )}
                </>
              ) : field.name === 'city' ? (
                <>
                  <Select
                    className={`custom-select w-100 ${selectCityError ? 'is-invalid' : ''
                      }`}
                    name={field.name}
                    value={cityOption.find(
                      (option) => option.value === addressData[field.name]
                    )}
                    onChange={(selectedOption) => {
                      handleCityChange(selectedOption);
                      setSelectCityError('');
                    }}
                    options={cityOption}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select City
                      </div>
                    }
                  />
                  {selectCityError && (
                    <div className="text-danger">{selectCityError}</div>
                  )}
                </>
              ) : (
                <>
                  <Form.Control
                    type={field.type}
                    name={field.name}
                    min='0'
                    value={addressData[field.name]}
                    onChange={handleChange}
                    className={`${addressfieldErrors[field.name] ? 'is-invalid' : ''
                      }`}
                  />
                  {addressfieldErrors[field.name] && (
                    <span className="text-danger">
                      {addressfieldErrors[field.name]}
                    </span>
                  )}
                </>
              )}
            </Form.Group>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditAddress;
