import React from 'react';
import { useState } from 'react';
import { Context } from './Context';
export const ContextProvider = ({ children }) => {
  const [vehicles, setVehicles] = useState([{}]);
  const [profileObject, setProfileObject] = useState();
  const [disableState, setDisableState] = useState(false);
  const [manualSelectAccess, setManualSelectAccess] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [docuSignDone, setDocuSignDone] = useState();
  const [userId, setUserId] = useState();
  const [copyHomeToBilling, setCopyHomeToBilling] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [directCheckout, setDirectCheckout] = useState(false);

  return (
    <Context.Provider
      value={{
        vehicles,
        setVehicles,
        profileObject,
        setProfileObject,
        disableState,
        setDisableState,
        manualSelectAccess,
        setManualSelectAccess,
        userEmail,
        setUserEmail,
        userId,
        setUserId,
        docuSignDone,
        setDocuSignDone,
        copyHomeToBilling,
        setCopyHomeToBilling,
        modalValue,
        setModalValue,
        directCheckout,
        setDirectCheckout,
      }}
    >
      {children}
    </Context.Provider>
  );
};
