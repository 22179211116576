import { GetGuestUserToken } from '../components/pages/GetQuote/guestUserTokenUtils.jsx';

export async function getRequest(url, params = {}, headers = {}) {
  try {
    const guestUserToken = GetGuestUserToken();
    if (guestUserToken != null) {
      headers.Authorization = guestUserToken;
    }
    params = Object.keys(params)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
    if (params) url += `?${params}`;
    let response = await fetch(url, {
      method: 'GET',
      headers: Object.assign({ Accept: 'application/json' }, headers),
    });

    if (response.status === 200) {
      let result = await response.json();
      return { result };
    } else if (response.status === 422 || response.status === 404) {
      let result = await response.json();
      return { result };
    } else if (response.status === 500) {
      return { error: 'Internal Server Error' };
    } else {
      throw new Error(
        `Error with status ${response.status}, url: ${response.url}`
      );
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function postRequest(url, data = {}, headers = {}, params = {}) {
  try {
    params = Object.keys(params)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
    if (params) url += `?${params}`;
    let response = await fetch(url, {
      method: 'POST',
      headers: Object.assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        headers
      ),
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      let result = await response.json();
      return { result, bearerToken: response.headers.get('Authorization') };
    } else if (response.status === 201) {
      let result = await response.json();
      return { result, bearerToken: response.headers.get('Authorization') };
    } else if (response.status === 422) {
      let result = await response.json();
      return { result };
    } else {
      let result = await response.json();
      return { result };
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function deleteRequest(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: 'DELETE',
      headers: Object.assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        headers
      ),
      body: JSON.stringify(data),
    });
    if (response) {
      const result = await response.json();
      return { result };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function putRequest(url, params = {}, data = {}, headers = {}) {
  try {
    const { id, ...otherParams } = params;
    if(id){
      url += `/${id}.json`;
    }
    const queryString = Object.keys(otherParams)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(otherParams[key])}`;
      })
      .join('&');

    if (queryString) {
      // Append the query string to the URL if there are other parameters
      url += `?${queryString}`;
    }
    let response = await fetch(url, {
      method: 'PUT',
      headers: Object.assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        headers
      ),
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      let result = await response.json();
      return { result, bearerToken: response.headers.get('Authorization') };
    } else if (response.status === 201) {
      let result = await response.json();
      return { result, bearerToken: response.headers.get('Authorization') };
    } else if (response.status === 422) {
      let result = await response.json();
      return { result };
    } else {
      let result = await response.json();
      return { result };
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function putRequestUser(url, params = {}, data = {}, headers = {}) {
  try {
    url += `?confirm_password=${params.confirm_password}&email=${params.email}&password=${params.password}`;
    let response = await fetch(url, {
      method: 'PUT',
      headers: Object.assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        headers
      ),
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      let result = await response.json();
      return { result, bearerToken: response.headers.get('Authorization') };
    } else if (response.status === 201) {
      let result = await response.json();
      return { result, bearerToken: response.headers.get('Authorization') };
    } else if (response.status === 422) {
      let result = await response.json();
      return { result };
    } else {
      let result = await response.json();
      return { result };
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

