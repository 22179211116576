import React, { useMemo, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './year.scss';
import Select from 'react-select';

const Year = ({ yearError, setYearError, vehicleIndex }) => {
  const { vehicles, setVehicles, disableState } = useContext(Context);

  const currentYear = new Date().getFullYear();

  const [minYear, maxYear] = useMemo(() => {
    return [
      currentYear - 100, // Last 100 years
      currentYear + 2, // Next 2 years
    ];
  }, [currentYear]);

  useEffect(() => {
    if (vehicles[vehicleIndex]?.vehicle_model !== '') {
    }
  }, [vehicles, vehicleIndex]);

  const handleYearChange = (selectedOption) => {
    if (selectedOption) {
      const selectedYearIs = selectedOption.value;
      // Create a copy of the current vehicle
      const updatedVehicle = { ...vehicles[vehicleIndex] };

      // Update the vehicle's body_type with the selected option
      updatedVehicle.declared_year = selectedYearIs;

      // Create a copy of the current vehicles array
      const updatedVehicles = [...vehicles];

      // Update the vehicle at the specified index with the modified vehicle
      updatedVehicles[vehicleIndex] = updatedVehicle;

      // Set the updated vehicles array
      setVehicles(updatedVehicles);

      if (yearError) {
        setYearError(null);
      }
    }
  };

  const yearOptions = Array.from(
    { length: maxYear - minYear + 1 },
    (_, index) => ({
      value: minYear + index,
      label: `${minYear + index}`, // Convert to string if needed
    })
  ).reverse();

  return (
    <div>
      <Form.Label className="fw-bold">
        Vehicle Year<span className="text-danger">&nbsp;*</span>
      </Form.Label>
      <br />
      {/* <DatePicker
        selected={
          vehicles[vehicleIndex]?.declared_year
            ? new Date(`${vehicles[vehicleIndex]?.declared_year}-01-01`)
            : null
        }
        disabled={disableState}
        onChange={handleYearChange}
        showYearPicker
        dateFormat="yyyy"
        className={`custom-year-datepicker ${yearError ? 'is-invalid' : ''} `}
        isClearable = {!disableState}
        placeholderText="Choose Your Vehicle Year"
        minDate={new Date(`${minYear}`)}
        maxDate={new Date(`${maxYear}`)}
      /> */}
      <Select
        value={
          vehicles[vehicleIndex]?.declared_year
            ? {
                label: `${vehicles[vehicleIndex]?.declared_year}`,
                value: `${vehicles[vehicleIndex]?.declared_year}`,
              }
            : null
        }
        isDisabled={disableState}
        onChange={handleYearChange}
        options={yearOptions}
        className={`custom-select ${yearError ? 'is-invalid' : ''} `}
        placeholder="Choose Your Vehicle Year"
      />
      {yearError && <div className="text-danger">{yearError}</div>}
    </div>
  );
};

export default Year;
