import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import { Button, InputGroup, Spinner } from 'react-bootstrap';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import Arrowsvg from '../../../../../assets/images/Form/Arrow.svg';
import { ToastManager } from '../../../../../ToastContainer/ToastContainer';
import { getRequest } from '../../../../../api/api';
import { VERIFY_VIN } from '../../../../../api/endpoint';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../Shared/ModalContainer/ModalContainer';
import { IoIosWarning } from 'react-icons/io';

function UpdateMissingVIN({
  onBackButtonClick,
  saveProfile,
  setShowContractDetails,
  setShowUpdateVINSection,
  handleSendOTP,
}) {
  const [restrictedSubscription, setRestrictedSubscription] = useState(false);
  const [vinNumbers, setVinNumbers] = useState([]);
  const [loadingArr, setLoadingArr] = useState([]);
  const [vinWarnings, setVinWarnings] = useState([]);
  const [vinErrors, setVinErrors] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [updatedVehicleDetails, setUpdatedVehicleDetails] = useState();
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState({});
  const [vehicleDetailsList, setVehicleDetailsList] = useState();
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const { vehicles, profileObject, setVehicles } = useContext(Context);

  const navigate = useNavigate();

  const getSubscriptionFor = (code_name) => {
    let subscription = {};
    if (profileObject?.programs[0]?.subscriptions) {
      profileObject.programs[0].subscriptions.forEach((subscriptionObj) => {
        if (subscriptionObj.coverage_type_code_name === code_name) {
          subscription = subscriptionObj;
        }
      });
    }
    return subscription;
  };

  const checkSubscription = () => {
    const subscriptionTypes = ['CG', 'NT', 'PD'];
    setRestrictedSubscription(
      subscriptionTypes.some(
        (type) => Object.keys(getSubscriptionFor(type)).length !== 0
      )
    );
  };

  useEffect(() => {
    checkSubscription();
    getMissingVinVehiclesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject]);

  useEffect(() => {
    if (showCountdown) {
      const timer = setInterval(() => {
        if (countdown === 1) {
          clearInterval(timer); // Stop the countdown when it reaches 1
          navigate('/quote/choose-plan#OAPA');
        } else {
          setCountdown(countdown - 1); // Decrease the countdown by 1 second
        }
      }, 1000);

      return () => {
        clearInterval(timer); // Cleanup the timer when the component unmounts
      };
    } else {
      if (
        filteredData &&
        !(filteredData.length > 0 && restrictedSubscription)
      ) {
        window.scrollTo(0, 0);
        if (!profileObject?.is_verified) {
          setShowContractDetails(true);
          setShowUpdateVINSection(false);
          handleSendOTP();
        } else {
          window.scrollTo(0, 0);
          navigate('/quote/payment');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countdown,
    navigate,
    showCountdown,
    filteredData,
    restrictedSubscription,
  ]);

  const getMissingVinVehiclesData = () => {
    let missingVinVehicles = [];
    let loadingVehicles = [];
    let vinNumbersArr = [];
    vehicles.forEach((vehicle) => {
      if (vehicle.vin_number === '' || !vehicle.vin_number) {
        missingVinVehicles.push({
          id: vehicle.id,
          vehicle_make: vehicle.vehicle_make,
          vehicle_model: vehicle.vehicle_model,
          declared_year: vehicle.declared_year,
        });
        loadingVehicles.push(false);
        vinNumbersArr.push('');
      }
    });
    setLoadingArr(loadingVehicles);
    setFilteredData(missingVinVehicles);
    setVinNumbers(vinNumbersArr);
  };

  const updateLoaderArray = (value, index) => {
    const newArray = [...loadingArr];
    newArray[index] = value;
    setLoadingArr(newArray);
  };

  const updateVinNumber = async () => {
    const { item, index } = selectedVehicleDetails;
    setConfirmBtnLoading(true);
    const vehicleIndex = vehicles.findIndex(
      (vehicle) => vehicle.id === item.id
    );
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    updatedVehicle.vin_number = vinNumbers[index];
    updatedVehicle.with_vin = true;
    const updatedVehicles = [...vehicles];
    updatedVehicles[vehicleIndex] = updatedVehicle;
    setVehicles(updatedVehicles);
    const data = {
      vehicle_details_attributes: updatedVehicles,
    };

    const success = await saveProfile(data, vehicleIndex, true);
    if (!success) {
      const updatedVehicle = { ...vehicles[vehicleIndex] };
      updatedVehicle.vin_number = null;
      const updatedVehicles = [...vehicles];
      updatedVehicles[vehicleIndex] = updatedVehicle;
      setVehicles(updatedVehicles);
    } else {
      //check if total amount is 0 then navigate to choose plan
      setShowModal(false);
    }
    setConfirmBtnLoading(false);
  };

  useEffect(() => {
    if (profileObject.programs[0].grand_total === 0) {
      setShowCountdown(true);
    }
  }, [profileObject]);

  const handleOnChange = (e, index) => {
    const newVinNumbers = [...vinNumbers];
    newVinNumbers[index] = e.target.value;
    setVinNumbers(newVinNumbers);
  };

  const verifyVin = async (item, index) => {
    setVinWarnings([]);
    setVinErrors([]);
    updateLoaderArray(true, index);
    if (vinNumbers[index].length > 0) {
      try {
        const vin_number = {
          vin_number: vinNumbers[index],
          vehicle_detail: item.id,
        };
        const response = await getRequest(VERIFY_VIN, vin_number);

        // Update warning and error messages based on the index
        const newVinWarnings = [...vinWarnings];
        const newVinErrors = [...vinErrors];
        if (response.result?.success) {
          setUpdatedVehicleDetails(response?.result?.vehicle_details);
          setShowModal(true);
          updateLoaderArray(false, index);
          setSelectedVehicleDetails({
            item: item,
            index: index,
          });
          fillVehicleDetails(response?.result?.vehicle_details);
          if (response?.result?.warning_message) {
            newVinWarnings[index] = response.result.warning_message;
            setVinWarnings(newVinWarnings);
          }
          return false;
        }
        if (response?.result?.warning_message) {
          newVinWarnings[index] = response.result.warning_message;
          setVinWarnings(newVinWarnings);
        } else {
          newVinWarnings[index] = null;
          updateLoaderArray(false, index);
        }

        if (response?.result?.message) {
          newVinErrors[index] = response.result.message;
          setVinErrors(newVinErrors);
        } else {
          newVinErrors[index] = null;
        }
      } catch (error) {
        console.log('API error:', error);
      }
    } else {
      ToastManager.error('VIN cannot be empty!');
    }
  };

  const fillVehicleDetails = (data) => {
    const createTableData = [
      {
        key: 'Body Type',
        value: data?.body_type?.name,
      },
      {
        key: 'Year',
        value: data?.declared_year,
      },
      {
        key: 'Vehicle Type',
        value: data?.vehicle_type?.name,
      },
      {
        key: 'Make',
        value: data?.make?.name,
      },
      {
        key: 'Model',
        value: data?.model?.name,
      },
    ];
    setVehicleDetailsList(createTableData);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="container current-container">
        <h3 className="text-center">
          <b>Enter your VIN Number to Proceed</b>
        </h3>
        <hr />
        <div>
          <div className="text-center silo_page_section-heading">
            <span className="text-left d-block">
              <span className="cursor-pointer" onClick={onBackButtonClick}>
                <img src={Arrowsvg} alt="arrow" />
                &nbsp;&nbsp;
                <span className="mt-5 text-blue  fs-6">Back</span>
              </span>
            </span>
            <br />
            <div>
              {showCountdown ? (
                <div className="logout_child">
                  <h3>Total amount cannot be zero</h3>
                  <p>
                    Redirecting you back to choose plan in{' '}
                    <b className="countdown-text">{countdown}</b> seconds
                  </p>
                </div>
              ) : (
                <Form className="user-detail-form-blue">
                  {filteredData &&
                    filteredData.length > 0 &&
                    filteredData.map((item, key) => {
                      return (
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicVIN"
                          key={key}
                        >
                          <Form.Label className="fw-bold vin-label text-muted fs-6">
                            {item?.vehicle_make?.name + ' '}
                            {item?.vehicle_model
                              ? item?.vehicle_model?.name + ' '
                              : ''}
                            {item?.declared_year ? item?.declared_year : ''}
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter VIN Number"
                              autoComplete="false"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  updateVinNumber();
                                }
                              }}
                              onChange={(e) => handleOnChange(e, key)}
                              value={vinNumbers[key]}
                              // onBlur={(e) => verifyVin(e, key)}
                              maxLength={17}
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                              // onClick={() => updateVinNumber(item, key)}
                              onClick={() => verifyVin(item, key)}
                              className="vin-field-save-btn"
                            >
                              {loadingArr[key] ? (
                                <Spinner size="sm" />
                              ) : (
                                <>Save</>
                              )}
                            </Button>
                          </InputGroup>
                          {vinWarnings[key] && (
                            <div
                              className="alert alert-warning mt-2"
                              role="alert"
                            >
                              <strong>Warning: </strong>
                              {vinWarnings[key]}
                            </div>
                          )}
                          {vinErrors[key] && (
                            <div
                              className="alert alert-danger mt-2"
                              role="alert"
                            >
                              <strong>Error: </strong>
                              {vinErrors[key]}
                            </div>
                          )}
                        </Form.Group>
                      );
                    })}
                  <br />
                  <div className="alert alert-primary vin-note" role="alert">
                    <span className="d-flex align-items-center">
                      <BsFillInfoCircleFill />
                      <strong className="px-2">Note:</strong>
                    </span>
                    <hr />
                    The vehicle details will be updated according to your
                    entered VIN
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        size="md silo_modal"
        show={showModal}
        handleClose={handleClose}
        title="Are you Sure? "
        content={
          <div className="text-center">
            <span>
              The vehicle details will be updated according to your entered VIN.
              <div className='pt-3'>Updated vehicle details are as follows:</div>
              <br />
              <br />
              <table className="table">
                <tbody>
                  {vehicleDetailsList && vehicleDetailsList.length > 0
                    ? vehicleDetailsList.map((data, index) => {
                        return data.value ? (
                          <tr key={index}>
                            <th scope="row">{data.key}</th>
                            <td>{data.value}</td>
                          </tr>
                        ) : null;
                      })
                    : null}
                </tbody>
              </table>
              <br />
              <div className='pb-3'>
                <b>Are you sure you want to continue?</b>
              </div>
            </span>
            {vinWarnings.length > 0 && (
              <div className="alert alert-warning" role="alert">
                <span className="d-flex align-items-center">
                  <IoIosWarning />
                  <strong className="px-2">Caution:</strong>
                </span>
                <hr />
                {vinWarnings[0]}
              </div>
            )}
            {updatedVehicleDetails?.message?.length > 0 ? (
              <div className="alert alert-danger vin-note" role="alert">
                <span className="d-flex align-items-center">
                  <IoIosWarning />
                  <strong className="px-2">Warning:</strong>
                </span>
                <hr />
                {updatedVehicleDetails?.message}
              </div>
            ) : null}
            <hr />
            <div className="d-flex justify-content-end">
              <button
                className="btn silo-btn-small mr-2 "
                onClick={handleClose}
              >
                Cancel
              </button>
              <br />
              <button
                className="btn silo-btn-small silo-btn__blue ml-20"
                onClick={() => updateVinNumber()}
              >
                {confirmBtnLoading ? <Spinner size="sm" /> : <>Okay</>}
              </button>
            </div>
            <br />
          </div>
        }
      />
    </>
  );
}

export default UpdateMissingVIN;
