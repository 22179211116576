import { useState } from "react";
import { getRequest } from "../../../../../../api/api";
import { GET_LIENHOLDER_LIST } from "../../../../../../api/endpoint";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../../../../Shared/ModalContainer/DeleteModal";
import { AsyncPaginate } from "react-select-async-paginate";
import { Row } from "react-bootstrap";

export const CommonLienholderApi = ({
    page,
    Setpage,
    handleLeinHolderChange,
    displayValue,
    vehicleIndex,
    vehicles,
    setShowModal,
    newLienholderDetails,
    setLienholderDetails,
    setDropdownChanged,
    setVehicles,
    saveProfile,
  }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const lienholders = async (search) => {
      try {
        const response = await getRequest(
          `${GET_LIENHOLDER_LIST}?name=${search}&page=${page}`
        );
        const lienHolderList = response.result;
  
        // Filter out any empty or invalid options
        const lienholderMapped = lienHolderList
          .filter((item) => item.name) // Filter out items with empty names
          .map((item) => ({
            value: item.id,
            label: item.name,
            subtext: `${item?.address1?.trim() || ''} ${
              item?.address2?.trim() || ''
            }
              ${
                (item?.address1?.trim()?.length > 0 ||
                  item?.address2?.trim()?.length > 0) &&
                item?.city?.name
                  ? ', '
                  : ''
              }
              ${item?.city?.name || ''}
              ${item.city?.name && item?.state?.state_code ? ', ' : ''}
              ${item?.state?.state_code || ''}
              ${
                item.state?.state_code && item?.zip?.trim().length > 0 ? ', ' : ''
              }
              ${item?.zip || ''}`,
          }));
  
        const lastElement = lienHolderList[lienHolderList.length - 1];
        const lastPageUrl = lastElement.links?.last_url;
  
        if (lastPageUrl) {
          const lastPageNumber = new URLSearchParams(lastPageUrl).get('page');
  
          return {
            options: lienholderMapped,
            hasMore: lienholderMapped.length > 0 && page < lastPageNumber,
            additional: {
              page: Setpage(page + 1),
            },
          };
        } else {
          // Handle the case when last_url is not available in the response
          return {
            options: [],
            hasMore: false,
          };
        }
      } catch (error) {
        console.log('err', error);
        return {
          options: [],
          hasMore: false,
        };
      }
    };
  
    const isOptionDisabled = (option) => {
      if (
        option.value === vehicles[vehicleIndex]?.lienholder_1_id ||
        option.value === vehicles[vehicleIndex]?.lienholder_2_id
      ) {
        return true;
      } else return false;
    };
  
    const handleEditLienholder = () => {
      setLienholderDetails({
        name: newLienholderDetails?.name,
        mobile: newLienholderDetails?.phone,
        address_line_1: newLienholderDetails?.address1,
        address_line_2: newLienholderDetails?.address2,
        state: newLienholderDetails?.state,
        city: newLienholderDetails?.city,
        zip: newLienholderDetails?.zip,
        parent_lienholder: newLienholderDetails?.parent_lienholder
          ? {
              value: newLienholderDetails?.parent_lienholder?.id,
              label: newLienholderDetails?.parent_lienholder?.name,
            }
          : null,
        id: newLienholderDetails?.id,
      });
      setShowModal(true);
    };
  
    const handleLienholderDelete = () => {
      const new_lienholders_attributes = [
        {
          id: newLienholderDetails?.id,
          _destroy: true,
        },
      ];
      const updatedVehicle = { ...vehicles[vehicleIndex] };
  
      updatedVehicle.lienholders_attributes = new_lienholders_attributes || {};
  
      const updatedVehicles = [...vehicles];
      updatedVehicles[vehicleIndex] = updatedVehicle;
      setVehicles(updatedVehicles);
  
      const data = {
        vehicle_details_attributes: updatedVehicles,
      };
      saveProfile(data, vehicleIndex, true).then((success) => {
        if (success) {
          setShowModal(false);
        }
      });
      setDropdownChanged(true);
      handleCloseDeleteModal();
    };
  
    const handleDeleteLienholder = () => {
      setShowDeleteModal(true);
    };
  
    const handleCloseDeleteModal = () => {
      setShowDeleteModal(false);
    };
  
    return (
      <>
        {newLienholderDetails ? (
          <>
            <Row>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Lienholder Name"
                  aria-label="Recipient's username with two button addons"
                  aria-describedby="button-addon4"
                  value={newLienholderDetails?.name}
                  disabled={true}
                />
                <div className="input-group-append" id="button-addon4">
                  <div className="btn edit-btn" type="button">
                    <AiFillEdit
                      size={25}
                      className="vehicle-edit-icon py-1"
                      onClick={handleEditLienholder}
                    />
                  </div>
                  <div className="btn delete-btn" type="button">
                    <RiDeleteBin6Line
                      className="cursor-pointer"
                      color="red"
                      size="20"
                      onClick={handleDeleteLienholder}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <DeleteModal
              show={showDeleteModal}
              handleClose={handleCloseDeleteModal}
              handleDelete={handleLienholderDelete}
              content={
                <p>
                  Are you sure you want to delete lienholder{' '}
                  <b>{newLienholderDetails && newLienholderDetails.name}</b>
                </p>
              }
            />
          </>
        ) : (
          <>
            <AsyncPaginate
              value={displayValue}
              loadOptions={lienholders}
              isOptionDisabled={isOptionDisabled}
              isSearchable={true}
              placeholder="Select Lienholder "
              isClearable
              onChange={(selectedOption) => {
                handleLeinHolderChange(selectedOption);
              }}
              additional={{
                page: 1,
              }}
              className="custom-select"
              getOptionLabel={(option) => (
                <>
                  <div>{option.label}</div>
                  <div style={{ fontSize: '80%', color: '#888' }}>
                    {option.subtext}
                  </div>
                </>
              )}
              getOptionValue={(option) => option.value}
            />
          </>
        )}
      </>
    );
  };
  
  export const CommonParentLienholder = ({
    page,
    Setpage,
    handleLeinHolderChange,
    displayValue,
  }) => {
    const parentLienholders = async (search) => {
      try {
        const response = await getRequest(
          `${GET_LIENHOLDER_LIST}?name=${search}&page=${page}`
        );
        const lienHolderList = response.result;
  
        const lienholderMapped = lienHolderList
          .filter((item) => item.name)
          .map((item) => ({
            value: item.id,
            label: item.name,
            subtext: `${item?.address1?.trim() || ''} ${
              item?.address2?.trim() || ''
            }
              ${
                (item?.address1?.trim()?.length > 0 ||
                  item?.address2?.trim()?.length > 0) &&
                item?.city?.name
                  ? ', '
                  : ''
              }
              ${item?.city?.name || ''}
              ${item.city?.name && item?.state?.state_code ? ', ' : ''}
              ${item?.state?.state_code || ''}
              ${
                item.state?.state_code && item?.zip?.trim().length > 0 ? ', ' : ''
              }
              ${item?.zip || ''}`,
          }));
  
        const lastElement = lienHolderList[lienHolderList.length - 1];
        const lastPageUrl = lastElement.links?.last_url;
  
        if (lastPageUrl) {
          const lastPageNumber = new URLSearchParams(lastPageUrl).get('page');
  
          return {
            options: lienholderMapped,
            hasMore: lienholderMapped.length > 0 && page < lastPageNumber,
            additional: {
              page: Setpage(page + 1),
            },
          };
        } else {
          return {
            options: [],
            hasMore: false,
          };
        }
      } catch (error) {
        console.log('err', error);
        return {
          options: [],
          hasMore: false,
        };
      }
    };
  
    return (
      <>
        <AsyncPaginate
          value={displayValue}
          loadOptions={parentLienholders}
          isSearchable={true}
          placeholder="Select Lienholder "
          isClearable
          onChange={(selectedOption) => {
            handleLeinHolderChange(selectedOption);
          }}
          additional={{
            page: 1,
          }}
          className="custom-select"
          getOptionLabel={(option) => (
            <>
              <div>{option.label}</div>
              <div style={{ fontSize: '80%', color: '#888' }}>
                {option.subtext}
              </div>
            </>
          )}
          getOptionValue={(option) => option.value}
        />
      </>
    );
  };
  