import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import BarContent from './BarContent';
import Accordion from 'react-bootstrap/Accordion';
import { RiCustomerService2Line } from 'react-icons/ri';
import './sidebar.scss';

// import ChatButton from '../../../../LiveChat/ChatButton';

const Sidebar = ({ toggleMenu }) => {
  const [subnav, setSubnav] = useState(true);

  const showSubnav = () => setSubnav(!subnav);

  // const [isChatOpen, setIsChatOpen] = useState(false);

  // const toggleChat = () => {
  //   setIsChatOpen(!isChatOpen);
  // };

  const NavItem = ({ item }) => (
    <div key={item.id}>
      {item.subNav ? (
        <div>
          <div
            className="list-group-item d-flex justify-content-between align-items-center sub-child"
            onClick={() => showSubnav()}
          >
            <div>
              <span>{item.icon}</span>
              <span>{item.text}</span>
            </div>
            <div>
              <BsChevronDown />
            </div>
          </div>
          {subnav && (
            <div>
              {item.subNav.map((subItem, index) => (
                <div key={index} className="">
                  <NavLink
                    to={subItem.path}
                    className="text-decoration-none fw-medium list-group-item px-5"
                    onClick={toggleMenu}
                  >
                    {subItem.icon}
                    {subItem.title}
                  </NavLink>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <NavLink
          to={item.link}
          className="list-group-item"
          onClick={toggleMenu}
        >
          <span>{item.icon}</span>
          <span>{item.text}</span>
        </NavLink>
      )}
    </div>
  );

  return (
    <>
      <div className="nav_icons_sidebar">
        <NavLink
          to="/"
          className="d-inline-block text-decoration-none nav-link border-line fs-6"
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className="d-inline-block text-decoration-none nav-link border-line fs-6"
        >
          About
        </NavLink>
      </div>
      <div className="bg-white sidebar">
        <div className="list-group">
          {BarContent.map((item, index) => (
            <NavItem key={index} item={item} />
          ))}
        </div>
        {/*<ChatButton openChat={isChatOpen} setIsChatOpen={setIsChatOpen} />*/}
      </div>
      <Accordion defaultActiveKey="0" className="help-accordion">
        <Accordion.Item eventKey="0" className="shadow-none">
          <Accordion.Header className="help-btn mb-3 fw-bold text-center text-white cursor-pointer help-accordion-btn">
            Need Help ?
          </Accordion.Header>
          <Accordion.Body className="py-0">
            <div className="help-icon">
              <RiCustomerService2Line />
            </div>
            <div className='mt-2'>support@SILOmembers.com</div>
            <div>302-659-SILO (7456)</div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Sidebar;
