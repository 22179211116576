import React, { useState } from 'react';
import login from '../../../assets/images/Login/login.svg';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';
import shape from '../../../assets/images/Login/Shape.png';
import emailSvg from '../../../assets/images/Login/email.svg';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { postRequest } from '../../../api/api';
import { PASSWORDS } from '../../../api/endpoint';
import { ToastManager } from '../../../ToastContainer/ToastContainer';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [captchaFilled, setCaptchaFilled] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    e.preventDefault();
    const emailValue = e.target.value;
    setEmail(emailValue);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(emailValue);
    setIsValidEmail(isValidEmail);
  };

  const handleResetPassword = async (e) => {
    if (isValidEmail && captchaFilled) {
      try {
        const sendLinkData = {
          user: {
            email: email,
          },
        };
        const { result } = await postRequest(PASSWORDS, sendLinkData);
        if (result.message) {
          ToastManager.success(result.message);
          navigate(
            `/forgetPasswordConfirmation?email=${encodeURIComponent(email)}`
          );
        } else {
          ToastManager.error(result.error);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
      }
    } else {
      if (!captchaFilled) {
        ToastManager.error('Please fill reCAPTCHA');
      }
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaFilled(true);
    } else {
      setCaptchaFilled(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    handleResetPassword();
  };

  return (
    <div className="">
      <div className="silo_page-container py-5 d-flex align-items-center">
        <div className="container position-relative">
          <img src={shape} className="dotted-lines" alt="dotted-lines" />
          <div className="row">
            <div className="col-sm py-2">
              <img
                className="img-fluid login-form-img"
                src={login}
                alt="login_image"
                onError={(e) => {
                  // If AVIF fails to load, fallback to PNG
                  e.target.src = logoPng;
                }}
              />
            </div>

            <div className="col-sm">
              <div className="contact-form-box">
                <div>
                  <img className="mb-4" width="220" src={logo} alt="logo" />
                </div>

                <div className="mb-4">
                  <span>Please enter your email to reset the password</span>
                  <form id="account" className="mt-4" onSubmit={handleFormSubmit}>
                    <div className="form-group">
                      <div className="icon">
                        <img src={emailSvg} alt="email" />
                      </div>
                      <input
                        type="email"
                        className={`form-control mb-0 ${
                          isValidEmail ? '' : 'is-invalid'
                        }`}
                        placeholder="Enter email"
                        onChange={handleEmailChange}
                        value={email}
                      />
                      {!isValidEmail && (
                        <div className="invalid-feedback">
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                    <br />

                    <div className="d-flex justify-content-between">
                      <div className="form-check m-0">
                        <input type="checkbox" className="form-check-input" />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                      onChange={handleCaptchaChange}
                    />
                    <span
                      className="mt-4 btn silo-btn silo-btn__blue"
                      onClick={handleResetPassword}
                    >
                      Submit
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
