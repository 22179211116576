import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import ModalContainer from '../../pages/Shared/ModalContainer/ModalContainer';
import { Context } from '../../../utils/Context';

export const DriverDetails = ({
  selectedDriverAsMember,
  setSelectedDriverAsMember,
  handleSelectedDriver,
  handleDriverModalShow,
  modalOpen,
  setModalOpen,
  setCopyDriverToMember,
}) => {
  const { profileObject } = useContext(Context);
  const [showSelectedDriver, setShowSelectedDriver] = useState();

  const driverData = [
    {
      label: 'First Name',
      id: 'firstName',
      name: 'first_name',
      value: '',
      type: 'text',
    },
    {
      label: 'Last Name',
      id: 'lastName',
      name: 'last_name',
      value: '',
      type: 'text',
    },
    {
      label: 'Email Address',
      id: 'email',
      name: 'email',
      value: '',
      type: 'email',
    },
    {
      label: 'Date of Birth',
      id: 'DOBuser',
      name: 'date_of_birth',
      value: '',
      type: 'date',
    },
  ];
  const driverAddress = [
    {
      label: 'Street Address',
      id: 'address_line_1',
      name: 'address_line_1',
      value: '',
      type: 'text',
    },
    {
      label: 'State',
      id: 'state',
      name: 'state',
      value: '',
      type: 'text',
    },
    {
      label: 'City',
      id: 'city',
      name: 'city',
      value: '',
      type: 'text',
    },
    {
      label: 'Zip',
      id: 'zip5',
      name: 'zip5',
      value: '',
      type: 'number',
    },
  ];

  const mappedOptions = profileObject?.drivers?.map((item) => ({
    value: item.first_name,
    label: item.first_name,
    id: item.id,
  }));

  const handleDriverChange = (val) => {
    setShowSelectedDriver(val.id);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(()=>{
    if(profileObject?.drivers?.length === 1){
      setShowSelectedDriver(profileObject?.drivers[0]?.id);
    }
  },[profileObject?.drivers, mappedOptions])

  return (
    <>
      <ModalContainer
        size="xl"
        show={modalOpen}
        handleClose={handleModalClose}
        title="Add Driver Details as Member Details"
        content={
          <div className="user-detail-form-blue mx-0 mx-md-4">
            <div>
              <Form.Group className="user-detail-form-blue mb-3">
                <Form.Label className="font-16-sm fw-bold mb-2 ">
                  Select a Driver
                </Form.Label>
                <Select
                  className="custom-select"
                  options={mappedOptions}
                  //   isClearable
                  value={(mappedOptions?.length === 1) && mappedOptions[0]}
                  onChange={handleDriverChange}
                  placeholder="Select a Driver"
                />
              </Form.Group>
            </div>
            {profileObject?.drivers?.map(
              (item,outerIndex) =>
                item.id === showSelectedDriver && (
                  <React.Fragment key={outerIndex}>
                    <div className="row mt-2">
                      {driverData.map((field,index) => (
                        <div className="col-md-6" key={index}>
                          <Form.Group
                            controlId={field.id}
                            className="d-flex flex-column user-detail-form-blue"
                          >
                            <Form.Label className="fw-bold mt-2">
                              <span>{field.label}:</span>
                            </Form.Label>
                            <Form.Control
                              readOnly
                              disabled
                              type={field.type}
                              name={field.name}
                              value={item[field.name]}
                            />
                          </Form.Group>
                        </div>
                      ))}

                      {driverAddress.map((field,index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-6" key={index}>
                            <Form.Group
                              controlId={field.id}
                              className="d-flex flex-column user-detail-form-blue "
                            >
                              <Form.Label className="fw-bold mt-2">
                                <span>{field.label}:</span>
                              </Form.Label>
                              {field.name === 'state' ? (
                                <>
                                  <Form.Control
                                    readOnly
                                    isDisabled
                                    type={field.type}
                                    name={field.name}
                                    value={item.driver_address?.state?.name}
                                  />
                                </>
                              ) : field.name === 'city' ? (
                                <>
                                  <Form.Control
                                    readOnly
                                    isDisabled
                                    type={field.type}
                                    name={field.name}
                                    value={item.driver_address?.city?.name}
                                  />
                                </>
                              ) : (
                                <Form.Control
                                  readOnly
                                  disabled
                                  type={field.type}
                                  name={field.name}
                                  value={item.driver_address[field.name]}
                                />
                              )}
                            </Form.Group>
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="d-flex align-items-center justify-content-end mt-3 mt-md-5 px-md-4">
                        <button
                          className="btn silo-btn-small silo-btn__blue btn-warning mx-2"
                          onClick={() => {
                            handleSelectedDriver(null);
                            setCopyDriverToMember(false);
                            handleModalClose();
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn silo-btn-small silo-btn__blue btn-primary"
                          onClick={() => {
                            handleSelectedDriver(showSelectedDriver);
                            setCopyDriverToMember(true);
                            handleModalClose();
                          }}
                        >
                          Select this Driver
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                )
            )}
          </div>
        }
      />
    </>
  );
};
