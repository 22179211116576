import React, { useState, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import './vin.scss';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import blue_truck from '../../../../../../assets/images/blue_truck_white_bg.avif';
import blue_truck_png from '../../../../../../assets/images/blue_truck_white_bg.png';
import truck_vin from '../../../../../../assets/images/Quote/truck_vin.avif';
import truck_vin_png from '../../../../../../assets/images/Quote/truck_vin.png';
import Button from 'react-bootstrap/Button';
import ModalContainer from '../../../../Shared/ModalContainer/ModalContainer';
import CargoCommodities from '../CargoCommodities/CargoCommodities';
import Spinner from 'react-bootstrap/Spinner';
import VehicleLicenseNumber from '../VehicleLicenseNumber/VehicleLicenseNumber';
import VehicleLicenseState from '../VehicleLicenseState/VehicleLicenseState';
import ScrollToTopLink from '../../../../../ScrollToTop/ScrollToTop';
import { VERIFY_VIN } from '../../../../../../api/endpoint';
import { getRequest } from '../../../../../../api/api';

const VIN = ({
  setShowSection1,
  setShowSection2,
  vehicleIndex,
  setCargo,
  saveProfile,
  profileObject,
}) => {
  const { vehicles, setVehicles } = useContext(Context);

  const [show, setShow] = useState(false);
  const [vinError, setVinError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cargoError, setCargoError] = useState(null);
  const [licenseError, setLicenseError] = useState(null);
  const [licenseStateError, setLicenseStateError] = useState(null);
  const [warningMessage, setWarningMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const whitelabelId = parseInt(localStorage.getItem('agencyId')) || null;
  const meshviReferralId = parseInt(localStorage.getItem('producerId')) || null;
  const isMobileScreen = window.innerWidth < 768;

  const validate_vin = async () => {
    try {
      setLoading(true);
      const cargoIds = vehicles[vehicleIndex].cargo_commodities.map(
        (cargoItem) => cargoItem.id
      );
      const data = {
        vehicle_details_attributes: [
          {
            vin_number: vehicles[vehicleIndex]?.vin_number,
            cargo_commodity_ids: cargoIds,
            with_vin: true,
          },
        ],
        programs_attributes: profileObject?.programs
          ? profileObject?.programs
          : [
              {
                whitelabel_id: whitelabelId,
                meshvi_referral_id: meshviReferralId,
                device_type: isMobileScreen ? 'mobile' : 'desktop',
              },
            ],
      };
      const success = await saveProfile(data, vehicleIndex);
      setVinError(null);
      if (success) {
        setShowSection2(true);
        setShowSection1(false);
      }
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!vehicles[vehicleIndex]?.vin_number) {
      setVinError('VIN Number is required');
      isValid = false;
    } else {
      setVinError(null);
    }

    if (!vehicles[vehicleIndex].cargo_commodities) {
      setCargoError('Cargo Commodities are required');
      isValid = false;
    } else {
      setCargoError(null);
    }

    if (
      !vehicles[vehicleIndex].license_plate_state?.id ||
      vehicles[vehicleIndex].license_plate_state?.id === ''
    ) {
      setLicenseStateError('License state is required');
      isValid = false;
    } else {
      setLicenseStateError(null);
    }
    // if (!vehicles[vehicleIndex].license_plate_number ||
    //   vehicles[vehicleIndex].license_plate_number === ''
    // ) {
    //   setLicenseError('License plate number is required');
    //   isValid = false;
    // } else {
    //   setLicenseError(null);
    // }

    if (isValid) {
      validate_vin();
    }
  };

  const handleOnChange = (e) => {
    const newVIN = e.target.value;
    if (vehicles[vehicleIndex]?.vin_number) {
      setVinError(null);
    }

    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.vin_number = newVIN.trim();

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
  };

  const verifyVin = async (e) => {
    try {
      const vin_number = {
        vin_number: e.target.value,
      };
      const response = await getRequest(VERIFY_VIN, vin_number);
      setWarningMessage(response?.result?.warning_message);
      setErrorMessage(response?.result?.message);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  return (
    <div className="text-start">
      <Row>
        <Col md={6}>
          <Form className="user-detail-form-blue">
            <Form.Group className="mb-3" controlId="formBasicVIN">
              <Form.Label className="fw-bold">
                VIN Number<span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Ex. IFAQ12446gh786"
                value={vehicles[vehicleIndex]?.vin_number || ''}
                onChange={handleOnChange}
                isInvalid={!!vinError}
                onBlur={verifyVin}
                maxLength={17}
              />
              {loading && (
                <span className="text-success">
                  <strong>Validating Vin...</strong>
                  <Spinner
                    animation="border"
                    variant="success"
                    size="sm"
                    className="mx-2"
                  />
                </span>
              )}
              <Form.Text
                className="text-muted float-end cursor-pointer"
                onClick={handleShow}
              >
                What is VIN?
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {vinError}
              </Form.Control.Feedback>
            </Form.Group>
            {warningMessage && (
              <div className="alert alert-warning mt-4" role="alert">
                <strong>Warning : </strong>
                {warningMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger mt-4" role="alert">
                <strong>Error : </strong>
                {errorMessage}
              </div>
            )}

            <Form.Group controlId="formGridLicenseNumber" className="mt-2">
              <VehicleLicenseNumber
                licenseError={licenseError}
                setLicenseError={setLicenseError}
                vehicleIndex={vehicleIndex}
              />
            </Form.Group>
            <Form.Group controlId="formGridLicenseState" className="mt-2">
              <VehicleLicenseState
                licenseStateError={licenseStateError}
                setLicenseStateError={setLicenseStateError}
                vehicleIndex={vehicleIndex}
              />
            </Form.Group>
            <Form.Group controlId="formGridCargo" className="mt-2">
              {/* Cargo Commodities Dropdown */}
              <CargoCommodities
                vehicleIndex={vehicleIndex}
                setCargo={setCargo}
                cargoError={cargoError}
                setCargoError={setCargoError}
              />
            </Form.Group>
            <ModalContainer
              size="lg"
              show={show}
              handleClose={handleClose}
              title="Understand your Vehicle Identification Number"
              content={
                <div>
                  <p>
                    The Vehicle Identification Number (VIN) is a unique
                    alphanumeric code assigned to every vehicle. It serves as a
                    crucial identifier for various purposes, including vehicle
                    registration, insurance, and maintenance. Typically located
                    on the dashboard near the windshield on the driver's side,
                    the VIN provides essential information about the vehicle's
                    make, model, and year of manufacture. Understanding your VIN
                    is important for ensuring accurate documentation and
                    compliance with regulatory requirements.
                  </p>
                  <Row>
                    <Col md={6}>
                      <img
                        src={truck_vin}
                        alt="truck-vin"
                        width="400"
                        onError={(e) => {
                          // If AVIF fails to load, fallback to PNG
                          e.target.src = truck_vin_png;
                        }}
                      />
                    </Col>
                    <Col md={6} className="p-5">
                      <ol>
                        <li className="m-3">
                          It is a unique alphanumeric code assigned to each
                          vehicle.
                        </li>
                        <li className="m-3">
                          The VIN provides essential information about the
                          vehicle's make, model, and manufacturing year.
                        </li>
                        <li className="m-3">
                          Understanding your VIN is important for accurate
                          documentation, compliance, and various tasks such as
                          registration and insurance.
                        </li>
                      </ol>
                    </Col>
                  </Row>
                </div>
              }
            />
            <span className="navigation_bottom">
              <ScrollToTopLink>
                <Button
                  className="btn silo-btn-small silo-btn__blue my-3"
                  type="submit"
                  onClick={handleSave}
                >
                  Next
                </Button>{' '}
              </ScrollToTopLink>
            </span>
          </Form>
        </Col>
        <Col md={6}>
          <div className="p-0 p-md-4">
            <h6>Where to find my VIN Number?</h6>
            <Row>
              <Col lg={12} md={12}>
                <p className="text-muted">
                  Discovering your Vehicle Identification Number (VIN) is
                  essential for various purposes. Find it on the dashboard near
                  the windshield, on the driver's side. Alternatively, check the
                  driver's side door jamb, your vehicle registration documents,
                  or under the hood near the engine. Accurately recording your
                  VIN is crucial for tasks such as vehicle registration,
                  insurance, and maintenance.
                </p>
              </Col>
              <Col lg={12} md={12} className="d-flex justify-content-center">
                <img
                  src={blue_truck}
                  className="truck-vin"
                  alt="truck_vin"
                  width="200"
                  onError={(e) => {
                    // If AVIF fails to load, fallback to PNG
                    e.target.src = blue_truck_png;
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VIN;
