import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRequest } from '../../../../../../../../../api/api';
import { GET_VEHICLE_SAFETY_API } from '../../../../../../../../../api/endpoint';
import { GET_ELD_PROVIDER_API } from '../../../../../../../../../api/endpoint';
import { GET_CARGO_COMMODITIES_API } from '../../../../../../../../../api/endpoint';
import { GET_VEHICLE_TYPE_API } from '../../../../../../../../../api/endpoint';
import { GET_BODY_TYPE_API } from '../../../../../../../../../api/endpoint';
import { GET_MAKE_API } from '../../../../../../../../../api/endpoint';
import { GET_VEHICLE_MODEL_API } from '../../../../../../../../../api/endpoint';
import debounce from 'lodash/debounce';
import { ToastManager } from '../../../../../../../../../ToastContainer/ToastContainer';

const animatedComponents = makeAnimated();

const EditVehicle = () => {
  const [vinError, setVinError] = useState(null);
  const [yearError, setYearError] = useState(null);
  const [makeOptions, setmakeOptions] = useState([]);
  const [vinNumber, setVinNumber] = useState(''); // Initialize VIN state with an empty string
  const [year, setYear] = useState('');

  const vehicleFields = [
    {
      label: 'Unit Name',
      id: 'unitName',
      name: 'unitName',
      value: 'Vehicle 1',
      type: 'text',
    },
    {
      label: 'Vin Number',
      id: 'vinNumber',
      name: 'vinNumber',
      value: '',
      type: 'number',
    },
    {
      label: 'Body Type',
      id: 'bodyType',
      name: 'bodyType',
      value: '',
      type: 'text',
    },
    {
      label: 'Vehicle Type',
      id: 'vehicleType',
      name: 'vehicleType',
      value: '',
      type: 'text',
    },
    {
      label: 'Make',
      id: 'make',
      name: 'make',
      value: '',
      type: 'text',
    },
    {
      label: 'Model',
      id: 'model',
      name: 'model',
      value: '',
      type: 'text',
    },
    {
      label: 'Year',
      id: 'year',
      name: 'year',
      value: '',
      type: 'number',
    },
    {
      label: 'Value',
      id: 'value',
      name: 'value',
      value: '',
      type: 'number',
    },
    {
      label: 'Vehicle Safety Systems',
      id: 'vehicleSafetySystems',
      name: 'vehicleSafetySystems',
      value: '',
      type: 'text',
    },
    {
      label: 'ELD Provider',
      id: 'eldProvider',
      name: 'eldProvider',
      value: '',
      type: 'text',
    },
    {
      label: 'Cargo Commodities',
      id: 'cargoCommodities',
      name: 'cargoCommodities',
      value: '',
      type: 'text',
    },
    {
      label: 'Contract Date',
      id: 'contractDate',
      name: 'contractDate',
      value: '',
      type: 'date',
    },
  ];

  const [vehicleData, setVehicleData] = useState({
    unitName: '',
    vinNumber: '',
    bodyType: '',
    vehicleType: '',
    make: '',
    model: '',
    year: '',
    value: '',
    vehicleSafetySystems: '',
    eldProvider: '',
    cargoCommodities: '',
    contractDate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicleData({
      ...vehicleData,
      [name]: value,
    });
  };

  const [vehicleSafetyOptions, setVehicleSafetyOptions] = useState([]);

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [bodyTypeOptions, setBodyTypeOptions] = useState([]);

  const vehicleSafetyTypeMenu = async () => {
    try {
      const response = await getRequest(GET_VEHICLE_SAFETY_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));
      setVehicleSafetyOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  // eld provider

  const [eldProviderOptions, setEldProviderOptions] = useState([]);

  const eldProvdierMenu = async () => {
    try {
      const response = await getRequest(GET_ELD_PROVIDER_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
      }));
      setEldProviderOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  // cargo_commodity

  const [cargoCommoditiesOptions, setCargoCommoditiesOptions] = useState([]);

  const cargoCommoditiesMenu = async () => {
    try {
      const response = await getRequest(GET_CARGO_COMMODITIES_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));
      setCargoCommoditiesOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const bodyImageNames = [
    'Cargo/Sprinter Van',
    'Truck-Tractor',
    'flatbed-tractor-trailer',
    'Other-Bus',
    'Straight Truck',
    'Trailer',
  ];

  const bodyImageMap = {};

  Promise.all(
    bodyImageNames.map((imageName) =>
      import(
        `../../../../../../../../../assets/images/body-types/${imageName}.png`
      )
        .then((imageModule) => {
          bodyImageMap[imageName] = imageModule.default;
        })
        .catch((error) => {
          ToastManager.error(error);
        })
    )
  );

  const CustomOption = ({ innerProps, label, isFocused }) => (
    <div
      {...innerProps}
      className={`icons-custom-options ${isFocused ? 'is-focused' : ''}`}
    >
      <span>{label}</span>
      {bodyImageMap[label] && (
        <img
          src={bodyImageMap[label]}
          alt={`${label} Logo`}
          className="opacity-75"
          width="60"
          height="25"
        />
      )}
    </div>
  );

  const vehicleTypeMenu = async (vehicle_body_type_id) => {
    try {
      const body_type_id = { vehicle_body_type_id: vehicle_body_type_id };
      const response = await getRequest(GET_VEHICLE_TYPE_API, body_type_id);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
      }));
      setVehicleTypeOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const bodyTypeMenu = async () => {
    try {
      const response = await getRequest(GET_BODY_TYPE_API);
      const mappedOptions = response.result.map((item) => ({
        ...item,
        label: item.name,
      }));
      setBodyTypeOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const makeImageNames = [
    'ASTON MARTIN',
    'BENSON',
    'CHEVROLET',
    'DODGE',
    'FONTAINE TRAILER',
    'FORD',
    'FREIGHTLINER',
    'FUSO',
    'GMC',
    'GREAT DANE TRAILETRS',
    'HEIL TRAILER INTERNACIONAL',
    'HYUNDAI',
    'INTERNATIONAL',
    'ISUZU',
    'KENWORTH',
    'MAC',
    'MACK',
    'NISSAN',
    'PETERBILT',
    'RAM',
    'REITNOUER INC',
    'STERLING',
    'STOUGHTON TRAILERS',
    'UD TRUCKS',
    'UTILITY',
    'VOLVO',
    'WABASH NATIOANL CORPORATION',
    'WABASH',
    'WESTERN STAR',
    'WHITE TRUCKS',
    'HONDA',
    'TESLA',
    '',
  ];

  const makeImageMap = {};

  Promise.all(
    makeImageNames.map((imageName) =>
      import(`../../../../../../../../../assets/images/make/${imageName}.png`)
        .then((imageModule) => {
          makeImageMap[imageName] = imageModule.default;
        })
        .catch((error) => {
          ToastManager.error(error);
        })
    )
  );

  const CustomOptionMake = ({ innerProps, label, isFocused }) => (
    <div
      {...innerProps}
      className={`icons-custom-options ${isFocused ? 'is-focused' : ''}`}
    >
      <span>{label}</span>
      {makeImageMap[label] && (
        <img
          src={makeImageMap[label]}
          alt={`${label} Logo`}
          width="50"
          height="25"
        />
      )}
    </div>
  );

  const makeTypeMenu = async (inputValue) => {
    try {
      const response = await getRequest(GET_MAKE_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
      }));
      setmakeOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const [modelOptions, setModelOptions] = useState([]);

  const modelTypeMenu = async (inputValue) => {
    try {
      const response = await getRequest(GET_VEHICLE_MODEL_API);
      const attributes = response.result;
      attributes.pop();
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
      }));
      setModelOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    }
  };
  const debouncedModelTypeMenu = debounce(modelTypeMenu, 1000);

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Check VIN number
    if (!vinNumber) {
      setVinError('VIN Number is required');
    } else {
      setVinError(null);
    }

    if (!year) {
      setYearError('Year is required');
    } else {
      setYearError(null);
    }
  };

  const currentYear = new Date().getFullYear();

  const [minYear, maxYear] = useMemo(() => {
    return [
      currentYear - 100, // Last 100 years
      currentYear + 2, // Next 2 years
    ];
  }, [currentYear]);

  useEffect(() => {
    bodyTypeMenu();
    vehicleTypeMenu();
    makeTypeMenu();
    vehicleSafetyTypeMenu();
    eldProvdierMenu();
    cargoCommoditiesMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-detail-form-blue silo_page_section-heading">
      <h4>Edit Vehicle</h4>
      <div className="row mt-2">
        {vehicleFields.map((field) => (
          <div className="col-md-6">
            <Form.Group controlId={field.id}>
              <Form.Label className="fw-bold mt-2">{field.label}</Form.Label>
              {field.name === 'vehicleSafetySystems' ? (
                <>
                  <Select
                    className="custom-select"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Vehicle Safety Technologies
                      </div>
                    }
                    isMulti
                    options={vehicleSafetyOptions}
                  />
                </>
              ) : field.name === 'vinNumber' ? (
                <>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Ex. IFAQ12446gh786"
                    value={vinNumber}
                    onChange={(e) => setVinNumber(e.target.value)}
                    isInvalid={!!vinError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {vinError}
                  </Form.Control.Feedback>
                </>
              ) : field.name === 'bodyType' ? (
                <>
                  <Select
                    options={bodyTypeOptions}
                    className="custom-select"
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Body Type
                      </div>
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: 'var(--form-blue)',
                        primary: 'var(--blue)',
                      },
                    })}
                    components={{
                      Option: CustomOption,
                    }}
                  />
                </>
              ) : field.name === 'vehicleType' ? (
                <>
                  <Select
                    options={vehicleTypeOptions}
                    className="custom-select"
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Vehicle Type
                      </div>
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: 'var(--form-blue)',
                        primary: 'var(--blue)',
                      },
                    })}
                  />
                </>
              ) : field.name === 'make' ? (
                <>
                  <Select
                    options={makeOptions}
                    className="custom-select"
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Vehicle Make
                      </div>
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: 'var(--form-blue)',
                        primary: 'var(--blue)',
                      },
                    })}
                    onInputChange={(inputValue) => {
                      makeTypeMenu(inputValue);
                    }}
                    components={{
                      Option: CustomOptionMake,
                    }}
                  />
                </>
              ) : field.name === 'model' ? (
                <>
                  <Select
                    options={modelOptions}
                    className="custom-select"
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Vehicle Model
                      </div>
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: 'var(--form-blue)',
                        primary: 'var(--blue)',
                      },
                    })}
                    onInputChange={(inputValue) => {
                      debouncedModelTypeMenu(inputValue); // Use the debounced function
                    }}
                  />{' '}
                </>
              ) : field.name === 'year' ? (
                <>
                  <DatePicker
                    selected={year ? new Date(`${year}-01-01`) : null}
                    showYearPicker
                    dateFormat="yyyy"
                    className={`custom-year-datepicker ${
                      yearError ? 'is-invalid' : ''
                    } `}
                    isClearable
                    placeholderText="Choose Your Vehicle Year"
                    minDate={new Date(`${minYear}`)}
                    maxDate={new Date(`${maxYear}`)}
                    onChange={(date) => {
                      // Set the year state when the user selects a year
                      setYear(date ? date.getFullYear().toString() : ''); // Update the year state
                    }}
                  />

                  {yearError && <div className="text-danger">{yearError}</div>}
                </>
              ) : field.name === 'eldProvider' ? (
                <>
                  <Select
                    className="custom-select w-100"
                    name={field.name}
                    value={vehicleSafetyOptions.find(
                      (option) => option.value === vehicleFields[field.name]
                    )}
                    options={eldProviderOptions}
                    placeholder={
                      <div className="select-placeholder-text text-start">
                        Select ELD Provider
                      </div>
                    }
                  />
                </>
              ) : field.name === 'cargoCommodities' ? (
                <>
                  <Select
                    className="custom-select "
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Cargo Commodities
                      </div>
                    }
                    isMulti
                    options={cargoCommoditiesOptions}
                    value={cargoCommoditiesOptions.find(
                      (option) => option.value === vehicleFields[field.name]
                    )}
                  />
                </>
              ) : (
                <Form.Control
                  type={field.type}
                  name={field.name}
                  value={vehicleData[field.name] || ''}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </div>
        ))}
        <div className="d-flex align-items-center">
          <button
            className="btn silo-btn-small silo-btn__blue btn-primary"
            onClick={handleSave}
          >
            Save
          </button>
          <Link
            to="/account/vehicle-info"
            className="btn silo-btn-small ant-steps-item-content mx-2"
          >
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditVehicle;
