import React from 'react';
import shape from '../../../assets/images/Login/Shape.png';
import login from '../../../assets/images/Login/login.svg';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { postRequest } from '../../../api/api';
import { PASSWORDS } from '../../../api/endpoint';
import { ToastManager } from '../../../ToastContainer/ToastContainer';

const ForgetPasswordConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = new URLSearchParams(location.search).get('email');

  const resendRecoveryMail = async (email) => {
    try {
      const sendLinkData = {
        user: {
          email: email,
        },
      };
      const { result } = await postRequest(PASSWORDS, sendLinkData);
      if (result) {
        ToastManager.success(result.message);
        navigate(
          `/forgetPasswordConfirmation?email=${encodeURIComponent(email)}`
        );
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  };
  return (
    <div>
      <main>
        <section className="silo_page-container py-5 d-flex align-items-center">
          <div className="container position-relative">
            <img src={shape} className="dotted-lines" alt="shape" />
            <div className="row">
              <div className="col-sm py-2">
                <img
                  className="img-fluid login-form-img"
                  src={login}
                  alt="Login"
                />
              </div>
              <div className="col-sm">
                <div className="contact-form-box">
                  <div>
                    <img
                      className="mb-4"
                      width="220"
                      src={logo}
                      alt="Logo"
                      onError={(e) => {
                        // If AVIF fails to load, fallback to PNG
                        e.target.src = logoPng;
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <span className="mb-4">Forgot password confirmation</span>
                  </div>
                  <form id="account">
                    <div className="form-group">
                      <b>We sent a recovery link to your email address:</b>{' '}
                      <br />
                      <div className="fs-4">
                        {email}
                      </div>
                      <div className="py-2">
                        <Link onClick={() => resendRecoveryMail(email)}>
                          Resend recovery link
                        </Link>
                      </div>
                      <div className="py-2">
                        <Link to="/login">Back to Login</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ForgetPasswordConfirmation;
