import React from 'react';
import { Container } from 'react-bootstrap';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';

const TermsAndConditions = () => {
  return (
    <>
      <Breadcrumb title={'Terms and Conditions'} />
      <Container className="my-4">
        <div>
          <div className="container">
            <div className="text-center py-5 silo_page_section-heading">
              <small>
                SILO MEMBERS<sup>TM</sup>
              </small>
              <h2>Our Terms and Conditions</h2>
            </div>

            <div className="pages-content-inner">
              <h3>Introduction</h3>
              <p>
                Content layouts are set of pre-made layouts that can be used as
                the starter on any page/app design. While they provide some
                styling by default, it's very minimal and can be easily modified
                from the component you create.
              </p>
              <p>Main benefits of using content layouts are:</p>
              <ul>
                <li>
                  <p>
                    <strong>Consistency</strong>
                    <br />
                    Your apps and pages will all look similar in terms of layout
                    and general styling which essentially make them more user
                    friendly and easy to learn.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Easy modifications</strong>
                    <br />
                    In the future, if you decide to change the design of your
                    pages, add elements or modify the existing ones, it can be
                    done very easily since all your pages will be sharing same
                    class names and the general code structure.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Easier learning curve for your users</strong>
                    <br />
                    Making the general design and layout of your pages similar
                    will make them easier to learn for your users. They won't be
                    looking for a save button or a search field over and over
                    again, trying to find and memorize their locations for each
                    page since all pages will be sharing a similar structure.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default TermsAndConditions;
