import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../../../../utils/Context';
import { AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { formatInput } from '../../../../../../utils/Commonutils';
import Accordion from 'react-bootstrap/Accordion';

const VehicleListing = ({ isMemberArea }) => {
  const { vehicles } = useContext(Context);
  const navigate = useNavigate();
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  
  const capitalizeFirstLetter = (str) => {
    if (str) return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };
  
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth < 991) {
      setIsFirstAccordionOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);

  return (
    <Accordion activeKey={isFirstAccordionOpen ? '0' : ''}>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={toggleFirstAccordion}>
          <div className="m-0">List of Vehicles</div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="mb-3">
            <div className="text-end">
              {' '}
              <AiFillEdit
                size={25}
                className="vehicle-edit-icon py-1"
                onClick={() =>
                  navigate('/quote/vehicle-details', { replace: true })
                }
              />
            </div>
            <div className="text-start">
              {vehicles &&  vehicles.map((vehicle, index) => (
                <div key={index} className="d-flex">
                  <div className="w-50">
                    <span className="fw-bold">Vehicle {index + 1}</span>
                    <p className="fs-6">
                      {vehicle?.vehicle_make?.name}-
                      {vehicle?.vehicle_model?.name} ({vehicle?.declared_year})
                    </p>
                    <p
                      className={`badge ${
                        vehicle?.vehicle_type?.category_type === 'power unit'
                          ? 'backgroundBlue'
                          : 'bg-secondary'
                      } fs-6`}
                    >
                      {capitalizeFirstLetter(
                        vehicle?.vehicle_type?.category_type
                      )}{' '}
                    </p>
                  </div>
                  <div className="w-50 text-end p-3">
                    {vehicle?.declared_value
                      ? `$${formatInput(vehicle?.declared_value)}`
                      : null}
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default VehicleListing;
