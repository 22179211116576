import React, { useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
const VehicleLicenseNumber = ({
  licenseError,
  setLicenseError,
  vehicleIndex,
}) => {
  const { vehicles, setVehicles } = useContext(Context);

  const handleChange = (e) => {
    const { value } = e.target;
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    // Update the vehicle's body_type with the selected option
    updatedVehicle.license_plate_number = value;
    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];
    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;
    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    if (licenseError) {
      setLicenseError(null);
    }
  };

  return (
    <>
      <Form.Label className="fw-bold">License Plate Number</Form.Label>
      <Form.Control
        required
        type="text"
        placeholder="Ex. AB-12345"
        name="licensePlateNumber"
        className={`custom-select w-100 ${licenseError ? 'is-invalid' : ''}`}
        onChange={handleChange}
        value={vehicles[vehicleIndex]?.license_plate_number || ''}
      />
      <div className="text-danger">{licenseError}</div>
    </>
  );
};

export default VehicleLicenseNumber;
