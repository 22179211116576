import React from 'react';
import { Container } from 'react-bootstrap';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';

const TermsAndConditions = () => {
  return (
    <>
      <Breadcrumb title={'Surplus Lines Disclosure'} />
      <Container className="my-4">
        <div>
          <div className="container">
            <div className="text-center py-5 silo_page_section-heading">
              <small>
                SILO MEMBERS<sup>TM</sup>
              </small>
              <h2>Surplus Lines Disclosure & Acknowledgement</h2>
            </div>
            
            <div className="pages-content-inner">
              <h3>Surplus Lines Disclosure</h3>
              <p>
                SILO Purchasing Group (Solutions for Independent & Leased Operators, LLC) and/or its affiliates, subsidiaries, and members have placed coverage in the surplus lines market. With your membership, you have agreed to this placement. As a member, you understand that persons and/or businesses insured by surplus lines carriers are not protected by the ANY STATE Insurance Guaranty Association with respect to any right of recovery for the obligation of an insolvent unlicensed insurer. You also understand that you have no right to appeal a claim or other insurance company decision with your state's Department of Insurance. As a member, you further understand the policy forms, conditions, premiums, and deductibles used by surplus lines insurers may be different from those found in policies used in the nonsurplus (admitted) market. You have been advised to carefully read the entire policy.  The risk purchasing group or such insurer may not be subject to all insurance laws and regulations of this state.  
              </p>
              <h3>Texas Surplus Lines Disclosure</h3>
              <p>
                SILO Purchasing Group (Solutions for Independent & Leased Operators, LLC) and/or its affiliates, subsidiaries, and members have placed coverage in the surplus lines market. With your membership, you have agreed to this placement. As a member, you understand that persons and/or businesses insured by surplus lines carriers are not protected by the Texas Insurance Guaranty Association with respect to any right of recovery for the obligation of an insolvent unlicensed insurer. You also understand that you have no right to appeal a claim or other insurance company decision with your state's Department of Insurance. As a member, you further understand the policy forms, conditions, premiums, and deductibles used by surplus lines insurers may be different from those found in policies used in the nonsurplus (admitted) market. You have been advised to carefully read the entire policy.  The risk purchasing group or such insurer may not be subject to all insurance laws and regulations of this state.  
              </p>
            </div>

          </div>
        </div>
      </Container>
    </>
  );
};

export default TermsAndConditions;
