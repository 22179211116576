import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { getRequest } from '../../../../../../api/api';
import { GET_MAKE_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import debounce from 'lodash/debounce';
import './make.scss';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';

const Make = ({ makeError, setMakeError, vehicleIndex }) => {
  const { vehicles, setVehicles, manualSelectAccess, setManualSelectAccess, disableState } =
    useContext(Context);

  const [makeOptions, setmakeOptions] = useState([]);
  const [isMakeDisabled, setIsMakeDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const makeImageNames = [
    'ASTON MARTIN',
    'BENSON',
    'CHEVROLET',
    'DODGE',
    'FONTAINE TRAILER',
    'FORD',
    'FREIGHTLINER',
    'FUSO',
    'GMC',
    'GREAT DANE TRAILETRS',
    'HEIL TRAILER INTERNACIONAL',
    'HYUNDAI',
    'INTERNATIONAL',
    'ISUZU',
    'KENWORTH',
    'MAC',
    'MACK',
    'NISSAN',
    'PETERBILT',
    'RAM',
    'REITNOUER INC',
    'STERLING',
    'STOUGHTON TRAILERS',
    'UD TRUCKS',
    'UTILITY',
    'VOLVO',
    'WABASH NATIOANL CORPORATION',
    'WABASH',
    'WESTERN STAR',
    'WHITE TRUCKS',
    'HONDA',
    'TESLA',
    '',
  ];

  const makeImageMap = {};
  let value;

  Promise.all(
    makeImageNames.map((imageName) =>
    Promise.all([
      import(`../../../../../../assets/images/make/${imageName}.avif`).catch(() => null),
      import(`../../../../../../assets/images/make/${imageName}.png`).catch(() => null),
    ])
      .then(([avifModule, pngModule]) => {
        makeImageMap[imageName] = avifModule?.default || pngModule?.default;
        })
        .catch((error) => {
          ToastManager.error(error);
        })
    )
  );

  
  

  const CustomOption = ({ innerProps, label, isFocused }) => (
    <div
      {...innerProps}
      className={`icons-custom-options ${isFocused ? 'is-focused' : ''}`}
    >
      <span>{label}</span>
      {makeImageMap[label] && (
        <img
          src={makeImageMap[label]}
          alt={`${label} Logo`}
          width="50"
          height="25"
        />
      )}
    </div>
  );

  const makeTypeMenu = async (inputValue) => {
    setIsLoading(true);
    try {
      if (!value) {
        value = true;
        let vehicle_type_id;
        if (inputValue !== undefined && inputValue !== '') {
          vehicle_type_id = {
            name: inputValue,
            vehicle_type_id: vehicles[vehicleIndex]?.vehicle_type?.id,
          };
        } else {
          vehicle_type_id = {
            vehicle_type_id: vehicles[vehicleIndex]?.vehicle_type?.id,
          };
        }
        const response = await getRequest(GET_MAKE_API, vehicle_type_id);
        const attributes = response.result;
        attributes.pop();
        const mappedOptions = attributes.map((item) => ({
          ...item,
          label: item.name,
        }));
        setmakeOptions(mappedOptions);
        setIsMakeDisabled(false);
      }
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedMakeTypeMenu = debounce(makeTypeMenu, 1000);

  useEffect(() => {
    if (vehicles[vehicleIndex]?.vehicle_type !== '') {
      makeTypeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles[vehicleIndex]?.vehicle_type]);

  const handleMakeChange = (selectedOption) => {
    value = true;
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.vehicle_make = selectedOption;
    updatedVehicle.vehicle_model = null;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    setManualSelectAccess(3);

    if (makeError) {
      setMakeError(null);
    }
  };
  return (
    <div>
      <Form.Label className="fw-bold">
        Make<span className="text-danger">&nbsp;*</span>
      </Form.Label>
      {isLoading && (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="mx-2"
        />
      )}
      <Select
        isDisabled={
          (manualSelectAccess !== 2 &&
            manualSelectAccess !== 3 &&
            manualSelectAccess !== 4) ||
          isMakeDisabled || disableState
        }
        options={makeOptions}
        isClearable
        className={`custom-select ${makeError ? 'is-invalid' : ''}`}
        placeholder={
          <div className="select-placeholder-text">Search Vehicle Make</div>
        }
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: 'var(--form-blue)',
            primary: 'var(--blue)',
          },
        })}
        value={
          vehicles[vehicleIndex]?.vehicle_make?.name
            ? {
                value: vehicles[vehicleIndex]?.vehicle_make?.name,
                label: vehicles[vehicleIndex]?.vehicle_make?.name,
              }
            : null
        }
        onChange={handleMakeChange}
        onInputChange={(inputValue) => {
          debouncedMakeTypeMenu(inputValue); // Use the debounced function
        }}
        components={{
          Option: CustomOption,
        }}
      />
      <Form.Control.Feedback type="invalid">{makeError}</Form.Control.Feedback>
    </div>
  );
};

export default Make;
