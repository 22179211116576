export function SetLoggedInUserToken(token) {

  const expirationTime = new Date().getTime() + 100 * 24 * 60 * 60 * 1000; // 100 days in milliseconds
  
    // Create an object to store the token and its expiration time
    const tokenData = {
      token: token,
      expirationTime: expirationTime,
    };
  
    localStorage.setItem('loggedInUserToken', JSON.stringify(tokenData));
  }
  
  export function GetLoggedInUserToken() {
    // Get the token data from localStorage
    const tokenData = localStorage.getItem('loggedInUserToken');
  
    if (tokenData) {
      const parsedTokenData = JSON.parse(tokenData);
  
      // Check if the token has not expired
      if (parsedTokenData.expirationTime > new Date().getTime()) {
        return parsedTokenData.token;
      } else {
        // Token has expired, remove it from storage
        localStorage.removeItem('loggedInUserToken');
      }
    }
  
    return null; // No valid token found
  }

  export function RemoveLoggedInUserToken() {
    localStorage.removeItem('loggedInUserToken');
  }
  