import React from 'react';
import { Link } from 'react-router-dom';

const ScrollToTopLink = ({ to, children }) => {
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <Link to={to} onClick={handleClick} className="text-decoration-none">
      {children}
    </Link>
  );
};

export default ScrollToTopLink;
