import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';
import Logo from '../../assets/images/logo.avif';
import logoPng from '../../assets/images/logo.png';

import './navbar.scss';

const LoggedInNavigationbar = () => {
  const [expanded, setExpanded] = useState(false); // Initialize the expanded state

  // const closeNavbar = () => {
  //   setExpanded(false); // Close the Navbar when a NavLink is clicked
  // };

  return (
    <>
      <Navbar
        collapseOnSelect
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
        bg="light"
        expand="lg"
        className="sticky-top py-3 accountNav"
      >
        <div className="flex-wrap-unset">
          <Navbar.Brand href="/" className="p-0-sm ml-50">
            <img
              src={Logo}
              alt="SILO"
              className="w-40 w-150px-sm"
              onError={(e) => {
                // If AVIF fails to load, fallback to PNG
                e.target.src = logoPng;
              }}
            />
          </Navbar.Brand>
          <>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="text-sm-center justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav
              className="d-flex align-items-center mt-10-sm"
              onClick={closeNavbar} // Close Navbar when NavLink is clicked
            >
              <NavLink
                to="/"
                className="silo-trans-btn text-decoration-none nav-link border-line fs-6"
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className="silo-trans-btn text-decoration-none nav-link border-line fs-6"
              >
                About
              </NavLink>
            </Nav>
          </Navbar.Collapse> */}
          </>
        </div>
      </Navbar>
      <div className="height-75"></div>
    </>
  );
};

export default LoggedInNavigationbar;
