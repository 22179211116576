import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../../../../../utils/Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../ChoosePolicy/choosePolicy.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import OAcomparison from './OAcomparison';
import { useNavigate } from 'react-router-dom';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { formatInput } from '../../../../../../../../utils/Commonutils';

import { IoIosRibbon } from 'react-icons/io';
import { ImCross } from 'react-icons/im';
import { Spinner } from 'react-bootstrap';

const ChoosePolicy = ({
  policyData,
  handleTabChange,
  saveProfile,
  OApolicyListingRepsonse,
  getSubscriptionFor,
  OAPolicyDetailsListing,
  PAPolicyDetailsListing,
  isLoading,
  handleModalShow,
  isDirectCheckout,
}) => {
  const { profileObject, setDirectCheckout } = useContext(Context);
  const { vehicles } = useContext(Context);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [isCoverageDeclined, setIsCoverageDeclined] = useState(false);
  const [driverOAPolicy, setDriverOAPolicy] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    let selectedPolicyDetail =
      policyData &&
      policyData.find((item) =>
        item.policy_details.find((detail) => detail.is_selected)
      );
    if (selectedPolicyDetail === undefined) {
      setIsCoverageDeclined(true);
      selectedPolicyDetail = 'declined';
    } else {
      setIsCoverageDeclined(false);
    }

    setDriverOAPolicy(selectedPolicyDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyData]);

  const planColors = {
    'Gold + Contingent Liability Plan': '#ff9900',
    'Gold Plan': '#FFBF00',
    'Silver Plan': '#A1A1A1',
    'Bronze Plan': '#CD7F32',
  };

  // eslint-disable-next-line no-unused-vars
  const handleShow = () => {
    setShow(!show);
  };

  const handleSaveAndTabChange = (isCheckout = false) => {
    setButtonLoading(true);
    const field = driverOAPolicy;
    const oaSubscription = getSubscriptionFor('OA');
    if (field === 'declined') {
      const data = {
        programs_attributes: [
          {
            id: profileObject?.programs[0]?.id,
            subscriptions_attributes: [
              {
                id: oaSubscription.id,
                _destroy: true,
              },
            ],
          },
        ],
        vehicle_details_attributes: [{}],
      };
      saveProfile(data).then((success) => {
        if (success) {
          handleTabChange('passengerAccident');
          window.scrollTo(0, 0);
          navigate('choose-plan#PA');
        }
        setButtonLoading(false);
      });
      return false;
    }

    const data = {
      programs_attributes: [
        {
          id: profileObject?.programs[0]?.id,
          subscriptions_attributes: [
            {
              id: oaSubscription.id,
              drivers_attributes: {
                id: profileObject?.programs[0]?.subscriptions[0]?.subject?.id,
                vehicle_detail_id: vehicles[0]?.id,
              },
              subject_id: vehicles?.id,
              subject_type: 'Driver',
              subscribable_type: 'PolicyDetail',
              subscribable_id: field?.policy_details[0]?.id,
              type: 'YearlySubscription',
            },
          ],
        },
      ],
      vehicle_details_attributes: [{}],
    };
    saveProfile(data).then((success) => {
      if (success) {
        OAPolicyDetailsListing().then(() => {
          PAPolicyDetailsListing();
        });
      }
      setButtonLoading(false);
    });
    if (isCheckout) {
      setDirectCheckout(true);
      isDirectCheckout('OA');
    } else {
      setDirectCheckout(false);
      handleTabChange('passengerAccident');
      window.scrollTo(0, 0);
    }
    setButtonLoading(false);
  };

  const handleRadioChange = (field) => {
    setDriverOAPolicy(field);
    if (field === 'declined') {
      setIsCoverageDeclined(true);
    } else {
      setIsCoverageDeclined(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {/* <span
          className={`btn silo-btn-small ${show ? 'silo-btn__blue' : ''}`}
          onClick={handleShow}
        >
          {show ? 'Summary' : 'Compare Policy'}
        </span> */}
      </div>
      <div className="text-start ">
        {show ? (
          <div>
            <OAcomparison policyData={policyData} />
          </div>
        ) : (
          <Row>
            <Form className="user-detail-form-blue cursor-pointer pricing-plan">
              <Row>
                <>
                  {isLoading
                    ? Array.from({ length: 4 }, (_, index) => (
                        <Col md={6} lg={6} sm={12} key={index}>
                          <div className="skeleton-card mb-4">
                            <Skeleton height={350} />
                          </div>
                        </Col>
                      ))
                    : policyData.map((field) => (
                        <React.Fragment key={field.plan_type?.id}>
                          <Col
                            md={6}
                            lg={6}
                            sm={12}
                            key={field.plan_type?.id}
                            className="box mt-3 cursor-pointer"
                          >
                            {field.plan_type?.name ===
                            'Gold + Contingent Liability Plan' ? (
                              <div className="ribbon">
                                <span>POPULAR</span>
                              </div>
                            ) : (
                              ''
                            )}
                            <Form.Group
                              className={`mb-3 card h-100 ${
                                field === driverOAPolicy ? 'selected' : ''
                              }`}
                              onClick={() => handleRadioChange(field)}
                              controlId={field.plan_type?.id}
                            >
                              <div className="d-flex justify-content-end ">
                                <Form.Check
                                  type={field.type}
                                  name={field.plan_type?.name}
                                  value={field.plan_type?.code_name}
                                  checked={
                                    field === driverOAPolicy ? true : false
                                  }
                                  readOnly
                                />
                              </div>

                              <span className="plan-details">
                                <span className="text-center mb-2">
                                  <IoIosRibbon
                                    size={40}
                                    style={{
                                      color: planColors[field.plan_type?.name],
                                    }}
                                  />
                                </span>

                                <span className="plan-type text-center">
                                  {field.plan_type?.name}
                                </span>

                                <span className="plan-cost text-center">
                                  $
                                  {field?.policy_details[0]?.annual_rate
                                    ? formatInput(
                                        field.policy_details[0].annual_rate
                                      )
                                    : ''}
                                  <span className="slash">/</span>
                                  <span className="plan-cycle" title="year">
                                    yr
                                  </span>
                                  <br />
                                  <span className="fs-4 text-muted">
                                    {field.policy_details[0]?.monthly_rate &&
                                      '$'}
                                    {field.policy_details[0]?.monthly_rate ? (
                                      <>
                                        {formatInput(
                                          field.policy_details[0].monthly_rate
                                        )}
                                        <span className="slash">/</span>
                                        <span className="" title="month">
                                          mo
                                        </span>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                  <div className="fs-4 text-muted">
                                    {field.policy_details[0]?.weekly_rate &&
                                      '$'}
                                    {field.policy_details[0]?.weekly_rate ? (
                                      <>
                                        {formatInput(
                                          field.policy_details[0].weekly_rate
                                        )}
                                        <span className="slash">/</span>
                                        <span className="" title="week">
                                          wk
                                        </span>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </span>
                                <span className="text-center text-muted">
                                  Master Policy:{' '}
                                  {field?.policy_details[0]?.effective_date} -{' '}
                                  {field?.policy_details[0]?.expiration_date}
                                </span>
                                <span
                                  type="submit"
                                  className="mt-4 btn silo-btn silo-btn__blue text-center"
                                >
                                  <strong>SELECT</strong>
                                </span>
                              </span>
                            </Form.Group>
                          </Col>
                        </React.Fragment>
                      ))}
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    className="box mt-3 cursor-pointer"
                  >
                    <Form.Group
                      className={`mb-3 card h-100 ${
                        isCoverageDeclined ? 'selected' : ''
                      }`}
                      onClick={() => handleRadioChange('declined')}
                    >
                      <div className="d-flex justify-content-end">
                        <Form.Check
                          type="radio"
                          name="coverageDeclined"
                          value="coverageDeclined"
                          checked={isCoverageDeclined ? true : false}
                          readOnly
                        />
                      </div>
                      <span className="plan-details">
                        <span className="text-center mb-2">
                          <ImCross color="red" size={30} />
                        </span>

                        <span className="plan-type text-center">
                          Decline Coverage
                        </span>
                        <p className="text-center fs-6 py-3">
                          I do not wish to take this coverage.
                        </p>
                      </span>
                    </Form.Group>
                  </Col>
                </>
              </Row>
              <br />
              <div className="alert alert-primary" role="alert">
                <span className="d-flex flex-direction-C">
                  <div className="d-flex align-items-center">
                    <BsFillInfoCircleFill />
                    <strong className="px-2">Note:</strong>
                  </div>
                  <span>Not Workers Compensation Insurance </span>
                </span>
              </div>
              <div className="alert alert-warning" role="alert">
                <span className="d-flex flex-direction-C">
                  <div className="d-flex align-items-center">
                    <BsFillInfoCircleFill />
                    <strong className="px-2">Note:</strong>
                  </div>
                  <span>
                    We are not providing this service in the following states:
                    New York, Washington, Massachusetts.{' '}
                  </span>
                </span>
              </div>

              <span className="navigation_bottom">
                <div className="d-flex justify-content-between">
                  <Button
                    className="btn silo-btn-small silo-btn__white my-3"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate('vehicle-details');
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="btn silo-btn-small silo-btn__blue my-3"
                    onClick={() => handleSaveAndTabChange()}
                  >
                    {buttonLoading ? (
                      <Spinner size="sm" />
                    ) : driverOAPolicy !== 'declined' ? (
                      <>Save & Next Coverage</>
                    ) : (
                      <>I do not want this coverage</>
                    )}
                  </Button>
                </div>
                {driverOAPolicy !== 'declined' && (
                  <div>
                    <span
                      className="text-primary fw-bold cursor-pointer"
                      onClick={() => handleSaveAndTabChange(true)}
                    >
                      <u>Save & Proceed to Checkout</u>
                    </span>
                  </div>
                )}
              </span>
            </Form>
          </Row>
        )}
      </div>
    </>
  );
};

export default ChoosePolicy;
