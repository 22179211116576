import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from '../../Shared/Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import Arrowsvg from '../../../../assets/images/Form/Arrow.svg';

const FAQ = () => {
  return (
    <>
      <Breadcrumb title={'FAQ'} />

      <div className="container mt-5 ">
        <Link to={'/contact'} className="mt-5 text-decoration-none">
          <span className="align-items back-btn-style">
            <img src={Arrowsvg} className="pe-2" alt="" />
            <span className="mt-5 text-blue">Back to Contact Page</span>
          </span>
        </Link>
        <div className="container">
          <div className="pt-md-4 pb-md-4 pt-4 pb-4">
            <h1 className="text-center">Most frequently asked questions</h1>
            <p className="text-center">
              Here are the most frequently asked questions you may check before
              getting started
            </p>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Accordion Item #1</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                  in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                  sunt in culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                  in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                  sunt in culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
