import React, { useContext, useEffect, useState } from 'react';
import './personalDocuments.scss';
import UploadDocument from './PersonalDocuments/UploadDocument';
import { saveProfileDetails } from '../../../../../../utils/Profile';
import { UserContext } from '../../../../../../utils/Context';
import DownloadDocument from './PersonalDocuments/DownloadDocument';
import { getVehicleDetails } from '../../../../../../utils/Commonutils';
import { Spinner } from 'react-bootstrap';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';

function PersonalDocuments({ setIsLoading }) {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [deletedFileType, setDeletedFileType] = useState();
  const [documentList, setDocumentList] = useState([
    {
      name: "Drivers' License",
      key: 'driver_license_certificates',
      type: 'driver_license',
      savedFile: userDetails?.driver_license_certificates_url || null,
      uploadedFile: userDetails?.driver_license_certificates_url || null,
    },
    {
      name: 'MVR Certificate',
      key: 'mvr_certificates',
      type: 'mvr',
      savedFile: userDetails?.mvr_certificates_url || null,
      uploadedFile: userDetails?.mvr_certificates_url || null,
    },
    {
      name: 'Medical Certificate',
      key: 'medical_certificates',
      type: 'medical',
      savedFile: userDetails?.medical_certificates_url || null,
      uploadedFile: userDetails?.medical_certificates_url || null,
    },
  ]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const uploadDocuments = async () => {
    const hasSelectedDocument = documentList.some(
      (doc) => doc.uploadedFile !== null
    );

    if (!hasSelectedDocument) {
      // Display an error message if no new document is selected
      ToastManager.error('Please select a document to upload.');
      return;
    }

    // Check if the user has made modifications (new file selected)
    const hasModifications = documentList.some(
      (doc) => doc.uploadedFile !== doc.savedFile
    );

    if (!hasModifications) {
      // Display an error message if no modifications are made
      ToastManager.error('Please select a new file to upload.');
      return;
    }

    setButtonLoading(true);
    const profile = {
      driver_license_certificates: documentList.filter(
        (doc) => doc.key === 'driver_license_certificates'
      )[0].uploadedFile,
      mvr_certificates: documentList.filter(
        (doc) => doc.key === 'mvr_certificates'
      )[0].uploadedFile,
      medical_certificates: documentList.filter(
        (doc) => doc.key === 'medical_certificates'
      )[0].uploadedFile,
    };
    const response = await saveProfileDetails(
      userDetails,
      setUserDetails,
      profile
    );
    if (response) {
      const res = await getVehicleDetails(setUserDetails);
      if (res) {
        setButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isFileSelected && !deletedFileType) {
      setDocumentList([
        {
          name: "Drivers' License",
          key: 'driver_license_certificates',
          type: 'driver_license',
          savedFile: userDetails?.driver_license_certificates_url || null,
          uploadedFile: userDetails?.driver_license_certificates_url || null,
        },
        {
          name: 'MVR Certificate',
          key: 'mvr_certificates',
          type: 'mvr',
          savedFile: userDetails?.mvr_certificates_url || null,
          uploadedFile: userDetails?.mvr_certificates_url || null,
        },
        {
          name: 'Medical Certificate',
          key: 'medical_certificates',
          type: 'medical',
          savedFile: userDetails?.medical_certificates_url || null,
          uploadedFile: userDetails?.medical_certificates_url || null,
        },
      ]);
      setIsFileSelected(false);
    } else if (deletedFileType) {
      const updatedDocumentList = documentList.map((document) => {
        if (document.type === deletedFileType) {
          return {
            ...document,
            savedFile: null,
            uploadedFile: null,
          };
        }
        return document;
      });
      setDocumentList(updatedDocumentList);
      setDeletedFileType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <>
      <h3>
        <b>Personal Documents</b>
      </h3>
      <hr />
      <div className="row">
        {documentList.map((document, i) => {
          return document.savedFile ? (
            <DownloadDocument
              document={document}
              profileId={userDetails?.id}
              setUserDetails={setUserDetails}
              setDeletedFileType={setDeletedFileType}
            />
          ) : (
            <UploadDocument
              document={document}
              documentList={documentList}
              setDocumentList={setDocumentList}
              setIsFileSelected={setIsFileSelected}
            />
          );
        })}
      </div>
      {documentList.every((document) => document.savedFile) ? null : (
        <div className="upload-document-button">
          <div
            type="submit"
            className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
            onClick={uploadDocuments}
          >
            <strong>
              {buttonLoading ? <Spinner size="sm" /> : <>UPLOAD DOCUMENTS</>}
            </strong>
          </div>
        </div>
      )}
    </>
  );
}

export default PersonalDocuments;
