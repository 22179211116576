import React, { useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import { FileUploader } from 'react-drag-drop-files';
import { Row } from 'react-bootstrap';
import { convertToBase64 } from '../../../../../../../utils/CommonDocumentsFunctions';

function UploadDocument({
  document,
  documentList,
  setDocumentList,
  setIsFileSelected,
}) {
  const fileTypes = ['JPG', 'PNG', 'JPEG', 'PDF'];
  const [selectedFile, setSelectedFile] = useState();
  const handleChange = async (file) => {
    setSelectedFile(file);
    let convertedFile = await convertToBase64(file);
    const updatedDocumentList = documentList.map((doc) => {
      if (doc.key === document.key) {
        return {
          ...doc,
          uploadedFile: convertedFile,
        };
      }
      return doc;
    });

    setDocumentList(updatedDocumentList);
    setIsFileSelected(true);
  };

  const handleSizeError = () => {
    alert(`File size should be between 10 KB and 1 MB.`);
  };

  return (
    <div className="col-md-6 mb-4">
      <div className="details-container">
        <div className="fs-5 fw-bold">
          <span>{document.name}</span>
        </div>
        <Row className="m-4">
          <div className="text-decoration-none">
            <div className="d-flex flex-column align-items-center">
              <BsUpload size="50" color="#444" className="cursor-pointer" />
              <br />
              <div className="drag-drop-section">
                <span>Drag & Drop file here or click here to Browse</span>
                <br />
                <FileUploader
                  multiple={false}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  label="Drag & Drop or click"
                  maxSize={1}
                  minSize={0.01}
                  onSizeError={handleSizeError}
                />
                <br />
                <span>
                  {selectedFile ? `Uploaded File- ${selectedFile.name}` : ''}
                </span>
                <div className="text-muted py-4 fs-8">
                  <strong>Note : </strong>Only PDF, JPG, PNG and JPEG file
                  formats are accepted. <br />
                  Max allowed size : 10kb - 1mb
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default UploadDocument;
