import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../../../utils/Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import PAcomparison from './PAcomparison';
import '../../Section/OccupationalAccident/Section/ChoosePolicy/choosePolicy.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import { IoIosRibbon } from 'react-icons/io';
import { ImCross } from 'react-icons/im';
import './passengerAccident.scss';
import { formatInput } from '../../../../../../utils/Commonutils';
import ScrollToTopLink from '../../../../../ScrollToTop/ScrollToTop';
import { Spinner } from 'react-bootstrap';

const PassengerAccident = ({
  setPaPolicyData,
  paPolicyData,
  handleTabChange,
  openSecondAccordion,
  saveProfile,
  getSubscriptionFor,
  PAPolicyDetailsListing,
  isLoading,
  handleTabChangeNTCGPD,
  handleModalShow,
  isDirectCheckout,
}) => {
  const planColors = {
    'Bronze Plan': '#CD7F32',
    'Silver Plan': '#A1A1A1',
    'Gold Plan': '#FFBF00',
  };

  const { vehicles, profileObject, setModalValue, setDirectCheckout } =
    useContext(Context);
  const [isCoverageDeclined, setIsCoverageDeclined] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [PApolicy, setPApolicy] = useState();

  useEffect(() => {
    let selectedPolicyDetail =
      paPolicyData &&
      paPolicyData.find((item) =>
        item.policy_details.find((detail) => detail.is_selected)
      );
    if (selectedPolicyDetail === undefined) {
      setIsCoverageDeclined(true);
      selectedPolicyDetail = 'declined';
    } else {
      setIsCoverageDeclined(false);
    }

    setPApolicy(selectedPolicyDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paPolicyData]);

  const handleRadioChange = (selectedPolicy) => {
    if (selectedPolicy === 'declined') {
      setIsCoverageDeclined(true);
    } else {
      setIsCoverageDeclined(false);
    }
    setPApolicy(selectedPolicy);
  };

  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const handleShow = () => {
    setShow(!show);
  };

  const handleSaveAndTabChange = (isCheckout = false) => {
    if (isCheckout) {
      setDirectCheckout(true);
    } else {
      setDirectCheckout(false);
    }
    setButtonLoading(true);
    const selectedPolicy = PApolicy;
    const paSubscription = getSubscriptionFor('PA');

    if (selectedPolicy === 'declined') {
      const data = {
        programs_attributes: [
          {
            id: profileObject?.programs[0]?.id,
            subscriptions_attributes: [
              {
                id: paSubscription.id,
                _destroy: true,
              },
            ],
          },
        ],
        vehicle_details_attributes: [{}],
      };
      saveProfile(data).then((success) => {
        const isOAPlanSelected = getSubscriptionFor('OA');
        const isPAPlanSelected = getSubscriptionFor('PA');
        if (success) {
          if (
            profileObject?.drivers.length === 0 &&
            (Object.keys(isOAPlanSelected).length !== 0 ||
              Object.keys(isPAPlanSelected).length !== 0)
          ) {
            setModalValue('add');
            handleModalShow();
          } else {
            openSecondAccordion();
            handleTabChangeNTCGPD('physicalDamage');
            window.scrollTo(0, 0);
          }
        }
        setButtonLoading(false);
      });
      return false;
    }

    const data = {
      programs_attributes: [
        {
          id: profileObject?.programs[0]?.id,
          subscriptions_attributes: [
            {
              id: paSubscription.id,
              subject_type: 'VehicleDetail',
              subject_id: vehicles[0]?.id,
              subscribable_type: 'PolicyDetail',
              subscribable_id: selectedPolicy?.policy_details[0]?.id,
              type: 'YearlySubscription',
            },
          ],
        },
      ],
      vehicle_details_attributes: [{}],
    };
    saveProfile(data).then((success) => {
      if (success) {
        PAPolicyDetailsListing();
      }
      setButtonLoading(false);
    });

    const isOAPlanSelected = getSubscriptionFor('OA');
    const isPAPlanSelected = getSubscriptionFor('PA');

    if (
      profileObject?.drivers.length === 0 &&
      (Object.keys(isOAPlanSelected).length !== 0 ||
        Object.keys(isPAPlanSelected).length !== 0)
    ) {
      setModalValue('add');
      setButtonLoading(false);
      handleModalShow();
    } else {
      if (isCheckout) {
        setButtonLoading(false);
        isDirectCheckout('PA');
      } else {
        openSecondAccordion();
      }
      setButtonLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {/* <span
          className={`btn silo-btn-small ${show ? 'silo-btn__blue' : ''}`}
          onClick={handleShow}
        >
          {show ? 'Summary' : 'Plan Details'}
        </span> */}
      </div>
      <div className="text-start">
        {show ? (
          <div>
            <PAcomparison paPolicyData={paPolicyData} />
          </div>
        ) : (
          <Row>
            <Form className="user-detail-form-blue pricing-plan plan_mobile_res">
              <Row>
                <Form.Label className="fw-bold mt-5">
                  Select Policy
                  <span className="text-danger">&nbsp;*</span>
                </Form.Label>
                {isLoading
                  ? Array.from({ length: 1 }, (_, index) => (
                      <Col md={6} lg={6} sm={12} key={index}>
                        <div className="skeleton-card mb-4">
                          <Skeleton height={350} />
                        </div>
                      </Col>
                    ))
                  : paPolicyData.map((field) => (
                      <Col
                        md={6}
                        lg={6}
                        sm={12}
                        key={field.plan_type?.id}
                        className={`box mt-3 ${
                          field.plan_type?.is_disabled
                            ? 'd-none'
                            : 'cursor-pointer'
                        }`}
                      >
                        {field.plan_type?.code_name === 'pa-silo-gold' ? (
                          <div className="ribbon">
                            <span>POPULAR</span>
                          </div>
                        ) : (
                          ''
                        )}
                        <Form.Group
                          className={`mb-3 card h-100 ${
                            field === PApolicy ? 'selected' : ''
                          }`}
                          onClick={() => {
                            if (!field.plan_type?.is_disabled) {
                              handleRadioChange(field);
                            }
                          }}
                          controlId={field.plan_type?.id}
                        >
                          <div className="d-flex justify-content-end">
                            <Form.Check
                              type={field.type}
                              name={field.plan_type?.name}
                              value={field.plan_type?.code_name}
                              checked={field === PApolicy ? true : false}
                              readOnly
                            />
                          </div>

                          <span className="plan-details text-center">
                            <span className="text-center mb-2">
                              <IoIosRibbon
                                size={40}
                                style={{
                                  color:
                                    planColors[field.plan_type?.name] ||
                                    'defaultColor',
                                }}
                              />
                            </span>

                            <span className="plan-type">
                              {field?.plan_type?.name}
                            </span>
                          </span>
                          <span className="plan-cost text-center">
                            $
                            {field?.policy_details[0]?.annual_rate
                              ? formatInput(field.policy_details[0].annual_rate)
                              : ''}
                            <span className="slash">/</span>
                            <span className="plan-cycle" title="year">
                              yr
                            </span>
                            <div className="fs-4 text-muted">
                              {field.policy_details[0]?.monthly_rate && '$'}
                              {field.policy_details[0]?.monthly_rate ? (
                                <>
                                  {formatInput(
                                    field.policy_details[0].monthly_rate
                                  )}
                                  <span className="slash">/</span>
                                  <span className="" title="month">
                                    mo
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="fs-4 text-muted">
                              {field.policy_details[0]?.weekly_rate && '$'}
                              {field.policy_details[0]?.weekly_rate ? (
                                <>
                                  {formatInput(
                                    field.policy_details[0].weekly_rate
                                  )}
                                  <span className="slash">/</span>
                                  <span className="" title="week">
                                    wk
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </span>

                          <span className="text-center text-muted">
                            Master Policy:{' '}
                            {field?.policy_details[0]?.effective_date} -{' '}
                            {field?.policy_details[0]?.expiration_date}
                          </span>
                          <span
                            type="submit"
                            className="mt-4 btn silo-btn silo-btn__blue text-center"
                          >
                            <strong>SELECT</strong>
                          </span>
                        </Form.Group>
                      </Col>
                    ))}
                <Col md={6} lg={6} sm={12} className="box mt-3 cursor-pointer">
                  <Form.Group
                    className={`mb-3 card h-100 ${
                      isCoverageDeclined ? 'selected' : ''
                    }`}
                    onClick={() => handleRadioChange('declined')}
                  >
                    <div className="d-flex justify-content-end">
                      <Form.Check
                        type="radio"
                        name="coverageDeclined"
                        value="coverageDeclined"
                        checked={isCoverageDeclined ? true : false}
                        readOnly
                      />
                    </div>
                    <span className="plan-details">
                      <span className="text-center mb-2">
                        <ImCross color="red" size={30} />
                      </span>

                      <span className="plan-type text-center">
                        Decline Coverage
                      </span>
                      <p className="text-center fs-6 py-3">
                        I do not wish to take this coverage.
                      </p>
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <div className="alert alert-primary" role="alert">
                <span className="d-flex align-items-center">
                  <BsFillInfoCircleFill />
                  <strong className="px-2">Passenger Definition:</strong>
                </span>
                <hr />
                Guest passengers over 10 years of age who are on file with the
                Participating Motor Carrier and noted on the monthly driver
                census. Guest Passengers do not include hitchhikers, co‐drivers,
                or employees of the Participating Motor Carrier who are
                receiving settlements or compensation of any kind for the
                Covered Activity.
                <div>
                  <strong>PASSENGER ACCIDENT INJURY:</strong>
                  To report an Injury: Phone{' '}
                  <b>
                    <i>888.799.6642</i>
                  </b>
                  ; Fax{' '}
                  <b>
                    <i>888.799.5628.</i>
                  </b>
                  <strong className="text-danger p-1 bg-danger bg-opacity-25">
                    Must be reported immediately or within 72 hours.
                  </strong>
                </div>
              </div>
              <div className="alert alert-warning" role="alert">
                <span className="d-flex flex-direction-C">
                  <div className="d-flex align-items-center">
                    <BsFillInfoCircleFill />
                    <strong className="px-2">Note:</strong>
                  </div>
                  <span>
                    We are not providing this service in the following states:
                    New York, Washington, Massachusetts.
                  </span>
                </span>
              </div>
              <span className="navigation_bottom">
                <div className="d-flex justify-content-between">
                  <ScrollToTopLink to="choose-plan#OA">
                    <Button
                      className="btn silo-btn-small silo-btn__white my-3"
                      onClick={() => {
                        handleTabChange('occupationalAccident');
                        // navigate('choose-plan#OA');
                      }}
                    >
                      Back
                    </Button>
                  </ScrollToTopLink>
                  <Button
                    className="btn silo-btn-small silo-btn__blue my-3"
                    onClick={() => handleSaveAndTabChange()}
                  >
                    {buttonLoading ? (
                      <Spinner size="sm" />
                    ) : PApolicy !== 'declined' ? (
                      <>Save & Next Coverage</>
                    ) : (
                      <>I do not want this coverage</>
                    )}
                  </Button>
                </div>
                {PApolicy !== 'declined' && (
                  <div>
                    <span
                      className="text-primary fw-bold cursor-pointer"
                      onClick={() => handleSaveAndTabChange(true)}
                    >
                      <u>Save & Proceed to Checkout</u>
                    </span>
                  </div>
                )}
              </span>
            </Form>
          </Row>
        )}
      </div>
    </>
  );
};

export default PassengerAccident;
