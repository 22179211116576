import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import ModalContainer from '../../../../Shared/ModalContainer/ModalContainer';
import { Button, Spinner } from 'react-bootstrap';
import { lienHolderData } from '../../../../../shared/Constants/Constants';
import { getRequest } from '../../../../../../api/api';
import { GET_STATE_LIST } from '../../../../../../api/endpoint';
import {
  CommonCityApi,
  formatUSBasedNumber,
  formatZipCode,
} from '../../../../../../utils/Commonutils';
import validator from 'validator';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import './lienholder.scss';
import { CommonLienholderApi, CommonParentLienholder } from './LienholderUtils';

function LienHolder({ vehicleIndex, holderId, saveProfile }) {
  const { vehicles, setVehicles } = useContext(Context);
  const [selectedValue, setSelectedValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityPage, setCityPage] = useState(1);
  const [cityOption, setCityOption] = useState([]);
  const [selectCityError, setSelectCityError] = useState('');
  const [lienholderPage, setLienholderPage] = useState(1);
  const [displayValue, setDisplayValue] = useState();
  const [dropdownChanged, setDropdownChanged] = useState(false);
  const [newLienholderDetails, setNewLienholderDetails] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [lienholderDetails, setLienholderDetails] = useState({
    name: '',
    mobile: '',
    address_line_1: '',
    address_line_2: '',
    state: {},
    city: {},
    zip: '',
    parent_lienholder: '',
  });
  const [lienholderDetailsErrors, setLienholderDetailsErrors] = useState({
    name: '',
    mobile: '',
    zip: '',
  });

  const handleLeinHolderChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    if (holderId === 1) {
      updatedVehicle.lienholder_1_id = selectedOption
        ? selectedOption.value
        : null;
    } else if (holderId === 2) {
      updatedVehicle.lienholder_2_id = selectedOption
        ? selectedOption.value
        : null;
    }
    const updatedVehicles = [...vehicles];
    updatedVehicles[vehicleIndex] = updatedVehicle;
    setVehicles(updatedVehicles);
    setDropdownChanged(true);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setLienholderDetails({
      name: '',
      phoneNumber: '',
      address_1: '',
      address_2: '',
      state: {},
      city: {},
      zip: '',
      parent_lienholder: '',
    });
    setLienholderDetailsErrors({
      name: '',
      phoneNumber: '',
      zip: '',
    });
  };

  const handleChange = (e, fieldName) => {
    if (fieldName === 'mobile') {
      const formattedNumber = formatUSBasedNumber(e.target.value);
      setLienholderDetails({
        ...lienholderDetails,
        mobile: formattedNumber,
      });
    } else if (fieldName === 'zip') {
      const formattedZip = formatZipCode(e.target.value);
      setLienholderDetails({
        ...lienholderDetails,
        zip: formattedZip,
      });
    } else {
      setLienholderDetails({
        ...lienholderDetails,
        [fieldName]: e.target.value,
      });
    }
    setLienholderDetailsErrors({
      ...lienholderDetailsErrors,
      [fieldName]: '',
    });
  };

  const licenseFetchState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST);
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setStateOptions(mappedOptions);
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    licenseFetchState();
  }, []);

  const handleStateChange = (selectedOption) => {
    setLienholderDetails({
      ...lienholderDetails,
      state: { id: selectedOption?.id, name: selectedOption?.value },
      city: '',
    });
    setCityPage(1);
  };

  const handleCityChange = (selectedOption) => {
    setLienholderDetails({
      ...lienholderDetails,
      city: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
    });
    if (!selectedOption) {
      setSelectCityError('Please select an option');
    } else {
      setSelectCityError('');
    }
  };

  const handleParentLienholderChange = (selectedOption) => {
    setLienholderDetails({
      ...lienholderDetails,
      parent_lienholder: selectedOption,
    });
  };

  const hanldeSave = async () => {
    let hasError = false;
    let fieldErrors = { name: '', mobile: '', zip: '' };
    setButtonLoading(true);
    if (lienholderDetails.name.trim() === '') {
      hasError = true;
      fieldErrors = { ...fieldErrors, name: 'Lienholder name is required' };
    }
    if (
      lienholderDetails.mobile &&
      !isValidUSPhoneNumber(lienholderDetails.mobile)
    ) {
      hasError = true;
      fieldErrors = { ...fieldErrors, mobile: 'Invalid phone number' };
    }
    if (lienholderDetails.zip && lienholderDetails.zip.length < 5) {
      hasError = true;
      fieldErrors = { ...fieldErrors, zip: 'Invalid Zip Code' };
    }
    if (!hasError) {
      const new_lienholders_attributes = [
        {
          name: lienholderDetails.name.trim(),
          phone: lienholderDetails?.mobile?.trim(),
          address1: lienholderDetails?.address_line_1?.trim(),
          address2: lienholderDetails?.address_line_2?.trim(),
          state_id: lienholderDetails?.state?.id,
          city_id: lienholderDetails?.city?.id,
          zip: lienholderDetails?.zip,
          vehicle_detail_id: vehicles[vehicleIndex]?.id,
          parent_lienholder_id:
            lienholderDetails.parent_lienholder?.value || null,
          selected_type: holderId === 1 ? 'lienholder_1' : 'lienholder_2',
          id: lienholderDetails.id ? lienholderDetails.id : null,
        },
      ];

      const updatedVehicle = { ...vehicles[vehicleIndex] };

      updatedVehicle.lienholders_attributes = new_lienholders_attributes || {};

      const updatedVehicles = [...vehicles];
      updatedVehicles[vehicleIndex] = updatedVehicle;
      setVehicles(updatedVehicles);

      const data = {
        vehicle_details_attributes: updatedVehicles,
      };
      await saveProfile(data, vehicleIndex, true).then((success) => {
        if (success) {
          handleCloseModal();
        }
      });
    } else {
      setLienholderDetailsErrors(fieldErrors);
    }
    setButtonLoading(false);
    if (hasError) {
      ToastManager.error(
        'Form Validation failed. Please check errors before saving.'
      );
    }
  };

  function isValidUSPhoneNumber(phoneNumber) {
    // Use the isMobilePhone function with 'en-US' locale for U.S. phone numbers
    return validator.isMobilePhone(phoneNumber, 'en-US');
  }

  useEffect(() => {
    if (selectedValue || dropdownChanged) {
      setDisplayValue(selectedValue);
    } else {
      if (holderId === 1 && vehicles[vehicleIndex]?.lienholder_1) {
        setDisplayValue({
          value: vehicles[vehicleIndex]?.lienholder_1?.id,
          label: vehicles[vehicleIndex]?.lienholder_1?.name,
        });
      } else if (holderId === 2 && vehicles[vehicleIndex]?.lienholder_2) {
        setDisplayValue({
          value: vehicles[vehicleIndex]?.lienholder_2?.id,
          label: vehicles[vehicleIndex]?.lienholder_2?.name,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, dropdownChanged]);

  const isNewLienholder = () => {
    if (
      holderId === 1 &&
      vehicles[vehicleIndex]?.lienholder_1 &&
      vehicles[vehicleIndex]?.lienholder_1?.external_lienholder_id === null
    ) {
      setNewLienholderDetails(vehicles[vehicleIndex].lienholder_1 || null);
      return true;
    } else if (
      holderId === 2 &&
      vehicles[vehicleIndex]?.lienholder_2 &&
      vehicles[vehicleIndex]?.lienholder_2?.external_lienholder_id === null
    ) {
      setNewLienholderDetails(vehicles[vehicleIndex]?.lienholder_2);
      return true;
    } else {
      setNewLienholderDetails();
      return false;
    }
  };

  useEffect(() => {
    isNewLienholder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles[vehicleIndex]?.lienholder_1,vehicles[vehicleIndex]?.lienholder_2]);

  return (
    <>
      <div>
        <Form.Label className="fw-bold">Lienholder {holderId}</Form.Label>
        <CommonLienholderApi
          page={lienholderPage}
          Setpage={setLienholderPage}
          handleLeinHolderChange={handleLeinHolderChange}
          displayValue={displayValue}
          vehicleIndex={vehicleIndex}
          vehicles={vehicles}
          setShowModal={setShowModal}
          newLienholderDetails={newLienholderDetails}
          setLienholderDetails={setLienholderDetails}
          setDropdownChanged={setDropdownChanged}
          setVehicles={setVehicles}
          saveProfile={saveProfile}
        />
        {displayValue || newLienholderDetails ? null : (
          <Form.Text
            className="add-lien-holder-link float-end"
            onClick={handleOpenModal}
          >
            Can't find your LienHolder? Add a new one.
          </Form.Text>
        )}
      </div>
      <ModalContainer
        size="xl"
        show={showModal}
        handleClose={handleCloseModal}
        title={
          lienholderDetails?.id ? 'Lienholders - Edit' : 'Lienholders - Add New'
        }
        content={
          <div className="user-detail-form-blue mx-0 mx-md-4">
            <div className="row">
              <h4>Personal Details: </h4>
              {lienHolderData.map((field, index) => (
                <div className="col-md-6" key={index}>
                  <Form.Group className="d-flex flex-column mb-10-sm">
                    <Form.Label className="fw-bold mt-2">
                      <span>{field.label}</span>
                      {field.name === 'name' ? (
                        <span className="text-danger">&nbsp;*</span>
                      ) : null}
                    </Form.Label>
                    {field.name === 'state' ? (
                      <>
                        <Select
                          className="custom-select w-100"
                          name={field.name}
                          value={stateOptions.find(
                            (option) =>
                              option.id === lienholderDetails?.state?.id
                          )}
                          onChange={(selectedOption) => {
                            handleStateChange(selectedOption);
                          }}
                          options={stateOptions}
                          placeholder={
                            <div className="select-placeholder-text text-start">
                              Select State
                            </div>
                          }
                        />
                      </>
                    ) : field.name === 'city' ? (
                      <>
                        <CommonCityApi
                          stateId={lienholderDetails?.state?.id}
                          page={cityPage}
                          Setpage={setCityPage}
                          CityData={lienholderDetails}
                          CityOptions={cityOption}
                          SetCityOptions={setCityOption}
                          handleCityChange={handleCityChange}
                          selectDriverCityError={selectCityError}
                        />

                        {selectCityError && (
                          <div className="text-danger">{selectCityError}</div>
                        )}
                      </>
                    ) : field.name === 'parent_lienholder' ? (
                      <>
                        <CommonParentLienholder
                          page={lienholderPage}
                          Setpage={setLienholderPage}
                          handleLeinHolderChange={handleParentLienholderChange}
                          displayValue={
                            lienholderDetails?.parent_lienholder || null
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Form.Control
                          type={field.type}
                          onChange={(e) => handleChange(e, field.name)}
                          name={field.name}
                          placeholder={field.placeholder}
                          value={lienholderDetails[field.name]}
                        />
                        {lienholderDetailsErrors[field.name] && (
                          <span className="text-danger">
                            {lienholderDetailsErrors[field.name]}
                          </span>
                        )}
                      </>
                    )}
                  </Form.Group>
                </div>
              ))}
            </div>
            <Button
              className="my-4 btn silo-btn-small silo-btn__blue w-auto"
              onClick={hanldeSave}
            >
              {buttonLoading ? <Spinner size="sm" /> : <>Save</>}
            </Button>
          </div>
        }
      />
    </>
  );
}

export default LienHolder;
