import React from 'react';

export const DocusignDesktopScreen = () => {
  return (
    <>
      {
        <>
          <div className="alert alert-primary fs-5" role="alert">
            Please review and consent to the DocuSign Agreement below :
          </div>
        </>
      }
      <div id="agreement" className="docusignHeight"></div>
    </>
  );
};
