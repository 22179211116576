import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ActiveSubscription = () => {
  const userAddress = {
    street: '123 Main Street',
    city: 'Cityville',
    state: 'State',
    zip: '12345',
  };

  const renderRow = (label, value) => (
    <div className="col-md-6">
      <div className="silo_page_section-heading">
        <p>{label}</p>
        <h6>{value}</h6>
      </div>
    </div>
  );
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="">
            <h5 className="m-0 fw-bold">Monthly Membership - SILO Association</h5>
            <div className="row mt-2">
              {renderRow('Since', userAddress.street)}
              {renderRow('Next Billing Date', userAddress.city)}
            </div>
            <div className="row mt-4 justify-content-center">
              {renderRow('Unit #', userAddress.state)}
              {renderRow('Estimate Price', userAddress.zip)}
            </div>
          </div>
          </Col>
          <Col md={6}>
          <div className="">
            <div className="silo_page_section-heading">
              <h5 className="m-0 fw-bold">
                Monthly Membership - SILO Purchasing Group
              </h5>
              <div className="row mt-2">
                {renderRow('Since', userAddress.street)}
                {renderRow('Next Billing Date', userAddress.city)}
              </div>
              <div className="row mt-4 justify-content-center">
                {renderRow('Unit #', userAddress.state)}
                {renderRow('Estimate Price', userAddress.zip)}
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} className="mt-4">
          <div className="">
            <h5 className="m-0 fw-bold">BILLING ESTIMATE</h5>
            <hr />
            <h5 className="m-0 fw-bold">Order Summary</h5>
            <hr />
            <div className="text-start">
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <span className="text-muted">
                    <b>MONTHLY MEMBERSHIP - SILO ASSOCIATION</b>
                    <p>Total 1 Member</p>
                  </span>
                </div>

                <div>
                  <span>$9.71</span>
                  &nbsp;&nbsp;
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <span className="text-muted">
                    <b>MONTHLY MEMBERSHIP - SILO PURCHASING GROUP</b>
                    <p>Total 1 Members</p>
                  </span>
                </div>

                <div>
                  <span>$0.29</span>
                  &nbsp;&nbsp;
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <span className="text-muted">
                    <b>SURPLUS LINES TAX - DE (OA & PA)</b>
                  </span>
                </div>

                <div>
                  <span>$0.29</span>
                  &nbsp;&nbsp;
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <span className="text-muted ">
                    <h3 className="fw-bold">Grand Total</h3>
                  </span>
                </div>

                <div>
                  <h5>$100</h5>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ActiveSubscription;
