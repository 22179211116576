/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../../../utils/Context';
import Button from 'react-bootstrap/Button';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ToastManager } from '../../../../../ToastContainer/ToastContainer';
import { GetGuestUserToken } from '../../guestUserTokenUtils';
import {
  PAYMENT_CHECKOUT,
  GET_VEHICLE_DETAILS,
} from '../../../../../api/endpoint';
import { putRequest, getRequest } from '../../../../../api/api';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../Shared/ModalContainer/ModalContainer';
import DeleteModal from '../../../Shared/ModalContainer/DeleteModal';

const PaymentPartial = ({ saveProfile }) => {
  const { profileObject, userId, setProfileObject } = useContext(Context);
  const partial_payment =
    profileObject?.programs[0]?.payment_options?.partial_payment;
  const [subscriptions, setSubscriptions] = useState(
    profileObject?.programs[0]?.subscriptions || []
  );
  const navigate = useNavigate();
  const [buttonLoadingMap, setButtonLoadingMap] = useState({});
  const [modalContent, setModalContent] = useState('');
  const [paymentIdToDelete, setPaymentIdToDelete] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (id, filteredSubscriptions) => {
    setPaymentIdToDelete(id);

    const coverageTypeMappings = {
      OA: 'Occupational Accident',
      PA: 'Passenger Accident',
      PD: 'Physical Damage',
      CG: 'Cargo',
      NT: 'Non Trucking',
    };

    const coverageTypeDetails = filteredSubscriptions.map((subscription) => {
      const codeName = subscription.coverage_type_code_name;
      const fullForm = coverageTypeMappings[codeName] || codeName;
      return `${fullForm}`;
    });

    const modalContentString = coverageTypeDetails.join(', ');

    setModalContent(modalContentString);
    setShow(true);
  };

  useEffect(() => {
    setSubscriptions(profileObject?.programs[0]?.subscriptions);
  }, [profileObject]);

  const handlePayment = async (pay_option, partIndex) => {
    // setLoading(true);
    setButtonLoadingMap((prevMap) => ({
      ...prevMap,
      [partIndex]: true,
    }));
    const data = {
      profile_id: profileObject?.id,
      program_id: profileObject?.programs[0]?.id,
      pay_option: pay_option,
    };

    const guestUserToken = GetGuestUserToken();
    const headers = {};
    headers.Authorization = guestUserToken;
    // Check if guestUserToken is not empty or null
    if (guestUserToken) {
      // If guestUserToken is not empty, include it in the headers
      headers.Authorization = guestUserToken;
    }

    const params = {
      id: userId,
      ...data,
    };

    const { result } = await putRequest(
      PAYMENT_CHECKOUT,
      params,
      data,
      headers
    );
    if (result?.public_url) {
      window.location.href = result.public_url;
      setButtonLoadingMap((prevMap) => ({
        ...prevMap,
        [partIndex]: false,
      }));
      // setLoading(false);
    }
    if (result?.error) {
      ToastManager.error(result?.error);
      setButtonLoadingMap((prevMap) => ({
        ...prevMap,
        [partIndex]: false,
      }));
    }
    if (!result?.success) {
      setButtonLoadingMap((prevMap) => ({
        ...prevMap,
        [partIndex]: false,
      }));
      ToastManager.error('Payment cannot be processed - ' + result?.message);
      setTimeout(() => {
        navigate('/quote/' + result?.navigation_key);
      }, 3000);
    }
  };

  const getProfileDetails = async () => {
    try {
      const response = await getRequest(GET_VEHICLE_DETAILS);
      setProfileObject(response.result.profiles[0]);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const subscriptionTypes = [
    {
      title: 'Make Payment - Pay for Below Policies :',
      coverageTypes: ['OA', 'PA'],
      tooltip:
        'This is Part 1 of Payment, there will be no downpayment option, this amount has to be paid in full.',
    },
    {
      title: 'Make Payment - Pay for Below Policies :',
      coverageTypes: ['NT', 'CG', 'PD'],
      tooltip:
        'This is Part 2 of Payment, there will be a downpayment option as these are yearly policies.',
    },
  ];

  const handlePolicyDelete = () => {
    const coverageTypesToDelete = paymentIdToDelete.coverageTypes;

    coverageTypesToDelete.forEach((coverageType) => {
      const subscription = getSubscriptionFor(coverageType);

      if (subscription) {
        deleteSubscription(subscription.id);
      }
    });
    getProfileDetails();
  };

  const getSubscriptionFor = (code_name) => {
    let subscription = {};
    if (profileObject?.programs[0]?.subscriptions) {
      profileObject.programs[0].subscriptions.forEach((subscriptionObj) => {
        if (subscriptionObj.coverage_type_code_name === code_name) {
          subscription = subscriptionObj;
        }
      });
    }
    return subscription;
  };

  const deleteSubscription = (subscriptionId) => {
    const data = {
      programs_attributes: [
        {
          id: profileObject?.programs[0]?.id,
          subscriptions_attributes: [
            {
              id: subscriptionId,
              _destroy: true,
            },
          ],
        },
      ],
      vehicle_details_attributes: [{}],
    };

    // Update the state immediately after deletion
    const updatedProfile = {
      ...profileObject,
      programs: [
        {
          ...profileObject?.programs[0],
          subscriptions: profileObject?.programs[0]?.subscriptions.filter(
            (sub) => sub.id !== subscriptionId
          ),
        },
      ],
    };

    saveProfile(data).then((success) => {
      setSubscriptions(updatedProfile?.programs[0]?.subscriptions || []);
    });
    return true;
  };

  const subscriptionsAvailable = subscriptions.length > 0;

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            This option allows you to pay in parts, this gateway allows you to
            pay in full for mandatory policies and downpayment option for yearly
            policies.
          </Tooltip>
        }
      >
        <span className="float-end cursor-pointer text-muted">
          <AiOutlineQuestionCircle size="20" />
        </span>
      </OverlayTrigger>
      <h4 className="font-20-sm fw-bold">Pay in Parts</h4>
      <hr />

      {subscriptionsAvailable ? (
        subscriptionTypes.map((subscriptionType, partIndex) => {
          const filteredSubscriptions = subscriptions.filter((subscription) =>
            subscriptionType.coverageTypes.includes(
              subscription?.coverage_type_code_name
            )
          );

          return filteredSubscriptions.length > 0 ? (
            <React.Fragment key={partIndex}>
              <div key={partIndex} className="border border-1 p-3 mb-3 paid">
                <div className="text-start fs-5">
                  <strong>{subscriptionType.title}</strong>
                  <span className="paymentActionBtn float-end">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${partIndex}`}>
                          {subscriptionType.tooltip}
                        </Tooltip>
                      }
                    >
                      <span className="cursor-pointer text-muted">
                        <AiOutlineQuestionCircle size="20" />
                      </span>
                    </OverlayTrigger>
                    <RiDeleteBin6Line
                      className="cursor-pointer"
                      color="red"
                      onClick={() =>
                        handleShow(subscriptionType, filteredSubscriptions)
                      }
                    />
                  </span>
                  <div className="my-3">
                    {filteredSubscriptions.map((subscription, index) => (
                      <div key={index}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="fs-6">
                              <b>{index + 1}. </b>
                              <i>
                                <strong>{subscription?.formal_name}</strong>
                              </i>{' '}
                              <span className="text-start fs-6">
                                {subscription?.coverage_type_code_name ===
                                  'OA' ||
                                subscription?.coverage_type_code_name ===
                                  'PA' ? (
                                  <p>
                                    (Drivers: {profileObject?.number_of_drivers}
                                    )
                                  </p>
                                ) : (
                                  <p>
                                    (Vehicles:{' '}
                                    {profileObject?.vehicle_details.length})
                                  </p>
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
                {partial_payment && !partial_payment[partIndex]?.enabled && (
                  <div className="paidContent">
                    <div>Paid</div>
                  </div>
                )}
                <br />
                <Button
                  className="btn silo-btn-small silo-btn__blue"
                  onClick={() =>
                    handlePayment(
                      partial_payment[partIndex]?.pay_option,
                      partIndex
                    )
                  }
                >
                  {buttonLoadingMap[partIndex] ? (
                    <Spinner size="sm" />
                  ) : (
                    partial_payment && partial_payment[partIndex]?.type
                  )}
                </Button>
              </div>
            </React.Fragment>
          ) : null;
        })
      ) : (
        <Spinner size="30" />
      )}

      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleDelete={handlePolicyDelete}
        content={
          <p>
            You are about to delete <b>{modalContent}</b>
            <br />
            <p>Are you sure you want to continue?</p>
          </p>
        }
      />
    </div>
  );
};

export default PaymentPartial;
