import React, { useEffect, useContext } from 'react';
import { UserContext } from '../../../../../utils/Context';
import { Nav, Tab } from 'react-bootstrap';
import ActivePolicy from './Section/ActivePolicy';
import ExpiredPolicy from './Section/ExpiredPolicy';
import { getVehicleDetails } from '../../../../../utils/Commonutils';
import { useNavigate } from 'react-router-dom';

const Manage = () => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const navigate = useNavigate();

  // const getSubscriptionFor = (code_name) => {
  //   let subscription = {};
  //   if (userDetails?.programs[0]?.subscriptions) {
  //     userDetails.programs[0].subscriptions.forEach((subscriptionObj) => {
  //       if (subscriptionObj.coverage_type_code_name === code_name) {
  //         subscription = subscriptionObj;
  //       }
  //     });
  //   }
  //   return subscription;
  // };

  useEffect(() => {
    getVehicleDetails(setUserDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h3>
          <b>My Policies</b>
        </h3>
        <hr />
      </div>
      <div className="d-flex justify-content-end mb-4 jCenter-sm">
        {/* <div className='mx-4'>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className='silo-btn-small silo-btn__white'>
              Select Vehicle
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      {!(userDetails?.programs[0]?.is_fully_paid) && (
        <div className="d-flex justify-content-end mt-2 mb-3">
          <div
            onClick={() => navigate('/quote/payment')}
            className=" btn btn-sm silo-btn silo-btn__blue text-center "
          >
            <strong>Resume Payment</strong>
          </div>
        </div>
      )}

      <div className="tabs-parent">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
          className=""
        >
          <div>
            <Nav variant="pills" className="text-center">
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link eventKey="first">Active Policies</Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link eventKey="second">Expired Policies</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <ActivePolicy />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <ExpiredPolicy />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default Manage;
