import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RemoveGuestUserToken } from '../GetQuote/guestUserTokenUtils';
import { RemoveLoggedInUserToken } from '../LoggedIn-Area/loggedInUserTokenUtils';
import '../../../styles/formStyles.scss';
import '../../../styles/mainLoaderStyles.scss';
import '../../../styles/globalStyles.scss';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';
import { getRequest } from '../../../api/api';
import { GET_AGENCY_PRODUCER_ID } from '../../../api/endpoint';
import { ToastManager } from '../../../ToastContainer/ToastContainer';

function ProducerPage() {
  const whitelabelId = window.location.hostname;
  const { producerName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    //Remove previous session
    RemoveGuestUserToken();
    RemoveLoggedInUserToken();

    fetchAgencyProducerId();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producerName, whitelabelId]);

  const fetchAgencyProducerId = async() => {
    try {
      const agency_producer_data = {
        whitelabel_id: whitelabelId,
        meshvi_referral_id: producerName,
      };
      const response = await getRequest(GET_AGENCY_PRODUCER_ID, agency_producer_data);
      if(response?.result?.success) {
        localStorage.setItem('agencyId', response?.result?.whitelabel_id);
        localStorage.setItem('producerId', response?.result?.meshvi_referral_id);
        navigate('/quote/vehicle-details');
      } else {
        ToastManager.error(response?.result?.message)
      }
    } catch (error) {
      console.log("err", error)
    }
  }

  return (
    <div>
      <div className="silo_page-container py-5 d-flex align-items-center">
        <div className="container">
          <div className="silo_page_section-heading text-center py-5">
            <div>
              <img
                className="mb-5"
                width="220"
                src={logo}
                alt="logo"
                onError={(e) => {
                  // If AVIF fails to load, fallback to PNG
                  e.target.src = logoPng;
                }}
              />
            </div>
            <h2>Welcome to SiloMembers!</h2>
            <h4 className="text-primary">
              Redirecting you to registeration form. Please wait a moment.
            </h4>
            <div className="silo-loader">loading....</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProducerPage;
