import React, { useState, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import VehicleType from '../VehicleType/VehicleType';
import Make from '../Make/Make';
import Year from '../Year/Year';
import VehicleModel from '../VehicleModel/VehicleModel';
import CargoCommodities from '../CargoCommodities/CargoCommodities';
import VehicleLicenseState from '../VehicleLicenseState/VehicleLicenseState';
import './manualDetails.scss';
import VehicleLicenseNumber from '../VehicleLicenseNumber/VehicleLicenseNumber';
import ScrollToTopLink from '../../../../../ScrollToTop/ScrollToTop';
import { Spinner } from 'react-bootstrap';

const ManualDetails = ({
  setShowSection1,
  setShowSection2,
  vehicleIndex,
  profile,
  setProfile,
  cargo,
  setCargo,
  saveProfile,
  unitType,
  profileObject,
}) => {
  const { vehicles } = useContext(Context);

  const [vehicleTypeError, setVehicleTypeError] = useState(null);
  const [bodyTypeError, setBodyTypeError] = useState(null);
  const [makeError, setMakeError] = useState(null);
  const [yearError, setYearError] = useState(null);
  const [cargoError, setCargoError] = useState(null);
  const [licenseError, setLicenseError] = useState(null);
  const [licenseStateError, setLicenseStateError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const whitelabelId = parseInt(localStorage.getItem('agencyId')) || null;
  const meshviReferralId = parseInt(localStorage.getItem('producerId')) || null;
  const isMobileScreen = window.innerWidth < 768;

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    setIsLoading(true);
    let isValid = true;
    if (!vehicles[vehicleIndex].vehicle_type) {
      setVehicleTypeError('Vehicle Type is required');
      isValid = false;
    } else {
      setVehicleTypeError(null);
    }

    if (!vehicles[vehicleIndex].vehicle_body_type) {
      setBodyTypeError('Vehicle Body Type is required');
      isValid = false;
    } else {
      setBodyTypeError(null);
    }

    if (!vehicles[vehicleIndex].vehicle_make) {
      setMakeError('Make is required');
      isValid = false;
    } else {
      setMakeError(null);
    }

    if (!vehicles[vehicleIndex].declared_year) {
      setYearError('Year is required');
      isValid = false;
    } else {
      setYearError(null);
    }

    if (
      !vehicles[vehicleIndex].cargo_commodities ||
      vehicles[vehicleIndex]?.cargo_commodities?.length === 0
    ) {
      setCargoError('Cargo Commodities are required');
      isValid = false;
    } else {
      setCargoError(null);
    }
    if (
      !vehicles[vehicleIndex].license_plate_state?.id ||
      vehicles[vehicleIndex].license_plate_state?.id === ''
    ) {
      setLicenseStateError('License state is required');
      isValid = false;
    } else {
      setLicenseStateError(null);
    }
    if (isValid) {
      try {
        const cargoIds = vehicles[vehicleIndex].cargo_commodities.map(
          (cargoItem) => cargoItem.id
        );
        const data = {
          vehicle_details_attributes: [
            {
              vehicle_make_id: vehicles[vehicleIndex]?.vehicle_make?.id,
              vehicle_model_id: vehicles[vehicleIndex]?.vehicle_model?.id,
              vehicle_type_id: vehicles[vehicleIndex]?.vehicle_type?.id,
              vehicle_body_type_id:
                vehicles[vehicleIndex]?.vehicle_body_type?.id,
              declared_year: vehicles[vehicleIndex]?.declared_year,
              cargo_commodity_ids: cargoIds,
              license_plate_number:
                vehicles[vehicleIndex]?.license_plate_number,
              license_plate_state_id:
                vehicles[vehicleIndex]?.license_plate_state?.id,
              with_vin: false,
            },
          ],
          programs_attributes: profileObject?.programs
            ? profileObject?.programs
            : [
                {
                  whitelabel_id: whitelabelId,
                  meshvi_referral_id: meshviReferralId,
                  device_type: isMobileScreen ? 'mobile' : 'desktop',
                },
              ],
        };
        saveProfile(data, vehicleIndex).then((success) => {
          if (success) {
            setShowSection1(false);
            setShowSection2(true);
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error('API error:', error);
        setIsLoading(false);
        // Handle API error
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="text-start">
        <Form className="user-detail-form-blue">
          {/* Vehicle Type Dropdown */}
          <Form.Group>
            <VehicleType
              vehicleTypeError={vehicleTypeError}
              setVehicleTypeError={setVehicleTypeError}
              bodyTypeError={bodyTypeError}
              setBodyTypeError={setBodyTypeError}
              vehicleIndex={vehicleIndex}
              unitType={unitType}
            />
          </Form.Group>
          <Row className="">
            <Form.Group
              as={Col}
              md={6}
              controlId="formGridMake"
              className="mb-3"
            >
              {/* Make Dropdown */}
              <Make
                makeError={makeError}
                setMakeError={setMakeError}
                vehicleIndex={vehicleIndex}
              />
              <Form.Control.Feedback type="invalid">
                {makeError}
              </Form.Control.Feedback>
            </Form.Group>
            {
              <Form.Group
                as={Col}
                md={6}
                controlId="formGridModel"
                className="mb-3"
              >
                {/* Model Dropdown */}
                <VehicleModel vehicleIndex={vehicleIndex} />
              </Form.Group>
            }
          </Row>
          <Row>
            <Form.Group
              as={Col}
              md={6}
              controlId="formGridYear"
              className="mb-3"
            >
              {/* Year Dropdown */}
              <Year
                yearError={yearError}
                setYearError={setYearError}
                vehicleIndex={vehicleIndex}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={6}
              controlId="formGridCargo"
              className="mb-3"
            >
              {/* Cargo Commodities Dropdown */}
              <CargoCommodities
                vehicleIndex={vehicleIndex}
                cargo={cargo}
                setCargo={setCargo}
                cargoError={cargoError}
                setCargoError={setCargoError}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              md={6}
              controlId="formGridState"
              className="mb-3"
            >
              <VehicleLicenseNumber
                licenseError={licenseError}
                setLicenseError={setLicenseError}
                vehicleIndex={vehicleIndex}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={6}
              controlId="formGridState"
              className="mb-3"
            >
              <VehicleLicenseState
                licenseStateError={licenseStateError}
                setLicenseStateError={setLicenseStateError}
                vehicleIndex={vehicleIndex}
              />
            </Form.Group>
          </Row>
          <span className="navigation_bottom">
            <ScrollToTopLink>
              <Button
                className="btn silo-btn-small silo-btn__blue my-3"
                onClick={handleSave}
              >
                {isLoading ? <Spinner size="sm" /> : <>Next</>}
              </Button>
            </ScrollToTopLink>
          </span>
        </Form>
      </div>
    </div>
  );
};

export default ManualDetails;
