import React from 'react';
import './featured.scss';
import step_icon_1 from '../../../../../assets/images/Home/step icon 1.svg';
import step_icon_2 from '../../../../../assets/images/Home/step icon 2.svg';
import step_icon_3 from '../../../../../assets/images/Home/step icon 3.svg';
import step_icon_4 from '../../../../../assets/images/Home/step icon 4.svg';
import shape from '../../../../../assets/images/Home/shape.avif';
import shape_png from '../../../../../assets/images/Home/shape.png';

const Featured = () => {
  return (
    <>
      <div className="container featured">
        <div className="silo_page_section-heading text-center pt-5 pb-2">
          <small>WHAT WE DO</small>
          <h2>Featured Services</h2>
          <p className="">
            Competitive Group Insurance Pricing for Independent Contractors
          </p>
        </div>
        <div className="row mt-4 mb-2 row1">
          <div className="design_element2">
            <img
              src={shape}
              alt="shape"
              onError={(e) => {
                // If AVIF fails to load, fallback to PNG
                e.target.src = shape_png;
              }}
            />
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 mb-4">
            <div className="steps-box mx-2">
              <div className="icon">
                <img src={step_icon_3} alt="step_icon_3" />
              </div>
              <h4 className="mt-4 fw-bold">Passenger Accident</h4>
              <p className="m-0">
                {' '}
                Passenger insurance covers passengers when riding as a guest and
                become injured in the event of an accident, the passenger can
                sue not only the company but the driver of the vehicle as well.
                This insurance provides accident medical benefits to authorized
                passengers.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 mb-4">
            <div className="steps-box mx-2" step="03">
              <div className="icon">
                <img src={step_icon_2} alt="step_icon_3" />
              </div>
              <h4 className="mt-4 fw-bold">Occupational Accident</h4>
              <p className="m-0">
                Occupational accident insurance coverage provides benefits to
                employees injured or killed in a job-related accident. Optional
                limits and pricing based on type of vehicle and what is being
                transported. Optional Contingent Liability aka "if any" workers
                compensation if leased onto a motor carrier. This is not
                Workers’ Compensation Insurance.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 mb-4">
            <div className="steps-box mx-2" step="01">
              <div className="icon">
                <img src={step_icon_1} alt="step_icon_1" />
              </div>
              <h4 className="mt-4 fw-bold">Physcial Damage</h4>
              <p className="m-0">
                Physical damage insurance policies provide actual cash value
                protection (or stated value, whichever is less) to an insured’s
                equipment in the event of an accident. It is important that you
                accurately value your transportation & trucking equipment. This
                coverage applies for collision and comprehensive losses
                including fire, theft and vandalism of the insured equipment.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 mb-4">
            <div className="steps-box mx-2" step="01">
              <div className="icon">
                <img src={step_icon_1} alt="step_icon_1" />
              </div>
              <h4 className="mt-4 fw-bold">Cargo</h4>
              <p className="m-0">
                Cargo insurance protects the transporter for their
                responsibility in the event of damaged or lost freight. Cargo
                can be one of the trickiest insurance policies a trucker will
                buy. This insurance policy, without question, requires careful
                thought and evaluation prior to purchasing. In addition, you
                need to be constantly evaluating your loads to make sure the
                coverage meets your needs. Optional limits available.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 mb-4">
            <div className="steps-box mx-2" step="04">
              <div className="icon">
                <img src={step_icon_4} alt="step_icon_4" />
              </div>
              <h4 className="mt-4 fw-bold">Non-Trucking/Bobtail</h4>
              <p className="m-0">
                {' '}
                Non-Trucking Liability provides limited liability insurance for
                independent contractors when not under dispatch. It provides
                limited liability protection when the owner-operator is not
                under dispatch or pulling a loaded trailer. When the
                owner-operator is under dispatch they are covered under Auto
                Liability insurance.{' '}
              </p>
            </div>
          </div>
          <div className="design_element2">
            <img src={shape} alt="shape" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Featured;
