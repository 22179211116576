import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../utils/Context';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import './vehicleDetails.scss';
import VIN from './Section/VIN/VIN';
import ManualDetails from './Section/ManualDetails/ManualDetails';
import NonMadatoryVehicleDetails from './Section/NonMadatoryVehicleDetails/NonMandatoryVehicleDetails';
import DOTInfo from './Section/DOTInfo/DOTInfo';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Arrowsvg from '../../../../assets/images/Form/Arrow.svg';
import VehicleOrderSummary from './Section/VehicleOrderSummary/VehicleOrderSummary';
import { GET_VEHICLE_DETAILS } from '../../../../api/endpoint';
import { getRequest } from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import { GetGuestUserToken } from '../guestUserTokenUtils';

const VehicleDetails = ({ current, setCurrent, saveProfile }) => {
  const location = useLocation();
  const wasRedirected = location.state && location.state.fromRedirected;
  const navigate = useNavigate();

  const {
    vehicles,
    setVehicles,
    profileObject,
    setProfileObject,
    setUserEmail,
    setUserId,
    setDisableState,
  } = useContext(Context);
  const [showSection1, setShowSection1] = useState(true);
  const [showSection2, setShowSection2] = useState(false);
  const [showDotInfo, setshowDotInfo] = useState(false);
  const [activeTab, setActiveTab] = useState('second');
  const [editVehicleIndex, setEditVehicleIndex] = useState(null);
  const [unitType, setUnitType] = useState('powerUnit');

  /* Mandatory Vehicle Details */
  const [cargo, setCargo] = useState(null);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    getVehicleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const guestUserToken = GetGuestUserToken();
    if (profileObject && guestUserToken === null) {
      navigate('/quote/vehicle-details', { replace: true });
      window.location.reload();
    } else if (profileObject && profileObject?.is_verified) {
      window.scrollTo(0, 0);
      navigate('/quote/payment');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject]);

  const getVehicleDetails = async () => {
    try {
      const response = await getRequest(GET_VEHICLE_DETAILS);
      setProfileObject(
        response.result.profiles && response?.result?.profiles[0]
      );
      setUserEmail(response?.result?.email);
      setUserId(response?.result?.id);
      response.result.profiles &&
        setVehicles(
          ...response?.result?.profiles.map(
            (profile) => profile.vehicle_details
          )
        );
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const addNewVehicle = (unitType, vehicleIndex) => {
    setDisableState(false);
    setUnitType(unitType);
    setVehicles((prevVehicles) => [...prevVehicles, {}]);
    setShowSection1(true);
    setShowSection2(false);
    setshowDotInfo(false);
    setActiveTab('second');
    setEditVehicleIndex(null);
  };

  const handleEditClick = (stepTitle, vehicleIndex) => {
    // List of stepTitles for Section 1
    const section1StepTitles = [
      'vin_number',
      'vehicle_body_type',
      'vehicle_type',
      'declared_year',
      'vehicle_make',
      'vehicle_model',
      'cargo_commodities',
      'license_plate_state',
      'license_plate_number',
      'New Vehicle',
    ];

    // List of stepTitles for DOT section
    const dotStepTitles = ['dot_information_value', 'dot_information_id'];

    // List of stepTitles for Section 2
    const section2StepTitles = [
      'eld_provider',
      'vehicle_safety_systems',
      'declared_value',
      'lienholder_1',
      'lienholder_2',
    ];

    if (section1StepTitles.includes(stepTitle)) {
      setShowSection1(true);
      setShowSection2(false);
      setshowDotInfo(false);
      setActiveTab(stepTitle !== 'vin_number' ? 'second' : 'first');
    } else if (dotStepTitles.includes(stepTitle)) {
      setShowSection1(false);
      setShowSection2(false);
      setshowDotInfo(true);
    } else if (section2StepTitles.includes(stepTitle)) {
      setShowSection1(false);
      setShowSection2(true);
      setshowDotInfo(false);
    }
    setEditVehicleIndex(vehicleIndex);
    if (vehicles[vehicleIndex].vin_number === null) {
      setDisableState(false);
    }
  };

  return (
    <>
      {vehicles &&
        vehicles.map((vehicle, vehicleIndex) => {
          const isLatestVehicle = vehicleIndex === vehicles.length - 1;
          const colClassName =
            (isLatestVehicle && editVehicleIndex === null) ||
            editVehicleIndex === vehicleIndex
              ? 4
              : 12;
          return (
            <Row className={`mb-3`} key={vehicleIndex}>
              <Col
                lg={8}
                className={`${
                  (isLatestVehicle && editVehicleIndex === null) ||
                  editVehicleIndex === vehicleIndex
                    ? ''
                    : 'd-none'
                }`}
              >
                <div className="current-container mb-3 mb-md-5">
                  {wasRedirected && (
                    <Link
                      to={'/account/vehicle-info'}
                      className="mt-5 text-decoration-none"
                    >
                      <span className="float-start back-btn-style">
                        <img src={Arrowsvg} className="pe-2" alt="" />
                        <span className="mt-5 text-blue">
                          Back to Vehicle Info
                        </span>
                      </span>
                    </Link>
                  )}
                  {showSection1 && (
                    <div className="">
                      <h3 className="fw-bold">
                        How would you like to add your Vehicle?
                      </h3>
                      <Container>
                        <Tab.Container
                          defaultActiveKey={
                            vehicle.vin_number && vehicle.vin_number.length > 0
                              ? 'first'
                              : 'second'
                          }
                        >
                          <Row>
                            <Nav variant="pills" className="">
                              <Nav.Item className="tab-button fw-bold fs-6">
                                <Nav.Link eventKey="second">
                                  Without VIN
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="tab-button fw-bold fs-6">
                                <Nav.Link eventKey="first">With VIN</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Row>
                          <Row>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <VIN
                                  setShowSection2={setShowSection2}
                                  setShowSection1={setShowSection1}
                                  activeTab={activeTab}
                                  vehicleIndex={vehicleIndex}
                                  setCargo={setCargo}
                                  saveProfile={saveProfile}
                                  profileObject={profileObject}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <ManualDetails
                                  setShowSection2={setShowSection2}
                                  setShowSection1={setShowSection1}
                                  activeTab={activeTab}
                                  vehicleIndex={vehicleIndex}
                                  profile={profile}
                                  setProfile={setProfile}
                                  cargo={cargo}
                                  setCargo={setCargo}
                                  saveProfile={saveProfile}
                                  unitType={unitType}
                                  profileObject={profileObject}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Row>
                        </Tab.Container>
                      </Container>
                    </div>
                  )}
                  {showSection2 && (
                    <div className="">
                      <h3 className="fw-bold">
                        Tell us more about your Vehicle...?
                      </h3>
                      <Container className="plr-0-sm">
                        <NonMadatoryVehicleDetails
                          setShowSection2={setShowSection2}
                          setshowDotInfo={setshowDotInfo}
                          setShowSection1={setShowSection1}
                          vehicleIndex={vehicleIndex}
                          saveProfile={saveProfile}
                        />
                      </Container>
                    </div>
                  )}
                  {showDotInfo && (
                    <div>
                      <h3 className="fw-bold">
                        Select Vehicle DOT Information
                      </h3>
                      <Container>
                        <DOTInfo
                          setShowSection2={setShowSection2}
                          setshowDotInfo={setshowDotInfo}
                          vehicleIndex={vehicleIndex}
                          addNewVehicle={addNewVehicle}
                          saveProfile={saveProfile}
                        />
                      </Container>
                    </div>
                  )}
                </div>
              </Col>
              <VehicleOrderSummary
                vehicleIndex={vehicleIndex}
                handleEditClick={handleEditClick}
                colClassName={colClassName}
              />
            </Row>
          );
        })}
    </>
  );
};

export default VehicleDetails;
