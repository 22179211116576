import React, { useContext, useState } from 'react';
import { Context } from '../../../../../../utils/Context';
import { RiDeleteBin6Line } from 'react-icons/ri';
// import Dropdown from 'react-bootstrap/Dropdown';
import { formatInput } from '../../../../../../utils/Commonutils';
import './vehiclePlanSelectionSummary.scss';
import DeleteModal from '../../../../Shared/ModalContainer/DeleteModal';

const VehiclePlanSelectionSummaryWithoutAccordion = ({
  saveProfile,
  isMemberDetail,
  isLoggedIn,
  userDetails,
}) => {
  const { profileObject } = useContext(Context);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (subscriptionId, formal_name) => {
    setSubscriptionToDelete(subscriptionId);
    setModalContent(formal_name);

    setShow(true);
  };

  // const [selectedOption, setSelectedOption] = useState('Year');

  const deleteSubscription = () => {
    if (deleteInProgress) {
      return; // Prevent multiple clicks when delete is in progress
    }
    setDeleteInProgress(true);
    const data = {
      programs_attributes: [
        {
          id: profileObject?.programs[0]?.id,
          subscriptions_attributes: [
            {
              id: subscriptionToDelete,
              _destroy: true,
            },
          ],
        },
      ],
      vehicle_details_attributes: [{}],
    };
    saveProfile(data)
      .then((success) => {
        if (success) {
          setShow(false);
        }
      })
      .finally(() => {
        setDeleteInProgress(false);
      });
  };

  // const CustomToggle = ({ onClick }) => (
  //   <h6
  //     className=""
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     Per {selectedOption}
  //   </h6>
  // );

  const userProfile = isLoggedIn ? userDetails : profileObject;
  const subscriptions = userProfile?.programs[0]?.subscriptions || [];
  const grandTotal = userProfile?.programs[0]?.grand_total || 0;
  const siloMembershipfees = 100;
  const grandTotalWithAdditionalFee = grandTotal + siloMembershipfees;

  return (
    <div className={`${isLoggedIn ? 'details-container mb-4' : null}`}>
      <div className="silo_page_section-heading">
        {isLoggedIn ? (
          <>
            <h5 className="m-0 fw-bold">Vehicle Plan Selection Summary</h5>
            <hr />
          </>
        ) : null}
        {subscriptions.length > 0 ? (
          <>
            {subscriptions.map((subscription, index) => (
              <div key={index}>
                <div className="">
                  <div className="grid-item-delete">
                    {!isMemberDetail && !userDetails && (
                      <RiDeleteBin6Line
                        className="cursor-pointer"
                        color="red"
                        size="20"
                        onClick={() =>
                          handleShow(subscription.id, subscription?.formal_name)
                        }
                      />
                    )}
                  </div>
                  <div className="grid-container">
                    <div className="grid-item-subscription">
                      <p className="fs-6 text-start fw-bold">
                        <i>{subscription?.formal_name} Premium</i>
                      </p>
                      <div className="text-start fs-6">
                        <>
                          {subscription?.policies_tax_name ? (
                            <p className="text-muted mt-2">
                              {subscription?.policies_tax_name} :
                            </p>
                          ) : null}
                          {subscription?.coverage_type_code_name === 'OA' ||
                          subscription?.coverage_type_code_name === 'PA' ? (
                            <span>
                              (Drivers: {userProfile?.number_of_drivers})
                            </span>
                          ) : (
                            <span>
                              (Vehicles: {userProfile?.vehicle_details.length})
                            </span>
                          )}
                        </>
                      </div>
                    </div>
                    <div className="grid-item-right text-right">
                      <p className="fw-bold fs-6">
                        $
                        {formatInput(subscription?.calculated_total.toFixed(2))}
                      </p>
                      <div>
                        <p className="fw-bold fs-6 mt-2">
                          $
                          {formatInput(subscription?.policies_tax.toFixed(2)) ||
                            0}
                        </p>
                        {subscription?.coverage_type_code_name !== 'OA' &&
                        subscription?.coverage_type_code_name !== 'PA' ? (
                          <span className="text-muted small">Per Year</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {(subscription?.coverage_type_code_name === 'OA' ||
                  subscription?.coverage_type_code_name === 'PA') &&
                !subscription?.pa_oa_availablity_user_state?.is_available ? (
                  <div className="text-danger text-start my-2 fontWeightBold">
                    {subscription?.pa_oa_availablity_user_state?.message}
                  </div>
                ) : (
                  ''
                )}
                <hr />
              </div>
            ))}
            <div className="grid-container">
              <div className="grid-item-left">
                <div className="fw-bold text-muted">
                  SILO Purchasing Group Membership
                </div>
              </div>
              <div className="grid-item-right">
                <p className="fw-bold fs-5  mb-0">$50.00</p>
                <span className="small text-muted">Per Year</span>
              </div>
            </div>
            <hr />
            <div className="grid-container">
              <div className="grid-item-left">
                <div className="fw-bold text-muted">
                  SILO Association Membership
                </div>
              </div>
              <div className="grid-item-right">
                <p className="fw-bold fs-5  mb-0">$50.00</p>
                <span className="small text-muted">Per Year</span>
              </div>
            </div>
            <hr />
            <div className="grid-container">
              <div className="grid-item-left">
                <h5 className="fw-bold">Grand Total</h5>
              </div>
              <div className="grid-item-right">
                <h4 className="fw-bold">
                  ${formatInput(grandTotalWithAdditionalFee?.toFixed(2))}
                </h4>
              </div>
            </div>
          </>
        ) : (
          'No Policy Selected'
        )}
        <DeleteModal
          show={show}
          handleClose={handleClose}
          handleDelete={deleteSubscription}
          // disabled={deleteInProgress}
          content={
            <p>
              Are you sure you want to delete <b>{modalContent}</b>
            </p>
          }
        />
      </div>
    </div>
  );
};

export default VehiclePlanSelectionSummaryWithoutAccordion;
