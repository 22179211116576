import React from 'react';
import About_1 from '../../../assets/images/About/about_img-1.avif';
import About_1_Png from '../../../assets/images/About/about_img-1.png';
import About_2 from '../../../assets/images/About/about_img-2.avif';
import About_2_Png from '../../../assets/images/About/about_img-2.png';
import Dotted_Lines from '../../../assets/images/About/dotted-lines1.png';
import independent from '../../../assets/images/About/independent.svg';
import powerful from '../../../assets/images/About/powerful.svg';
import About_3 from '../../../assets/images/About/about_img-3.avif';
import About_3_Png from '../../../assets/images/About/about_img-3.png';

import high from '../../../assets/images/About/high.svg';
import './about.scss';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import CommonSection from '../Home/Section/CommonSection/CommonSection';

const About = () => {
  return (
    <div>
      <Breadcrumb title={'About'} />
      <div>
        <div className="container">
          <div className="silo_page_section-heading text-center py-5">
            <small>
              SILO MEMBERS<sup>TM</sup>
            </small>
            <h2>Join Our Growing Family of Independent Contractors</h2>
          </div>
          <div className="dotted-lines-holder">
            <img
              src={Dotted_Lines}
              className="dotted-lines"
              alt="dotted_lines"
            />
            <div className="about_box pb-5">
              <div className="row justify-content-around align-items-center">
                <div className="col-sm-6">
                  <img
                    src={About_1}
                    className="img-fluid"
                    alt="about_img"
                    onError={(e) => {
                      // If AVIF fails to load, fallback to PNG
                      e.target.src = About_1_Png;
                    }}
                  />
                </div>
                <div className="col-sm-5">
                  <div className="about_box-desc">
                    <div className="icon">
                      <img
                        src={independent}
                        className="img-fluid"
                        alt="independent"
                      />
                    </div>
                    <h2 className="my-3">Independents Matter</h2>
                    <p>
                      We know that our company's success comes from
                      transportation professionals who take on the
                      responsibility of being an independent contractor.
                      <br />
                      <br />
                      In the same way that you support us to be our best, we
                      support you to be your best.
                      <br />
                      From last mile to long haul trucking drivers, we are all
                      transportation professionals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="about_box about_box--alt py-5">
              <div className="row justify-content-around align-items-center">
                <div className="col-sm-5 order-sm-2">
                  <img
                    src={About_2}
                    className="img-fluid"
                    alt="about_2"
                    onError={(e) => {
                      // If AVIF fails to load, fallback to PNG
                      e.target.src = About_2_Png;
                    }}
                  />
                </div>
                <div className="col-sm-5">
                  <div className="about_box-desc">
                    <div className="icon">
                      <img
                        src={powerful}
                        className="img-fluid"
                        alt="powerful"
                      />
                    </div>
                    <h2 className="my-3">Powerful Insurance Programs</h2>
                    <p>
                      From great occupational accident coverage when injured to
                      better cargo policy wording to protect your client, we
                      provide options you need to protect your independence.
                      <br />
                      <br />
                      Competitive insurance packages are just the start. Let's
                      talk today about how being a member of our company can
                      take you to the next level.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="about_box pt-5">
              <div className="row justify-content-around align-items-center">
                <div className="col-sm-6">
                  <img
                    src={About_3}
                    className="img-fluid"
                    alt="about_3"
                    onError={(e) => {
                      // If AVIF fails to load, fallback to PNG
                      e.target.src = About_3_Png;
                    }}
                  />
                </div>
                <div className="col-sm-5">
                  <div className="about_box-desc">
                    <div className="icon">
                      <img src={high} className="img-fluid" alt="high" />
                    </div>
                    <h2 className="my-3">High Standards</h2>
                    <p>
                      Sure, we have high standards for our members, but we have
                      high standards for ourselves, too!
                      <br />
                      <br />
                      We want to be a company that you are proud to be a part
                      of. We pride ourselves on providing high-quality insurance
                      as the center of our business plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommonSection />
      </div>
    </div>
  );
};

export default About;
