import React from 'react';
import logo from '../../../../assets/images/logo.avif';
import logoPng from '../../../../assets/images/logo.png';
import '../../footer.scss';

const FooterLinks = () => {
  return (
    <>
      <div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="100"
              y="0"
              fill="rgba(18, 99, 169,0.3)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="200"
              y="3"
              fill="rgba(18, 99, 169,0.1)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="150"
              y="5"
              fill="rgba(18, 99, 169,0.05)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="80"
              y="8"
              fill="var(--light_blue--alt)"
            />
          </g>
        </svg>
      </div>
      <footer className="font-small backgroundLightBlue">
        <div>
          <ul className="text-center py-2 text-blue mb-0">
            <div className="mb-4">
              <img
                src={logo}
                alt="silo"
                width="200px"
                onError={(e) => {
                  // If AVIF fails to load, fallback to PNG
                  e.target.src = logoPng;
                }}
              />
            </div>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default FooterLinks;
