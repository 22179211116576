import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { getRequest } from '../../../../../../api/api';
import { GET_ELD_PROVIDER_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';

const ELDProvider = ({ vehicleIndex }) => {
  const { vehicles, setVehicles } = useContext(Context);

  const [eldProviderOptions, setEldProviderOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const eldProvdierMenu = async () => {
    setIsLoading(true);
    try {
      const response = await getRequest(GET_ELD_PROVIDER_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
      }));
      setEldProviderOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    eldProvdierMenu();
  }, []);

  const handleELDProviderChange = (selectedOption) => {
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.eld_provider = selectedOption;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
  };

  return (
    <div>
      <Form.Label className="fw-bold">ELD Provider</Form.Label>
      {isLoading && (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="mx-2"
        />
      )}
      <Select
        options={eldProviderOptions}
        isClearable
        className="custom-select"
        placeholder={
          <div className="select-placeholder-text">Select ELD Provider</div>
        }
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: 'var(--form-blue)',
            primary: 'var(--blue)',
          },
        })}
        value={
          vehicles[vehicleIndex]?.eld_provider?.name
            ? {
                value: vehicles[vehicleIndex]?.eld_provider?.name,
                label: vehicles[vehicleIndex]?.eld_provider?.name,
              }
            : null
        }
        onChange={handleELDProviderChange}
      />
    </div>
  );
};

export default ELDProvider;
