import { ToastManager } from "../../../ToastContainer/ToastContainer";

export function SetGuestUserToken(token) {
  
  const expirationTime = new Date().getTime() + 100 * 24 * 60 * 60 * 1000; // 100 days in milliseconds

  // Create an object to store the token and its expiration time
  const tokenData = {
    token: token,
    expirationTime: expirationTime,
  };
  if (token) {
    localStorage.setItem('guestUserToken', JSON.stringify(tokenData));
  }
}

const isTokenExpired = (token) => {
  if (!token) return true; // Token not present
  const tokenData = JSON.parse(atob(token.split('.')[1])); // Decode token payload
  const expiryTime = tokenData.exp * 1000; // Expiry time in milliseconds
  return Date.now() >= expiryTime; // Check if current time is past expiry time
};

export function GetGuestUserToken() {
  // Get the token data from localStorage
  const tokenData = localStorage.getItem('guestUserToken');

  if (tokenData) {
    const parsedTokenData = JSON.parse(tokenData);

    // Check if the token has not expired
    if (!isTokenExpired(parsedTokenData.token)) {
      return parsedTokenData.token;
    } else {
      ToastManager.error('Oh No! Your session has expired.');
      // Token has expired, remove it from storage
      RemoveGuestUserToken();
      window.location.href = '/';
    }
  }
  return null; // No valid token found
}

export function RemoveGuestUserToken() {
  // Remove the guest user token from localStorage
  localStorage.removeItem('guestUserToken');
}