import React, { useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { handleDeleteDocument } from '../../../../../../../utils/CommonDocumentsFunctions';
import { ToastManager } from '../../../../../../../ToastContainer/ToastContainer';
import { getVehicleDetails } from '../../../../../../../utils/Commonutils';
import { GetLoggedInUserToken } from '../../../../loggedInUserTokenUtils';
import DeleteModal from '../../../../../Shared/ModalContainer/DeleteModal';

function DownloadDocument({
  document,
  profileId,
  setUserDetails,
  setDeletedFileType,
}) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleDeleteClick = () => {
    setShow(true);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setShow(false);
    const headers = {
      Authorization: GetLoggedInUserToken(),
    };
    const response = await handleDeleteDocument(
      document.type,
      profileId,
      headers
    );
    if (response?.result?.success) {
      ToastManager.success(response?.result?.message);
      await getVehicleDetails(setUserDetails);
      setDeletedFileType(document.type);
    } else {
      ToastManager.error(response.result?.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="col-md-6 mb-4 ">
      <div className="details-container h-100 justify-content-start">
        <div className="fs-5 fw-bold document-title-section">
          <span>{document.name}</span>
          <RiDeleteBin6Line
            className="cursor-pointer margin-left"
            color="red"
            size="20"
            onClick={() => handleDeleteClick()}
          />
        </div>
        <Row className="m-4 h-100 d-flex align-items-center">
          {isLoading ? (
            <div className="delete-document-loader">
              <Spinner
                animation="border"
                variant="secondary"
                size="lg"
                className="mx-2 spinner-border-xl"
                style={{ width: '5rem', height: '5rem' }}
              />
            </div>
          ) : (
            <div>
              <a
                className="text-white text-decoration-none"
                href={document.uploadedFile}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="d-flex flex-column align-items-center">
                  <BsDownload
                    size="50"
                    color="#444"
                    className="cursor-pointer"
                  />
                  <div
                    type="submit"
                    className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                  >
                    <strong>DOWNLOAD</strong>
                  </div>
                </div>
              </a>
            </div>
          )}
        </Row>
      </div>
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleDelete={handleDelete}
        content={
          <p>
            Are you sure you want to delete <b>{document.name}</b>
          </p>
        }
      />
    </div>
  );
}

export default DownloadDocument;
