import React from 'react';
import { Outlet } from 'react-router';
import shape from '../../../../assets/images/Home/shape.avif';
import shape_png from '../../../../assets/images/Home/shape.png';
import './display.scss';

const Display = () => (
  <div className="current-container-parent">
    <div className="design_element1">
      <img
        src={shape}
        alt="shape"
        onError={(e) => {
          // If AVIF fails to load, fallback to PNG
          e.target.src = shape_png;
        }}
      />
    </div>

    <div className="account-wrapper">
      <div>{<Outlet />}</div>
    </div>
    <div className="design_element2">
      <img
        src={shape}
        alt="shape"
        onError={(e) => {
          // If AVIF fails to load, fallback to PNG
          e.target.src = shape_png;
        }}
      />
    </div>
  </div>
);

export default Display;
