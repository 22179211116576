import React, { useState, useEffect } from 'react';
import './profileSection.scss';
import { useContext } from 'react';
import { UserContext } from '../../../../../utils/Context';
import { GET_VEHICLE_DETAILS } from '../../../../../api/endpoint';
import { getLoggedinRequest } from '../../../../../api/logginInUserAPI';
import Spinner from 'react-bootstrap/Spinner';
import { MdOutlineMail } from 'react-icons/md';
import { BsTelephone } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const ProfileSection = () => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pendingPayment, setPendingPayment] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await getLoggedinRequest(GET_VEHICLE_DETAILS);
      setUserDetails(response?.result?.profiles[0]);
      setEmailAddress(response?.result?.email);
      setIsLoading(false);
      const filteredData =
        await response?.result?.profiles[0]?.programs[0]?.subscriptions?.filter(
          (item) => item.status === 'pending_payment'
        );
      setPendingPayment(filteredData);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const renderRow = (label, value) => (
    <div className="col-md-6">
      <div className="silo_page_section-heading">
        <p>{label}</p>
        <h6>{value}</h6>
      </div>
    </div>
  );

  return (
    <>
      {' '}
      {isLoading ? (
        <div className="min-height">
          <Spinner
            animation="border"
            variant="secondary"
            size="lg"
            className="mx-2 spinner-border-xl"
            style={{ width: '5rem', height: '5rem' }}
          />
        </div>
      ) : (
        <>
          <div>
            <h3>
              <b>Profile</b>
            </h3>
            <hr />
          </div>
          <div className="panel_theme">
            <div className="panel_theme_head">
              Personal Information
              <div className="float-right">
                {/* <OverlayTrigger placement="bottom" overlay={editTooltip}>
                <Link to="/account/info/edit">
                  <span className="text-white">
                    <FaUserEdit size={20} />
                  </span>
                  <span className="text-white font-16 ml-five d-inline-block">
                    EDIT
                  </span>
                </Link>
              </OverlayTrigger> */}
              </div>
            </div>
            <div className="panel_theme_body">
              <div className="panel_mini_heading">Profile details</div>
              <div className="profile-section">
                <div className="d-flex justify-content-between "></div>
                <div className="row mt-4 align-items-center">
                  <div className="col-md-3 text-center">
                    {userDetails?.profile_picture_url ? (
                      <img
                        src={userDetails?.profile_picture_url}
                        alt="User"
                        width={110}
                        className="profile-name-icon"
                      />
                    ) : (
                      <span className="profile-name-icon">
                        {`${userDetails?.first_name
                          .charAt(0)
                          .toUpperCase()}${userDetails?.last_name
                          .charAt(0)
                          .toUpperCase()}`}
                      </span>
                    )}
                  </div>
                  <div className="col-md-9">
                    <div className="silo_page_section-heading">
                      <h4 className="fw-bold">{`${
                        userDetails && userDetails?.full_name
                      }`}</h4>
                      <p>
                        <MdOutlineMail /> &nbsp;
                        {emailAddress && emailAddress}
                      </p>
                      <p>
                        <BsTelephone /> &nbsp;
                        {userDetails && userDetails?.mobile}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel_mini_heading mt-5">Basic Details</div>
                <div className="row mt-4">
                  {renderRow(
                    'Street',
                    userDetails && userDetails?.shipping_address?.address_line_1
                  )}
                  {renderRow(
                    'City',
                    userDetails && userDetails?.shipping_address?.city?.name
                  )}
                </div>
                <div className="row mt-2 justify-content-center">
                  {renderRow(
                    'State',
                    userDetails && userDetails?.shipping_address?.state?.name
                  )}
                  {renderRow(
                    'ZIP',
                    userDetails && userDetails?.shipping_address?.zip5
                  )}
                </div>
              </div>
            </div>
          </div>
          {!userDetails?.programs[0]?.is_fully_paid && (
            <div className="alert alert-primary mt-5" role="alert">
              <span className="d-flex flex-direction-C justify-content-center ">
                <div className="info-text">
                  <div className="d-flex align-items-center">
                    <strong className="px-1"></strong>
                  </div>
                  <span>
                    Your payment is incomplete for{' '}
                    {pendingPayment?.map((item, index, array) => (
                      <React.Fragment key={index}>
                        <strong>{item.formal_name}</strong>
                        {index < array.length - 1 && ','}{' '}
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              </span>
              <div className="d-flex justify-content-center mt-3">
                <div
                  onClick={() => navigate('/quote/payment')}
                  className=" btn btn-sm silo-btn silo-btn__blue text-center m-auto"
                >
                  <strong>Pay Now</strong>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProfileSection;
