import { ToastManager } from '../ToastContainer/ToastContainer';
import { putRequest } from '../api/api';
import { UPDATE_PROFILE } from '../api/endpoint';
import { GetLoggedInUserToken } from '../components/pages/LoggedIn-Area/loggedInUserTokenUtils';

export const saveProfileDetails = async (
  userDetails,
  setUserDetails,
  profile
) => {
  let data = {
    vehicle_details_attributes: [{}],
    programs_attributes: [],
    shipping_address_attributes: {},
    billing_address_attributes: {},
  };

  if (profile) {
    data = { ...data, ...profile };
  }
  if (userDetails && !profile.programs_attributes) {
    data.programs_attributes = userDetails.programs;
  }
  if (userDetails && !profile.drivers_attributes) {
    data.drivers_attributes = userDetails.drivers;
  }
  if (userDetails && !profile.shipping_address_attributes) {
    data.shipping_address_attributes = userDetails.shipping_address;
  }
  if (userDetails && !profile.billing_address_attributes) {
    data.billing_address_attributes = userDetails.billing_address;
  }
  if (userDetails && !profile.vehicle_details_attributes) {
    data.vehicle_details_attributes = userDetails.vehicle_details;
  }
  const loggedInUserToken = GetLoggedInUserToken();
  const headers = {};
  headers.Authorization = loggedInUserToken;

  if (userDetails?.id) {
    const params = {
      id: userDetails.id,
    };
    const { result } = await putRequest(UPDATE_PROFILE, params, data, headers);
    if (!result?.id) {
      if (result?.error) ToastManager.error(result?.error);
      else if (result?.message) ToastManager.error(result?.message);
      else ToastManager.error('Something went wrong!');
      return false;
    } else {
      setUserDetails(result?.profiles[0]);
      ToastManager.successDefault('Details saved successfully.');
      return true;
    }
  } else {
    ToastManager.error('Something went wrong!');
  }
};
