import React from 'react';

const NonActiveDriver = () => {
  return (
    <div className="text-center">
      <div className="info-form-box">
        No Driver Found
      </div>
    </div>
  );
};

export default NonActiveDriver;
