import React from 'react';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import './privacy-policy.scss';

const Policy = () => {
  return (
    <>
      <Breadcrumb title={'Privacy Policy'} />
      <div className="container">
        <div className="text-center py-5 silo_page_section-heading">
          <small>
            SILO MEMBERS<sup>TM</sup>
          </small>
          <h2>Our Privacy Policy</h2>
        </div>
        

        <div className="pages-content-inner">
          <p>Solutions for Independent & Leased Operators, LLC respects the privacy
          of the individuals and businesses accessing/using(Users) its website
          (Website). The purpose of this General Public Privacy and Data
          Collection Policy (Policy) is to inform Users about information that may
          be gathered about them when they visit the areas of Solutions for
          Independent & Leased Operators, LLC Website accessible to the public
          (Public Areas).
          </p>
          
          <p>Solutions for Independent & Leased Operators, LLC collects limited
          information about Users who visit the Public Areas of the Website. This
          information is automatically collected on an aggregate basis (Aggregate
          Information)to measure traffic on the Website, including the Agent
          Locator. Aggregate Information is collected through the use of a cookie
          or small text file placed on the User’s hard drive by the User’s
          Internet browser. The cookie allows Solutions for Independent & Leased
          Operators, LLC to track the number of hits or visits to various pages on
          the Website, including the Agent Locator. When Solutions for Independent
          & Leased Operators, LLC Website is accessed through a hyperlink on
          another website, the cookie will identify and track the Internet
          Protocol address (IP) that linked the User to the Website. If the User
          did not hyperlink to Solutions for Independent & Leased Operators, LLC
          Website from the most recently visited IP, then Solutions for
          Independent & Leased Operators, LLC will only identify the User’s
          Internet Service Provider (e.g., Spectrum.com, yahoo.com, etc.).
          </p>
          
          <p>
          The cookie is used only during a single connection to the Website and
          Agent Locator, and is not used to track User activity after leaving the
          Website and Agent Locator. It is permanently disabled when the User
          exits the Website, and information about individual Users is not
          collected, saved or distributed to others by Solutions for Independent &
          Leased Operators, LLC. Aggregate Information and other information
          gathered through voluntary User surveys and forms assists Solutions for
          Independent & Leased Operators, LLC to better understand Users’ needs
          and improve the Website, and to enable Solutions for Independent &
          Leased Operators, LLC to demonstrate to vendors and others information
          about the volume and nature of its Website traffic.</p>
          
          <p>
          The Website and Agent Locator contain hyperlinks to Internet sites of
          Solutions for Independent & Leased Operators, LLC members and other
          businesses and resources, and each Internet site may have its own
          privacy and data collection policies and practices. Solutions for
          Independent & Leased Operators, LLC is not responsible for the privacy
          and data collection policies and practices of any of its members or
          other businesses and resources, or for the content of their Internet
          sites. Users interested in the privacy and data collection policies and
          practices of Solutions for Independent & Leased Operators, LLC member or
          other businesses and resources should review their policies. Solutions
          for Independent & Leased Operators, LLC makes no representations of any
          kind about the Internet sites of its members or other businesses and
          resources, and Solutions for Independent & Leased Operators, LLC
          disclaims all warranties and responsibilities of any kind, including,
          without limitation, warranties and responsibilities with respect to
          Solutions for Independent & Leased Operators, LLC members’ and other
          businesses’ and resources’ Internet sites, content, privacy and data
          collection policies and practices, and actions.
          </p>
          <p>
          Solutions for Independent & Leased Operators, LLC reserves the right to
          change this Policy at any time by posting changes to this page.
          </p>
        </div>
        </div>
    </>
  );
};

export default Policy;
