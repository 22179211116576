import React from 'react';
import './footer.scss';
import FooterCopyright from './Section/FooterCopyright/FooterCopyright';
import FooterLinks from './Section/FooterLinks/FooterLinks';
import { useLocation } from 'react-router-dom';
import configFooter from './configFooter';

const Footer = () => {
  const location = useLocation();
  const shouldRenderFooterLinks = !configFooter.some((route) =>
    location.pathname.startsWith(route)
  );

  const isGetQuote = location.pathname.startsWith('/quote');

  return (
    <div>
      {shouldRenderFooterLinks && <FooterLinks />}
      {isGetQuote ? (
        <div
          className={`get_quote_footer`}
        >
          <FooterCopyright />
        </div>
      ) : <FooterCopyright />}
    </div>
  );
};

export default Footer;
