import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import ActiveSubscription from './Section/ActiveSubscription';
import ExpiredSubscription from './Section/ExpiredSubscription';
import CancelledSubscription from './Section/CancelledSubscription';

const ManageSubscription = () => {
  return (
    <>
      <h3><b>Manage Subscription</b></h3>
      <hr/>
      <div className="tabs-parent">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <>
          <Nav
            variant="pills"
            className="text-center nav-three"
          >
            <Nav.Item className="tab-button fw-bold fs-6">
              <Nav.Link eventKey="first">Active Subscription</Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab-button fw-bold fs-6">
              <Nav.Link eventKey="second">Expired Subscription</Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab-button fw-bold fs-6">
              <Nav.Link eventKey="third">Cancelled Subscription</Nav.Link>
            </Nav.Item>
          </Nav>
        </>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <ActiveSubscription />
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <ExpiredSubscription />
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            <CancelledSubscription />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      </div>
    </>
  );
};

export default ManageSubscription;
