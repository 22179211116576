import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import OtpInput from 'react-otp-input';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import Arrowsvg from '../../../../../../assets/images/Form/Arrow.svg';
import './ContractDetails.scss';
import { postRequest } from '../../../../../../api/api';
import { VERIFY_OTP, RESEND_OTP } from '../../../../../../api/endpoint';
import { GetGuestUserToken } from '../../../guestUserTokenUtils';
import { POST_LOGIN } from '../../../../../../api/endpoint';
import { SetLoggedInUserToken } from '../../../../LoggedIn-Area/loggedInUserTokenUtils';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { BsFillInfoCircleFill } from 'react-icons/bs';

const ContractDetails = ({
  password,
  setShowContractDetails,
  onBackButtonClick,
}) => {
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(30);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { profileObject, userEmail } = useContext(Context);
  const navigate = useNavigate();

  const guestUserToken = GetGuestUserToken();
  const headers = {};
  headers.Authorization = guestUserToken;
  if (guestUserToken) {
    headers.Authorization = guestUserToken;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        clearInterval(timer); // Stop the countdown when it reaches 1
      } else {
        setCountdown(countdown - 1); // Decrease the countdown by 1 second
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts
    };
  }, [countdown]);

  const handleClear = () => {
    setOtp('');
  };

  const handlePostLogin = async () => {
    const loginData = {
      user: {
        email: userEmail,
        password: password,
      },
    };
    try {
      const { result, bearerToken } = await postRequest(POST_LOGIN, loginData);
      if (result) {
        const tokenWithoutBearer = bearerToken.replace('Bearer ', '');
        SetLoggedInUserToken(tokenWithoutBearer);
      } else {
        ToastManager.error(result.error);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  };

  const handleSave = async (otp, eType) => {
    setOtp(otp);
    if (otp?.length === 6) {
      setButtonLoading(true);
      const userOtp = {
        otp: otp.toString(),
      };
      const { result } = await postRequest(VERIFY_OTP, userOtp, headers, otp);
      // if (otp == 123456) {
      //   ToastManager.success('Test OTP verification successful');
      //   setButtonLoading(false);
      //   navigate('/quote/payment');
      //   handlePostLogin();
      // } else
       if (result?.error) {
        ToastManager.error(result?.error);
        setButtonLoading(false);
      } else {
        ToastManager.success('Verification Successful!');
        window.scrollTo(0, 0);
        navigate('/quote/payment');
        setButtonLoading(false);
        setShowContractDetails(false);
        handlePostLogin();
      }
    } else {
      if (eType === 'clicked') {
        ToastManager.error('Please fill all 6 fields');
      }
    }
  };

  const handleResendOTP = async () => {
    setCountdown(30);
    const resendOtp = {
      user: profileObject,
    };

    const { result } = await postRequest(RESEND_OTP, resendOtp, headers);
    if (result?.message) {
      ToastManager.success(result?.message);
    }
  };

  return (
    <div className="container current-container">
      <h3 className="text-center">
        <b>Contract and Agreement Details</b>
      </h3>
      <hr />
      <div>
        <div className="text-center silo_page_section-heading">
          <span className="text-left d-block">
            <span className="cursor-pointer" onClick={onBackButtonClick}>
              <img src={Arrowsvg} alt="arrow" />
              &nbsp;&nbsp;
              <span className="mt-5 text-blue  fs-6">Back</span>
            </span>
          </span>
          <h5 className="mt-3 fw-bold">Enter One Time Password</h5>

          <p className="my-md-3">
            Please check your email <b>{userEmail}</b> and enter the One Time
            Password you should have recieved there.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <OtpInput
            value={otp}
            onChange={(e) => handleSave(e, null)}
            inputType="tel"
            numInputs={6}
            renderSeparator={<span>-</span>}
            inputStyle="otp-input"
            renderInput={(props, i) => <input {...props} key={i} />}
            shouldAutoFocus={true}
          />
        </div>
        <div
          className={!countdown ? 'cursor-pointer text-blue fw-bold mt-4' : ''}
          onClick={countdown ? null : handleResendOTP}
          disabled={countdown > 1 ? true : false}
        >
          <span className="mt-4 d-block">
            Resend OTP {countdown ? `after ${countdown} seconds` : ''}
          </span>
        </div>
        <span className="navigation_bottom">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <button
              className="btn my-3 silo-btn-small mr-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <div className="ml-2">
              <button
                className="btn silo-btn-small silo-btn__blue my-3"
                onClick={(e) => handleSave(otp, 'clicked')}
              >
                {buttonLoading ? <Spinner size="sm" /> : <>Save</>}
              </button>
              <br />
            </div>
          </div>
        </span>
      </div>
      <div className="alert alert-primary text-start" role="alert">
        <span className="d-flex align-items-center">
          <BsFillInfoCircleFill />
          <strong className="px-2">Note:</strong>
        </span>
        <hr />
        Upon successful validation of your OTP, your information will be
        securely stored with us. Even if you choose not to make a payment
        immediately, you can log in anytime using your email and password from
        the login page. We prioritize the security of your data and appreciate
        your trust in our services.
      </div>
    </div>
  );
};

export default ContractDetails;
