import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ActiveDriver from './Section/ActiveDriver/ActiveDriver';
import NonActiveDriver from './Section/NonActiveDriver/NonActiveDriver';
// import { AiOutlinePlus } from 'react-icons/ai';
import '../DriverInfo/driverInfo.scss';

const DriverInfo = () => {
  const [activeTab, setActiveTab] = useState('activeDriver');

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className='mb-0'>
            <strong>Driver Info</strong>
          </h3>
          {/* <button className="btn silo-btn-small silo-btn__blue btn-primary">
            <AiOutlinePlus />
            Add Driver
          </button> */}
        </div>
        <hr />
        <div className='tabs-parent'>
          <Tab.Container activeKey={activeTab}>
            <Nav
              variant="pills"
              className="text-center"
            >
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="activeDriver"
                  onClick={() => handleTabChange('activeDriver')}
                >
                  Active Driver
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="nonActiveDriver"
                  onClick={() => handleTabChange('nonActiveDriver')}
                >
                  Non Active Driver
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="activeDriver">
                <ActiveDriver />
              </Tab.Pane>
              <Tab.Pane eventKey="nonActiveDriver">
                <NonActiveDriver />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default DriverInfo;
