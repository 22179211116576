import React from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../../../../../assets/images/payment-incomplete.jpg';
import './paymentIncomplete.scss'

const PaymentIncomplete = () => {

  return (
    <>
    <div className="container">
    <div className="silo_page-container">
      <div className="silo_page_section-heading text-center pt-5 pb-2 m-auto">
        <div>
          <img className="mb-4 payment-imcomplete-img" width="220" src={logo} alt="logo" />
        </div>
        <h3>Payment Incomplete !!!</h3>
        <p className="">We're sorry but your payment session has timed out. Please don't worry, you can simply initiate the payment process again.</p>
        {/* <Link to="/" className='btn btn silo-btn silo-btn__blue'>Make Payment</Link> */}
      </div>
    </div>
    </div>
    </>
  );
};

export default PaymentIncomplete;
