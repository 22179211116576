import React, { useState, useEffect, useContext } from 'react';
import {Context} from '../../../../../../utils/Context';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Form from 'react-bootstrap/Form';
import { getRequest } from '../../../../../../api/api';
import { GET_VEHICLE_SAFETY_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';

const animatedComponents = makeAnimated();

const VehicleSafety = ({ vehicleIndex }) => {
  const { vehicles, setVehicles } = useContext(Context);

  const [vehicleSafetyOptions, setVehicleSafetyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSafetyTypeMenu = async () => {
    setIsLoading(true);
    try {
      const response = await getRequest(GET_VEHICLE_SAFETY_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));
      setVehicleSafetyOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    vehicleSafetyTypeMenu();
  }, []);

  const handleSafetyTechnologiesChange = (selectedOptions) => {
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    // Update the vehicle's body_type with the selected option
    updatedVehicle.vehicle_safety_systems = selectedOptions;
    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];
    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;
    // Set the updated vehicles array
    setVehicles(updatedVehicles);
  };

  const isOptionDisabled = (option) => {
    // Check if the option is already selected
    return vehicles[vehicleIndex]?.vehicle_safety_systems?.some(
      (safetySystem) => safetySystem.id === option.id
    );
  };

  return (
    <div className="mb-3">
      <Form.Label className="fw-bold">Vehicle Safety Technologies</Form.Label>
      {isLoading && (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="mx-2"
        />
      )}
      <Select
        className="custom-select"
        closeMenuOnSelect={false}
        components={animatedComponents}
        placeholder={
          <div className="select-placeholder-text">
            Select Vehicle Safety Technologies
          </div>
        }
        isMulti
        options={vehicleSafetyOptions}
        value={vehicles[vehicleIndex]?.vehicle_safety_systems?.map(
          (safetySystem) => ({
            ...safetySystem,
            value: safetySystem.id,
            label: safetySystem.name,
          })
        )}
        onChange={handleSafetyTechnologiesChange}
        isOptionDisabled={isOptionDisabled}
      />
    </div>
  );
};

export default VehicleSafety;
