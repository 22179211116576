import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import logo from '../../../../../../assets/images/logo.avif';
import logoPng from '../../../../../../assets/images/logo.png';
import { deleteRequest } from '../../../../../../api/api';
import { DELETE_LOGOUT_USER } from '../../../../../../api/endpoint';
import {
  GetLoggedInUserToken,
  RemoveLoggedInUserToken,
} from '../../../loggedInUserTokenUtils';
import { RemoveGuestUserToken } from '../../../../GetQuote/guestUserTokenUtils';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import './logout.scss';

const Logout = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 1) {
        clearInterval(timer); // Stop the countdown when it reaches 1
        navigate('/'); // Redirect to the home page
      } else {
        setCountdown(countdown - 1); // Decrease the countdown by 1 second
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts
    };
  }, [countdown, navigate]);

  const handleLogout = async () => {
    try {
      const headers = {};
      const data = {};
      headers.Authorization = GetLoggedInUserToken();
      const response = await deleteRequest(DELETE_LOGOUT_USER, data, headers);
      if (response) {
        RemoveLoggedInUserToken();
        RemoveGuestUserToken();
      }
    } catch (error) {
      ToastManager.error(error);
    }
  };

  return (
    <div className="container">
      <div className="logout text-center">
        <div className="logout_child">
          <div>
            <img
              className="mb-4"
              width="220"
              src={logo}
              alt="logo"
              onError={(e) => {
                // If AVIF fails to load, fallback to PNG
                e.target.src = logoPng;
              }}
            />
          </div>
          <h3>You have successfully logged out!</h3>
          <p className="">
            Redirecting you back to home page in {countdown} seconds
          </p>
          <div className="gap-2 d-flex justify-content-center">
            <NavLink to="/" className="btn silo-btn-small silo-btn__blue">
              Home
            </NavLink>
            <NavLink
              to="/quote/vehicle-details"
              className="btn silo-btn-small silo-btn__blue"
            >
              Get a Quote
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
