import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import Logo from '../../assets/images/logo.avif';
import logoPng from '../../assets/images/logo.png';

import { NavLink, useLocation } from 'react-router-dom';
import './navbar.scss';
import configNav from './configNav';
import ScrollToTopLink from '../ScrollToTop/ScrollToTop';

const Navigationbar = ({ isLoggedIn }) => {
  const location = useLocation();
  const shouldRenderNavbarLinks = !configNav.some((route) =>
    location.pathname.startsWith(route)
  );
  const [expanded, setExpanded] = useState(false); // Initialize the expanded state

  const closeNavbar = () => {
    setExpanded(false); // Close the Navbar when a NavLink is clicked
  };

  return (
    <Navbar
      collapseOnSelect
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)} // Toggle the expanded state when the Navbar Toggle is clicked
      bg="light"
      expand="lg"
      className="sticky-top py-3"
    >
      <Container className="flex-wrap-unset">
        <Navbar.Brand href="/" className="p-0-sm">
          <img
            src={Logo}
            alt="SILO"
            className="w-40 w-150px-sm"
            onError={(e) => {
              // If AVIF fails to load, fallback to PNG
              e.target.src = logoPng;
            }}
          />
        </Navbar.Brand>
        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="text-sm-center justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav
              className="d-flex align-items-center mt-10-sm"
              onClick={closeNavbar} // Close Navbar when NavLink is clicked
            >
              <NavLink
                to="/"
                className="silo-trans-btn text-decoration-none nav-link border-line fs-6"
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className="silo-trans-btn text-decoration-none nav-link border-line fs-6"
              >
                About
              </NavLink>
            </Nav>
            {shouldRenderNavbarLinks && (
              <Nav className="d-flex ml-20 ml-0-sm" onClick={closeNavbar}>
                <NavLink
                  to="/login"
                  className="silo-trans-btn text-decoration-none nav-link border-line fs-6"
                >
                  {isLoggedIn ? 'My Account' : 'Login'}
                </NavLink>
                {!isLoggedIn ? (
                  <ScrollToTopLink
                    to="/quote/vehicle-details"
                    className="text-decoration-none"
                  >
                    <div className="backgroundBlue text-white px-4 py-2 w-sm-25 w-md-25 btn silo-btn silo-btn__blue fs-6 mt-10-sm  mb-10-sm ">
                      Get a Quote
                    </div>
                  </ScrollToTopLink>
                ) : null}
              </Nav>
            )}
          </Navbar.Collapse>
        </>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
