import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import { getRequest } from '../../../../../../api/api';
import { GET_VEHICLE_MODEL_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import { VehicleModelPaginateApi } from './VehicleModelPaginateAPI';

const VehicleModel = ({ vehicleIndex }) => {
  const {
    vehicles,
    setVehicles,
    setManualSelectAccess,
  } = useContext(Context);

  const [modelOptions, setModelOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, Setpage] = useState(1);

  let value;

  useEffect(() => {
    Setpage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles[vehicleIndex]?.vehicle_make?.id])

  const modelTypeMenu = async (inputValue) => {
    let vehicle_make_id;
    if (!value) {
      value = true;
      if (inputValue !== undefined && inputValue !== '') {
        vehicle_make_id = {
          name: inputValue,
          vehicle_make_id: vehicles[vehicleIndex]?.vehicle_make?.id,
        };
      } else {
        vehicle_make_id = {
          vehicle_make_id: vehicles[vehicleIndex]?.vehicle_make?.id,
        };
      }

      if (vehicle_make_id.vehicle_make_id !== undefined) {
        setIsLoading(true);
        try {
          const response = await getRequest(
            GET_VEHICLE_MODEL_API,
            vehicle_make_id
          );
          const attributes = response.result;
          attributes.pop();
          const mappedOptions = attributes.map((item) => ({
            ...item,
            label: item.name,
          }));
          setModelOptions(mappedOptions);
        } catch (error) {
          console.log('API error:', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (vehicles[vehicleIndex]?.vehicle_make) {
      modelTypeMenu();
    } else {
      // Reset Model and Model options when Make is blank
      setModelOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles[vehicleIndex]?.vehicle_make]);

  useEffect(() => {
    if (modelOptions.length === 1) {
      const updatedVehicle = { ...vehicles[vehicleIndex] };
      updatedVehicle.vehicle_model = modelOptions[0];
      const updatedVehicles = [...vehicles];
      updatedVehicles[vehicleIndex] = updatedVehicle;
      setVehicles(updatedVehicles);
      setManualSelectAccess(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelOptions]);

  const onModelChange = (model) => {
    value = true;
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    // Update the vehicle's body_type with the selected option
    updatedVehicle.vehicle_model = model;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    setManualSelectAccess(4);
  };

  return (
    <div>
      <Form.Label className="fw-bold">Model</Form.Label>
      {isLoading && (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="mx-2"
        />
      )}
      <VehicleModelPaginateApi
        makeId={vehicles[vehicleIndex]?.vehicle_make?.id}
        page={page}
        Setpage={Setpage}
        ModelData={vehicles[vehicleIndex]?.vehicle_model}
        SetModelOptions={setModelOptions}
        handleVehicleModelChange={onModelChange}
      />
    </div>
  );
};

export default VehicleModel;
