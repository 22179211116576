import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Arrowsvg from '../../../../../../assets/images/Form/Arrow.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getRequest } from '../../../../../../api/api';
import { GET_DOT_INFORMATION_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import ScrollToTopLink from '../../../../../ScrollToTop/ScrollToTop';
import { checkVehicleDetailsFilled } from '../../../../../../utils/Commonutils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './dotInfo.scss';

const DOTInfo = ({
  setShowSection2,
  setshowDotInfo,
  vehicleIndex,
  addNewVehicle,
  saveProfile,
}) => {
  const { vehicles, setVehicles, profileObject } = useContext(Context);

  const navigate = useNavigate();
  const [dotRadioOptions, setdotRadioOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dotNumberError, setDotNumberError] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dotNumber, setDotNumber] = useState(
    vehicles[vehicleIndex]?.dot_information_value || ''
  );
  const isVehicleDetailsFilled = checkVehicleDetailsFilled(
    profileObject?.vehicle_details
  );
  const tooltip = !isVehicleDetailsFilled ? (
    <Tooltip id="tooltip">
      Please fill all information of your other vehicles.
    </Tooltip>
  ) : (
    <></>
  );

  // const isLastVehicle = vehicleIndex === vehicles.length - 1;

  const dotOptionsMenu = async () => {
    setIsLoading(true);
    try {
      const response = await getRequest(GET_DOT_INFORMATION_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setdotRadioOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dotOptionsMenu();

    const savedDotNumber = localStorage.getItem('clientDotNumber');
    if (savedDotNumber) {
      setDotNumber(savedDotNumber);
      // Update the vehicles state with the saved dotNumber
      const updatedVehicle = { ...vehicles[vehicleIndex] };
      updatedVehicle.dot_information_value = savedDotNumber;
      const updatedVehicles = [...vehicles];
      updatedVehicles[vehicleIndex] = updatedVehicle;
      setVehicles(updatedVehicles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    setShowSection2(true);
    setshowDotInfo(false);
  };

  const handleDOTInfoSave = async (DotSave) => {
    // e.preventDefault();
    setButtonLoading(true);
    let isValid = true;

    if (
      vehicles[vehicleIndex]?.dot_information?.id !== 2 &&
      !vehicles[vehicleIndex].dot_information_value
    ) {
      setButtonLoading(false);
      setDotNumberError('DOT Number is required');
      isValid = false;
    } else {
      setDotNumberError(null);
    }

    if (isValid) {
      try {
        const data = {
          vehicle_details_attributes: [
            {
              dot_information_value:
                vehicles[vehicleIndex]?.dot_information_value,
              dot_information: vehicles[vehicleIndex]?.dot_information,
              dot_information_id: vehicles[vehicleIndex]?.dot_information_id,
            },
          ],
        };
        saveProfile(data, vehicleIndex).then((success) => {
          if (success) {
            if (DotSave) {
              navigate('/quote/choose-plan#OAPA');
              setshowDotInfo(false);
            }
          }
          setButtonLoading(false);
        });
      } catch (error) {
        console.log('API error: ', error);
      } finally {
      }
    }
  };

  const handleOnChange = (e) => {
    const newDotNumber = e.target.value;
    if (vehicles[vehicleIndex]?.dot_information_value) {
      setDotNumberError(null);
    }

    setDotNumber(newDotNumber);
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.dot_information_value = newDotNumber;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
  };

  const handleOptionChange = (value) => {
    const newSelectedOption = value.id;
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.dot_information_id = newSelectedOption;
    updatedVehicle.dot_information = value;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    if (value.id === 2) {
      setDotNumberError(null);
    }
  };

  const handleAddNewVehicleClick = (unitType) => {
    if (
      (vehicles[vehicleIndex].dot_information?.id !== 2 &&
        !vehicles[vehicleIndex]?.dot_information_value) ||
      dotNumberError
    ) {
      setDotNumberError('DOT Number is required');
    } else {
      const data = {
        vehicle_details_attributes: [
          {
            dot_information_value:
              vehicles[vehicleIndex]?.dot_information_value,
            dot_information: vehicles[vehicleIndex]?.dot_information,
            dot_information_id: vehicles[vehicleIndex]?.dot_information_id,
          },
        ],
      };
      saveProfile(data, vehicleIndex).then(() => {
        addNewVehicle(unitType, vehicleIndex);
      });
    }
  };

  return (
    <div className="text-start">
      <div className="">
        <div className="text-start p-0 py-3">
          <span
            className="cursor-pointer back-btn-style ml-n10"
            onClick={handleBack}
          >
            <img src={Arrowsvg} alt="arrow" />
            &nbsp;&nbsp;
            <span className="mt-5 text-blue fs-6">Back</span>
          </span>{' '}
        </div>
      </div>
      <Form className="user-detail-form-blue">
        <Row>
          <Col
            md={6}
            className="mb-3 fs-6 p-4 lh-lg bg-light rounded bg-area-sm"
          >
            {isLoading ? (
              <>
                <span>Loading Items...</span>
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                  className="mx-2"
                />
              </>
            ) : (
              // Render dotRadioOptions here
              dotRadioOptions.map((option) => (
                <Form.Group key={option.value}>
                  <Form.Check
                    className="radio-label"
                    type="radio"
                    label={option.label}
                    name="options"
                    id={option.value}
                    value={option.value}
                    checked={
                      vehicles[vehicleIndex]?.dot_information_id
                        ? option.id ===
                          vehicles[vehicleIndex]?.dot_information_id
                        : localStorage.getItem('clientDotNumber')
                        ? handleOptionChange(dotRadioOptions[2])
                        : handleOptionChange(dotRadioOptions[0])
                    }
                    onChange={() => handleOptionChange(option)}
                  />
                </Form.Group>
              ))
            )}
          </Col>
          <Col md={6} className="plr-0-sm">
            <Form.Group className="mb-3" controlId="formBasicDot">
              <Form.Label className="fw-bold">
                DOT #
                {vehicles[vehicleIndex]?.dot_information?.id !== 2 && (
                  <span className="text-danger">&nbsp;*</span>
                )}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter DOT #"
                value={dotNumber}
                onChange={handleOnChange}
                isInvalid={!!dotNumberError}
                readOnly={!!localStorage.getItem('clientDotNumber')}
                className={
                  localStorage.getItem('clientDotNumber')
                    ? 'grayedOutInput'
                    : ''
                }
              />
              <Form.Control.Feedback type="invalid">
                {dotNumberError}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <span className="navigation_bottom navi_pad">
          <div className="mlr-n15 pb-3">
            {vehicleIndex === vehicles?.length - 1 ? (
              <>
                <div className="d-flex justify-content-between flex-direction-C w-100-sm">
                  <div className="d-flex  flex-direction-C btn_set_100_sm">
                    <ScrollToTopLink>
                      <Button
                        className="btn w-100-sm silo-btn-small silo-btn__white my-1 my-md-0"
                        onClick={() => handleAddNewVehicleClick('powerUnit')}
                      >
                        Add a Power Unit
                      </Button>
                    </ScrollToTopLink>
                    <ScrollToTopLink>
                      <Button
                        className="btn w-100-sm silo-btn-small silo-btn__white my-1 my-md-0 ml-10-desk"
                        onClick={() => handleAddNewVehicleClick('nonPowerUnit')}
                      >
                        Add a Non Power Unit
                      </Button>
                    </ScrollToTopLink>
                  </div>
                  <div className="d-flex flex-direction-C btn_set_100_sm">
                    {buttonLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        <ScrollToTopLink>
                          <Button
                            className="btn w-100-sm silo-btn-small silo-btn__blue my-1 my-md-0 me-2"
                            onClick={() => handleDOTInfoSave(true)}
                          >
                            View Plans
                          </Button>
                        </ScrollToTopLink>
                        {vehicles.length > 1 && (
                          <ScrollToTopLink>
                            <Button
                              className="btn w-100-sm silo-btn-small silo-btn__blue my-3 float-end my-1 my-md-0"
                              onClick={() => handleDOTInfoSave(false)}
                            >
                              Save DOT Info
                            </Button>
                          </ScrollToTopLink>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {buttonLoading ? (
                  <div className="d-flex justify-content-end">
                    <Spinner size="sm" />
                  </div>
                ) : (
                  <>
                    <OverlayTrigger placement="top" overlay={tooltip}>
                      <Button
                        className="btn w-100-sm silo-btn-small silo-btn__blue my-3 float-end my-1 my-md-0 ms-2"
                        disabled={!isVehicleDetailsFilled}
                        onClick={() => handleDOTInfoSave(true)}
                      >
                        View Plans
                      </Button>
                    </OverlayTrigger>

                    {vehicles.length > 1 && (
                      <ScrollToTopLink>
                        <Button
                          className="btn w-100-sm silo-btn-small silo-btn__blue my-3 float-end my-1 my-md-0"
                          onClick={() => handleDOTInfoSave(false)}
                        >
                          Save DOT Info
                        </Button>
                      </ScrollToTopLink>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </span>
      </Form>
    </div>
  );
};

export default DOTInfo;
