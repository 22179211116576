import React, { useState, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
// import { postRequest } from '../../../../../../api/api.js';
// import { VALUE_AI } from '../../../../../../api/endpoint.js';
import './value.scss';
// import ChatButton from '../../../../../LiveChat/ChatButton';
// import Spinner from 'react-bootstrap/Spinner';

const Value = ({ vehicleIndex }) => {
  const { vehicles, setVehicles } = useContext(Context);

  // const [answer, setAnswer] = useState(
  //   <>
  //     'Using AI to calculate vehicle value...'&nbsp;
  //     <Spinner animation="border" variant="secondary" size="sm" />
  //   </>
  // );

  const [isChatOpen, setIsChatOpen] = useState(false);
  // const [hour, setHour] = useState(null);

  // useEffect(() => {
  //   const hour = new Date().getHours();
  //   setHour(hour);
  // }, []);

  // eslint-disable-next-line no-unused-vars
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const formatInput = (value) => {
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      return new Intl.NumberFormat('en-US').format(numericValue);
    } else {
      return '';
    }
  };

  const handleOnChange = (event) => {
    // Get the input value and remove non-numeric characters
    const inputValue = event.target.value.replace(/[^0-9]/g, '');

    // Parse the input value as a number or set it to null if empty
    const numericValue = inputValue !== '' ? parseInt(inputValue, 10) : null;

    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    updatedVehicle.declared_value = numericValue;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
  };

  // useEffect(() => {
  //   const { vehicle_body_type, VehicleType, Make, Model, Year } =
  //     vehicles[vehicleIndex];
  //   const data = {
  //     user_prompt:
  //       'Based on selected vehicle, please suggest estimated price of vehicle. Keep it short.',
  //     context: `Vehicle Body Type: ${vehicle_body_type}, Vehicle Type: ${VehicleType}, Make: ${Make}, Model: ${Model}, Year: ${Year}`,
  //     chat_memory_key: `nkjdf83${hour}`,
  //   };

  // const fetchData = async () => {
  //   try {
  //     const response = await postRequest(VALUE_AI, data);
  //     const answer = response.result.Answer;
  //     setAnswer(answer);
  //   } catch (error) {
  //     console.error('API error:', error);
  //   }
  // };

  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hour]);

  return (
    <div className="text-start">
      <Form.Label className="fw-bold">Value</Form.Label>
      <InputGroup>
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          required
          placeholder="Enter Value of Vehicle"
          value={
            vehicles[vehicleIndex]?.declared_value
              ? formatInput(vehicles[vehicleIndex]?.declared_value)
              : ''
          }
          onChange={handleOnChange}
        />
      </InputGroup>

      <Form.Text className="text-muted float-end">
        Value should only contain digits
      </Form.Text>
      {/* <Col md={6}>
          <div className="">
            <b>
              How to calculate Value of your vehicle?&nbsp; &nbsp;
              <span
                className="badge bg-primary cursor-pointer"
                onClick={toggleChat}
              >
                Need Help ?
              </span>
            </b>
            <div className="">
              <div className="alert alert-secondary my-2">{answer}</div>
            </div>
            <ChatButton openChat={isChatOpen} setIsChatOpen={setIsChatOpen} />
          </div>
        </Col> */}
    </div>
  );
};

export default Value;
