import React from 'react';
import { useState } from 'react';
import { UserContext } from './Context';

export const LoggedinUserContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState();

  return (
    <UserContext.Provider
      value={{
        userDetails,
        setUserDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
