import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getRequest } from '../../../../../../api/api';
import { GET_VEHICLE_TYPE_API } from '../../../../../../api/endpoint';
import { GET_BODY_TYPE_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';

const VehicleType = ({
  vehicleIndex,
  vehicleTypeError,
  setVehicleTypeError,
  bodyTypeError,
  setBodyTypeError,
  unitType,
}) => {
  const {
    vehicles,
    setVehicles,
    manualSelectAccess,
    setManualSelectAccess,
    disableState,
    setDisableState,
  } = useContext(Context);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(false);
  const [isBodyTypeLoading, setIsBodyTypeLoading] = useState(false);

  const vehicleTypeMenu = async (vehicle_body_type_id) => {
    setIsVehicleTypeLoading(true);
    try {
      const body_type_id = { vehicle_body_type_id: vehicle_body_type_id };
      const response = await getRequest(GET_VEHICLE_TYPE_API, body_type_id);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
      }));
      setVehicleTypeOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsVehicleTypeLoading(false);
    }
  };

  useEffect(() => {
    if (
      vehicles[vehicleIndex].vin_number === null ||
      vehicles[vehicleIndex].vin_number === undefined
    ) {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
    bodyTypeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vehicles[vehicleIndex]?.vehicle_body_type?.id !== undefined) {
      vehicleTypeMenu(vehicles[vehicleIndex]?.vehicle_body_type?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles[vehicleIndex]?.vehicle_body_type]);

  useEffect(() => {
    // Check if there's only one body type option
    if (vehicleTypeOptions.length === 1) {
      // If there's only one option, auto-select it
      // Create a copy of the current vehicle
      const updatedVehicle = { ...vehicles[vehicleIndex] };

      // Update the vehicle's body_type with the selected option
      updatedVehicle.vehicle_type = vehicleTypeOptions[0];

      // Create a copy of the current vehicles array
      const updatedVehicles = [...vehicles];

      // Update the vehicle at the specified index with the modified vehicle
      updatedVehicles[vehicleIndex] = updatedVehicle;
      setManualSelectAccess(2);
      // Set the updated vehicles array
      setVehicles(updatedVehicles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeOptions]);

  const bodyImageNames = [
    'Cargo/Sprinter Van',
    'Truck-Tractor',
    'flatbed-tractor-trailer',
    'Other-Bus',
    'Straight Truck',
    'Trailer',
  ];

  const bodyImageMap = {};

  Promise.all(
    bodyImageNames.map((imageName) =>
      Promise.all([
        import(`../../../../../../assets/images/body-types/${imageName}.avif`).catch(() => null),
        import(`../../../../../../assets/images/body-types/${imageName}.png`).catch(() => null),
      ]).then(([avifModule, pngModule]) => {
        // Use AVIF if supported, otherwise use PNG
        bodyImageMap[imageName] = avifModule?.default || pngModule?.default;
      })
      .catch((error) => {
        ToastManager.error(error)
      })
    )
  );

  const CustomOption = ({ innerProps, label, isFocused }) => (
    <div
      {...innerProps}
      className={`icons-custom-options ${isFocused ? 'is-focused' : ''}`}
    >
      <span>{label}</span>
      {bodyImageMap[label] && (
        <img
          src={bodyImageMap[label]}
          alt={`${label} Logo`}
          className="opacity-75"
          width="60"
          height="25"
        />
      )}
    </div>
  );

  const bodyTypeMenu = async () => {
    setIsBodyTypeLoading(true);

    let category_type = unitType === 'powerUnit' ? false : true;
    if (vehicles[vehicleIndex]?.vehicle_type?.category_type) {
      if (
        vehicles[vehicleIndex]?.vehicle_type?.category_type === 'power unit'
      ) {
        category_type = false;
      } else {
        category_type = true;
      }
    }
    try {
      const response = await getRequest(GET_BODY_TYPE_API, {
        category_type: category_type,
      });
      const mappedOptions = response.result.map((item) => ({
        ...item,
        label: item.name,
      }));
      setBodyTypeOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsBodyTypeLoading(false);
    }
  };

  const handleVehicleTypeChange = (selectedOption) => {
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.vehicle_type = selectedOption;
    updatedVehicle.vehicle_make = null;
    updatedVehicle.vehicle_model = null;
    updatedVehicle.cargo_commodities = null;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    if (selectedOption !== null) {
      setManualSelectAccess(2);
    } else {
      setManualSelectAccess(1);
    }
    if (vehicleTypeError) {
      setVehicleTypeError(null);
    }
  };

  const handleBodyTypeChange = (selectedOption) => {
    // Create a copy of the current vehicle
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.vehicle_body_type = selectedOption;
    updatedVehicle.vehicle_type = null;
    updatedVehicle.vehicle_make = null;
    updatedVehicle.vehicle_model = null;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    if (selectedOption !== null) {
      setManualSelectAccess(1);
    } else {
      setManualSelectAccess(0);
    }

    if (bodyTypeError) {
      setBodyTypeError(null);
    }
    setDisableState(false);
  };

  return (
    <div>
      <Row>
        <Form.Group
          as={Col}
          md={6}
          controlId="formGridBodyType"
          className="mb-3"
        >
          <Form.Label className="fw-bold">
            Vehicle Body Type<span className="text-danger">&nbsp;*</span>
          </Form.Label>
          {isBodyTypeLoading && (
            <Spinner
              animation="border"
              variant="secondary"
              size="sm"
              className="mx-2"
            />
          )}
          <Select
            options={bodyTypeOptions}
            isDisabled={disableState}
            isClearable
            className={`custom-select ${bodyTypeError ? 'is-invalid' : ''}`}
            placeholder={
              <div className="select-placeholder-text">Select Vehicle Body Type</div>
            }
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: 'var(--form-blue)',
                primary: 'var(--blue)',
              },
            })}
            value={
              vehicles[vehicleIndex]?.vehicle_body_type?.name
                ? {
                    value: vehicles[vehicleIndex]?.vehicle_body_type?.name,
                    label: vehicles[vehicleIndex]?.vehicle_body_type?.name,
                  }
                : null
            }
            onChange={handleBodyTypeChange}
            components={{
              Option: CustomOption,
            }}
          />{' '}
          <Form.Control.Feedback type="invalid">
            {bodyTypeError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          as={Col}
          md={6}
          controlId="formGridVehicleType"
          className="mb-3"
        >
          <Form.Label className="fw-bold">
            Vehicle Type<span className="text-danger">&nbsp;*</span>
          </Form.Label>
          {isVehicleTypeLoading && (
            <Spinner
              animation="border"
              variant="secondary"
              size="sm"
              className="mx-2"
            />
          )}
          <Select
            isDisabled={
              disableState ||
              (manualSelectAccess !== 1 &&
                manualSelectAccess !== 2 &&
                manualSelectAccess !== 3 &&
                manualSelectAccess !== 4)
            }
            options={vehicleTypeOptions}
            isClearable
            className={`custom-select ${vehicleTypeError ? 'is-invalid' : ''}`}
            placeholder={
              <div className="select-placeholder-text">Select Vehicle Type</div>
            }
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: 'var(--form-blue)',
                primary: 'var(--blue)',
              },
            })}
            value={
              vehicles[vehicleIndex]?.vehicle_type?.name
                ? {
                    value: vehicles[vehicleIndex]?.vehicle_type?.name,
                    label: vehicles[vehicleIndex]?.vehicle_type?.name,
                  }
                : null
            }
            onChange={handleVehicleTypeChange}
          />
          <Form.Control.Feedback type="invalid">
            {vehicleTypeError}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </div>
  );
};

export default VehicleType;
