import React, { useEffect, useState } from 'react';
import EditProfile from './Section/EditProfile/EditProfile';
import EditAddress from './Section/EditAddress/EditAddress';
import { Link } from 'react-router-dom';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import { GET_CITY_LIST, GET_STATE_LIST } from '../../../../../../api/endpoint';
import { getRequest } from '../../../../../../api/api';

const EditAccount = () => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: '',
  });

  const [profileFieldError, setProfileFieldError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: '',
  })
  const [addressData, setAddressData] = useState({
    address: '',
    zip: '',
    state: '',
    city: '',
  });

  const [addressfieldErrors, setAddressFieldErrors] = useState({
    address: '',
    zip: '',
    state: '',
    city: '',
  });

  const [selectStateError, setSelectStateError] = useState('');
  const [selectCityError, setSelectCityError] = useState('');
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    })
    setAddressData({
      ...addressData,
      [name]: value,
    });
    setProfileFieldError({
      ...profileFieldError,
      [name]: '',
    });
    setAddressFieldErrors({
      ...addressfieldErrors,
      [name]: '',
    });
  };

  const handleStateChange = (selectedOption) => {
    setAddressData({
      ...addressData,
      state: selectedOption.value,
    });
    billingCity(selectedOption ? selectedOption.id : null)
    if (!selectedOption) {
      setSelectStateError('Please select an option');
    } else {
      setSelectStateError('');
    }
  };

  const handleCityChange = (selectedOption) => {
    setAddressData({
      ...addressData,
      city: selectedOption.value,
    });
    if (!selectedOption) {
      selectCityError('Please select an option');
    } else {
      setSelectCityError('');
    }
  };

  const billingState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST)
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id
      }))
      setStateOption(mappedOptions);
    } catch (error) {
      console.log("err", error)
    }
  }

  const billingCity = async (state_id) => {
    try {
      const billing_state_id = {
        state_id: state_id,
      };
      const response = await getRequest(GET_CITY_LIST, billing_state_id)
      const cityData = response.result.data;
      const cityMapped = cityData.map((item) => ({
        value: item.attributes.name,
        label: item.attributes.name,
        id: item.attributes.id,
      }))
      setCityOption(cityMapped)
    } catch (error) {
      console.log("err", error)
    }
  }

  const handleAutoCompleteSelect = (selectedAddress) => {
    setAddressData({
      ...addressData,
      city: selectedAddress.city,
      state: selectedAddress.state,
    });
  };

  useEffect(() => {
    billingState();
  }, [])

  const handleSave = (e) => {
    e.preventDefault();
    let hasErrors = false;
    const newProfileFieldErrors = {};
    const newAddressFieldErrors = {};

    for (const fieldName in profileData) {
      if (!profileData[fieldName] || profileData[fieldName].trim() === '') {
        newProfileFieldErrors[fieldName] = `${fieldName} is required`;
        hasErrors = true;
      }
    }

    for (const fieldName in addressData) {
      if (!addressData[fieldName] || addressData[fieldName].trim() === '') {
        newAddressFieldErrors[fieldName] = `${fieldName} is required`;
        hasErrors = true;
      }
    }

    if (!addressData.address || addressData.address.length < 15) {
      newAddressFieldErrors['address'] =
        'Address must be at least 15 characters long';
      hasErrors = true;
    }

    if (!addressData.state && !addressData.city) {
      setSelectStateError('Please select an option');
      setSelectCityError('Please select an option');
      hasErrors = true;
    }

    if (hasErrors) {
      setProfileFieldError(newProfileFieldErrors)
      setAddressFieldErrors(newAddressFieldErrors);
      ToastManager.error('Form Validation failed. Please check errors before saving.');
      return;
    }

    const userData = {
      profile: profileData,
      address: addressData,
    };
    console.log(userData);
  };


  return (
    <>
      <EditProfile
        profileData={profileData}
        setProfileData={setProfileData}
        handleChange={handleChange}
        profileFieldError={profileFieldError}
        setProfileFieldError={setProfileFieldError}
      />
      <EditAddress
        addressData={addressData}
        setAddressData={setAddressData}
        handleChange={handleChange}
        addressfieldErrors={addressfieldErrors}
        setAddressFieldErrors={setAddressFieldErrors}
        selectCityError={selectCityError}
        setSelectCityError={setSelectCityError}
        selectStateError={selectStateError}
        setSelectStateError={setSelectStateError}
        handleCityChange={handleCityChange}
        handleStateChange={handleStateChange}
        stateOption={stateOption}
        setStateOption={setStateOption}
        cityOption={cityOption}
        setCityOption={setCityOption}
        handleAutoCompleteSelect={handleAutoCompleteSelect}
      />
      <button
        className="btn silo-btn-small silo-btn__blue my-3 btn-primary mx-3"
        onClick={handleSave}
      >
        Save
      </button>
      <Link to={'/account/info'}>
        <button className="btn silo-btn-small my-3 ant-steps-item-content ">
          Cancel
        </button>
      </Link>
    </>
  );
};

export default EditAccount;
