import React from 'react';
import shape from '../../../../../assets/images/Home/shape.avif';
import shape_png from '../../../../../assets/images/Home/shape.png';
import step_icon_1 from '../../../../../assets/images/Home/step icon 1.svg';
import step_icon_2 from '../../../../../assets/images/Home/step icon 2.svg';
import step_icon_3 from '../../../../../assets/images/Home/step icon 3.svg';
import step_icon_4 from '../../../../../assets/images/Home/step icon 4.svg';
import ScrollToTopLink from '../../../../ScrollToTop/ScrollToTop';

const HowItWorks = () => {
  return (
    <>
      <section className="silo_page-how-it-works py-5">
        <div className="design_element2">
          <img
            src={shape}
            alt="shape"
            onError={(e) => {
              // If AVIF fails to load, fallback to PNG
              e.target.src = shape_png;
            }}
          />
        </div>

        <div className="silo_page_section-heading text-center pt-5 pb-2">
          <div className="container">
            <small>HOW IT WORKS</small>
            <h2>Simple & Easy Steps</h2>
            <p className="">
              Select Insurance for your Commercial Vehicle Needs in 4 Steps
            </p>
          </div>
        </div>

        <div className="silo_page-steps-boxes">
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="steps-box mt-4" step="01">
                <div className="icon">
                  <img src={step_icon_1} alt="step_icon_1" />
                </div>
                <h4>Vehicle Details</h4>
                <p className="m-0">
                  Enter 17 digit VIN or select vehicle details.
                </p>
              </div>

              <div className="steps-box mt-5" step="02">
                <div className="icon">
                  <img src={step_icon_2} alt="step_icon_2" />
                </div>
                <h4>Choose Plan</h4>
                <p className="m-0">
                  Limits and coverage option to fit your needs.
                </p>
              </div>

              <div className="steps-box" step="03">
                <div className="icon">
                  <img src={step_icon_3} alt="step_icon_3" />
                </div>
                <h4>Member's Information</h4>
                <p className="m-0">Enter you and your beneficiary's info.</p>
              </div>

              <div className="steps-box mt-5" step="04">
                <div className="icon">
                  <img src={step_icon_4} alt="step_icon_4" />
                </div>
                <h4>Payment</h4>
                <p className="m-0">Monthly payment options available.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <ScrollToTopLink to="/quote/vehicle-details">
            <span className="btn silo-btn silo-btn__blue m-5 mb-0-sm-imp">
              Get Started
            </span>
          </ScrollToTopLink>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
