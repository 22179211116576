import React, { useState } from 'react';
import './comparison.scss';

const OAcomparison = ({ policyData }) => {
  const [yearly, Setyearly] = useState(false);

  const PlanCost = [
    {
      planName: 'Basic',
      monthlyPrice: 450,
      yearlyPrice: 450 * 12,
    },
    {
      planName: 'Simple Start',
      monthlyPrice: 530,
      yearlyPrice: 530 * 12,
    },
    {
      planName: 'Simple Start',
      monthlyPrice: 530,
      yearlyPrice: 530 * 12,
    },
    {
      planName: 'Simple Start',
      monthlyPrice: 530,
      yearlyPrice: 530 * 12,
    },
  ];

  const toggle = () => {
    Setyearly(!yearly);
  };

  const getPrice = (index) => {
    const plan = PlanCost[index];
    return yearly ? plan.yearlyPrice : plan.monthlyPrice;
  };

  return (
    <article>
      <h1 className="text-center m-5">Pricing Plan</h1>
      <table>
        <thead>
          <tr>
            <th className="hide">
              <div className="d-flex">
                <h5>Monthly</h5>
                <div className="form-check form-switch mx-1">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    onClick={toggle}
                  />
                </div>
                <h5>Annual</h5>
              </div>
            </th>
            {policyData.slice(0, -1).map((header, index) => (
              <th
                key={index}
                className={`text-center bg-${
                  index === 1 || index === 3
                    ? 'blue'
                    : index === 2
                    ? 'red'
                    : 'purple'
                } ${index === 2 ? 'default' : ''}`}
              >
                {header.planName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="txt-top fs-3 fw-bold">Cost</td>
            {PlanCost.map((_, index) => (
              <td key={index} className="text-center">
                <span className="fs-3 fw-bold">$</span>
                <span className="fs-3 fw-bold">{getPrice(index)}</span>
                {yearly && <span className="fs-3 fw-bold">/yr</span>}
                {!yearly && <span className="fs-3 fw-bold">/mo</span>}
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="11" className="sep">
              ACCIDENTAL DEATH BENEFIT
            </td>
          </tr>
          {['Principal Sum'].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              SURVIVOR'S BENEFIT
            </td>
          </tr>
          {[
            'Principal Sum',
            'Monthly Benefit Percentage',
            'Monthly Benefit Amount',
            'Lump Sum Amount if Elected',
          ].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              ACCIDENTAL DISMEMBERMENT BENEFIT
            </td>
          </tr>
          {['Principal Sum'].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              PARALYSIS BENEFIT
            </td>
          </tr>
          {['Principal Sum'].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              TEMPORARY TOTAL DISABILITY BENEFIT
            </td>
          </tr>
          {[
            'Disability Commencement Period',
            'Waiting Period',
            'Beneﬁt Percentage',
            'Maximum Weekly Beneﬁt Amount',
            'Maximum Beneﬁt Period',
          ].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              CONTINUOUS TOTAL DISABILITY BENEFIT
            </td>
          </tr>
          {[
            'Waiting Period',
            'Maximum Beneﬁt Period for Temporary Total Disability Percentage',
            'Maximum Weekly Beneﬁt Amount',
            'Maximum Beneﬁt Period',
          ].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$124</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              PARALYSIS BENEFIT
            </td>
          </tr>
          {['Principal Sum'].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="11" className="sep">
              PARALYSIS BENEFIT
            </td>
          </tr>
          {['Principal Sum'].map((feature, index) => (
            <tr key={index}>
              <td>{feature}</td>
              {[...Array(4)].map((_, rowIndex) => (
                <td key={rowIndex} className={index === 2 ? 'default' : ''}>
                  <span className="">$123</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </article>
  );
};

export default OAcomparison;
