import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import logo from '../../../../../../assets/images/payment-success.avif';
import logoPng from '../../../../../../assets/images/payment-success.avif';
import './paymentSuccess.scss';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import {
  GetGuestUserToken,
  SetGuestUserToken,
} from '../../../guestUserTokenUtils';
import { putRequest, getRequest } from '../../../../../../api/api';
import {
  PAYMENT_SUCCESS,
  GET_VEHICLE_DETAILS,
} from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = ({
  securityKey,
  token,
  profile_id,
  program_id,
  setDisplayPaymentSuccess,
}) => {
  const [succesResponse, setSuccessResponse] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const { setUserEmail, setProfileObject } = useContext(Context);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();

  const handlePaymentSuccess = async () => {
    let guestUserToken = GetGuestUserToken();
    if (!guestUserToken) {
      SetGuestUserToken(token);
      guestUserToken = token;
    }
    const headers = {};
    headers.Authorization = guestUserToken;
    // Check if guestUserToken is not empty or null
    if (guestUserToken) {
      // If guestUserToken is not empty, include it in the headers
      headers.Authorization = guestUserToken;
    }

    const params = {
      id: '',
    };
    const data = {
      profile_id: profile_id,
      program_id: program_id,
      security_key: securityKey,
    };
    const { result } = await putRequest(PAYMENT_SUCCESS, params, data, headers);
    if (result?.message === 'Payment Successful') {
      setShowLoader(false);
      setTimeout(() => {
        setDisplayPaymentSuccess(false);
      }, 4000);
      setSuccessResponse(result?.message);
      getVehicleDetails();
      localStorage.removeItem('agencyId');
      localStorage.removeItem('producerId');
    }
    if (result?.error) {
      setShowLoader(false);
      window.scrollTo(0, 0);
      navigate('/quote/payment');
      ToastManager.error(result?.error);
    }
    if (!result?.success) {
      setShowLoader(false);
      ToastManager.error('Payment cannot be completed - ' + result?.message);
      setShowErrorMessage(true);
    }
  };

  const getVehicleDetails = async () => {
    try {
      const response = await getRequest(GET_VEHICLE_DETAILS);
      setProfileObject(response.result.profiles[0]);
      setUserEmail(response?.result?.email);
      if (response.result.profiles[0]?.programs[0]?.is_fully_paid) {
        navigate('/account/info');
      }
    } catch (error) {
      console.log('API error:', error);
    }
  };

  useEffect(() => {
    handlePaymentSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="silo_page-container py-5 d-flex align-items-center">
        <div className="silo_page_section-heading text-center pt-5 pb-2 m-auto overflow-hidden">
          {showLoader ? (
            <Spinner
              animation="border"
              variant="success"
              size="lg"
              className="mx-2 spinner-border-xl"
              style={{ width: '11rem', height: '11rem' }}
            />
          ) : (
            <>
              {showErrorMessage ? (
                <div className="d-flex justify-content-center align-items-center border border-5 p-5">
                  <h4 className='lh-lg'>
                    We regret to inform you that there is a delay in processing
                    your payment. Our team is actively working to resolve the
                    issue, and we kindly ask for your patience. If your payment
                    has been debited, but the payment process is incomplete, we
                    kindly request you to reach out to us at{' '}
                    <a href="mailto:support@SILOmembers.com">
                      support@SILOmembers.com
                    </a>{' '}
                    for prompt assistance. We appreciate your understanding and
                    apologize for any inconvenience caused.
                  </h4>
                </div>
              ) : (
                <>
                  <div>
                    <img
                      className="mb-4 payment-success-img"
                      width="220"
                      src={logo}
                      alt="logo"
                      onError={(e) => {
                        // If AVIF fails to load, fallback to PNG
                        e.target.src = logoPng;
                      }}
                    />
                  </div>
                  <h3>{succesResponse}</h3>
                  <p className="">
                    Your Payment is Successful, Redirecting you in few seconds
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
