import React from 'react';
import CTA from '../../../../../assets/images/CTA.avif';
import CTA_png from '../../../../../assets/images/CTA.png';
import ScrollToTopLink from '../../../../ScrollToTop/ScrollToTop';
import shape from '../../../../../assets/images/Home/shape.avif';
import shape_png from '../../../../../assets/images/Home/shape.png';
import dots from '../../../../../assets/images/Home/dots.png';

const CommonSection = () => {
  return (
    <div className="silo_page-insurance py-5">
      <div className="design_element1">
        <img src={dots} alt="dots" />
      </div>
      <div className="design_element2">
        <img
          src={shape}
          alt="shape"
          onError={(e) => {
            // If AVIF fails to load, fallback to PNG
            e.target.src = shape_png;
          }}
        />
      </div>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-6">
            <div className="silo_page_section-heading py-5">
              <small>
                SILO MEMBERS<sup>TM</sup>
              </small>
              <h2>Get Insurance For Your Commercial Vehicle Now</h2>
              <p className="">
                Options available for Class 1-8 Vehicles.&nbsp; <br />
                Find your SILO Savings and get started now.&nbsp;
              </p>
              <ScrollToTopLink to="/quote/vehicle-details">
                <span className="mt-4 btn silo-btn silo-btn__blue">
                  Get Started
                </span>
              </ScrollToTopLink>
            </div>
          </div>
          <div className="col-sm-5">
            <img
              className="img-fluid"
              src={CTA}
              alt="CTA"
              onError={(e) => {
                // If AVIF fails to load, fallback to PNG
                e.target.src = CTA_png;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSection;
