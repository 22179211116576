import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../../../utils/Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NTComparison from './NTComparison';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import { formatInput } from '../../../../../../utils/Commonutils';
import { Spinner } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import NTDocument from '../../../../../../assets/Documents/Association Member NTL Guidelines 23 - Signed.pdf';

const NonTrucking = ({
  handleTabChange,
  policyData,
  getSubscriptionFor,
  saveProfile,
  NTPolicyDetailsListing,
  NTpolicyListingRepsonse,
  handleModalShow,
}) => {
  const { vehicles, profileObject, setModalValue } = useContext(Context);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isCoverageDeclined, setIsCoverageDeclined] = useState(false);

  useEffect(() => {
    const ntSubscription = getSubscriptionFor('NT');
    if (Object.keys(ntSubscription).length !== 0) {
      setIsCoverageDeclined(false);
    } else {
      setIsCoverageDeclined(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTabChange]);

  const handleNTSave = () => {
    setButtonLoading(true);
    if (isCoverageDeclined) {
      handleDecline();
      setButtonLoading(false);
      return false;
    }

    const ntSubscription = getSubscriptionFor('NT');
    const data = {
      programs_attributes: [
        {
          id: profileObject?.programs[0]?.id,
          subscriptions_attributes: [
            {
              id: ntSubscription.id,
              subject_type: 'Program',
              subject_id: vehicles[0]?.id,
              subscribable_type: 'PolicyDetail',
              subscribable_id:
                NTpolicyListingRepsonse && NTpolicyListingRepsonse[0]?.id,
              type: 'YearlySubscription',
            },
          ],
        },
      ],
      vehicle_details_attributes: [{}],
    };
    saveProfile(data).then((success) => {
      const isOAPlanSelected = getSubscriptionFor('OA');
      const isPAPlanSelected = getSubscriptionFor('PA');
      if (success) {
        NTPolicyDetailsListing();
        if (
          profileObject?.drivers.length === 0 &&
          (Object.keys(isOAPlanSelected).length !== 0 ||
            Object.keys(isPAPlanSelected).length !== 0)
        ) {
          setModalValue('add');
          handleModalShow();
        } else {
          window.scrollTo(0, 0);
          navigate('/quote/member-information');
        }
      }
      setButtonLoading(false);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleShow = () => {
    setShowCompare(!showCompare);
  };
  const [showCompare, setShowCompare] = useState(false);

  const ntPolicyData = [
    {
      id: 'bronze',
      type: 'radio',
      name: 'policy',
      value: 'bronze',
    },
  ];

  const handleDecline = () => {
    const ntSubscription = getSubscriptionFor('NT');
    const data = {
      programs_attributes: [
        {
          id: profileObject?.programs[0]?.id,
          subscriptions_attributes: [
            {
              id: ntSubscription.id,
              _destroy: true,
            },
          ],
        },
      ],
      vehicle_details_attributes: [{}],
    };
    saveProfile(data).then((success) => {
      if (success) {
        const shouldNavigate =
          profileObject &&
          profileObject?.programs[0]?.subscriptions.length === 1 &&
          profileObject?.programs[0]?.subscriptions[0]
            .coverage_type_code_name === 'NT';
        handleCheckout(shouldNavigate);
      }
    });
    return false;
  };

  const handleCheckout = (shouldNavigate = false) => {
    if (
      profileObject?.programs[0]?.subscriptions.length > 0 &&
      !shouldNavigate
    ) {
      if (profileObject?.programs[0]?.grand_total <= 0) {
        ToastManager.error('Subscription total cannot be zero!');
        return false;
      } else {
        const isOAPlanSelected = getSubscriptionFor('OA');
        const isPAPlanSelected = getSubscriptionFor('PA');
        if (
          profileObject?.drivers.length === 0 &&
          (Object.keys(isOAPlanSelected).length !== 0 ||
            Object.keys(isPAPlanSelected).length !== 0)
        ) {
          setModalValue('add');
          handleModalShow();
        } else {
          window.scrollTo(0, 0);
          navigate('/quote/member-information');
          setButtonLoading(false);
        }
      }
    } else {
      ToastManager.error('Please Select atleast one policy to Checkout!');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {/* <span
          className={`btn silo-btn-small ${
            showCompare ? 'silo-btn__blue' : ''
          }`}
          onClick={handleShow}
        >
          {showCompare ? 'Summary' : 'Compare Policy'}
        </span> */}
      </div>
      <div className="text-start">
        {showCompare ? (
          <div>
            <NTComparison policyData={policyData} />
          </div>
        ) : (
          <Row>
            <Form className="user-detail-form-blue">
              <Row className="mt-2 mt-md-5 pricing-plan">
                {profileObject?.for_nt_policy?.number_of_pu === 0
                  ? null
                  : ntPolicyData.map((field) => (
                      <React.Fragment key={field.id}>
                        <Col md={6} lg={6} sm={12} className="">
                          <Form.Group className={`m-0`} controlId={field.id}>
                            <>
                              <div className="mb-3">
                                <div className="box cursor-pointer">
                                  <div
                                    className={`card ${
                                      isCoverageDeclined ? '' : 'selected'
                                    }`}
                                    onClick={() => setIsCoverageDeclined(false)}
                                  >
                                    <div className="d-flex justify-content-end">
                                      <Form.Check
                                        type={field.type}
                                        name={field.plan_type?.name}
                                        value={field.plan_type?.code_name}
                                        checked={!isCoverageDeclined}
                                        readOnly
                                      />
                                    </div>
                                    <span className="plan-type">
                                      Non-Trucking/Bobtail{' '}
                                    </span>
                                    <hr />
                                    {NTpolicyListingRepsonse && (
                                      <>
                                        <span className="d-flex justify-content-between flex-direction-C align-items-C-sm">
                                          <span className="text-black m-0 m-md-2 fs-4 font-16-sm">
                                            <b>Total</b>{' '}
                                          </span>

                                          <span className="text-black fw-bold text-center-sm text-md-end text-center fs-4 p-0 pt-md-2 mt-2 mt-md-0 font-16-sm">
                                            <div className="text-black fw-bold text-md-end text-center fs-4 p-0 pt-md-2 text-center-sm font-16-sm">
                                              $
                                              {(NTpolicyListingRepsonse[0]?.annual_rate).toFixed(
                                                2
                                              )}
                                              / yr
                                            </div>
                                          </span>
                                        </span>

                                        <span className="text-muted fw-bold text-end fs-5 pt-2">
                                          {NTpolicyListingRepsonse[0]
                                            ?.monthly_rate && '$'}
                                          {NTpolicyListingRepsonse[0]
                                            ?.monthly_rate ? (
                                            <>
                                              {formatInput(
                                                NTpolicyListingRepsonse[0]
                                                  ?.monthly_rate
                                              )}
                                              /mo
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </span>
                                        <span className="text-muted fw-bold text-end fs-6 pt-2">
                                          {NTpolicyListingRepsonse[0]
                                            ?.weekly_rate && '$'}
                                          {NTpolicyListingRepsonse[0]
                                            ?.weekly_rate ? (
                                            <>
                                              {formatInput(
                                                NTpolicyListingRepsonse[0]
                                                  ?.weekly_rate
                                              )}
                                              <span className="slash">/</span>
                                              <span className="" title="week">
                                                wk
                                              </span>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </span>
                                      </>
                                    )}

                                    <hr />

                                    <span className="d-flex justify-content-between">
                                      <span className="text-black m-0 m-md-2 fs-4 font-16-sm">
                                        <b>Limits</b>
                                      </span>
                                      <span className="text-muted fw-bold text-center fs-4 p-0 pt-md-2 font-16-sm">
                                        $<span>1,000,000.00</span>
                                      </span>
                                    </span>
                                    <span className="text-muted fw-bold text-center fs-6 pt-2">
                                      <a
                                        href={NTDocument}
                                        className="NT-link-text"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <u>
                                          MVR Membership Guidelines for
                                          Non-Trucking Liability{' '}
                                        </u>
                                      </a>
                                    </span>
                                    <span
                                      type="submit"
                                      className="mt-4 btn silo-btn silo-btn__blue text-center"
                                    >
                                      <strong>SELECT</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          </Form.Group>
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          sm={12}
                          className="box cursor-pointer"
                        >
                          <Form.Group
                            className={`mb-3 card h-100 ${
                              isCoverageDeclined ? 'selected' : ''
                            }`}
                            onClick={() => setIsCoverageDeclined(true)}
                          >
                            <div className="d-flex justify-content-end">
                              <Form.Check
                                type="radio"
                                name="coverageDeclined"
                                value="coverageDeclined"
                                checked={isCoverageDeclined ? true : false}
                                readOnly
                              />
                            </div>
                            <span className="plan-details">
                              <span className="text-center mb-2">
                                <ImCross color="red" size={30} />
                              </span>

                              <span className="plan-type text-center">
                                Decline Coverage
                              </span>
                              <p className="text-center fs-6 py-3">
                                I do not wish to take this coverage.
                              </p>
                            </span>
                          </Form.Group>
                        </Col>
                        <hr className="mt-4" />
                        {profileObject?.for_nt_policy?.message.length > 0 ? (
                          <div className="col-md-12">
                            <div
                              className="alert alert-danger mt-3"
                              role="alert"
                            >
                              <span className="d-flex align-items-center">
                                <BsFillInfoCircleFill />
                                <strong className="px-2">Note:</strong>
                              </span>
                              <hr />
                              <div>{profileObject.for_nt_policy.message}</div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-12">
                          <div
                            className="alert alert-primary mt-3"
                            role="alert"
                          >
                            <span className="d-flex align-items-center">
                              <BsFillInfoCircleFill />
                              <strong className="px-2">
                                Non Trucking/Bobtail:
                              </strong>
                            </span>
                            <hr />

                            <div>
                              <strong>ACCIDENT/LOSS:</strong>
                              To report an Accident: Phone{' '}
                              <b>
                                <i>888.799.6642</i>
                              </b>
                              ; Fax{' '}
                              <b>
                                <i>888.799.5628.</i>
                              </b>
                              <div>
                                <strong className="text-danger p-1 mt-2 bg-danger bg-opacity-25">
                                  Must be reported immediately or within 72
                                  hours.
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
              </Row>
              {profileObject?.for_nt_policy?.number_of_pu === 0 ? (
                <>
                  <div className="alert alert-danger mb-5" role="alert">
                    <span className="d-flex flex-direction-C">
                      <div className="d-flex align-items-center">
                        <BsFillInfoCircleFill />
                        <strong className="px-1"></strong>
                      </div>
                      <span>{profileObject?.for_nt_policy?.message}</span>
                    </span>
                  </div>
                  <span className="navigation_bottom">
                    <div className="d-flex justify-content-between flex-direction-C">
                      <Button
                        className="btn w-100-sm silo-btn-small silo-btn__white my-2"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          handleTabChange('cargo');
                        }}
                      >
                        Back
                      </Button>
                      <div className="d-flex justify-content-end flex-direction-C">
                        <Button
                          className="btn w-100-sm silo-btn-small silo-btn__blue my-2"
                          onClick={() => handleCheckout()}
                        >
                          {buttonLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <>Move to Checkout</>
                          )}
                        </Button>
                      </div>
                    </div>
                  </span>
                </>
              ) : (
                <>
                  <div className="alert alert-warning" role="alert">
                    <span className="d-flex flex-direction-C">
                      <div className="d-flex align-items-center">
                        <BsFillInfoCircleFill />
                        <strong className="px-1"></strong>
                      </div>
                      <span>
                        Sorry, we are not providing this service in the
                        following state: Michigan.{' '}
                      </span>
                    </span>
                  </div>
                  <span className="navigation_bottom">
                    <div className="d-flex justify-content-between flex-direction-C">
                      <Button
                        className="btn w-100-sm silo-btn-small silo-btn__white my-2"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          handleTabChange('cargo');
                        }}
                      >
                        Back
                      </Button>
                      <div className="d-flex justify-content-end flex-direction-C">
                        <Button
                          className="btn w-100-sm silo-btn-small silo-btn__blue my-2"
                          onClick={() => handleNTSave(true)}
                        >
                          {buttonLoading ? (
                            <Spinner size="sm" />
                          ) : !isCoverageDeclined ? (
                            <>Save & Continue to Checkout</>
                          ) : (
                            <>I do not want this coverage</>
                          )}
                        </Button>
                      </div>
                    </div>
                  </span>
                </>
              )}
            </Form>
          </Row>
        )}
      </div>
    </>
  );
};

export default NonTrucking;
