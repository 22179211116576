import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <>
      <section className="silo_page-banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="silo_page_section-heading py-5">
                <small>For Members of SILO™ Association</small>
                <h2>SILO Members</h2>
                <p className="">
                  Exclusive sponsored group insurance rates for members of SILO
                  Association.
                </p>
                <ul className="silo_ul-pointers">
                  <li>
                    Occupational Accident &amp; Contingent Liability Workers
                    Comp&nbsp;
                  </li>
                  <li>Physical Damage with Towing Limits Options</li>
                  <li>Cargo</li>
                  <li>Non-Trucking Liability / Bobtail</li>
                  <li>Passenger Accident Liability&nbsp;&nbsp;</li>
                </ul>

                <Link to="/about" className="btn silo-btn silo-btn__blue">
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
