import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RemoveGuestUserToken } from '../GetQuote/guestUserTokenUtils';
import { RemoveLoggedInUserToken } from '../LoggedIn-Area/loggedInUserTokenUtils';
import '../../../styles/formStyles.scss';
import '../../../styles/mainLoaderStyles.scss';
import '../../../styles/globalStyles.scss';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';

function DotPage() {
  const { dotNumber } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    //Remove previous session
    RemoveGuestUserToken();
    RemoveLoggedInUserToken();

    // Save dotNumber to localStorage
    localStorage.setItem('clientDotNumber', dotNumber);

    // Redirect to /quote
    navigate('/quote/vehicle-details');
  }, [dotNumber, navigate]);

  return (
    <div>
      <div className="silo_page-container py-5 d-flex align-items-center">
        <div className="container">
          <div className="silo_page_section-heading text-center py-5">
            <div>
              <img
                className="mb-5"
                width="220"
                src={logo}
                alt="logo"
                onError={(e) => {
                  // If AVIF fails to load, fallback to PNG
                  e.target.src = logoPng;
                }}
              />
            </div>
            <h2>Welcome to SiloMembers!</h2>
            <h4 className="text-primary">
              Redirecting you to registeration form. Please wait a moment.
            </h4>
            <div className="silo-loader">loading....</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DotPage;
