import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Context } from '../../../utils/Context';
import VehicleDetails from './VehicleDetails/VehicleDetails';
import ChoosePlan from './ChoosePlan/ChoosePlan';
import Payment from './Payment/Payment';
import MemberInformation from './MemberInformation/MemberInformation';
import shape from '../../../assets/images/Home/shape.png';
import shapeTwo from '../../../assets/images/Home/independent shape.png';
import Truck from '../../../assets/images/Quote/trucking.svg';
import Member from '../../../assets/images/Quote/member.svg';
import Plans from '../../../assets/images/Quote/plans.svg';
import Wallet from '../../../assets/images/Quote/wallet.svg';
import Vehicle from '../../../assets/images/Quote/vehicle.svg';
import CheckMark from '../../../assets/images/Quote/check.svg';
import { Steps, Popover } from 'antd';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import '../../../styles/mainLoaderStyles.scss';
import logo from '../../../assets/images/logo.avif';

import {
  SetGuestUserToken,
  GetGuestUserToken,
} from './guestUserTokenUtils.jsx';
import { putRequest, postRequest } from '../../../api/api';
import {
  CREATE_PROFILE,
  GET_VEHICLE_DETAILS,
  UPDATE_PROFILE,
} from '../../../api/endpoint';
import { ToastManager } from '../../../ToastContainer/ToastContainer';
import { getLoggedinRequest } from '../../../api/logginInUserAPI.js';

const GetQuote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    profileObject,
    setProfileObject,
    setUserEmail,
    setUserId,
    vehicles,
    setVehicles,
    userId,
  } = useContext(Context);
  const [current, setCurrent] = useState(0);
  const [isFullyPaid, setIsFullyPaid] = useState(false);
  const [getQuoteLoader, setGetQuoteLoader] = useState(true);

  const saveProfile = async (profile, vehicleIndex = 0, vinUpdate = false) => {
    let data = {
      vehicle_details_attributes: [{}],
      programs_attributes: [],
      shipping_address_attributes: {},
      billing_address_attributes: {},
    };

    if (profile) {
      data = { ...data, ...profile };
    }

    const vehicle = vehicles[vehicleIndex] || {};
    const {
      id,
      vin_number,
      declared_value,
      dot_information_value,
      vehicle_make,
      vehicle_model,
      vehicle_type,
      vehicle_body_type,
      declared_year,
      eld_provider,
      dot_information_id,
      cargo_commodities,
      vehicle_safety_systems,
      license_plate_number,
      license_plate_state,
      lienholder_1_id,
      lienholder_2_id,
      lienholders_attributes,
    } = vehicle;

    if (!vinUpdate) {
      data.vehicle_details_attributes[0] = {
        id: id || null,
        vin_number: vin_number || null,
        declared_value: declared_value || null,
        dot_information_value: dot_information_value || null,
        vehicle_make_id: vehicle_make?.id || null,
        vehicle_model_id: vehicle_model?.id || null,
        vehicle_type_id: vehicle_type?.id || null,
        vehicle_body_type_id: vehicle_body_type?.id || null,
        declared_year: declared_year || null,
        eld_provider_id: eld_provider?.id || null,
        dot_information_id: dot_information_id || null,
        cargo_commodity_ids: cargo_commodities?.map((item) => item.id) || [],
        vehicle_safety_system_ids:
          vehicle_safety_systems?.map((item) => item.id) || [],
        license_plate_state_id: license_plate_state?.id || null,
        license_plate_number: license_plate_number || null,
        with_vin: vin_number ? true : false,
        lienholder_1_id: lienholder_1_id || null,
        lienholder_2_id: lienholder_2_id || null,
        lienholders_attributes: lienholders_attributes || {},
      };
    }

    if (cargo_commodities && cargo_commodities?.length === 0) {
      // Check if cargo_commodities are not selected
      ToastManager.error(
        'Please select at least one cargo commodity to proceed.'
      );
      return false;
    }
    // Set shipping_address_attributes based on profileObject
    if (profileObject && !profile.programs_attributes) {
      data.programs_attributes = profileObject.programs;
    }

    if (profileObject && !profile.drivers_attributes) {
      data.drivers_attributes = profileObject.drivers;
    }
    if (profileObject && !profile.shipping_address_attributes) {
      data.shipping_address_attributes = profileObject.shipping_address;
    }
    if (profileObject && !profile.billing_address_attributes) {
      data.billing_address_attributes = profileObject.billing_address;
    }
    const guestUserToken = GetGuestUserToken();
    const headers = {};
    headers.Authorization = guestUserToken;
    // Check if guestUserToken is not empty or null
    if (guestUserToken) {
      // If guestUserToken is not empty, include it in the headers
      headers.Authorization = guestUserToken;
    }
    if (profileObject?.id) {
      const params = {
        id: profileObject.id,
      };
      const { result } = await putRequest(
        UPDATE_PROFILE,
        params,
        data,
        headers
      );
      // Handle the response as needed
      if (!result?.id) {
        if (result?.error) ToastManager.error(result?.error);
        else if (result?.message) ToastManager.error(result?.message);
        else ToastManager.error('Something went wrong!');
        return false;
      } else {
        setProfileObject(result?.profiles[0]);
        ToastManager.successDefault('Details saved successfully.');
        setUserEmail(result?.email);
        setUserId(result?.id);
        setVehicles(
          ...result?.profiles?.map((profile) => profile.vehicle_details)
        );
        return true;
      }
    } else {
      const { result, bearerToken } = await postRequest(
        CREATE_PROFILE,
        data,
        headers
      );
      // Handle the response as needed
      if (!result?.id) {
        if (result?.error) ToastManager.error(result?.error);
        else if (result?.message) ToastManager.error(result?.message);
        else ToastManager.error('Something went wrong!');
        return false;
      } else {
        ToastManager.successDefault('Details saved successfully.');
        setProfileObject(result?.profiles[0]);
        setUserEmail(result?.email);
        setUserId(result?.id);
        setVehicles(
          ...result?.profiles?.map((profile) => profile.vehicle_details)
        );
        if (guestUserToken == null) SetGuestUserToken(bearerToken);
        return true;
      }
    }
  };

  // to check if user exists
  const getUserDetails = async () => {
    try {
      const response = await getLoggedinRequest(GET_VEHICLE_DETAILS);
      setIsFullyPaid(response?.result?.profiles[0]?.programs[0]?.is_fully_paid);
      setGetQuoteLoader(false);
    } catch (error) {
      setGetQuoteLoader(false);
      console.log('API error:', error);
    }
  };

  useEffect(() => {
    if (profileObject?.id) {
      // Hotjar Tracking Code
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3809789, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      // Hotjar User Identification and Custom Attributes
      window.hj('identify', userId, {
        'Signed up': profileObject.created_at,
        'HasFullyPaid': profileObject?.programs[0]?.is_fully_paid,
        'NumberOfVehicles': profileObject?.vehicle_details.length,
        'UserDOB': profileObject?.date_of_birth,
        'UserName': profileObject?.first_name,
        'NumberOfDrivers': profileObject?.number_of_drivers,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject?.id]);

  useEffect(() => {
    getUserDetails();
    window.userId = userId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentStep = steps.findIndex(
      (step) =>
        location.pathname ===
        `/quote/${step.title.replace(/\s+/g, '-').toLowerCase()}`
    );

    if (currentStep !== -1) {
      setCurrent(currentStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const steps = [
    {
      title: 'Vehicle Details',
      content: (
        <VehicleDetails
          current={current}
          setCurrent={setCurrent}
          saveProfile={saveProfile}
        />
      ),
      icon: <img src={Vehicle} alt="Vehicle Icon" />,
    },
    {
      title: 'Choose Plan',
      content: (
        <ChoosePlan
          current={current}
          setCurrent={setCurrent}
          saveProfile={saveProfile}
        />
      ),
      icon: <img src={Wallet} alt="Wallet Icon" />,
    },
    {
      title: 'Member Information',
      content: (
        <MemberInformation saveProfile={saveProfile} current={current} />
      ),
      icon: <img src={Member} alt="Member Icon" />,
    },
    {
      title: 'Payment',
      content: <Payment saveProfile={saveProfile} />,
      icon: <img src={Plans} alt="Plans Icon" />,
    },
  ];

  const DelayRender = ({ delay, children }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShow(true);
      }, delay);

      return () => {
        clearTimeout(timeout);
      };
    }, [delay]);

    return show ? children : null;
  };

  const customDot = useMemo(
    () =>
      (dot, { status, index }) => (
        <Popover content={<span>step {index}</span>}>
          {status === 'process' && index === current ? (
            <DelayRender delay={1760}>
              <img src={Truck} className="step-truck-icon" alt="truck" />
            </DelayRender>
          ) : status !== 'finish' ? (
            dot
          ) : (
            <img src={CheckMark} alt="checkMark" />
          )}
        </Popover>
      ),
    [current]
  );

  const handleStepClick = (index) => {
    // Allow clicking only on the previous tab
    if (index === current - 1) {
      setCurrent(index);
      const stepTitle = steps[index].title.replace(/\s+/g, '-').toLowerCase();
      navigate(`/quote/${stepTitle}`);
    }
  };

  const items = steps.map((item, index) => ({
    key: item.title,
    title: (
      <span
        className={`fw-bold fs-6 ${
          index === current - 1 ? 'clickable-tab' : ''
        }`}
        onClick={() => handleStepClick(index)}
      >
        <span className="quote-step-icon px-2">{item.icon}</span>
        <span className="quote-step-title text-blue">{item.title}</span>
      </span>
    ),
  }));
  return (
    <>
      {getQuoteLoader ? (
        <div className="silo_page-container loader-layout">
          <div className="main-box">
            <img src={logo} alt="SILO" />
            <div className="silo-loader">loading....</div>
          </div>
        </div>
      ) : null}
      {localStorage.getItem('loggedInUserToken') &&
      (profileObject?.programs[0]?.is_fully_paid || isFullyPaid) ? (
        <Navigate to="/account/info" />
      ) : (
        <div className="silo_page-container">
          <div className="quote-container">
            <div className="design_element2">
              <img src={shape} alt="shape" />
            </div>
            <div className="design_element1">
              <img src={shapeTwo} alt="shape" />
            </div>
            <Steps
              current={current}
              items={items}
              progressDot={customDot}
              className="custom-steps py-2 container"
            />
            <div className="steps-content">{steps[current].content}</div>
          </div>
        </div>
      )}
    </>
  );
};
export default GetQuote;
