import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import { RemoveGuestUserToken } from './guestUserTokenUtils';
import '../../../styles/globalStyles.scss';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../Shared/ModalContainer/DeleteModal';

const NewGetQuote = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleNewQuote = () => {
    RemoveGuestUserToken();
    handleClose();
    navigate('/quote/vehicle-details', { replace: true });
    window.location.reload(); // Reload the page
  };

  return (
    <>
      <Col lg={5} className="m-auto">
        <div
          className="p-2 rounded cursor-pointer my-5 new-quote-btn"
          onClick={handleShow}
        >
          <h6 className="m-0 fw-bold text-white">Start a new Quotation </h6>
        </div>
      </Col>
      <div>
        <DeleteModal
          show={show}
          handleClose={handleClose}
          handleDelete={handleNewQuote}
          content={
            <p>
              This would delete all the data that you have filled up till now,
              which includes vehicles, and selected policies,
              <br />
              <br />
              <div>
                <b>Are you sure you want to continue?</b>
              </div>
            </p>
          }
        />
      </div>
    </>
  );
};

export default NewGetQuote;
