import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Form from 'react-bootstrap/Form';
import { getRequest } from '../../../../../../api/api';
import { GET_CARGO_COMMODITIES_API } from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const animatedComponents = makeAnimated();

const CargoCommodities = ({
  vehicleIndex,
  setCargo,
  cargoError,
  setCargoError,
}) => {
  const { vehicles, setVehicles } = useContext(Context);

  const [cargoCommoditiesOptions, setCargoCommoditiesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const cargoCommoditiesMenu = async () => {
    setIsLoading(true);
    try {
      const response = await getRequest(GET_CARGO_COMMODITIES_API);
      const attributes = response.result;
      const mappedOptions = attributes.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));
      setCargoCommoditiesOptions(mappedOptions);
    } catch (error) {
      console.log('API error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    cargoCommoditiesMenu();
  }, []);

  const onCommoditiesChange = (selectedOptions) => {
    const updatedVehicle = { ...vehicles[vehicleIndex] };

    // Update the vehicle's body_type with the selected option
    updatedVehicle.cargo_commodities = selectedOptions;

    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];

    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;

    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    if (selectedOptions.length === 0) {
      setCargoError('Cargo Commodities are required.'); // Set the error message
    } else {
      setCargoError(null); // Clear any previous error
    }
  };

  const isOptionDisabled = (option) => {
    // Check if the option is already selected
    return vehicles[vehicleIndex]?.cargo_commodities?.some(
      (cargoCommodity) => cargoCommodity.id === option.id
    );
  };

  return (
    <div>
      <Form.Label className="fw-bold">Cargo Commodities</Form.Label>
      <span className="text-danger">&nbsp;*</span>
      {isLoading && (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="mx-2"
        />
      )}
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            Cargo commodities typically refer to goods or products that are
            transported as cargo. These commodities can vary widely and include
            raw materials, finished products, and other items that are traded
            globally.
          </Tooltip>
        }
      >
        <span className="float-end cursor-pointer text-muted">
          <AiOutlineQuestionCircle size="20" />
        </span>
      </OverlayTrigger>
      <Select
        className={`custom-select ${cargoError ? 'is-invalid' : ''}`}
        closeMenuOnSelect={false}
        components={animatedComponents}
        placeholder={
          <div className="select-placeholder-text">
            Select Cargo Commodities
          </div>
        }
        isMulti
        options={cargoCommoditiesOptions}
        value={
          vehicles[vehicleIndex]?.cargo_commodities &&
          vehicles[vehicleIndex]?.cargo_commodities?.map((cargoCommodity) => ({
            ...cargoCommodity,
            value: cargoCommodity.id,
            label: cargoCommodity.name,
          }))
        }
        onChange={onCommoditiesChange}
        isOptionDisabled={isOptionDisabled}
      />
      <Form.Control.Feedback type="invalid">{cargoError}</Form.Control.Feedback>
    </div>
  );
};

export default CargoCommodities;
