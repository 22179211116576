import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getRequest } from '../../../../../../../../../api/api';
import { GET_STATE_LIST } from '../../../../../../../../../api/endpoint';

const EditDriver = () => {
  const [stateOption, setStateOption] = useState([]);

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  const vehicleFields = [
    {
      label: 'First Name',
      id: 'firstName',
      name: 'firstName',
      value: '',
      type: 'text',
    },
    {
      label: 'Last Name',
      id: 'lastName',
      name: 'lastName',
      value: '',
      type: 'text',
    },
    {
      label: 'Date of Birth',
      id: 'DOBpic',
      name: 'dob',
      value: '',
      type: 'date',
      max: new Date().toISOString().split('T')[0], // Set max attribute to today's date
    },
    {
      label: 'Email Address',
      id: 'email',
      name: 'email',
      value: '',
      type: 'email',
    },
    {
      label: 'Driver License Number',
      id: 'driverLicenseNumber',
      name: 'driverLicenseNumber',
      value: '',
      type: 'number',
    },
    {
      label: 'First Driver License Issue Date',
      id: 'driverLicenseDate',
      name: 'driverLicenseDate',
      value: '',
      type: 'date',
    },
    {
      label: 'Driver License State',
      id: 'driverLicenseState',
      name: 'driverLicenseState',
      type: 'select',
      options: stateOption,
    },
  ];

  const [driverData, setDriverData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    driverLicenseNumber: '',
    driverLicenseDate: '',
    driverLicenseState: '',
  });

  const billingState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST)
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id
      }))
      setStateOption(mappedOptions);
    } catch (error) {
      console.log("err", error)
    }
  }
  useEffect( () => {
    billingState();
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDriverData({
      ...driverData,
      [name]: value,
    });
  };


  const [selectedDate, setSelectedDate] = useState();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDriverData({
      ...driverData,
      dob: date,
    });
  };


  const handleSave = (e) => {
    //Save logic here
  };

  return (
    <div className="user-detail-form-blue silo_page_section-heading">
      <h4>Edit Driver</h4>
      <div className="row mt-2">
        {vehicleFields.map((field) => (
          <div className="col-md-6">
            <Form.Group controlId={field.id}>
              <Form.Label className="fw-bold mt-2">{field.label}</Form.Label>
              {field.type !== 'select' ? (

                <Form.Control
                  type={field.type}
                  name={field.name}
                  value={driverData[field.value] || ''}
                  max={field.max} // Add the max attribute
                  onChange={handleChange}
                />
              )  : field.type === 'date' ? (
                <>
                 <DatePicker
                      selected={selectedDate}
                      onChange={(date) => handleDateChange(date, field.name)}
                      isClearable
                      placeholderText="MM/DD/YYYY"
                      maxDate={eighteenYearsAgo}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  
                </>
              ): (
                <Select
                  className="custom-select"
                  name={field.name}
                  value={field.options.find(
                    (option) => option.value === driverData[field.name]
                  )}
                  options={field.options}
                  onChange={({ value }) =>
                    setDriverData({ ...driverData, [field.name]: value })
                  }
                  menuPortalTarget={document.body}
                />
              )}
              
            </Form.Group>
          </div>
        ))}
        <div className="d-flex align-items-center mt-3">
          <button
            className="btn silo-btn-small silo-btn__blue btn-primary"
            onClick={handleSave}
          >
            Save
          </button>
          <Link
            to="/account/driver-info"
            className="btn silo-btn-small ant-steps-item-content mx-2"
          >
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditDriver;
