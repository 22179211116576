import React from 'react';
import ChoosePolicy from './Section/ChoosePolicy/ChoosePolicy';

const OccupationalAccident = ({
  policyData,
  handleTabChange,
  saveProfile,
  OApolicyListingRepsonse,
  getSubscriptionFor,
  OAPolicyDetailsListing,
  PAPolicyDetailsListing,
  isLoading,
  handleModalShow,
  modalOpen,
  setModalOpen,
  isDirectCheckout,
}) => {
  return (
    <>
      <ChoosePolicy
        policyData={policyData}
        handleTabChange={handleTabChange}
        saveProfile={saveProfile}
        OApolicyListingRepsonse={OApolicyListingRepsonse}
        getSubscriptionFor={getSubscriptionFor}
        OAPolicyDetailsListing={OAPolicyDetailsListing}
        PAPolicyDetailsListing={PAPolicyDetailsListing}
        isLoading={isLoading}
        handleModalShow={handleModalShow}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        isDirectCheckout={isDirectCheckout}
      />
    </>
  );
};

export default OccupationalAccident;
