import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';

const NotLoggedIn = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 1) {
        clearInterval(timer); // Stop the countdown when it reaches 1
        navigate('/login'); // Redirect to the home page
      } else {
        setCountdown(countdown - 1); // Decrease the countdown by 1 second
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts
    };
  }, [countdown, navigate]);
  return (
    <div className="silo_page-container py-5 d-flex align-items-center">
      <div className="silo_page_section-heading text-center pt-5 pb-2 m-auto">
        <div>
          <img
            className="mb-4"
            width="220"
            src={logo}
            alt="logo"
            onError={(e) => {
              // If AVIF fails to load, fallback to PNG
              e.target.src = logoPng;
            }}
          />
        </div>
        <h3>You haven't Logged In yet</h3>
        <p className="">
          Redirecting you back to Log In page in {countdown} seconds
        </p>
      </div>
    </div>
  );
};

export default NotLoggedIn;
