import React, { useEffect, useContext } from 'react';
import { UserContext } from '../../../../../../utils/Context';
import {
  getVehicleDetails,
  formatInput,
  formatDate_MMDDYY,
} from '../../../../../../utils/Commonutils';
import { Row, Col, Badge } from 'react-bootstrap';

const ExpiredPolicy = () => {
  const { setUserDetails, userDetails } = useContext(UserContext);

  useEffect(() => {
    getVehicleDetails(setUserDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expiredPolicies = userDetails?.programs[0]?.subscriptions.filter(
    (subscription) => subscription.status === 'expired'
  );
  return (
    <>
      <Row>
        {expiredPolicies && expiredPolicies.length > 0 ? (
          expiredPolicies.map((subscription, index) => (
            <div className="col-lg-6" key={index}>
              <div className="details-container mb-4 container_override-sm">
                <div className="d-flex justify-content-between flex-wrap">
                  <h5 className="m-0 fw-bold text-muted">
                    {subscription?.formal_name ===
                    'Occupational Accident Gold + Contingent Liability Plan'
                      ? 'Occupational Accident Gold Plan'
                      : subscription?.formal_name}
                  </h5>

                  {/* Uncomment below block If you want to show Paid and Pwnding payment labels */}
                  <b className="text-end">
                    <span className="fail py-1">Expired</span>
                  </b>
                </div>

                {subscription?.formal_name ===
                'Occupational Accident Gold + Contingent Liability Plan' ? (
                  <Row className="mt-2">
                    <Col lg={6}>
                      <div className="silo_page_section-heading">
                        <p>Contingent Liability Plan</p>
                        <Badge bg="success">Included</Badge>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  subscription?.formal_name ===
                    'Occupational Accident Gold Plan' && (
                    <Row className="mt-2">
                      <Col lg={6}>
                        <div className="silo_page_section-heading">
                          <p>Contingent Liability Plan</p>
                          <Badge bg="warning" text="dark">
                            Rejected
                          </Badge>
                        </div>
                      </Col>
                    </Row>
                  )
                )}
                <Row className="mt-2">
                  <Col lg={6}>
                    <div className="silo_page_section-heading mb-3">
                      <p>Plan type</p>
                      <h6>
                        {subscription.subscribable.code_name
                          ? subscription.subscribable.code_name
                          : 'N/A'}
                      </h6>
                    </div>

                    <div className="silo_page_section-heading mb-3">
                      {subscription?.coverage_type_code_name === 'PA' ||
                      subscription?.coverage_type_code_name === 'OA' ? (
                        <>
                          <p>Driver</p>
                          <h6>
                            <ul className="mb-0">
                              {subscription?.finding_listings?.map((value) => (
                                <li key={index}>
                                  {value.first_name + ' ' + value.last_name}
                                </li>
                              ))}
                            </ul>
                          </h6>
                        </>
                      ) : (
                        <>
                          <p>Vehicles</p>
                          <h6>{subscription?.finding_listings}</h6>
                        </>
                      )}
                    </div>
                    <div className="silo_page_section-heading mb-3">
                      <p>Renewal</p>
                      <h6>Yearly</h6>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="silo_page_section-heading mb-3">
                      <p>Premium Paid</p>

                      {subscription?.status === 'active' ? (
                        <h6>
                          $
                          {formatInput(
                            (subscription?.calculated_total + subscription?.policies_tax).toFixed(2)
                          )}
                        </h6>
                      ) : (
                        <span className="fail fw-bold py-1 d-inline-block">
                          Pending Payment
                        </span>
                      )}
                    </div>
                    <div className="silo_page_section-heading mb-3">
                      <p>Start Date</p>
                      <h6>
                        {subscription.effective_date
                          ? formatDate_MMDDYY(subscription.effective_date)
                          : 'N/A'}
                      </h6>
                    </div>
                    <div className="silo_page_section-heading mb-3">
                      <p>End Date</p>
                      <h6>
                        {subscription.expiration_date
                          ? formatDate_MMDDYY(subscription.expiration_date)
                          : 'N/A'}
                      </h6>
                      &nbsp;&nbsp;
                    </div>
                  </Col>
                  {/* <Link to="edit">
                  <button className="mx-auto btn silo-btn-small silo-btn__blue my-3 card-btn">
                    View Benefit Summary
                  </button>
                </Link> */}
                </Row>
              </div>
            </div>
          ))
        ) : (
          <div className="info-form-box text-center fw-bold">
            No Policies to Show
          </div>
        )}
      </Row>
    </>
  );
};

export default ExpiredPolicy;
