import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../../../utils/Context';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { ToastManager } from '../../../../../ToastContainer/ToastContainer';
import { getVehicleDetails } from '../../../../../utils/Commonutils';

const CertificateOfInsurance = () => {
  const { userDetails, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    getVehicleDetails(setUserDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCOIDownload = async (item) => {
    if (item?.coi_url === null) {
      const response = await getVehicleDetails(setUserDetails);
      setUserDetails(response?.result?.profiles[0]);

      const vehicle = response?.result?.profiles[0].vehicle_details.find(
        (vehicle) => vehicle.id === item.id
      );
      if (vehicle?.coi_url) {
        window.open(vehicle?.coi_url, '_blank', 'noopener noreferrer');
      } else {
        ToastManager.info(
          'Your certificate of insurance is currently being generated. Please check back in a couple of minutes. Thank you for your patience!'
        );
      }
    } else {
      window.open(item?.coi_url, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <>
      <h3 className="">
        <b>Certificate of Insurance</b>
      </h3>
      <hr />
      <div className="row">
        {userDetails?.vehicle_details.map((item, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <div className="details-container" key={index}>
              <div className="fs-5 fw-bold">
                <span>{item.vehicle_make?.name || ''}</span> -{' '}
                <span>{item.vehicle_model?.name || ''}</span> -{' '}
                <span>{item.declared_year || ''}</span>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip">
                      A certificate of insurance (COI) is issued by an insurance
                      company and it verifies the existence of an insurance
                      policy.
                    </Tooltip>
                  }
                >
                  <span className="float-end cursor-pointer text-muted">
                    <AiOutlineQuestionCircle size="20" />
                  </span>
                </OverlayTrigger>
              </div>
              <Row className="m-4">
                <div>
                  <a
                    className="text-white text-decoration-none"
                    href={item?.coi_url && item?.coi_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleCOIDownload(item)}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <BsDownload
                        size="50"
                        color="#444"
                        className="cursor-pointer"
                      />
                      <div
                        type="submit"
                        className="mt-2 btn silo-btn silo-btn__blue text-center m-auto"
                      >
                        <strong>DOWNLOAD</strong>
                      </div>
                    </div>
                  </a>
                </div>
              </Row>
            </div>
          </div>
        ))}
      </div>
      <div className="alert alert-primary" role="alert">
        <span className="d-flex flex-direction-C fs-6">
          <span className="d-flex align-items-center">
            <BsFillInfoCircleFill size="20" />
            <strong className="px-2">
              Certificate of Insurance Definition:
            </strong>
          </span>
          <span>
            A Certificate of Insurance is a document containing all the crucial
            details regarding an insurance policy in a comprehensive and
            standardized format. The insurance company issues it as proof of
            insurance coverage for the policyholder, used in various business
            transactions.
          </span>
        </span>
      </div>
    </>
  );
};

export default CertificateOfInsurance;
