import React, { useEffect, useContext } from 'react';
import { UserContext } from '../../../../../../../utils/Context';
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import Dropdown from 'react-bootstrap/Dropdown';
import {
  formatDate_MMDDYY,
  getVehicleDetails,
} from '../../../../../../../utils/Commonutils';
import { formatInput } from '../../../../../../../utils/Commonutils';
// import { Badge } from 'react-bootstrap';

const BillingHistory = () => {
  // const CustomToggle = ({ onClick }) => (
  //   <BsThreeDotsVertical
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //     size={20}
  //   />
  // );

  const { setUserDetails, userDetails } = useContext(UserContext);

  useEffect(() => {
    getVehicleDetails(setUserDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>
        <b>Billing History</b>
      </h3>
      <hr />
      <div className="row">
        {userDetails &&
          userDetails?.programs[0]?.subscriptions.map((subscription, index) => (
            <div key={index} className="mt-2 mb-2 col-md-6">
              <div className="card-theme">
                <div className="d-flex justify-content-between flex-wrap">
                  <h5 className="m-0 text-muted">
                    <b>Policy Name:</b> {subscription?.formal_name}{' '}
                  </h5>

                  {/* <Dropdown className="cursor-pointer ellipsis">
                    <Dropdown.Toggle as={CustomToggle} /> 
                    <Dropdown.Menu size="sm" title="">
                      <Dropdown.Item>View Invoice</Dropdown.Item>
                      <hr />
                      <Dropdown.Item>Download Invoice</Dropdown.Item>
                      <Dropdown.Item>Email Invoice</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* Uncomment below block If you want to show Paid and Pwnding payment labels */}
                  {/* <b className="text-end">
                    {subscription?.status === 'active' ? (
                      subscription?.paid_on !== null ? (
                        subscription?.paid_on?.split('T')[0]
                      ) : (
                        <span className="success">Paid</span>
                      )
                    ) : (
                      <span className="fail py-1">Pending Payment</span>
                    )}
                  </b> */}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="silo_page_section-heading">
                    <p>Payment Date</p>
                    <div className="mt-1">
                      {subscription?.status === 'active' ? (
                        subscription?.paid_on !== null ? (
                          formatDate_MMDDYY(
                            subscription?.paid_on?.split('T')[0]
                          )
                        ) : (
                          'N/A'
                        )
                      ) : (
                        <span className="fail fw-bold py-1">
                          Pending Payment
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="silo_page_section-heading">
                    <p>Billing Period</p>
                    <h6>Yearly</h6>
                  </div>
                  <div className="silo_page_section-heading">
                    <p>Total Amount</p>
                    <h6>
                      ${formatInput(subscription?.calculated_total?.toFixed(2))}
                    </h6>
                    &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default BillingHistory;
