import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../../../../utils/Context';
import {
  formatDate_MMDDYY,
  formatInput,
} from '../../../../../../utils/Commonutils';
// import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdSimCardDownload } from 'react-icons/md';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import { getLoggedinRequest } from '../../../../../../api/logginInUserAPI';
import { GET_VEHICLE_DETAILS } from '../../../../../../api/endpoint';
import NTDocument from '../../../../../../assets/Documents/Association Member NTL Guidelines 23 - Signed.pdf';
import { RenewPolicy } from './RenewPolicy';

const ActivePolicy = () => {
  const { setUserDetails, userDetails } = useContext(UserContext);
  const [pdLink, setPdLink] = useState('');

  const getUserDetails = async () => {
    try {
      const response = await getLoggedinRequest(GET_VEHICLE_DETAILS);
      setPdLink(response?.result?.profiles[0]?.programs[0]?.pd_url);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const OAtooltip = <Tooltip id="tooltip">OA Benefits Summary PDF</Tooltip>;
  const NTtooltip = (
    <Tooltip id="tooltip">
      MVR Membership Guidelines for Non-Trucking Liability
    </Tooltip>
  );

  const CGPDtooltip = (
    <Tooltip id="tooltip">Download Purchased Policy Details</Tooltip>
  );

  useEffect(() => {
    getUserDetails(setUserDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOACertificateDownload = (subscription) => {
    if (subscription?.pdf_url !== null) {
      window.open(subscription.pdf_url, '_blank', 'noopener noreferrer');
    } else {
      ToastManager.info(
        'Your OA Policy Benefit Certificate will generate post payment completion. Please complete your Payment!'
      );
    }
  };

  const handlePDCGCertificateDownload = (subscription) => {
    // Check if pdLink is null
    if (pdLink === null) {
      // Display info message using ToastManager

      ToastManager.info(
        'Policy Document generation is in progress. Please try again after some time.'
      );
    } else {
      // If pdLink is not null, proceed with opening the link
      window.open(pdLink, '_blank', 'noopener noreferrer');
    }
  };

  const handleNTCertificateDownload = () => {
    window.open(NTDocument, '_blank', 'noopener noreferrer');
  };

  return (
    <>
      <Row>
        {userDetails &&
          userDetails.programs[0]?.subscriptions.map((subscription, index) =>
            subscription?.status === 'active' || 'pending_payment' ? (
              <div className="col-lg-6" key={index}>
                <div className="details-container mb-4 container_override-sm">
                  <div className="d-flex justify-content-between flex-wrap">
                    <h5 className="m-0 fw-bold text-muted">
                      {subscription?.formal_name ===
                      'Occupational Accident Gold + Contingent Liability Plan'
                        ? 'Occupational Accident Gold Plan'
                        : subscription?.formal_name}
                    </h5>
                    {/* <Dropdown className="cursor-pointer ellipsis">
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu size="sm" title="">
                      <Dropdown.Item>View Invoice</Dropdown.Item>
                      <hr />

                      <Dropdown.Item>Download Invoice</Dropdown.Item>

                      <Dropdown.Item>Email Invoice</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                    {/* Uncomment below block If you want to show Paid and Pwnding payment labels */}
                    {/* <b className="text-end">
                      {subscription?.status === 'active' ? (
                        <span className="success py-1">Paid</span>
                      ) : (
                        <span className="fail py-1">Pending Payment</span>
                      )}
                    </b> */}
                    {subscription.status === 'active' &&
                    subscription?.formal_name ===
                      'Occupational Accident Gold + Contingent Liability Plan' ? (
                      <OverlayTrigger placement="bottom" overlay={OAtooltip}>
                        <div
                          onClick={() =>
                            handleOACertificateDownload(subscription)
                          }
                        >
                          <MdSimCardDownload
                            size="40"
                            color="#1263a9"
                            className="cursor-pointer"
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      ''
                    )}
                    {subscription.status === 'active' &&
                    (subscription?.coverage_type_code_name === 'CG' ||
                      subscription?.coverage_type_code_name === 'PD') ? (
                      <OverlayTrigger placement="bottom" overlay={CGPDtooltip}>
                        <div
                          onClick={() =>
                            handlePDCGCertificateDownload(subscription)
                          }
                        >
                          <MdSimCardDownload
                            size="40"
                            color="#1263a9"
                            className="cursor-pointer"
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      ''
                    )}
                    {subscription.status === 'active' &&
                    subscription?.formal_name === 'Non-Trucking Plan' ? (
                      <OverlayTrigger placement="bottom" overlay={NTtooltip}>
                        <div
                          onClick={() =>
                            handleNTCertificateDownload(subscription)
                          }
                        >
                          <MdSimCardDownload
                            size="40"
                            color="#1263a9"
                            className="cursor-pointer"
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      ''
                    )}
                  </div>

                  {subscription?.formal_name ===
                  'Occupational Accident Gold + Contingent Liability Plan' ? (
                    <Row className="mt-2">
                      <Col lg={6}>
                        <div className="silo_page_section-heading">
                          <p>Contingent Liability Plan</p>
                          <Badge bg="success">Included</Badge>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    subscription?.formal_name ===
                      'Occupational Accident Gold Plan' && (
                      <Row className="mt-2">
                        <Col lg={6}>
                          <div className="silo_page_section-heading">
                            <p>Contingent Liability Plan</p>
                            <Badge bg="warning" text="dark">
                              Rejected
                            </Badge>
                          </div>
                        </Col>
                      </Row>
                    )
                  )}
                  <Row className="mt-2">
                    <Col lg={6}>
                      <div className="silo_page_section-heading mb-3">
                        <p>Plan type</p>
                        <h6>
                          {subscription.subscribable.code_name
                            ? subscription.subscribable.code_name
                            : subscription.subscribable.policy_name}
                        </h6>
                      </div>

                      <div className="silo_page_section-heading mb-3">
                        {subscription?.coverage_type_code_name === 'PA' ||
                        subscription?.coverage_type_code_name === 'OA' ? (
                          <>
                            <p>Driver</p>
                            <h6>
                              <ul className="mb-0">
                                {subscription?.finding_listings?.map(
                                  (value) => (
                                    <li key={index}>
                                      {value.first_name + ' ' + value.last_name}
                                    </li>
                                  )
                                )}
                              </ul>
                            </h6>
                          </>
                        ) : (
                          <>
                            <p>Vehicles</p>
                            <h6>{subscription?.finding_listings}</h6>
                          </>
                        )}
                      </div>
                      <div className="silo_page_section-heading mb-3">
                        <p>Renewal</p>
                        <h6>Yearly</h6>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="silo_page_section-heading mb-3">
                        <p>Premium Paid</p>

                        {subscription?.status === 'active' ? (
                          <h6>
                            {' '}
                            $
                            {formatInput(
                              (subscription?.calculated_total + subscription?.policies_tax).toFixed(2)
                            )}
                          </h6>
                        ) : subscription?.status === 'pending_payment' ? (
                          <span className="fail fw-bold py-1 d-inline-block">
                            Pending Payment
                          </span>
                        ) : subscription?.status === 'revise_payment' ? (
                          <span className="expired fw-bold py-1 d-inline-block">
                            Revise Payment
                          </span>
                        ) : null
                        }
                      </div>
                      <div className="silo_page_section-heading mb-3">
                        <p>Start Date</p>
                        <h6>
                          {subscription.effective_date
                            ? formatDate_MMDDYY(subscription.effective_date)
                            : 'N/A'}
                        </h6>
                      </div>
                      <div className="silo_page_section-heading mb-3">
                        <p>End Date</p>
                        <h6>
                          {subscription.expiration_date
                            ? formatDate_MMDDYY(subscription.expiration_date)
                            : 'N/A'}
                        </h6>
                        &nbsp;&nbsp;
                      </div>
                    </Col>
                    {/* <Link to="edit">
                    <button className="mx-auto btn silo-btn-small silo-btn__blue my-3 card-btn">
                      View Benefit Summary
                    </button>
                  </Link> */}
                  </Row>
                  {subscription?.status === 'revise_payment' && <RenewPolicy />}

                </div>
              </div>
            ) : (
              <div className="info-form-box">No Policies to Show</div>
            )
          )}
      </Row>
    </>
  );
};

export default ActivePolicy;
