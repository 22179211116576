import { AsyncPaginate } from 'react-select-async-paginate';
import { getRequest } from '../../../../../../api/api';
import { GET_VEHICLE_MODEL_API } from '../../../../../../api/endpoint';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../../utils/Context';

export const VehicleModelPaginateApi = ({
  page,
  makeId,
  Setpage,
  ModelData,
  SetModelOptions,
  handleVehicleModelChange,
  selectVehicleModelError,
}) => {
  const [makeName, setMakeName] = useState(null);
  const { manualSelectAccess, disableState } = useContext(Context);
  const isModelDisabled = (manualSelectAccess !== 3 && manualSelectAccess !== 4) || disableState;

  useEffect(() => {
    setMakeName(makeId);
  }, [makeId]);

  const modelList = async (search) => {
    try {
      // Check if make_id is defined before making the API request
      if (makeName) {
        const response = await getRequest(
          `${GET_VEHICLE_MODEL_API}?name=${search}&vehicle_make_id=${makeName}&page=${page}`
        );
        const modelData = response.result;

        // Filter out any empty or invalid options
        const modelMapped = modelData
          .filter((item) => item.name) // Filter out items with empty names
          .map((item) => ({
            ...item,
            label: item.name,
          }));

        SetModelOptions((prevOptions) => {
          if (search === '') {
            // Clear options for a new search
            return modelMapped;
          } else {
            // Concatenate options for paginated results
            return [...prevOptions, ...modelMapped];
          }
        });

        const lastElement = modelData[modelData.length - 1];
        const lastPageUrl = lastElement.links?.last_url;

        if (lastPageUrl) {
          const lastPageNumber = new URLSearchParams(lastPageUrl).get('page');

          return {
            options: modelMapped,
            hasMore: modelMapped.length > 0 && page < lastPageNumber,
            additional: {
              page: Setpage(page + 1),
            },
          };
        } else {
          // Handle the case when last_url is not available in the response
          return {
            options: [],
            hasMore: false,
          };
        }
      } else {
        // Handle the case when make_id is not defined
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log('err', error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <AsyncPaginate
      isDisabled={
        isModelDisabled
      }
      key={makeName} // Use makeId
      value={
        ModelData && Object.keys(ModelData).length !== 0
          ? {
              value: ModelData?.name,
              label: ModelData?.name,
            }
          : []
      }
      loadOptions={modelList}
      isSearchable={true}
      placeholder={
        <div className="select-placeholder-text">Search Vehicle Model</div>
      }
      isClearable
      onChange={(selectedOption) => {
        handleVehicleModelChange(selectedOption);
      }}
      additional={{
        page: 1,
      }}
      className={`custom-select w-100 ${
        selectVehicleModelError ? 'is-invalid' : ''
      }`}
      style={{
        control: (provided) => ({
          ...provided,
          padding: isModelDisabled && '1%',
        }),
      }}
    />
  );
};
