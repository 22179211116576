import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import DatePicker from 'react-datepicker';
import './membershipDetails.scss';
import 'react-datepicker/dist/react-datepicker.css';
import InputGroup from 'react-bootstrap/InputGroup';
import hidePassSvg from '../../../../../../assets/images/Login/hide_password.svg';
import showPassSvg from '../../../../../../assets/images/Login/show_password.svg';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Select from 'react-select';
import '../../memberInformation.scss';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import AutoCompleteAddress from '../../../../Shared/AutoCompleteAddress/AutoCompleteAddress';
import { getRequest, putRequestUser } from '../../../../../../api/api';
import { DriverDetails } from '../../../../../shared/DriverDetails/DriverDetails';
import {
  CommonCityApi,
  formatUSBasedNumber,
  formatDate,
  formatZipCode,
} from '../../../../../../utils/Commonutils';
import { Spinner } from 'react-bootstrap';
import validator from 'validator';
import { parseISO } from 'date-fns';

import {
  GET_STATE_LIST,
  GET_VEHICLE_DETAILS,
  SAVE_USER,
  CHECK_EMAIL,
} from '../../../../../../api/endpoint';
import { GetGuestUserToken } from '../../../guestUserTokenUtils';
import {
  billingAddressData,
  calculateAge,
  eighteenYearsAgo,
  homeAddressData,
  isValidEmail,
  maritalTypeoptions,
  membershipData,
  sexTypeoptions,
  userloginData,
} from '../../../../../shared/Constants/Constants';
import UploadProfilePicture from './UploadProfilePicture';
import { convertToBase64 } from '../../../../../../utils/CommonDocumentsFunctions';

const MembershipDetails = ({
  saveProfile,
  Setpassword,
  setShowUpdateVINSection,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [icon, Seticon] = useState(true);
  const [Confirmicon, Setconfirmicon] = useState(true);
  const [billingStateId, setBillingStateId] = useState();
  const [stateId, SetstateId] = useState();
  const [billingPage, SetbillingPage] = useState(1);
  const [page, Setpage] = useState(1);
  const [selectedDriverAsMember, setSelectedDriverAsMember] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleDateChange = (newdate) => {
    let DOB = formatDate(newdate);
    setSelectedDate(newdate);
    setMemberData({
      ...memberData,
      date_of_birth: DOB,
    });
    setFieldErrors({
      ...fieldErrors,
      date_of_birth: '',
    });
  };

  const handleAutoCompleteSelect = (selectedAddress) => {
    setAddressData({
      ...addressData,
      state: {
        name: addressData.state?.name,
        id: addressData.state?.id,
      },
      city: {
        name: addressData.city?.name,
        id: addressData.city?.id,
      },
    });
  };

  const {
    profileObject,
    userEmail,
    setUserEmail,
    setProfileObject,
    copyHomeToBilling,
    setCopyHomeToBilling,
  } = useContext(Context);
  const [selectStateError, setSelectStateError] = useState('');
  const [selectCityError, setSelectCityError] = useState('');
  const [selectBillingCityError, setSelectBillingCityError] = useState('');
  const [selectBillingStateError, setSelectBillingStateError] = useState('');
  const [copyDriverToMember, setCopyDriverToMember] = useState(false);

  const [memberData, setMemberData] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    dba_name: '',
    mobile: '',
    gender: '',
    marital_status: '',
    profile_picture: '',
  });

  const [addressData, setAddressData] = useState({
    address_line_1: '',
    zip5: '',
    state: {},
    city: {},
  });

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    confirm_password: '',
  });

  const [billingData, setBillingData] = useState({
    address_line_1: '',
    zip5: '',
    state: {},
    city: {},
  });

  const [loginDataError, setLoginDataError] = useState({
    email: '',
    password: '',
    confirm_password: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    mobile: '',
  });

  const [addressfieldErrors, setAddressFieldErrors] = useState({
    address: '',
    zip: '',
    state: '',
    city_id: '',
  });

  const [billingfieldErrors, setBillingFieldErrors] = useState({
    billingAddress: '',
    billingZip: '',
    state: '',
    city_id: '',
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [billingCityOptions, setBillingCityOptions] = useState([]);

  const handleCopyToBillingChange = () => {
    setCopyHomeToBilling(!copyHomeToBilling);
    if (!copyHomeToBilling) {
      setBillingData({
        address_line_1: addressData.address_line_1,
        zip5: addressData.zip5,
        state: {
          name: addressData.state?.name,
          id: addressData.state?.id,
        },
        city: {
          name: addressData.city?.name,
          id: addressData.city?.id,
        },
      });
      setBillingFieldErrors({});
      setSelectBillingStateError('');
      setSelectBillingCityError('');
    } else {
      setBillingData({
        address_line_1: '',
        zip5: '',
        state: {},
        city: {},
      });
    }
  };

  const handleSexChange = (selectedOption) => {
    if (selectedOption !== null) {
      setMemberData({
        ...memberData,
        gender: selectedOption?.value,
      });
    } else {
      setMemberData({
        ...memberData,
        gender: '',
      });
    }
  };

  const handleMaritalStatusChange = (selectedOption) => {
    if (selectedOption !== null) {
      setMemberData({
        ...memberData,
        marital_status: selectedOption?.value,
      });
    } else {
      setMemberData({
        ...memberData,
        marital_status: '',
      });
    }
  };

  const handleStateChange = (selectedOption) => {
    SetstateId(selectedOption.id);
    Setpage(1);

    setAddressData({
      ...addressData,
      state: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
      city: {},
    });
    if (copyHomeToBilling) {
      setBillingData({
        ...addressData,
        state: {
          name: selectedOption?.value,
          id: selectedOption?.id,
        },
      });
    }
    if (!selectedOption) {
      setSelectStateError('Please select an option');
    } else {
      setSelectStateError('');
    }
  };

  const handleBillingStateChange = (selectedOption) => {
    setBillingStateId(selectedOption.id);
    SetbillingPage(1);
    setBillingData({
      ...billingData,
      state: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
      city: {},
    });

    if (!selectedOption) {
      setSelectBillingStateError('Please select an option');
    } else {
      setSelectBillingStateError('');
    }
  };

  const handleCityChange = (selectedOption) => {
    setAddressData({
      ...addressData,
      city: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
    });
    if (copyHomeToBilling) {
      setBillingData({
        ...addressData,
        city: {
          name: selectedOption?.value,
          id: selectedOption?.id,
        },
      });
    }
    if (!selectedOption) {
      setSelectCityError('Please select an option');
    } else {
      setSelectCityError('');
    }
  };

  const handleBillingCityChange = (selectedOption) => {
    setBillingData({
      ...billingData,
      city: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
    });
    if (!selectedOption) {
      setSelectBillingCityError('Please select an option');
    } else {
      setSelectBillingCityError('');
    }
  };

  const billingState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST);
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
        unavailable: item.info?.unavailable,
      }));
      setStateOptions(mappedOptions);
    } catch (error) {
      console.log('err', error);
    }
  };

  const getVehicleDetails = async () => {
    try {
      const response = await getRequest(GET_VEHICLE_DETAILS);
      setProfileObject(response.result.profiles[0]);
      setUserEmail(response?.result?.email);
    } catch (error) {
      console.log('API error:', error);
    }
  };

  useEffect(() => {
    billingState();
    getVehicleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mobNumber =
      profileObject?.mobile && formatUSBasedNumber(profileObject?.mobile);
    profileObject &&
      setMemberData({
        first_name: profileObject?.first_name,
        last_name: profileObject?.last_name,
        date_of_birth: profileObject?.date_of_birth,
        dba_name: profileObject?.dba_name,
        mobile: mobNumber,
        gender: profileObject?.gender,
        marital_status: profileObject?.marital_status,
        profile_picture: profileObject?.profile_picture_url,
      });
    profileObject &&
      setSelectedDate(
        profileObject.date_of_birth && parseISO(profileObject?.date_of_birth)
      );
    profileObject &&
      setAddressData({
        address_line_1: profileObject?.shipping_address.address_line_1,
        state: {
          name: profileObject?.shipping_address.state?.name,
          id: profileObject?.shipping_address.state?.id,
        },
        city: profileObject?.shipping_address.city?.name
          ? {
              name: profileObject?.shipping_address.city?.name,
              id: profileObject?.shipping_address.city?.id,
            }
          : {},
        country: '',
        zip5: profileObject?.shipping_address.zip5,
      });
    profileObject &&
      setBillingData({
        address_line_1: profileObject?.billing_address.address_line_1,
        state: {
          name: profileObject?.billing_address.state?.name,
          id: profileObject?.billing_address.state?.id,
        },
        city: profileObject?.billing_address.city?.name
          ? {
              name: profileObject?.billing_address.city?.name,
              id: profileObject?.billing_address.city?.id,
            }
          : {},
        country: '',
        zip5: profileObject?.billing_address.zip5,
      });
    userEmail && userEmail.includes('@example.com')
      ? setLoginData({ email: '' })
      : setLoginData({ email: userEmail, password: '', confirm_password: '' });
  }, [profileObject, setProfileObject, userEmail]);

  const handleSelectedDriver = async (showSelectedDriver) => {
    if (showSelectedDriver === null) {
      setMemberData({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        dba_name: '',
        mobile: '',
        gender: '',
        marital_status: '',
        profile_picture: '',
      });
      setAddressData({
        address_line_1: '',
        state: {},
        city: {},
        country: '',
        zip5: '',
      });
      setLoginData({ email: '' });
    } else {
      const driver = await profileObject.drivers.find(
        (item) => item.id === showSelectedDriver
      );
      setMemberData({
        first_name: driver?.first_name,
        last_name: driver?.last_name,
        date_of_birth: driver?.date_of_birth,
        dba_name: '',
        mobile: '',
        gender: '',
        marital_status: '',
        profile_picture: '',
      });
      setAddressData({
        address_line_1: driver?.driver_address.address_line_1,
        state: {
          name: driver?.driver_address?.state?.name,
          id: driver?.driver_address?.state?.id,
        },
        city: {
          name: driver?.driver_address?.city?.name,
          id: driver?.driver_address?.city?.id,
        },
        country: '',
        zip5: driver?.driver_address.zip5,
      });
      setLoginData({ email: driver?.email });
      setFieldErrors({
        ...fieldErrors,
        first_name: '',
        last_name: '',
        date_of_birth: '',
      });
      setAddressFieldErrors({
        address: '',
        zip: '',
        state: '',
        city_id: '',
      });
      setSelectStateError('');
      setSelectCityError('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberData({
      ...memberData,
      [name]: value,
    });

    if (name === 'mobile') {
      const formattedNumber = formatUSBasedNumber(value);
      setMemberData({
        ...memberData,
        [name]: formattedNumber,
      });
    }

    setAddressData({
      ...addressData,
      [name]: value,
    });
    if (name === 'zip5') {
      const formattedZip = formatZipCode(value);
      setAddressData({
        ...addressData,
        [name]: formattedZip,
      });
    }

    setLoginData({
      ...loginData,
      [name]: value,
    });
    setLoginDataError({
      ...loginDataError,
      [name]: '',
    });

    setFieldErrors({
      ...fieldErrors,
      [name]: '',
    });
    setAddressFieldErrors({
      ...addressfieldErrors,
      [name]: '',
    });
    if (copyHomeToBilling) {
      setBillingData({
        ...addressData,
        [name]: value,
      });
    }
  };

  const handleChangeBilling = (e) => {
    const { name, value } = e.target;
    setBillingData({
      ...billingData,
      [name]: value,
    });
    if (name === 'zip5') {
      const formattedZip = formatZipCode(value);
      setBillingData({
        ...billingData,
        [name]: formattedZip,
      });
    }
    setBillingFieldErrors({
      ...billingfieldErrors,
      [name]: '',
    });
  };

  const guestUserToken = GetGuestUserToken();

  const checkEmail = async (e) => {
    const headers = {
      Authorization: guestUserToken,
    };
    const data = {
      email: e.target.value,
    };
    const response = await getRequest(CHECK_EMAIL, data, headers);
    if (response?.result?.success) {
      setLoginDataError({
        ...loginDataError,
        email: response?.result?.message,
      });
    }
  };

  const handleSave = async (e) => {
    let base64;
    if (uploadedFile) {
      base64 = await convertToBase64(uploadedFile);
    }
    window.scrollTo(0, 0);
    setButtonLoading(true);
    e.preventDefault();
    let hasErrors = false;
    const newFieldErrors = {};
    const newAddressFieldErrors = {};
    const newBillingFieldErrors = {};
    const newLoginFieldErrors = {};

    membershipData.forEach((field) => {
      if (
        !memberData[field.name] ||
        (typeof memberData[field.name] === 'string' &&
          memberData[field.name].trim() === '')
      ) {
        // Skip validation for the "DBA Name" field
        if (
          field.name !== 'dba_name' &&
          field.name !== 'marital_status' &&
          field.name !== 'gender' &&
          field.name !== 'profile_picture'
        ) {
          newFieldErrors[field.name] = `${field.label} is required`;
          hasErrors = true;
        }
      } else if (
        field.name === 'date_of_birth' &&
        !isValidDate(memberData[field.name])
      ) {
        // Check date_of_birth validation (replace isValidDate with your validation logic)
        newFieldErrors[field.name] = 'Age must be greater than 18 years.';
        hasErrors = true;
      } else if (
        field.name === 'mobile' &&
        !isValidUSPhoneNumber(memberData[field.name])
      ) {
        // Check phone number validation (replace isValidPhoneNumber with your validation logic)
        newFieldErrors[field.name] = 'Invalid phone number';
        hasErrors = true;
      }
    });

    const data = {
      save_member_info: true,
      first_name: memberData.first_name,
      last_name: memberData.last_name,
      date_of_birth: memberData.date_of_birth,
      dba_name: memberData.dba_name,
      mobile: memberData?.mobile?.replace(/[^\d]/g, ''),
      gender: memberData.gender,
      marital_status: memberData.marital_status,
      profile_picture: base64 ? base64 : memberData.profile_picture,
      shipping_address_attributes: {
        id: profileObject?.shipping_address.id,
        address_line_1: addressData.address_line_1,
        state_id: addressData.state?.id,
        city_id: addressData.city?.id,
        country: '',
        zip5: addressData.zip5,
      },
      billing_address_attributes: {
        id: profileObject?.billing_address.id,
        address_line_1: billingData.address_line_1,
        state_id: billingData.state?.id,
        city_id: billingData.city?.id,
        country: '',
        zip5: billingData.zip5,
      },
      vehicle_details_attributes: [{}],
    };

    const guestUserToken = GetGuestUserToken();
    const headers = {};
    headers.Authorization = guestUserToken;
    // Check if guestUserToken is not empty or null
    if (guestUserToken) {
      // If guestUserToken is not empty, include it in the headers
      headers.Authorization = guestUserToken;
    }

    const userData = {
      confirm_password: loginData.confirm_password,
      email: loginData.email,
      password: loginData.password,
    };

    const saveUser = async (userData) => {
      const { result } = await putRequestUser(
        SAVE_USER,
        userData,
        userData,
        headers
      );
      if (!result.error) {
        setUserEmail(loginData.email);
        setButtonLoading(false);
        setShowUpdateVINSection(true);
      } else {
        ToastManager.error(result.error);
        setButtonLoading(false);
      }
    };

    Setpassword(loginData.password);

    if (!/^[A-Za-z]+$/.test(memberData.first_name)) {
      newFieldErrors.first_name = 'First Name should only contain letters.';
      hasErrors = true;
    }
    if (!/^[A-Za-z]+$/.test(memberData.last_name)) {
      newFieldErrors.last_name = 'Last Name should only contain letters.';
      hasErrors = true;
    }

    homeAddressData.forEach((field) => {
      if (!addressData[field?.id] || addressData[field?.id] === '') {
        newAddressFieldErrors[field.name] = `${field.label} is required`;
        hasErrors = true;
      } else if (field.name === 'zip5' && addressData[field.name].length < 5) {
        newAddressFieldErrors[field.name] = 'Invalid Zip Code';
        hasErrors = true;
      }
    });
    userloginData.forEach((field) => {
      if (!loginData[field.id] || loginData[field.id] === '') {
        newLoginFieldErrors[field.name] = `${field.label} is required`;
        hasErrors = true;
      } else if (
        field.name === 'confirm_password' &&
        loginData.confirm_password !== loginData.password
      ) {
        newLoginFieldErrors['confirm_password'] = 'Password not matched';
        hasErrors = true;
      } else if (field.name === 'password' && loginData.password.length < 6) {
        newLoginFieldErrors['password'] =
          'Password should be minimum 6 characters long';
        hasErrors = true;
      } else if (
        field.name === 'email' &&
        !isValidEmail(loginData[field.name])
      ) {
        // Check email validation
        newLoginFieldErrors[field.name] = 'Invalid email address';
        hasErrors = true;
      }
    });
    if (!addressData.address || addressData.address.length < 15) {
      newAddressFieldErrors['address'] =
        'Address must be at least 15 characters long';
    }
    billingAddressData.forEach((field) => {
      if (!billingData[field.name] || billingData[field.name] === '') {
        newBillingFieldErrors[field.name] = `${field.label} is required`;
        hasErrors = true;
      } else if (
        field.name === 'zip5' &&
        billingData[field.name].length < 5
      ) {
        newBillingFieldErrors[field.name] =
          'Invalid zip code';
        hasErrors = true;
      }
    });

    if (!addressData.state?.id) {
      setSelectStateError('Please select an option');
      hasErrors = true;
    }

    if (!addressData.city?.id) {
      setSelectCityError('Please select an option');
      hasErrors = true;
    }

    if (!billingData.state?.id) {
      setSelectBillingStateError('Please select an option');
      hasErrors = true;
    }

    if (!billingData.city?.id) {
      setSelectBillingCityError('Please select an option');
      hasErrors = true;
    }

    if (hasErrors) {
      setFieldErrors(newFieldErrors);
      setAddressFieldErrors(newAddressFieldErrors);
      setBillingFieldErrors(newBillingFieldErrors);
      setLoginDataError(newLoginFieldErrors);
      ToastManager.error(
        'Form Validation failed. Please check errors before saving.'
      );
      setButtonLoading(false);
      return;
    } else {
      saveProfile(data).then((success) => {
        if (!success) {
          setButtonLoading(false);
          return false;
        } else {
          setButtonLoading(false);
          saveUser(userData);
        }
      });
    }
  };

  const isValidDate = (date) => {
    const age = calculateAge(date);
    return age >= 18;
  };

  function isValidUSPhoneNumber(phoneNumber) {
    // Use the isMobilePhone function with 'en-US' locale for U.S. phone numbers
    return validator.isMobilePhone(phoneNumber, 'en-US');
  }

  const handleDriverModalShow = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className="container current-container text-start">
        <DriverDetails
          selectedDriverAsMember={selectedDriverAsMember}
          setSelectedDriverAsMember={setSelectedDriverAsMember}
          handleSelectedDriver={handleSelectedDriver}
          handleDriverModalShow={handleDriverModalShow}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setCopyDriverToMember={setCopyDriverToMember}
          stateOptions={stateOptions}
        />

        <h3 className="text-center">
          <b>Membership Details</b>
        </h3>
        <div className="py-3">
          <div className="user-detail-form-blue mx-md-4">
            {profileObject && profileObject?.drivers?.length > 0 ? (
              <div className="d-flex justify-content-end">
                <span
                  onClick={handleDriverModalShow}
                  className="cursor-pointer"
                >
                  <Form.Check
                    type="checkbox"
                    label="Copy Driver Details to Member Details"
                    checked={copyDriverToMember}
                    className="driver-checkbox"
                    readOnly
                  />
                </span>
              </div>
            ) : null}
            <h5 className="sub-heading_form">Login Details</h5>
            <div className="row mt-2">
              {userloginData.map((field, index) => (
                <div className="col-md-6" key={index}>
                  <Form.Group
                    controlId={field.id}
                    className="d-flex flex-column"
                    autoComplete="off"
                  >
                    <Form.Label className="fw-bold mt-2">
                      {field.label}
                      <span className="text-danger">&nbsp;*</span>
                      {field.name === 'email' &&
                      loginDataError[field.name] === 'Email Already Present' ? (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip">
                              This email address is already a SILO member.
                              Please enter a new email address or contact
                              support@SILOmembers.com.
                            </Tooltip>
                          }
                        >
                          <span className="float-end cursor-pointer text-muted">
                            <AiOutlineQuestionCircle size="20" />
                          </span>
                        </OverlayTrigger>
                      ) : null}
                    </Form.Label>
                    {field.name === 'confirm_password' ? (
                      <>
                        <InputGroup>
                          <Form.Control
                            required
                            name={field.name}
                            placeholder={field.placeholder}
                            value={loginData[field.name] || ''}
                            onChange={handleChange}
                            type={Confirmicon ? 'password' : 'text'}
                          />
                          <InputGroup.Text>
                            <div
                              className="icon"
                              onClick={() => Setconfirmicon(!Confirmicon)}
                            >
                              {Confirmicon ? (
                                <img src={hidePassSvg} alt="showPassword" />
                              ) : (
                                <img
                                  className="w_20px"
                                  src={showPassSvg}
                                  alt="showPassword"
                                />
                              )}
                            </div>
                          </InputGroup.Text>
                        </InputGroup>
                        {fieldErrors[field.name] && (
                          <span className="text-danger">
                            {fieldErrors[field.name]}
                          </span>
                        )}
                      </>
                    ) : field.name === 'password' ? (
                      <>
                        <InputGroup>
                          <Form.Control
                            name={field.name}
                            required
                            placeholder={field.placeholder}
                            value={loginData[field.name] || ''}
                            onChange={handleChange}
                            type={icon ? 'password' : 'text'}
                          />
                          <InputGroup.Text>
                            <div
                              className="icon"
                              onClick={() => Seticon(!icon)}
                            >
                              {icon ? (
                                <img src={hidePassSvg} alt="showPassword" />
                              ) : (
                                <img
                                  className="w_20px"
                                  src={showPassSvg}
                                  alt="showPassword"
                                />
                              )}
                            </div>
                          </InputGroup.Text>
                        </InputGroup>
                        {fieldErrors[field.name] && (
                          <span className="text-danger">
                            {fieldErrors[field.name]}x
                          </span>
                        )}
                      </>
                    ) : (
                      <Form.Control
                        autoComplete="new-password"
                        type={field.type}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={loginData[field.name] || ''}
                        onChange={handleChange}
                        onBlur={checkEmail}
                        className={`${
                          loginDataError[field.name] ? 'is-invalid' : ''
                        }`}
                        disabled={
                          field.name === 'email' && profileObject?.is_verified
                            ? true
                            : false
                        }
                      />
                    )}
                    {loginDataError[field.name] && (
                      <span className="text-danger">
                        {loginDataError[field.name]}
                      </span>
                    )}
                  </Form.Group>
                </div>
              ))}
            </div>
          </div>
          {/*<hr className="my-md-5 d-none-mobile" />*/}
          <div className="user-detail-form-blue mx-md-4">
            <div className="row mt-2">
              {membershipData.map((field, index) => (
                <div className="col-md-6" key={index}>
                  <Form.Group
                    controlId={field.id}
                    className="d-flex flex-column"
                  >
                    <Form.Label className="fw-bold mt-2">
                      {field.label}
                      {field.name === 'dba_name' ||
                        field.name === 'marital_status' ||
                        field.name === 'gender' ||
                        field.name === 'profile_picture' || (
                          <span className="text-danger">&nbsp;*</span>
                        )}

                      {field.name === 'mobile' ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Phone number is required because we need to give
                              the same to our payment gateway
                            </Tooltip>
                          }
                        >
                          <span className="float-end cursor-pointer text-muted">
                            <AiOutlineQuestionCircle size="20" />
                          </span>
                        </OverlayTrigger>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    {field.name === 'gender' ? (
                      <>
                        <Select
                          className={`custom-select w-100`}
                          name={field.name}
                          isClearable
                          value={
                            sexTypeoptions.find(
                              (option) =>
                                option.value === memberData[field.name]
                            ) || ''
                          }
                          onChange={(selectedOption) => {
                            handleSexChange(selectedOption);
                          }}
                          options={sexTypeoptions}
                          placeholder={
                            <div className="select-placeholder-text text-start">
                              Select Sex
                            </div>
                          }
                        />
                      </>
                    ) : field.name === 'marital_status' ? (
                      <>
                        <Select
                          className={`custom-select w-100`}
                          name={field.name}
                          isClearable
                          value={
                            maritalTypeoptions.find(
                              (option) =>
                                option.value === memberData[field.name]
                            ) || ''
                          }
                          onChange={(selectedOption) => {
                            handleMaritalStatusChange(selectedOption);
                          }}
                          options={maritalTypeoptions}
                          placeholder={
                            <div className="select-placeholder-text">
                              Select Marital Status
                            </div>
                          }
                        />
                      </>
                    ) : field.name === 'date_of_birth' ? (
                      <>
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          className={`custom-year-datepicker ${
                            fieldErrors[field.name] ? 'is-invalid' : ''
                          }`}
                          placeholderText="MM/DD/YYYY"
                          maxDate={eighteenYearsAgo}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          icon="BsFillCalendarFill"
                        />
                        {fieldErrors[field.name] && (
                          <span className="text-danger">
                            {fieldErrors[field.name]}
                          </span>
                        )}
                      </>
                    ) : field.name === 'profile_picture' ? (
                      <UploadProfilePicture
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                        setMemberData={setMemberData}
                        memberData={memberData}
                        profileId={profileObject?.id}
                      />
                    ) : (
                      <>
                        <Form.Control
                          autoComplete="new-password"
                          type={field.type}
                          name={field.name}
                          placeholder={field.placeholder}
                          value={memberData[field.name] || ''}
                          onChange={handleChange}
                          max={field.max} // Add the max attribute
                          className={`mb-0 ${
                            fieldErrors[field.name] ? 'is-invalid' : ''
                          }`}
                        />
                        {fieldErrors[field.name] && (
                          <span className="text-danger">
                            {fieldErrors[field.name]}
                          </span>
                        )}
                      </>
                    )}
                  </Form.Group>
                </div>
              ))}
            </div>
          </div>
          {/*<hr className="my-md-5 d-none-mobile" />*/}
          <div className="user-detail-form-blue mx-md-4 mt-4 mt-md-5">
            <h5 className="sub-heading_form">Home and Garaging Address</h5>
            <div className="row mt-2">
              <div className="col-md-12 d-none">
                <AutoCompleteAddress
                  onAddressSelect={handleAutoCompleteSelect}
                />
              </div>
              {homeAddressData.map((field, index) => (
                <div className="col-md-6" key={index}>
                  <Form.Group
                    controlId={field.id}
                    className="d-flex flex-column"
                  >
                    <Form.Label className="fw-bold mt-2">
                      {field.label}
                      <span className="text-danger">&nbsp;*</span>
                    </Form.Label>
                    {field.name === 'state' ? (
                      <>
                        <Select
                          className={`custom-select w-100 ${
                            selectStateError ? 'is-invalid' : ''
                          }`}
                          name={field.name}
                          value={
                            stateOptions.find(
                              (option) => option.id === addressData.state?.id
                            ) || ''
                          }
                          onChange={(selectedOption) => {
                            handleStateChange(selectedOption);
                            setSelectStateError('');
                          }}
                          options={stateOptions.map((option) => ({
                            ...option,
                            isDisabled: option.unavailable === true,
                          }))}
                          placeholder={
                            <div className="select-placeholder-text text-start">
                              Select State
                            </div>
                          }
                        />
                        {selectStateError && (
                          <div className="text-danger">{selectStateError}</div>
                        )}
                      </>
                    ) : field.name === 'city' ? (
                      <>
                        <CommonCityApi
                          stateId={stateId}
                          page={page}
                          Setpage={Setpage}
                          CityData={addressData}
                          CityOptions={cityOption}
                          SetCityOptions={setCityOption}
                          handleCityChange={handleCityChange}
                          selectDriverCityError={selectCityError}
                        />

                        {selectCityError && (
                          <div className="text-danger">{selectCityError}</div>
                        )}
                      </>
                    ) : (
                      <>
                        <Form.Control
                          autoComplete="home-address"
                          type={field.type}
                          name={field.name}
                          placeholder={field.placeholder}
                          value={addressData[field.name] || ''}
                          onChange={handleChange}
                          min="0"
                          className={`${
                            addressfieldErrors[field.name] ? 'is-invalid' : ''
                          }`}
                        />
                        {addressfieldErrors[field.name] && (
                          <span className="text-danger">
                            {addressfieldErrors[field.name]}
                          </span>
                        )}
                      </>
                    )}
                  </Form.Group>
                </div>
              ))}
            </div>
          </div>
          {/*<hr className="my-md-5 d-none-mobile" />*/}
          <div className="user-detail-form-blue mx-md-4 mt-4 mt-md-5">
            <h5 className="sub-heading_form">Billing Address</h5>
            <div className="d-flex">
              <span
                onClick={handleCopyToBillingChange}
                className="cursor-pointer"
              >
                <Form.Check
                  type="checkbox"
                  label="Copy Home Address to Billing Address"
                  checked={copyHomeToBilling}
                  className="driver-checkbox"
                  readOnly
                />
              </span>
            </div>
            {!copyHomeToBilling && (
              <div className="row mt-2">
                {billingAddressData.map((field, index) => (
                  <div className="col-md-6" key={index}>
                    <Form.Group
                      controlId={field.id}
                      className="d-flex flex-column"
                    >
                      <Form.Label className="fw-bold mt-2">
                        {field.label}
                        <span className="text-danger">&nbsp;*</span>
                      </Form.Label>
                      {field.name === 'state' ? (
                        <>
                          <Select
                            className={`custom-select w-100 ${
                              selectBillingStateError ? 'is-invalid' : ''
                            }`}
                            name={field.name}
                            value={
                              stateOptions.find(
                                (option) => option.id === billingData.state?.id
                              ) || ''
                            }
                            onChange={(selectedOption) => {
                              handleBillingStateChange(selectedOption);
                              setSelectBillingStateError('');
                            }}
                            options={stateOptions.map((option) => ({
                              ...option,
                              isDisabled: option.unavailable === true,
                            }))}
                            placeholder={
                              <div className="select-placeholder-text text-start">
                                Select State
                              </div>
                            }
                          />
                          {selectBillingStateError && (
                            <div className="text-danger">
                              {selectBillingStateError}
                            </div>
                          )}
                        </>
                      ) : field.name === 'city' ? (
                        <>
                          <CommonCityApi
                            stateId={billingStateId}
                            page={billingPage}
                            Setpage={SetbillingPage}
                            CityData={billingData}
                            CityOptions={billingCityOptions}
                            SetCityOptions={setBillingCityOptions}
                            handleCityChange={handleBillingCityChange}
                            selectDriverCityError={selectBillingCityError}
                          />
                          {selectBillingCityError && (
                            <div className="text-danger">
                              {selectBillingCityError}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <Form.Control
                            autoComplete="billing-address"
                            type={field.type}
                            name={field.name}
                            placeholder={field.placeholder}
                            value={billingData[field.name] || ''}
                            onChange={handleChangeBilling}
                            min="0"
                            className={`${
                              billingfieldErrors[field.name] ? 'is-invalid' : ''
                            }`}
                          />
                          {billingfieldErrors[field.name] && (
                            <span className="text-danger">
                              {billingfieldErrors[field.name]}
                            </span>
                          )}
                        </>
                      )}
                    </Form.Group>
                  </div>
                ))}
              </div>
            )}
          </div>
          <span className="navigation_bottom">
            <div className="d-flex align-items-center mt-3 mt-md-5 px-md-4">
              <button
                className="btn silo-btn-small silo-btn__blue btn-primary w-100-sm"
                onClick={handleSave}
              >
                {buttonLoading ? <Spinner size="sm" /> : <>Save</>}
              </button>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default MembershipDetails;
