import React, { useRef } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoMdCloudUpload } from 'react-icons/io';
import { handleDeleteDocument } from '../../../../../../utils/CommonDocumentsFunctions';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import { GetGuestUserToken } from '../../../guestUserTokenUtils';

function UploadProfilePicture({
  uploadedFile,
  setUploadedFile,
  setMemberData,
  memberData,
  profileId,
}) {
  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    const selectedFile = inputRef.current.files[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check file type
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!allowedTypes.includes(selectedFile.type)) {
        alert('Please select a valid image file (JPEG, JPG, or PNG).');
        return;
      }

      // Check file size
      const maxSizeKB = 200; // Maximum size in kilobytes
      const minSizeKB = 20; // Minimum size in kilobytes

      if (
        selectedFile.size > maxSizeKB * 1024 ||
        selectedFile.size < minSizeKB * 1024
      ) {
        alert(
          `File size should be between ${minSizeKB} KB and ${maxSizeKB} KB.`
        );
        return;
      }
    }
    inputRef.current?.files && setUploadedFile(inputRef.current.files[0]);
  };

  const handleDeleteImage = async () => {
    const headers = {
      Authorization: GetGuestUserToken(),
    };
    const response = await handleDeleteDocument(
      'profile_picture',
      profileId,
      headers
    );
    if (response?.result?.success) {
      ToastManager.success(response?.result?.message);
      setMemberData({
        ...memberData,
        profile_picture: '',
      });
      setUploadedFile(null);
    }
  };

  return (
    <>
      <div className="d-flex">
        <label className="margin-right">Choose file:</label>
        <input
          ref={inputRef}
          onChange={handleDisplayFileDetails}
          className="d-none"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
        />
        <button
          onClick={handleUpload}
          className={`d-flex justify-content-between btn btn-sm upload-btn btn-outline-${
            uploadedFile || memberData.profile_picture ? 'success' : 'primary'
          }`}
        >
          <IoMdCloudUpload size="20" />
          {uploadedFile
            ? uploadedFile.name
            : memberData.profile_picture
            ? 'profile_picture.png'
            : 'Upload'}
        </button>
        {uploadedFile || memberData.profile_picture ? (
          <RiDeleteBin6Line
            className="cursor-pointer margin-left"
            color="red"
            size="20"
            onClick={() => handleDeleteImage()}
          />
        ) : null}
      </div>
      <div className="text-muted py-4 fs-8">
        <strong>Note : </strong>Only JPG, PNG and JPEG file formats are
        accepted. <br />
        Max allowed size : 20kb - 200kb
      </div>
    </>
  );
}

export default UploadProfilePicture;
