import React, { useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Form } from 'react-bootstrap';

const AutoCompleteAddress = ({ onAddressSelect }) => {
  const [addressData, setAddressData] = useState('');

  const parseSelectedAddress = (selectedAddress) => {
    const addressComponents = selectedAddress.split(',');
    const city = addressComponents[addressComponents.length - 3].trim();
    const stateAndZip = addressComponents[addressComponents.length - 2].trim();
    const [state] = stateAndZip.split(' ');
    return { city, state };
  };

  return (
    <div>
      <Form.Group controlId="searchAddress">
        <Form.Label className="fw-bold mt-2">
          Search Address<span className="text-danger"> *</span>
        </Form.Label>
        <ReactGoogleAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
          className="form-control"
          onPlaceSelected={(place) => {
            setAddressData({
              ...addressData,
              searchAddress: place.formatted_address,
            });
            // Call the callback to pass the selected address
            if (typeof onAddressSelect === 'function') {
              onAddressSelect(parseSelectedAddress(place.formatted_address));
            }
          }}
          types={['geocode', 'establishment']}
          options={{
            componentRestrictions: { country: 'US' },
          }}
          inputprops={{
            name: 'searchAddress',
            value: addressData.searchAddress,
            onChange: (e) => {
              const { value } = e.target;
              setAddressData({
                ...addressData,
                searchAddress: value,
              });
            },
          }}
        />
      </Form.Group>
    </div>
  );
};

export default AutoCompleteAddress;
