import React, { useContext } from 'react';
import { Context } from '../../../../../utils/Context';
import { Spinner } from 'react-bootstrap';
import { MdMarkEmailRead } from 'react-icons/md';

export const DocusignMobileScreen = ({
  countdown,
  handleResendDocusignAgreementMail,
  handleDocusignCheckout,
  buttonLoading,
}) => {
  const { userEmail } = useContext(Context);
  return (
    <div className="docusign_agreement_checkbox">
      <MdMarkEmailRead size="100" color="#1263a9" />
      <h3 className="fw-bold">Check your inbox!</h3>
      <div className="text-muted fs-6 lh-lg p-3">
        An email has been sent to your registered email address -
        <i className="text-primary">{userEmail}</i>
        <br />
        <div>
          Please follow the instructions to complete the signing process.
        </div>
      </div>
      <div className="my-5 details-container">
        <button
          className="btn btn silo-btn silo-btn__blue m-auto"
          disabled={buttonLoading}
          onClick={handleDocusignCheckout}
        >
          {buttonLoading ? <Spinner size="sm" /> : <>Complete Signing</>}
        </button>
        <span className="text-muted mt-3">
          By clicking the button above, I acknowledge that I have digitally
          signed the document sent to my email. I've thoroughly reviewed the
          contents and agree to all the terms and conditions outlined within.
        </span>
      </div>
      If You have not received the email,{' '}
      <div
        className={!countdown ? 'cursor-pointer text-blue fw-bold mt-4' : ''}
        onClick={countdown ? null : handleResendDocusignAgreementMail}
        disabled={countdown > 1 ? true : false}
      >
        <span
          className={`${countdown ? 'text-muted' : 'text-primary'} fw-bold`}
        >
          Resend Email
        </span>{' '}
        {countdown ? `after ${countdown} seconds` : ''}
      </div>
    </div>
  );
};
