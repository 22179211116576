import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { ToastManager } from '../../../../../../../../../ToastContainer/ToastContainer';
import AutoCompleteAddress from '../../../../../../../Shared/AutoCompleteAddress/AutoCompleteAddress';
import { GET_CITY_LIST, GET_STATE_LIST } from '../../../../../../../../../api/endpoint';
import { getRequest } from '../../../../../../../../../api/api';

const EditBilling = () => {
  const homeAddressData = [
    {
      label: 'Address',
      id: 'address',
      name: 'address',
      value: '',
      type: 'text',
    },
    {
      label: 'Zip',
      id: 'zip',
      name: 'zip',
      value: '',
      type: 'number',
    },
    {
      label: 'State',
      id: 'state',
      name: 'state',
      value: '',
      type: 'text',
    },
    {
      label: 'City',
      id: 'city',
      name: 'city',
      value: '',
      type: 'text',
    },
  ];

  const handleAutoCompleteSelect = (selectedAddress) => {
    setAddressData({
      ...addressData,
      city: selectedAddress.city,
      state: selectedAddress.state,
    });
  };

  const [selectStateError, setSelectStateError] = useState('');
  const [selectCityError, setSelectCityError] = useState('');

  const [memberData, setMemberData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    dbaName: '',
    email: '',
    phone: '',
    sex: '',
    maritalStatus: '',
  });

  const [addressData, setAddressData] = useState({
    address: '',
    zip: '',
    state: '',
    city: '',
  });

  const [billingData, setBillingData] = useState({
    address: '',
    zip: '',
    state: '',
    city: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
    sex: '',
    maritalStatus: '',
  });

  const [addressfieldErrors, setAddressFieldErrors] = useState({
    address: '',
    zip: '',
    state: '',
    city: '',
  });

  const [billingfieldErrors, setBillingFieldErrors] = useState({
    address: '',
    zip: '',
    state: '',
    city: '',
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOption, setCityOption] = useState([])

  const handleStateChange = (selectedOption) => {
    setAddressData({
      ...addressData,
      state: selectedOption.value,
    });
    billingCity(selectedOption ? selectedOption.id : null)
    if (!selectedOption) {
      setSelectStateError('Please select an option');
    } else {
      setSelectStateError('');
    }
  };

  const handleCityChange = (selectedOption) => {
    setAddressData({
      ...addressData,
      city: selectedOption.value,
    });
    if (!selectedOption) {
      selectCityError('Please select an option');
    } else {
      setSelectCityError('');
    }
  };

  const billingState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST)
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id
      }))
      setStateOptions(mappedOptions);
    } catch (error) {
      console.log("err", error)
    }
  }

  const billingCity = async (state_id) => {
    try {
      const billing_state_id = {
        state_id: state_id,
      };
      const response = await getRequest(GET_CITY_LIST, billing_state_id)
      const cityData = response.result.data;
      const cityMapped = cityData.map((item) => ({
        value: item.attributes.name,
        label: item.attributes.name,
        id: item.attributes.id,
      }))
      setCityOption(cityMapped)
    } catch (error) {
      console.log("err", error)
    }
  }

  useEffect(() => {
    billingState();
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberData({
      ...memberData,
      [name]: value,
    });
    setAddressData({
      ...addressData,
      [name]: value,
    });
    setBillingData({
      ...billingData,
      [name]: value,
    });
    setFieldErrors({
      ...fieldErrors,
      [name]: '',
    });
    setAddressFieldErrors({
      ...addressfieldErrors,
      [name]: '',
    });
    setBillingFieldErrors({
      ...billingfieldErrors,
      [name]: '',
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    let hasErrors = false;
    const newFieldErrors = {};
    const newAddressFieldErrors = {};
    const newBillingFieldErrors = {};

    homeAddressData.forEach((field) => {
      if (!addressData[field.name] || addressData[field.name].trim() === '') {
        newAddressFieldErrors[field.name] = `${field.label} is required`;
        hasErrors = true;
      }
    });

    if (!addressData.state && !addressData.city) {
      setSelectStateError('Please select an option');
      setSelectCityError('Please select an option');
      hasErrors = true;
    }

    if (hasErrors) {
      setFieldErrors(newFieldErrors);
      setAddressFieldErrors(newAddressFieldErrors);
      setBillingFieldErrors(newBillingFieldErrors);
      ToastManager.error('Please fill mandatory fields');
      return;
    }
    ToastManager.success(
      'Form has been saved successfully'
    );
  };

  return (
    <>
      <div className="panel_theme">
        <div className="panel_theme_head">
          Billing Contact
        </div>
        <div className="panel_theme_body">
<div className="user-detail-form-blue">
        <div className="row mt-2">
          <div className="col-md-12">
            <AutoCompleteAddress onAddressSelect={handleAutoCompleteSelect} />
          </div>
          {homeAddressData.map((field) => (
            <div className="col-md-6">
              <Form.Group controlId={field.id} className="d-flex flex-column">
                <Form.Label className="fw-bold mt-2">
                  {field.label}
                  <span className="text-danger">&nbsp;*</span>
                </Form.Label>
                {field.name === 'state' ? (
                  <>
                    <Select
                      className={`custom-select w-100 ${selectStateError ? 'is-invalid' : ''
                        }`}
                      name={field.name}
                      value={stateOptions.find(
                        (option) => option.value === addressData[field.name]
                      )}
                      onChange={(selectedOption) => {
                        handleStateChange(selectedOption);
                        setSelectStateError('');
                      }}
                      options={stateOptions}
                      placeholder={
                        <div className="select-placeholder-text text-start">
                          Select State
                        </div>
                      }
                    />
                    {selectStateError && (
                      <div className="text-danger">{selectStateError}</div>
                    )}
                  </>
                ) : field.name === 'city' ? (
                  <>
                    <Select
                      className={`custom-select w-100 ${selectCityError ? 'is-invalid' : ''
                        }`}
                      name={field.name}
                      value={cityOption.find(
                        (option) => option.value === addressData[field.name]
                      )}
                      onChange={(selectedOption) => {
                        handleCityChange(selectedOption);
                        setSelectCityError('');
                      }}
                      options={cityOption}
                      placeholder={
                        <div className="select-placeholder-text">
                          Select City
                        </div>
                      }
                    />
                    {selectCityError && (
                      <div className="text-danger">{selectCityError}</div>
                    )}
                  </>
                ) : (
                  <>
                    <Form.Control
                      type={field.type}
                      name={field.name}
                      min="0"
                      value={addressData[field.name]}
                      onChange={handleChange}
                      className={`${addressfieldErrors[field.name] ? 'is-invalid' : ''
                        }`}
                    />
                    {addressfieldErrors[field.name] && (
                      <span className="text-danger">
                        {addressfieldErrors[field.name]}
                      </span>
                    )}
                  </>
                )}
              </Form.Group>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center  justify-content-end mt-4">
        <button
          className="btn silo-btn-small silo-btn__blue btn-primary"
          onClick={handleSave}
        >
          Save
        </button>
        <Link
          to="/account/billing/details"
          className="btn silo-btn-small ant-steps-item-content mx-2"
        >
          Cancel
        </Link>
      </div>
        </div>
      </div>

      
    </>
  );
};

export default EditBilling;
