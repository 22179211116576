import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
  return (
    <div className="silo_page-header">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between d-inherit-sm-imp">
          <h1 className="my-4">{props.title}</h1>
          <ol className="breadcrumb my-4">
            <li aria-current="page" className="breadcrumb-item">
              <Link to="/" className="nav-link">
                <span>HOME</span>
              </Link>
            </li>
            <li className="breadcrumb-item active">{props.title}</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
