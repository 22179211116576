import React, { useState, useEffect } from 'react';
import logo from '../../../../../../assets/images/payment-failed.avif';
import logoPng from '../../../../../../assets/images/payment-failed.png';
import { GetGuestUserToken, SetGuestUserToken } from '../../../guestUserTokenUtils';
import { putRequest } from '../../../../../../api/api';
import {
  PAYMENT_FAILED
} from '../../../../../../api/endpoint';
import Spinner from 'react-bootstrap/Spinner';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';

const PaymentFailed = ({ securityKey, token, profile_id, program_id, setDisplayPaymentFailed }) => {
  const [failedResponse, setFailedResponse] = useState('');
  const [showLoader, setShowLoader] = useState(true);

  const handlePaymentFailed = async () => {
    let guestUserToken = GetGuestUserToken();
    if(!guestUserToken) {
      SetGuestUserToken(token);
      guestUserToken = token;
    }
    const headers = {};
    headers.Authorization = guestUserToken;
    // Check if guestUserToken is not empty or null
    if (guestUserToken) {
      // If guestUserToken is not empty, include it in the headers
      headers.Authorization = guestUserToken;
    }

    const params = {
      id: '',
    };
    const data = {
      profile_id: profile_id,
      program_id: program_id,
      security_key: securityKey,
    };
    const { result } = await putRequest(PAYMENT_FAILED, params, data, headers);
    if (result?.message === 'Payment Failed') {
      setShowLoader(false);
      setTimeout(() => {
        setDisplayPaymentFailed(false);
      }, 3000);
      setFailedResponse(result?.message);
    }
    if (result?.error || !result?.success) {
      setShowLoader(false);
      ToastManager.error(result?.error || result?.message);
    }
  };

  useEffect(() => {
    handlePaymentFailed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="silo_page-container py-5 d-flex align-items-center">
      <div className="silo_page_section-heading text-center pt-5 pb-2 m-auto overflow-hidden">
        {showLoader ? (
          <Spinner
            animation="border"
            variant="danger"
            size="lg"
            className="mx-2 spinner-border-xl"
            style={{ width: '11rem', height: '11rem' }}
          />
        ) : (
          <>
            <div>
              <img
                className="mb-4 payment-success-img"
                width="220"
                src={logo}
                alt="logo"
                onError={(e) => {
                  // If AVIF fails to load, fallback to PNG
                  e.target.src = logoPng;
                }}
              />
            </div>
            <h3>{failedResponse} !!!</h3>
            <p className="">
              Your transaction has failed due to some technical error.
              Redirecting you to Payments..Please try again.{' '}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentFailed;
