import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { getRequest } from '../../../../../../api/api';
import { GET_STATE_LIST } from '../../../../../../api/endpoint';

const VehicleLicenseState = ({
  licenseStateError,
  setLicenseStateError,
  vehicleIndex,
}) => {
  const { vehicles, setVehicles } = useContext(Context);
  const [stateOptions, setStateOptions] = useState([]);

  const handleStateChange = (selectedOption) => {
    const updatedVehicle = { ...vehicles[vehicleIndex] };
    // Update the vehicle's body_type with the selected option
    updatedVehicle.license_plate_state = selectedOption;
    // Create a copy of the current vehicles array
    const updatedVehicles = [...vehicles];
    // Update the vehicle at the specified index with the modified vehicle
    updatedVehicles[vehicleIndex] = updatedVehicle;
    // Set the updated vehicles array
    setVehicles(updatedVehicles);
    if (licenseStateError) {
      setLicenseStateError(null);
    }
  };

  const billingState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST);
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        ...item,
        label: item.name,
      }));
      setStateOptions(mappedOptions);
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    billingState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <Form.Label className="fw-bold">
          License State<span className="text-danger">&nbsp;*</span>
        </Form.Label>
        <Select
          className={`custom-select w-100 ${licenseStateError ? 'is-invalid' : ''
            }`}
          name="licenseStateNumber"
          options={stateOptions}
          isClearable
          placeholder={
            <div className="select-placeholder-text text-start">
              Select State
            </div>
          }
          value={
            vehicles[vehicleIndex]?.license_plate_state?.name
              ? {
                value: vehicles[vehicleIndex]?.license_plate_state?.name,
                label: vehicles[vehicleIndex]?.license_plate_state?.name,
              }
              : null
          }
          onChange={handleStateChange}
        />
        <div className="text-danger">{licenseStateError}</div>
      </div>
    </>
  );
};

export default VehicleLicenseState;
