import PersonSvg from '../../../assets/images/Form/Person.svg';

import EmailSvg from '../../../assets/images/Login/email.svg';

import Descsvg from '../../../assets/images/Form/Description.svg';

import telephoneSvg from '../../../assets/images/Login/telephone.svg';

export const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

export const driverData = [
  {
    label: 'First Name',
    id: 'firstName',
    name: 'first_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your First Name',
  },
  {
    label: 'Middle Name',
    id: 'middleName',
    name: 'middle_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Middle Name',
  },
  {
    label: 'Last Name',
    id: 'lastName',
    name: 'last_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Last Name',
  },
  {
    label: 'Suffix',
    id: 'driverNameSuffix',
    name: 'driver_suffix_name',
    value: '',
    type: 'text',
  },
  {
    label: 'Email Address',
    id: 'email',
    name: 'email',
    value: '',
    type: 'email',
    placeholder: 'example@example.com',
  },
  {
    label: 'Date of Birth',
    id: 'DOBuser',
    name: 'date_of_birth',
    value: '',
    type: 'date',
    max: `${eighteenYearsAgo.getFullYear()}-${String(
      eighteenYearsAgo.getMonth() + 1
    ).padStart(2, '0')}-${String(eighteenYearsAgo.getDate()).padStart(2, '0')}`,
  },
  {
    label: 'Original CDL License Issue Date',
    id: 'date_of_hire',
    name: 'date_of_hire',
    value: '',
    type: 'date',
  },
  {
    label: 'Driver License Number',
    id: 'driverLicenseNumber',
    name: 'license_no',
    value: '',
    type: 'number',
    placeholder: 'Enter Your Driving License Number',
  },
  {
    label: 'Drivers License State',
    id: 'licenseState',
    name: 'license_state_id',
    value: '',
    type: 'text',
  },
  {
    label: 'Vehicle',
    id: 'vehicle',
    name: 'vehicle',
    value: '',
    type: 'text',
  },
  {
    label: 'Street Address',
    id: 'address_line_1',
    name: 'address_line_1',
    value: '',
    type: 'text',
    placeholder: 'Enter Street Address',
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    value: '',
    type: 'text',
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    value: '',
    type: 'text',
    placeholder: 'Select City',
  },
  {
    label: 'Zip',
    id: 'zip5',
    name: 'zip5',
    value: '',
    type: 'text',
    placeholder: 'Enter Zip',
  },
  {
    label: 'Is Primary Driver',
    id: 'is_primary_driver',
    type: 'checkbox',
    name: 'is_primary_driver',
    value: 'is_primary_driver',
  },
];

export const beneficiaryDriverData = [
  {
    label: 'First Name',
    id: 'beneficiary_first_name',
    name: 'beneficiary_first_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your First Name',
  },
  {
    label: 'Middle Name',
    id: 'beneficiary_middle_name',
    name: 'beneficiary_middle_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Middle Name',
  },
  {
    label: 'Last Name',
    id: 'beneficiary_last_name',
    name: 'beneficiary_last_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Last Name',
  },
  {
    label: 'Suffix',
    id: 'beneficiary_suffix_name',
    name: 'beneficiary_suffix_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Name Suffix',
  },
  {
    label: 'Street Address',
    id: 'ben_address_line_1',
    name: 'ben_address_line_1',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Street Address',
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    value: '',
    type: 'text',
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    value: '',
    type: 'text',
  },
  {
    label: 'Zip',
    id: 'ben_zip5',
    name: 'ben_zip5',
    value: '',
    type: 'text',
    placeholder: 'Enter Zip',
  },
];

export const membershipData = [
  {
    label: 'First Name',
    id: 'first_name',
    name: 'first_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your First Name',
  },
  {
    label: 'Last Name',
    id: 'last_name',
    name: 'last_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Last Name',
  },
  {
    label: 'Date of Birth',
    id: 'date_of_birth',
    name: 'date_of_birth',
    value: '',
    type: 'date',
    max: `${eighteenYearsAgo.getFullYear()}-${String(
      eighteenYearsAgo.getMonth() + 1
    ).padStart(2, '0')}-${String(eighteenYearsAgo.getDate()).padStart(2, '0')}`,
  },
  {
    label: 'DBA Name(If Any)',
    id: 'dba_name',
    name: 'dba_name',
    value: '',
    type: 'text',
    placeholder: 'Enter Your DBA Name',
  },
  {
    label: 'Phone Number',
    id: 'mobile',
    name: 'mobile',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Phone Number',
  },
  {
    label: 'Sex',
    id: 'gender',
    name: 'gender',
    value: '',
    type: 'text',
  },
  {
    label: 'Marital Status',
    id: 'marital_status',
    name: 'marital_status',
    value: '',
    type: 'text',
  },
  {
    label: 'Profile Picture',
    id: 'profile_picture',
    name: 'profile_picture',
    type: 'file',
  },
];

export const userloginData = [
  {
    label: 'Email Address',
    id: 'email',
    name: 'email',
    value: '',
    type: 'email',
    placeholder: 'example@example.com',
  },
  {
    label: 'Password',
    id: 'password',
    name: 'password',
    value: '',
    type: 'password',
    placeholder: 'Enter Password',
  },
  {
    label: 'Confirm Password',
    id: 'confirm_password',
    name: 'confirm_password',
    value: '',
    type: 'password',
    placeholder: 'Confirm Password',
  },
];

export const homeAddressData = [
  {
    label: 'Address',
    id: 'address_line_1',
    name: 'address_line_1',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Address',
  },
  {
    label: 'Zip',
    id: 'zip5',
    name: 'zip5',
    value: '',
    type: 'text',
    placeholder: 'Enter Zip',
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    value: '',
    type: 'text',
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    value: '',
    type: 'text',
  },
];

export const billingAddressData = [
  {
    label: 'Address',
    id: 'address_line_2',
    name: 'address_line_1',
    value: '',
    type: 'text',
    placeholder: 'Enter Your Address',
  },
  {
    label: 'Zip',
    id: 'zip5',
    name: 'zip5',
    value: '',
    type: 'text',
    placeholder: 'Enter Zip',
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    value: '',
    type: 'text',
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    value: '',
    type: 'text',
  },
];

export const sexTypeoptions = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];

export const maritalTypeoptions = [
  {
    value: 'Married',
    label: 'Married',
  },
  {
    value: 'Unmarried',
    label: 'Unmarried',
  },
  {
    value: 'Divorced',
    label: 'Divorced',
  },
];

export const contactFormData = [
  {
    id: 'name',

    name: 'name',

    value: '',

    type: 'text',

    placeholder: 'Your Name',

    icon: PersonSvg,
  },

  {
    id: 'email',

    name: 'email',

    value: '',

    type: 'email',

    placeholder: 'Your E-mail Address',

    icon: EmailSvg,
  },

  {
    id: 'phone',

    name: 'phone',

    value: '',

    type: 'text',

    placeholder: 'Your Phone Number',

    icon: telephoneSvg,
  },

  {
    id: 'description',

    name: 'description',

    value: '',

    type: 'text',

    placeholder: 'Provide a Detailed Description',

    icon: Descsvg,
  },
];

export const isValidEmail = (email) => {
  // Regular expression for a valid email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const calculateAge = (date_of_birth) => {
  const today = new Date();
  const birthDate = new Date(date_of_birth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const lienHolderData = [
  {
    label: 'Name',
    id: 'name',
    name: 'name',
    value: '',
    type: 'text',
    placeholder: 'Enter Name',
  },
  {
    label: 'Phone Number',
    id: 'mobile',
    name: 'mobile',
    value: '',
    type: 'text',
    placeholder: 'Enter Phone',
  },
  {
    label: 'Address 1',
    id: 'address_line_1',
    name: 'address_line_1',
    value: '',
    type: 'text',
    placeholder: 'Enter Address 1',
  },
  {
    label: 'Address 2',
    id: 'address_line_2',
    name: 'address_line_2',
    value: '',
    type: 'text',
    placeholder: 'Enter Address 2',
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    value: '',
    type: 'text',
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    value: '',
    type: 'text',
  },
  {
    label: 'Zip',
    id: 'zip',
    name: 'zip',
    value: '',
    type: 'text',
    placeholder: 'Enter Zip',
  },
  {
    label: 'Parent Lienholder',
    id: 'parent_lienholder',
    name: 'parent_lienholder',
    value: '',
    type: 'text',
    placeholder: 'Select Parent Lienholder',
  },
];
