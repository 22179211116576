import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastManager = {
  success: (message) => {
    toast.success(message, {
      hideProgressBar: true,
      position: 'bottom-left',
      theme: 'colored',
      autoClose: 3000,
    });
  },
  successDefault: (message) => {
    toast.success(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  },
  error: (message) => {
    toast.error(message, {
      hideProgressBar: true,
      position: 'bottom-left',
      theme: 'colored',
      autoClose: 3000,
    });
  },
  info: (message) => {
    toast.info(message, {
      hideProgressBar: true,
      position: 'bottom-left',
      theme: 'colored',
      autoClose: 3000,
    });
  },
  infoCustomDuration: (message, customDuration) => {
    toast.info(message, {
      hideProgressBar: true,
      position: 'bottom-left',
      theme: 'colored',
      autoClose: customDuration, // Use the custom duration here
    });
  },
  warning: (message) => {
    toast.warning(message);
  },
};
export { ToastManager, ToastContainer };
