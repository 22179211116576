import React, { useState } from 'react';
import login from '../../../assets/images/Login/login.svg';
import './resetPassword.scss';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';
import shape from '../../../assets/images/Login/Shape.png';
import passwordSvg from '../../../assets/images/Login/password.svg';
import showPassSvg from '../../../assets/images/Login/show_password.svg';
import hidePassSvg from '../../../assets/images/Login/hide_password.svg';
import { putRequest } from '../../../api/api';
import { RESET_PASSWORD } from '../../../api/endpoint';
import { ToastManager } from '../../../ToastContainer/ToastContainer';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [icon, Seticon] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [Confirmicon, Setconfirmicon] = useState(true);
  const navigate = useNavigate();

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    // Password validation logic
    if (newPasswordValue.trim() === '') {
      setNewPasswordError('Please enter a new password.');
    } else if (newPasswordValue.length < 6) {
      setNewPasswordError('Password must be 6 or more characters long.');
    } else {
      setNewPasswordError('');
    }
  };
  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setConfirmPassword(passwordValue);
    // Password validation logic
    if (passwordValue.trim() === '') {
      setPasswordError('Please enter your password.');
    } else {
      setPasswordError('');
    }
    // Check if passwords match
    if (newPassword !== passwordValue) {
      setPasswordMatchError('Passwords do not match.');
    } else {
      setPasswordMatchError('');
    }
  };

  const isFormValid = () => {
    return (
      newPassword.trim() !== '' &&
      confirmPassword.trim() !== '' &&
      newPasswordError === '' &&
      passwordError === '' &&
      passwordMatchError === ''
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid()) {
      try {
        let tokenValue = new URLSearchParams(window.location.search).get(
          'token'
        );
        const userData = {
          user: {
            password: newPassword,
            password_confirmation: confirmPassword,
          },
        };
        const params = {
          token: tokenValue,
        };
        const { result } = await putRequest(RESET_PASSWORD, params, userData);
        if (result.message) {
          ToastManager.success(result?.message);
          navigate('/login');
        } else {
          ToastManager.error(result?.error);
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <div className="">
        <div className="silo_page-container py-5 d-flex align-items-center">
          <div className="container position-relative">
            <img src={shape} className="dotted-lines" alt="dotted-lines" />
            <div className="row">
              <div className="col-sm py-2">
                <img
                  className="img-fluid login-form-img"
                  src={login}
                  alt="login_image"
                />
              </div>

              <div className="col-sm">
                <div className="contact-form-box">
                  <div>
                    <img
                      className="mb-4"
                      width="220"
                      src={logo}
                      alt="logo"
                      onError={(e) => {
                        // If AVIF fails to load, fallback to PNG
                        e.target.src = logoPng;
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <span>Please enter your new password.</span>
                    <form id="account" method="post" className="mt-4">
                      <div className="form-group">
                        <div className="icon">
                          <img src={passwordSvg} alt="password" />
                        </div>
                        <input
                          type={icon ? 'password' : 'text'}
                          className={`form-control mb-0 ${
                            newPasswordError ? 'is-invalid' : ''
                          }`}
                          placeholder="Enter new password"
                          onChange={handleNewPasswordChange}
                          value={newPassword}
                        />
                        {newPasswordError && (
                          <div className="invalid-feedback">
                            {newPasswordError}
                          </div>
                        )}
                        <div className="icon" onClick={() => Seticon(!icon)}>
                          <img
                            src={icon ? showPassSvg : hidePassSvg}
                            alt="showPassword"
                          />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <div className="icon">
                          <img src={passwordSvg} alt="password" />
                        </div>
                        <input
                          type={Confirmicon ? 'password' : 'text'}
                          className={`form-control mb-0 ${
                            passwordError || passwordMatchError
                              ? 'is-invalid'
                              : ''
                          }`}
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={handlePasswordChange}
                        />
                        {(passwordError || passwordMatchError) && (
                          <div className="invalid-feedback">
                            {passwordError || passwordMatchError}
                          </div>
                        )}
                        <div
                          className="icon"
                          onClick={() => Setconfirmicon(!Confirmicon)}
                        >
                          <img
                            src={Confirmicon ? showPassSvg : hidePassSvg}
                            alt="showPassword"
                          />
                        </div>
                      </div>
                      <button
                        className="mt-4 btn silo-btn silo-btn__blue"
                        disabled={!isFormValid()}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
