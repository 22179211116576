import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ToastContainer } from './ToastContainer/ToastContainer';
import * as Sentry from '@sentry/react';

const isLocalhost =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1';
const isProduction = process.env.NODE_ENV === 'production';

if (!isLocalhost && isProduction) {
  Sentry.init({
    dsn: 'https://749522a74bbcfd44baf626c61df3b631@o4506387628163072.ingest.sentry.io/4506387629932544',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/preprod\.silomembers\.com\/api/,
          /^https:\/\/staging\.silomembers\.com\/api/,
          /^https:\/\/www\.silomembers\.com\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
    <ToastContainer />
  </BrowserRouter>
);
