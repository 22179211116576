import React, { useContext, useState, useEffect } from 'react';
import { getRequest } from '../../../../../../api/api';
import {
  GET_CITY_LIST,
  GET_STATE_LIST,
  GET_SUFFIX_LIST,
} from '../../../../../../api/endpoint';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Context } from '../../../../../../utils/Context';
import ModalContainer from '../../../../Shared/ModalContainer/ModalContainer';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { AiFillEdit } from 'react-icons/ai';
import './MultiDriver.scss';
import { parseISO } from 'date-fns';
import {
  CommonCityApi,
  formatDate,
  formatZipCode,
} from '../../../../../../utils/Commonutils';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
  beneficiaryDriverData,
  calculateAge,
  driverData,
  eighteenYearsAgo,
  isValidEmail,
} from '../../../../../shared/Constants/Constants';
import { ToastManager } from '../../../../../../ToastContainer/ToastContainer';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../../../Shared/ModalContainer/DeleteModal';

const MultiDriver = ({
  saveProfile,
  isMemberArea,
  handleModalShow,
  modalOpen,
  setModalOpen,
  openSecondAccordion,
  currentAccordion,
}) => {
  const { profileObject, modalValue, setModalValue, directCheckout } =
    useContext(Context);
  const [driverselectLicenseStateError, setDriverSelectLicenseStateError] =
    useState('');
  const [driverSuffixNameError, setDriverSuffixNameError] = useState('');
  const [driverSelectStateError, setDriverSelectStateError] = useState('');
  const [beneficiaryStateError, setBeneficiaryStateError] = useState('');
  const [selectDriverCityError, setSelectDriverCityError] = useState('');
  const [experience, setExperience] = useState(null);
  const [stateOption, setStateOption] = useState([]);
  const [suffixOption, setSuffixOption] = useState([]);
  const [driverStateOption, setDriverStateOption] = useState([]);
  const [vehicleOption, setVehicleOption] = useState([]);
  const [vehicleError, setVehicleError] = useState('');
  const [driverEditId, setDriverEditId] = useState(null);
  const [beneficiarStateOption, setBeneficiaryStateOption] = useState([]);
  const [beneficiarySuffixOption, setBeneficiarySuffixOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [beneficiaryCityOption, setBeneficiaryCityOption] = useState([]);
  const [beneficiaryStateId, setBeneficiaryStateId] = useState();
  const [stateId, SetstateId] = useState();
  const [beneficiaryPage, SetbeneficiaryPage] = useState(1);
  const [page, Setpage] = useState(1);
  const [DOBError, setDOBError] = useState('');
  const [DOHError, setDOHError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [benediciaryValidationChange, setBenediciaryValidationChange] =
    useState(false);
  const [isDriverListOpen, setIsDriverListOpen] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [driverIdToDelete, setDriverIdToDelete] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (id) => {
    setDriverIdToDelete(id);

    setShow(true);
  };

  const [driverfieldErrors, setDriverFieldErrors] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    email: '',
    license_no: '',
    date_of_hire: '',
    license_state_id: '',
    vehicle: '',
    is_primary_driver: '',
    address_line_1: '',
    state_id: '',
    city_id: '',
    zip5: '',
  });
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateHire, setSelectedDateHire] = useState();
  const [isPrimaryDisabled, setIsPrimaryDisabled] = useState(
    profileObject?.drivers?.length > 0 ? true : false
  );
  const [driverModalFormData, setDriverModalFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    driver_suffix_name: '',
    date_of_birth: '',
    email: '',
    license_no: '',
    date_of_hire: '',
    license_state_id: '',
    vehicle: '',
    is_primary_driver: profileObject?.drivers?.length > 0 ? false : true,
    address_line_1: '',
    state: {},
    city: {},
    zip5: '',
  });
  const [beneficiaryDriverformData, setBeneficiaryDriverformData] = useState({
    beneficiary_first_name: '',
    beneficiary_middle_name: '',
    beneficiary_last_name: '',
    ben_address_line_1: '',
    city: {},
    state: {},
    ben_zip5: '',
  });
  const [beneficiaryDriverError, setBeneficiaryDriverError] = useState({
    beneficiary_first_name: '',
    beneficiary_middle_name: '',
    beneficiary_last_name: '',
    beneficiary_suffix_name: '',
    ben_address_line_1: '',
    state: '',
    city: '',
    ben_zip5: '',
  });
  const tooltip = (
    <Tooltip id="tooltip">
      Only one driver can be set as the primary for a vehicle. To designate a
      new primary driver, uncheck the 'Primary' checkbox from the current driver
      associated with that vehicle.
    </Tooltip>
  );

  const handleModalClose = () => {
    setModalOpen(false);
    setDriverModalFormData({
      ...driverModalFormData,
      first_name: '',
      middle_name: '',
      last_name: '',
      driver_suffix_name: '',
      date_of_birth: '',
      email: '',
      license_no: '',
      date_of_hire: '',
      license_state_id: '',
      is_primary_driver: profileObject?.drivers?.length > 0 ? false : true,
      address_line_1: '',
      state: {},
      city: {},
      zip5: '',
    });
    if (vehicleOption?.length !== 1) {
      setDriverModalFormData({
        ...driverModalFormData,
        vehicle: '',
      });
    }
    setBeneficiaryDriverformData({
      beneficiary_first_name: '',
      beneficiary_middle_name: '',
      beneficiary_last_name: '',
      beneficiary_suffix_name: '',
      ben_address_line_1: '',
      state: {},
      city: {},
      ben_zip5: '',
    });
    setDriverFieldErrors({
      first_name: '',
      last_name: '',
      date_of_birth: '',
      email: '',
      license_no: '',
      date_of_hire: '',
      license_state_id: '',
      vehicle: '',
      is_primary_driver: '',
      address_line_1: '',
      state_id: '',
      city_id: '',
      zip5: '',
    });
    setBeneficiaryDriverError({
      beneficiary_first_name: '',
      beneficiary_middle_name: '',
      beneficiary_last_name: '',
      ben_address_line_1: '',
      state: '',
      city: '',
      ben_zip5: '',
    });
    setExperience(null);
    setDriverEditId(null);
    setDriverSelectLicenseStateError('');
    setDriverSuffixNameError('');
    setDriverSelectStateError('');
    setBeneficiaryStateError('');
    setSelectedDate();
    setSelectedDateHire();
    setBenediciaryValidationChange(false);
    setModalValue('');
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth < 991) {
      setIsDriverListOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);

  const driverCity = async (state_id) => {
    try {
      const driver_state_id = {
        state_id: state_id,
      };
      if (state_id === undefined) {
        return false;
      }
      const response = await getRequest(GET_CITY_LIST, driver_state_id);
      const cityData = response.result.data;
      const cityMapped = cityData.map((item) => ({
        value: item.attributes.name,
        label: item.attributes.name,
        id: item.attributes.id,
      }));
      setCityOption(cityMapped);
      setBeneficiaryCityOption(cityMapped);
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleDateHireChange = (newdate) => {
    let DOH = formatDate(newdate);

    setSelectedDateHire(newdate);
    setDriverModalFormData({
      ...driverModalFormData,
      date_of_hire: DOH,
    });
    if (!newdate) {
      setDOHError('Plese select a date');
      setExperience(null);
    } else {
      setDOHError();
      yearsOfExperience(newdate);
    }
  };

  const handleDateChange = (newdate) => {
    let DOB = formatDate(newdate);
    setSelectedDate(newdate);
    setDriverModalFormData({
      ...driverModalFormData,
      date_of_birth: DOB,
    });

    if (!newdate) {
      setDOBError('Please select a date');
    } else {
      setDOBError('');
    }
  };

  const yearsOfExperience = (date_of_hire) => {
    const today = new Date();
    const issuedDate = new Date(date_of_hire);
    const yearDifference = today.getFullYear() - issuedDate.getFullYear();
    setExperience(yearDifference.toString());
  };

  const handleDeleteDriver = () => {
    let deleteData = {
      id: driverIdToDelete,
      _destroy: true,
    };
    let driverData = [deleteData];
    const data = {
      drivers_attributes: driverData,
      vehicle_details_attributes: [{}],
    };
    saveProfile(data);
    setShow(false);
  };

  const licenseFetchState = async () => {
    try {
      const response = await getRequest(GET_STATE_LIST);
      const stateData = response.result;
      const mappedOptions = stateData.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setStateOption(mappedOptions);
      setDriverStateOption(mappedOptions);
      setBeneficiaryStateOption(mappedOptions);
    } catch (error) {
      console.log('err', error);
    }
  };

  const getSuffixList = async () => {
    try {
      const response = await getRequest(GET_SUFFIX_LIST);
      const suffixData = response.result.suffix_names;
      const mappedOptions = suffixData.map((item) => ({
        value: item,
        label: item,
      }));
      setSuffixOption(mappedOptions);
      setBeneficiarySuffixOption(mappedOptions);
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleLicenseStateChange = (selectedOption) => {
    setDriverModalFormData({
      ...driverModalFormData,
      license_state_id: selectedOption.id,
    });
    if (!selectedOption) {
      setDriverSelectLicenseStateError('Please select an option');
    } else {
      setDriverSelectLicenseStateError('');
    }
  };

  const handleSuffixChange = (selectedOption) => {
    setDriverModalFormData({
      ...driverModalFormData,
      driver_suffix_name: selectedOption?.value,
    });
  };

  const handleVehicleChange = (selectedOption) => {
    let drivers = profileObject.drivers.filter(
      (item) =>
        item.vehicle_detail.id === selectedOption.id && item.is_primary_driver
    );
    let is_primary;
    if (drivers?.length > 0) {
      is_primary = false;
    } else {
      is_primary = true;
    }
    setIsPrimaryDisabled(!is_primary);
    setDriverModalFormData({
      ...driverModalFormData,
      vehicle: selectedOption.id,
      is_primary_driver: is_primary,
    });
    if (!selectedOption) {
      setVehicleError('Please select an option');
    } else {
      setVehicleError('');
    }
  };

  const handleDriverSelectStateChange = (selectedOption) => {
    SetstateId(selectedOption.id);
    Setpage(1);

    setDriverModalFormData({
      ...driverModalFormData,
      state: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
      city: {},
    });
    if (!selectedOption) {
      setDriverSelectStateError('Please select an option');
    } else {
      setDriverSelectStateError('');
    }
  };

  const handleDriverCityChange = (selectedOption) => {
    setDriverModalFormData({
      ...driverModalFormData,
      city: {
        name: selectedOption?.value,
        id: selectedOption?.id,
      },
    });
    if (!selectedOption) {
      setSelectDriverCityError('Please select an option');
    } else {
      setSelectDriverCityError('');
    }
  };

  const handleDriverChange = (e) => {
    const { name, value } = e.target;
    setDriverModalFormData({
      ...driverModalFormData,
      [name]: value,
    });
    if (name === 'zip5') {
      const formattedZip = formatZipCode(value);
      setDriverModalFormData({
        ...driverModalFormData,
        [name]: formattedZip,
      });
    }
    setDriverFieldErrors({
      ...driverfieldErrors,
      [name]: '',
    });
    if (name === 'date_of_hire') {
      yearsOfExperience(value);
    }
  };

  const handleBeneficiaryChange = (e, fieldName) => {
    if (
      e?.target?.name === 'beneficiary_first_name' ||
      e?.target?.name === 'beneficiary_middle_name' ||
      e?.target?.name === 'beneficiary_last_name' ||
      e?.target?.name === 'ben_address_line_1' ||
      e?.target?.name === 'ben_zip5'
    ) {
      const { name, value } = e.target;
      setBeneficiaryDriverError({
        ...beneficiaryDriverError,
        [name]: '',
      });
      setBenediciaryValidationChange(true);

      setBeneficiaryDriverformData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [name]: value,
        };

        const allFieldsNull = beneficiaryDriverData.every((field) => {
          const fieldValue = updatedFormData[field.name];
          if (field.name === 'city' || field.name === 'state') {
            // Check if the object has name and id undefined
            if (
              fieldValue &&
              fieldValue.name === undefined &&
              fieldValue.id === undefined
            ) {
              setBeneficiaryDriverError('');
              return true;
            }
          }
          if (name === 'ben_zip5') {
            const formattedZip = formatZipCode(value);
            setBeneficiaryDriverformData({
              ...beneficiaryDriverformData,
              [name]: formattedZip,
            });
          }
          // Check if the field value is null or an empty string
          return fieldValue === null || fieldValue === '';
        });
        setBenediciaryValidationChange(!allFieldsNull);

        return updatedFormData;
      });
    } else {
      const selectedOption = e;

      if (fieldName === 'state') {
        setBeneficiaryStateId(selectedOption?.id);
        SetbeneficiaryPage(1);

        setBeneficiaryDriverformData((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            state: {
              name: selectedOption?.value,
              id: selectedOption?.id,
            },
            city: {},
          };
          const allFieldsNull = beneficiaryDriverData.every((field) => {
            const fieldValue = updatedFormData[field.name];
            if (field.name === 'city' || field.name === 'state') {
              // Check if the object has name and id undefined
              if (
                fieldValue &&
                fieldValue.name === undefined &&
                fieldValue.id === undefined
              ) {
                setBeneficiaryDriverError('');
                return true;
              }
            }
            // Check if the field value is null or an empty string
            return fieldValue === null || fieldValue === '';
          });
          setBenediciaryValidationChange(!allFieldsNull);
          setBeneficiaryDriverError({
            ...beneficiaryDriverError,
            state: '',
          });
          setBenediciaryValidationChange(true);
          setBeneficiaryStateError('');

          return updatedFormData;
        });
      } else if (fieldName === 'city') {
        setBeneficiaryDriverformData((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            city: {
              name: selectedOption?.value,
              id: selectedOption?.id,
            },
          };
          const allFieldsNull = beneficiaryDriverData.every((field) => {
            const fieldValue = updatedFormData[field.name];
            if (field.name === 'city' || field.name === 'state') {
              // Check if the object has name and id undefined
              if (
                fieldValue &&
                fieldValue.name === undefined &&
                fieldValue.id === undefined
              ) {
                setBeneficiaryDriverError('');
                return true;
              }
            }
            // Check if the field value is null or an empty string
            return fieldValue === null || fieldValue === '';
          });
          setBenediciaryValidationChange(!allFieldsNull);
          setBeneficiaryDriverError({
            ...beneficiaryDriverError,
            city: '',
          });
          setBenediciaryValidationChange(true);

          return updatedFormData;
        });
      } else if (fieldName === 'beneficiary_suffix_name') {
        setBeneficiaryDriverformData((prevFormData) => {
          setBeneficiaryDriverError({
            ...beneficiaryDriverError,
            beneficiary_suffix_name: '',
          });
          const updatedFormData = { ...prevFormData };
          updatedFormData[fieldName] = e?.value;
          setBenediciaryValidationChange(true);
          setDriverSuffixNameError('');
          return updatedFormData;
        });
      }
    }
  };

  const isValidDate = (date) => {
    const age = calculateAge(date);
    return age >= 18;
  };

  let hasErrors = false;
  const newFieldErrors = {};
  const newBeneficiaryFieldErrors = {};

  useEffect(() => {
    if (modalValue === 'add') {
      setDriverModalFormData({
        ...driverModalFormData,
        first_name: '',
        middle_name: '',
        last_name: '',
        driver_suffix_name: '',
        date_of_birth: '',
        email: '',
        license_no: '',
        date_of_hire: '',
        license_state_id: '',
        is_primary_driver: profileObject?.drivers?.length > 0 ? false : true,
        address_line_1: '',
        state: {},
        city: {},
        zip5: '',
      });
      setBeneficiaryDriverformData({
        beneficiary_first_name: '',
        beneficiary_middle_name: '',
        beneficiary_last_name: '',
        beneficiary_suffix_name: '',
        ben_address_line_1: '',
        state: {},
        city: {},
        ben_zip5: '',
      });
      setDriverFieldErrors({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        email: '',
        license_no: '',
        date_of_hire: '',
        license_state_id: '',
        vehicle: '',
        is_primary_driver: '',
        address_line_1: '',
        state_id: '',
        city_id: '',
        zip5: '',
      });
      setBeneficiaryDriverError({
        beneficiary_first_name: '',
        beneficiary_middle_name: '',
        beneficiary_last_name: '',
        ben_address_line_1: '',
        state_id: '',
        city_id: '',
        ben_zip5: '',
      });
      setExperience(null);
      setDriverEditId(null);
      setDriverSelectLicenseStateError('');
      setDriverSuffixNameError('');
      setDriverSelectStateError('');
      setBeneficiaryStateError('');
      setDOBError('');
      setDOHError('');
      setVehicleError('');
      setSelectDriverCityError('');
      setSelectedDate();
      setSelectedDateHire();
      getSuffixList();
      licenseFetchState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalValue]);

  useEffect(() => {
    const vehicle = profileObject?.vehicle_details;
    const mappedOptions =
      vehicle &&
      vehicle.map((item, index) => {
        const make = item.vehicle_make?.name || '';
        const model = item.vehicle_model?.name || '';
        const year = item.declared_year || '';

        return {
          value: `${make}-${model}(${year})`,
          label: `${make}-${model}(${year})`,
          id: item.id,
        };
      });
    setDriverModalFormData({
      ...driverModalFormData,
      city: {},
    });
    setVehicleOption(mappedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject]);

  useEffect(() => {
    // Check if there's only one vehicle option
    if (vehicleOption?.length === 1) {
      // If there's only one option, auto-select it
      const selectedOption = vehicleOption[0];

      // Handle the change and clear any previous errors
      handleVehicleChange(selectedOption);
      setVehicleError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleOption]);

  const handleCheckboxChange = (e, fieldName) => {
    setDriverModalFormData({
      ...driverModalFormData,
      [fieldName]: e.target.checked,
    });
  };

  const handleDriverFormSave = async () => {
    setButtonLoading(true);
    const isPrimaryDriver = driverModalFormData?.is_primary_driver;
    const newDriver = {
      id: driverEditId,
      vehicle_detail_id: driverModalFormData.vehicle,
      first_name: driverModalFormData?.first_name,
      middle_name: driverModalFormData?.middle_name,
      driver_suffix_name: driverModalFormData?.driver_suffix_name,
      last_name: driverModalFormData?.last_name,
      email: driverModalFormData?.email,
      date_of_birth: driverModalFormData?.date_of_birth,
      date_of_hire: driverModalFormData?.date_of_hire,
      license_no: driverModalFormData?.license_no,
      license_state_id: driverModalFormData?.license_state_id,
      is_primary_driver: isPrimaryDriver,
      beneficiary_first_name: beneficiaryDriverformData.beneficiary_first_name,
      beneficiary_middle_name:
        beneficiaryDriverformData.beneficiary_middle_name,
      beneficiary_last_name: beneficiaryDriverformData.beneficiary_last_name,
      beneficiary_suffix_name:
        beneficiaryDriverformData.beneficiary_suffix_name,
      driver_address_attributes: {
        address_line_1: driverModalFormData?.address_line_1,
        state_id: driverModalFormData.state?.id,
        city_id: driverModalFormData.city?.id,
        zip5: driverModalFormData?.zip5,
      },
      beneficiary_address_attributes: {
        address_line_1: beneficiaryDriverformData.ben_address_line_1,
        state_id: beneficiaryDriverformData.state.id
          ? beneficiaryDriverformData.state?.id
          : '',
        city_id: beneficiaryDriverformData.city.id
          ? beneficiaryDriverformData.city?.id
          : '',
        zip5: beneficiaryDriverformData.ben_zip5,
      },
    };

    const updatedDrivers = [newDriver];
    driverData.forEach((field) => {
      if (!driverModalFormData[field.name]) {
        if (
          field.name !== 'license_state_id' &&
          field.name !== 'driver_suffix_name' &&
          field.name !== 'state' &&
          field.name !== 'date_of_hire' &&
          field.name !== 'date_of_birth' &&
          field.name !== 'vehicle' &&
          field.name !== 'city' &&
          field.name !== 'is_primary_driver' &&
          field.name !== 'middle_name'
        ) {
          newFieldErrors[field.name] = `${field.label} is required`;
          hasErrors = true;
          setButtonLoading(false);
        } else if (
          field.name === 'dob' &&
          !isValidDate(driverModalFormData[field.name])
        ) {
          newFieldErrors[field.name] = 'Age must be greater than 18 years.';
          hasErrors = true;
          setButtonLoading(false);
        }
      }
    });
    if (!driverModalFormData.vehicle) {
      setVehicleError('Please select an option');
      hasErrors = true;
      setButtonLoading(false);
    }
    if (!driverModalFormData?.date_of_birth) {
      setDOBError('Please select a date');
      hasErrors = true;
      setButtonLoading(false);
    }

    if (!driverModalFormData.date_of_hire) {
      setDOHError('Please select a date');
      hasErrors = true;
      setButtonLoading(false);
    }

    if (!/^[A-Za-z]+$/.test(driverModalFormData?.first_name)) {
      newFieldErrors.first_name = 'First Name should only contain letters.';
      hasErrors = true;
      setButtonLoading(false);
    }

    if (!/^[A-Za-z]*$/.test(driverModalFormData?.middle_name)) {
      newFieldErrors.middle_name = 'Middle Name should only contain letters.';
      hasErrors = true;
      setButtonLoading(false);
    }

    if (!/^[A-Za-z]+$/.test(driverModalFormData.last_name)) {
      newFieldErrors.last_name = 'Last Name should only contain letters.';
      hasErrors = true;
      setButtonLoading(false);
    }
    if (!driverModalFormData.license_state_id) {
      setDriverSelectLicenseStateError('Please select Driver Licesne State');
      hasErrors = true;
      setButtonLoading(false);
    }
    if (!driverModalFormData.state?.id) {
      setDriverSelectStateError('Please select an option');
      hasErrors = true;
      setButtonLoading(false);
    }
    if (!driverModalFormData.city?.id) {
      setSelectDriverCityError('Please select an option');
      hasErrors = true;
      setButtonLoading(false);
    }
    if (driverModalFormData.zip5?.length < 5) {
      newFieldErrors.zip5 = 'Invalid zip code';
      hasErrors = true;
      setButtonLoading(false);
    }

    if (driverModalFormData.email && !isValidEmail(driverModalFormData.email)) {
      // Email is not valid
      newFieldErrors.email = 'Invalid email';
      hasErrors = true;
      setButtonLoading(false);
    }

    if (benediciaryValidationChange) {
      if (
        !/^[A-Za-z\s-]+$/.test(
          beneficiaryDriverformData?.beneficiary_first_name
        )
      ) {
        newBeneficiaryFieldErrors.beneficiary_first_name =
          'First Name should only contain letters.';
        hasErrors = true;
        setBeneficiaryDriverError(newBeneficiaryFieldErrors);
        setButtonLoading(false);
      }
      if (
        !/^[A-Za-z]*$/.test(beneficiaryDriverformData?.beneficiary_middle_name)
      ) {
        newBeneficiaryFieldErrors.beneficiary_middle_name =
          'Middle Name should only contain letters.';
        hasErrors = true;
        setBeneficiaryDriverError(newBeneficiaryFieldErrors);
        setButtonLoading(false);
      }
      if (
        !/^[A-Za-z\s-]+$/.test(beneficiaryDriverformData?.beneficiary_last_name)
      ) {
        newBeneficiaryFieldErrors.beneficiary_last_name =
          'Last Name should only contain letters.';
        hasErrors = true;
        setBeneficiaryDriverError(newBeneficiaryFieldErrors);
        setButtonLoading(false);
      }
      if (beneficiaryDriverformData?.ben_zip5?.length < 5) {
        newBeneficiaryFieldErrors.ben_zip5 = 'Invalid zip code';
        hasErrors = true;
        setBeneficiaryDriverError(newBeneficiaryFieldErrors);
        setButtonLoading(false);
      }

      beneficiaryDriverData.forEach((field) => {
        if (
          field.name !== 'beneficiary_middle_name' &&
          field.name !== 'beneficiary_suffix_name' &&
          (!beneficiaryDriverformData[field.name] ||
            (typeof beneficiaryDriverformData[field.name] === 'string' &&
              beneficiaryDriverformData[field.name]?.trim() === ''))
        ) {
          newBeneficiaryFieldErrors[field.name] = `${field.label} is required`;
          hasErrors = true;
          setButtonLoading(false);
        }
        if (
          (typeof beneficiaryDriverformData[field.name] === 'object' &&
            beneficiaryDriverformData[field.name] !== null &&
            Object.keys(beneficiaryDriverformData[field.name])?.length === 0) ||
          (typeof beneficiaryDriverformData[field.name] === 'object' &&
            beneficiaryDriverformData[field.name]?.name === undefined &&
            beneficiaryDriverformData[field.name]?.id === undefined)
        ) {
          // Check if the object has name and id undefined
          newBeneficiaryFieldErrors[field.name] = `${field.label} is required`;
          hasErrors = true;
          setButtonLoading(false);
        }
      });
    }
    if (hasErrors) {
      setDriverFieldErrors(newFieldErrors);
      setBeneficiaryDriverError(newBeneficiaryFieldErrors);
      setButtonLoading(false);
      ToastManager.error(
        'Form Validation failed. Please check errors before saving.'
      );
      return;
    } else {
      const data = {
        drivers_attributes: updatedDrivers,
        vehicle_details_attributes: [{}],
      };
      const result = await saveProfile(data);

      if (result === true) {
        const hasSubscription = profileObject?.programs[0]?.subscriptions.length > 0;
        handleModalClose();
        setButtonLoading(false);
        if (directCheckout && hasSubscription) {
          window.scrollTo(0, 0);
          navigate('/quote/member-information');
        } else if (currentAccordion === 'Accordion1' && hasSubscription) {
          window.scrollTo(0, 0);
          openSecondAccordion();
        } else if (currentAccordion === 'Accordion2' && hasSubscription) {
          window.scrollTo(0, 0);
          navigate('/quote/member-information');
        }
      }
      setButtonLoading(false);
    }
    setButtonLoading(false);
  };

  const handleEditDriver = (driver) => {
    setDOHError('');
    setDOBError('');
    setSelectDriverCityError('');
    setDriverFieldErrors('');
    licenseFetchState();
    getSuffixList();
    driverCity();
    setModalOpen(true);
    setDriverEditId(driver.id);

    const vehicle = profileObject?.vehicle_details;
    const mappedOptions =
      vehicle &&
      vehicle.map((item, index) => {
        const make = item.vehicle_make?.name || '';
        const model = item.vehicle_model?.name || '';
        const year = item.declared_year || '';

        return {
          value: `${make}-${model}(${year})`,
          label: `${make}-${model}(${year})`,
          id: item.id,
        };
      });
    setVehicleOption(mappedOptions);
    if (driver.is_primary_driver) {
      setIsPrimaryDisabled(false);
    } else {
      const isPrimaryDriver = vehicle.some(
        (item) =>
          item.id === driver.vehicle_detail.id &&
          item.drivers.some((driver) => driver.is_primary_driver)
      );
      setIsPrimaryDisabled(isPrimaryDriver);
    }
    // Update the state with the driver's details
    setDriverModalFormData({
      ...driverModalFormData,
      id: driver.id,
      vehicle: driver.vehicle_detail.id,
      first_name: driver.first_name,
      middle_name: driver.middle_name,
      last_name: driver.last_name,
      driver_suffix_name: driver.driver_suffix_name,
      email: driver.email || '',
      date_of_birth: driver.date_of_birth,
      date_of_hire: driver.date_of_hire,
      is_primary_driver: driver.is_primary_driver,
      license_no: driver.license_no,
      license_state_id: driver.license_state_id,
      address_line_1: driver.driver_address.address_line_1,
      state: {
        name: driver?.driver_address?.state?.name,
        id: driver?.driver_address?.state?.id,
      },

      city: {
        name: driver?.driver_address?.city?.name,
        id: driver?.driver_address?.city?.id,
      },
      zip5: driver.driver_address.zip5,
    });
    setSelectedDate(parseISO(driver?.date_of_birth));

    setSelectedDateHire(parseISO(driver?.date_of_hire));

    // Set the initial state of the beneficiary form data
    setBeneficiaryDriverformData({
      beneficiary_first_name: driver.beneficiary_first_name,
      beneficiary_middle_name: driver.beneficiary_middle_name,
      beneficiary_last_name: driver.beneficiary_last_name,
      beneficiary_suffix_name: driver.beneficiary_suffix_name,
      ben_address_line_1: driver.beneficiary_address.address_line_1,
      state: {
        name: driver?.beneficiary_address?.state?.name,
        id: driver?.beneficiary_address?.state?.id,
      },
      city: driver?.beneficiary_address?.city?.name
        ? {
            name: driver?.beneficiary_address?.city?.name,
            id: driver?.beneficiary_address?.city?.id,
          }
        : {},
      ben_zip5: driver.beneficiary_address.zip5,
    });

    yearsOfExperience(driver.date_of_hire);
  };

  const toggleFirstAccordion = () => {
    setIsDriverListOpen(!isDriverListOpen);
  };

  return (
    <Accordion activeKey={isDriverListOpen ? '0' : ''}>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={toggleFirstAccordion}>
          <div className="m-0">List of Drivers</div>
        </Accordion.Header>
        <Accordion.Body>
          <div className=" mb-3">
            {profileObject?.drivers.length !== 0 ? (
              <>
                <div className="driver_details_card">
                  {profileObject?.drivers.map((driver) => (
                    <div className="driver_details_inner" key={driver.id}>
                      <div>
                        {!isMemberArea && (
                          <div className="cursor-pointer d-flex justify-content-end mb-2 gap-2 bb-1">
                            <AiFillEdit
                              className="cursor-pointer"
                              color="#1263a9"
                              size="20"
                              onClick={() => handleEditDriver(driver)}
                            />
                            <RiDeleteBin6Line
                              className="cursor-pointer"
                              color="red"
                              size="20"
                              onClick={() => handleShow(driver.id)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="">Profile</label>
                          <div className="driver_details_profile">
                            <span className="driver_details_profile_icon">
                              {`${driver.first_name.charAt(
                                0
                              )}${driver.last_name.charAt(0)}`}
                            </span>
                            <span>
                              {driver.first_name} {driver.last_name}
                            </span>
                          </div>
                        </div>

                        <div className="col-6">
                          <label htmlFor="">License Number</label>
                          <div>{driver.license_no}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              'No Driver Found'
            )}

            {!isMemberArea && (
              <Button
                className="d-block m-auto btn w-auto silo-btn-small silo-btn__blue my-3"
                onClick={() => {
                  handleModalShow();
                  setModalValue('add');
                }}
              >
                Add Driver
              </Button>
            )}

            <ModalContainer
              size="xl"
              show={modalOpen}
              handleClose={handleModalClose}
              title="Add Driver Details"
              content={
                <div className="user-detail-form-blue mx-0 mx-md-4">
                  <div className="row">
                    <h4>Personal Details: </h4>
                    {driverData.map((field) => (
                      <div
                        className={`col-md-${
                          field.name === 'licenseState' ? '12' : '6'
                        }`}
                        key={field.id}
                      >
                        <Form.Group
                          controlId={field.id}
                          className="d-flex flex-column mb-10-sm"
                        >
                          <Form.Label className="fw-bold mt-2">
                            <span>{field.label}</span>
                            <span>
                              {field.name === 'date_of_hire' &&
                                experience !== null && (
                                  <span className="text-success float-end">
                                    {experience} years of experience
                                  </span>
                                )}
                              <span className="text-danger">
                                {field.name !== 'is_primary_driver' &&
                                field.name !== 'middle_name' && field.name !== 'driver_suffix_name' ? (
                                  <>&nbsp;*</>
                                ) : null}
                              </span>
                              {field.name === 'is_primary_driver' &&
                                isPrimaryDisabled && (
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={tooltip}
                                  >
                                    <span className="info-icon">
                                      <FaInfoCircle />
                                    </span>
                                  </OverlayTrigger>
                                )}
                            </span>
                          </Form.Label>

                          {field.name === 'driver_suffix_name' ? (
                            <>
                              <Select
                                className={`custom-select w-100 ${
                                  driverSuffixNameError ? 'is-invalid' : ''
                                }`}
                                name={field.name}
                                isClearable
                                value={suffixOption.find(
                                  (option) =>
                                    option?.value ===
                                    driverModalFormData.driver_suffix_name
                                )}
                                onChange={(selectedOption) => {
                                  handleSuffixChange(selectedOption);
                                  setDriverSuffixNameError('');
                                }}
                                options={suffixOption}
                                placeholder={
                                  <div className="select-placeholder-text text-start">
                                    Select Name Suffix
                                  </div>
                                }
                              />
                              {driverSuffixNameError && (
                                <div className="text-danger">
                                  {driverSuffixNameError}
                                </div>
                              )}
                            </>
                          ) : field.name === 'license_state_id' ? (
                            <>
                              <Select
                                className={`custom-select w-100 ${
                                  driverselectLicenseStateError
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name={field.name}
                                value={stateOption.find(
                                  (option) =>
                                    option.id ===
                                    driverModalFormData.license_state_id
                                )}
                                onChange={(selectedOption) => {
                                  handleLicenseStateChange(selectedOption);
                                  setDriverSelectLicenseStateError('');
                                }}
                                options={stateOption}
                                placeholder={
                                  <div className="select-placeholder-text text-start">
                                    Select License State
                                  </div>
                                }
                              />
                              {driverselectLicenseStateError && (
                                <div className="text-danger">
                                  {driverselectLicenseStateError}
                                </div>
                              )}
                            </>
                          ) : field.name === 'vehicle' ? (
                            <>
                              <Select
                                className={`custom-select w-100 ${
                                  vehicleError ? 'is-invalid' : ''
                                }`}
                                name={field.name}
                                value={vehicleOption?.find(
                                  (option) =>
                                    option.id ===
                                    driverModalFormData[field.name]
                                )}
                                onChange={(selectedOption) => {
                                  handleVehicleChange(selectedOption);
                                  setVehicleError('');
                                }}
                                options={vehicleOption}
                                placeholder={
                                  <div className="select-placeholder-text text-start">
                                    Select Vehicle
                                  </div>
                                }
                              />
                              {vehicleError && (
                                <div className="text-danger">
                                  {vehicleError}
                                </div>
                              )}
                            </>
                          ) : field.name === 'state' ? (
                            <>
                              <Select
                                className={`custom-select w-100 ${
                                  driverSelectStateError ? 'is-invalid' : ''
                                }`}
                                name={field.name}
                                value={driverStateOption.find(
                                  (option) =>
                                    option.id === driverModalFormData.state?.id
                                )}
                                onChange={(selectedOption) => {
                                  handleDriverSelectStateChange(selectedOption);
                                  setDriverSelectStateError('');
                                }}
                                options={driverStateOption}
                                placeholder={
                                  <div className="select-placeholder-text text-start">
                                    Select State
                                  </div>
                                }
                              />
                              {driverSelectStateError && (
                                <div className="text-danger">
                                  {driverSelectStateError}
                                </div>
                              )}
                            </>
                          ) : field.name === 'city' ? (
                            <>
                              <CommonCityApi
                                stateId={stateId}
                                page={page}
                                Setpage={Setpage}
                                CityData={driverModalFormData}
                                CityOptions={cityOption}
                                SetCityOptions={setCityOption}
                                handleCityChange={handleDriverCityChange}
                                selectDriverCityError={selectDriverCityError}
                              />

                              {selectDriverCityError && (
                                <div className="text-danger">
                                  {selectDriverCityError}
                                </div>
                              )}
                            </>
                          ) : field.name === 'date_of_birth' ? (
                            <>
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                className={`custom-year-datepicker ${
                                  DOBError ? 'is-invalid' : ''
                                }`}
                                isClearable
                                placeholderText="MM/DD/YYYY"
                                maxDate={eighteenYearsAgo}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              {DOBError && (
                                <div className="text-danger">{DOBError}</div>
                              )}
                            </>
                          ) : field.name === 'date_of_hire' ? (
                            <>
                              <DatePicker
                                selected={selectedDateHire}
                                onChange={handleDateHireChange}
                                className={`custom-year-datepicker ${
                                  DOHError ? 'is-invalid' : ''
                                }`}
                                isClearable
                                placeholderText="MM/DD/YYYY"
                                maxDate={new Date()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              {DOHError && (
                                <div className="text-danger">{DOHError}</div>
                              )}
                            </>
                          ) : field.name !== 'is_primary_driver' ? (
                            <Form.Control
                              autoComplete="new-password"
                              type={field.type}
                              name={field.name}
                              min="0"
                              max={field.max} // Add the max attribute
                              placeholder={field.placeholder}
                              value={driverModalFormData[field.name]}
                              onChange={handleDriverChange}
                              className={`m-0 ${
                                driverfieldErrors[field.name]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                            />
                          ) : (
                            <Form.Check
                              type={field.type}
                              label={field.label}
                              checked={driverModalFormData[field.name]}
                              disabled={isPrimaryDisabled}
                              onChange={(e) =>
                                handleCheckboxChange(e, field.name)
                              }
                            />
                          )}
                          <span className="text-danger">
                            {driverfieldErrors[field.name]}
                          </span>
                        </Form.Group>
                      </div>
                    ))}
                    <h4 className="mt-5">Beneficiary Information:</h4>
                    {beneficiaryDriverData.map((field) => (
                      <div className="col-md-6" key={field.id}>
                        <Form.Group
                          controlId={field.id}
                          className="d-flex flex-column mb-10-sm"
                        >
                          <Form.Label className="fw-bold mt-2">
                            <span>{field.label}</span>
                          </Form.Label>
                          {
                            field.name === 'state' ? (
                              <>
                                <Select
                                  className={`custom-select w-100 ${
                                    beneficiaryStateError ? 'is-invalid' : ''
                                  }`}
                                  name={field.name}
                                  value={beneficiarStateOption.find(
                                    (option) =>
                                      option.id ===
                                      beneficiaryDriverformData.state?.id
                                  )}
                                  onChange={(selectedOption) => {
                                    handleBeneficiaryChange(
                                      selectedOption,
                                      'state'
                                    );
                                  }}
                                  options={beneficiarStateOption}
                                  isClearable
                                  placeholder={
                                    <div className="select-placeholder-text text-start">
                                      Select State
                                    </div>
                                  }
                                />
                              </>
                            ) : field.name === 'city' ? (
                              <>
                                <CommonCityApi
                                  stateId={beneficiaryStateId}
                                  page={beneficiaryPage}
                                  Setpage={SetbeneficiaryPage}
                                  CityData={beneficiaryDriverformData}
                                  CityOptions={beneficiaryCityOption}
                                  SetCityOptions={setBeneficiaryCityOption}
                                  handleCityChange={(e) =>
                                    handleBeneficiaryChange(e, 'city')
                                  }
                                  className={`m-0 ${
                                    beneficiaryDriverError[field.name]
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                />
                              </>
                            ) : field.name === 'beneficiary_suffix_name' ? (
                              <>
                                <Select
                                  className={`custom-select w-100 ${
                                    beneficiaryStateError ? 'is-invalid' : ''
                                  }`}
                                  name={field.name}
                                  value={beneficiarySuffixOption.find(
                                    (option) =>
                                      option?.value ===
                                      beneficiaryDriverformData.beneficiary_suffix_name
                                  )}
                                  onChange={(selectedOption) => {
                                    handleBeneficiaryChange(
                                      selectedOption,
                                      'beneficiary_suffix_name'
                                    );
                                  }}
                                  options={beneficiarySuffixOption}
                                  isClearable
                                  placeholder={
                                    <div className="select-placeholder-text text-start">
                                      Select Suffix
                                    </div>
                                  }
                                />
                              </>
                            ) : (
                              <Form.Control
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                min="0"
                                max={field.max} // Add the max attribute
                                value={beneficiaryDriverformData[field.name]}
                                onChange={(e) =>
                                  handleBeneficiaryChange(e, field.name)
                                }
                                className={`m-0 ${
                                  beneficiaryDriverError[field.name]
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                            ) // Handle the case where field.name is 'is_primary_driver'
                          }
                          <span className="text-danger">
                            {beneficiaryDriverError[field.name]}
                          </span>
                        </Form.Group>
                      </div>
                    ))}
                  </div>
                  <Button
                    className="my-4 btn silo-btn-small silo-btn__blue w-auto"
                    onClick={handleDriverFormSave}
                  >
                    {buttonLoading ? <Spinner size="sm" /> : <>Save</>}
                  </Button>
                </div>
              }
            />
            <DeleteModal
              show={show}
              handleClose={handleClose}
              handleDelete={handleDeleteDriver}
              content={
                <p>
                  This would delete all the data that you have filled up till
                  now, which includes Driver Information such as Personal and
                  Beneficiary Details
                  <br />
                  <br />
                  <div>
                    <b>Are you sure you want to continue?</b>
                  </div>
                </p>
              }
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default MultiDriver;
