import React, { useState } from 'react';
import logo from '../../../../assets/images/logo.avif';
import Breadcrumb from '../../Shared/Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import Arrowsvg from '../../../../assets/images/Form/Arrow.svg';
import './support.scss';
import { ToastManager } from '../../../../ToastContainer/ToastContainer';
import {
  contactFormData,
  isValidEmail,
} from '../../../shared/Constants/Constants';
import validator from 'validator';
import { formatUSBasedNumber } from '../../../../utils/Commonutils';

const Support = () => {
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
  });

  const [contactDataError, setcontactDataError] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData({
      ...contactData,
      [name]: value,
    });

    if (name === 'phone') {
      const formattedNumber = formatUSBasedNumber(value);
      setContactData({
        ...contactData,
        [name]: formattedNumber,
      });
    }
    setcontactDataError({
      ...contactDataError,
      [name]: '',
    });
  };

  function isValidUSPhoneNumber(phoneNumber) {
    // Use the isMobilePhone function with 'en-US' locale for U.S. phone numbers
    return validator.isMobilePhone(phoneNumber, 'en-US');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = false;
    const contactFieldErrors = {};

    contactFormData.forEach((field) => {
      if (!contactData[field.id] || contactData[field.id] === '') {
        contactFieldErrors[field.name] = `${
          field.name.charAt(0).toUpperCase() + field.name.slice(1).toLowerCase()
        } is required`;
        isValid = true;
      } else if (
        field.name === 'phone' &&
        !isValidUSPhoneNumber(contactData[field.name])
      ) {
        contactFieldErrors[field.name] = 'Invalid phone number';
        isValid = true;
      } else if (
        field.name === 'email' &&
        !isValidEmail(contactData[field.name])
      ) {
        // Check email validation
        contactFieldErrors[field.name] = 'Invalid email address';
        isValid = true;
      }
    });

    // const data = {
    //   name: contactData?.name,
    //   email: contactData?.email,
    //   phone: contactData?.phone?.replace(/[^\d]/g, ''),
    //   description: contactData?.description,
    // };
    if (isValid) {
      setcontactDataError(contactFieldErrors);
    } else {
      // console.log('datadata :', data);
      ToastManager.success(
        'You query has been submitted. We will get back to you.'
      );
    }
  };

  return (
    <>
      <Breadcrumb title={'Support'} />
      <div className="container ml-unset mt-5">
        <Link to={'/contact'} className="mt-5 text-decoration-none">
          <span className="align-items back-btn-style back-btn-style">
            <img src={Arrowsvg} className="pe-2" alt="" />
            <span className="mt-5 text-blue">Back to Contact Page</span>
          </span>
        </Link>
        <div className="contact-form-box text-center w-75 mt-5 form-box">
          <div>
            <img className="mb-4" width="220" src={logo} alt="logo" />
          </div>

          <div className="mb-4">
            <div className="mb-4">Please enter your details to get Help.</div>
            <form id="account" method="post">
              {contactFormData.map((field, index) => (
                <div className="form-group mb-3 text-start" key={index}>
                  <div className="icon">
                    <img src={field.icon} alt={field.icon} />
                  </div>
                  <input
                    name={field.name}
                    type={field.type}
                    className={`form-control mb-0 ${
                      contactDataError[field.name] ? 'is-invalid' : ''
                    }`}
                    placeholder={field.placeholder}
                    value={contactData[field.name] || ''}
                    onChange={handleChange}
                  />
                  {contactDataError[field.name] && (
                    <div className="text-danger">
                      {contactDataError[field.name]}
                    </div>
                  )}
                </div>
              ))}
              <button
                type="submit"
                className="mt-4 btn silo-btn silo-btn__blue"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
