import React, { useContext } from 'react';
import { Context } from '../../../../../../utils/Context';
import { AiFillEdit } from 'react-icons/ai';
import Col from 'react-bootstrap/Col';
import NewGetQuote from '../../../NewGetQuote';
import { formatInput } from '../../../../../../utils/Commonutils';

const VehicleOrderSummary = ({
  handleEditClick,
  vehicleIndex,
  data,
  colClassName,
}) => {
  const { vehicles } = useContext(Context);

  const vehicleKeys = {
    vin_number: 'VIN',
    vehicle_body_type: 'Body Type',
    vehicle_type: 'Vehicle Type',
    vehicle_make: 'Vehicle Make',
    vehicle_model: 'Vehicle Model',
    declared_year: 'Year',
    cargo_commodities: 'Cargo Commodities',
    vehicle_safety_systems: 'Vehicle Safety Systems',
    eld_provider: 'ELD Provider',
    declared_value: 'Value',
    dot_information_value: 'DOT#',
    license_plate_number: 'License Plate Number',
    license_plate_state: 'License Plate State',
    lienholder_1: 'Lienholder 1',
    lienholder_2: 'Lienholder 2',
  };

  const keysInOrder = Object.keys(vehicleKeys);

  const getVehicleKeys = (key) => {
    return vehicleKeys[key] || key;
  };

  const hasVehicleData =
    vehicleIndex >= 0 &&
    vehicleIndex < vehicles.length &&
    Object.keys(vehicles[vehicleIndex]).length > 0;

  return hasVehicleData ? (
    <Col lg={colClassName}>
      <div className="details-container">
        <h5 className="m-0 fw-bold">Vehicle - {vehicleIndex + 1}</h5>
        <div className="text-muted">Vehicle Details</div>
        <hr />
        {keysInOrder.map((key) => {
          let editDisabled = false;
          const value = vehicles[vehicleIndex][key];
          vehicles[vehicleIndex].dot_information_value = vehicles[vehicleIndex]
            .dot_information_value
            ? vehicles[vehicleIndex].dot_information_value
            : null;
          if (vehicles[vehicleIndex].vin_number !== null) {
            if (
              key === 'vehicle_body_type' ||
              key === 'vehicle_type' ||
              key === 'vehicle_make' ||
              key === 'vehicle_model' ||
              key === 'declared_year'
            ) {
              editDisabled = true;
            }
          } else {
            editDisabled = false;
          }
          if (
            (value && (!Array.isArray(value) || value.length > 0)) ||
            key === 'dot_information_value'
          ) {
            // Check if value is not null
            return (
              <div key={key} className="d-flex justify-content-between mt-2">
                <div className="me-3 text-start">
                  <span className="text-muted mr-2">
                    <b>{getVehicleKeys(key)}</b>
                  </span>
                </div>
                <div className="text-end">
                  <span>
                    {key === 'declared_value'
                      ? `$${formatInput(value)}`
                      : Array.isArray(value)
                      ? value?.map((item) => item?.name).join(', ')
                      : value?.name || value}
                  </span>
                  {!editDisabled ? (
                    <span>
                      <AiFillEdit
                        size={25}
                        className="vehicle-edit-icon py-1"
                        onClick={() => handleEditClick(key, vehicleIndex)}
                      />
                    </span>
                  ) : null}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
      {colClassName === 4 ? <NewGetQuote /> : null}
    </Col>
  ) : (
    <Col lg={4}>
      <div className="details-container">
        <h5 className="m-0 fw-bold">Vehicle - {vehicleIndex + 1}</h5>
        <div className="text-muted">Vehicle Details</div>
        <hr />
        <div className="d-flex justify-content-between mt-2">
          <div className="me-3">
            <span className="text-muted mr-2">
              <b>Resume filing Vehicle Details</b>
            </span>
          </div>

          <div className="text-end">
            <span>
              <AiFillEdit
                size={25}
                className="vehicle-edit-icon py-1"
                onClick={() => handleEditClick('New Vehicle', vehicleIndex)}
              />
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default VehicleOrderSummary;
