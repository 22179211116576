import React from 'react';

const ExpiredSubscription = () => {
  return (
    <div className="text-center">
      <div className="info-form-box">Expired Subscription</div>
    </div>
  );
};

export default ExpiredSubscription;
