import React, { useState } from 'react';
import './reminder.scss';
import { ToastManager } from '../../../../../ToastContainer/ToastContainer';
import validator from 'validator';
import { formatUSBasedNumber } from '../../../../../utils/Commonutils';

const Reminder = () => {
  const [formData, setFormData] = useState({
    mobile: '',
    expiryDate: '',
    agreeToTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    mobile: '',
    expiryDate: '',
    agreeToTerms: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'text' ? formatUSBasedNumber(value) : value,
      });
    }

    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  const isValidPhoneNumber = (phoneNumber) => {
    return validator.isMobilePhone(phoneNumber, 'any', { strictMode: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = false;
    const fieldErrors = {};

    if (!formData.mobile || formData.mobile === '') {
      fieldErrors.mobile = 'Mobile number is required';
      isValid = true;
    } else if (!isValidPhoneNumber(formData.mobile)) {
      fieldErrors.mobile = 'Invalid mobile number';
      isValid = true;
    }

    if (!formData.expiryDate || formData.expiryDate === '') {
      fieldErrors.expiryDate = 'Expiry date is required';
      isValid = true;
    }

    if (!formData.agreeToTerms) {
      fieldErrors.agreeToTerms = 'Please agree to the Terms and Conditions';
      isValid = true;
    }

    setFormErrors(fieldErrors);

    if (isValid) {
      return;
    }

    // If the form is valid, proceed with the form submission
    ToastManager.success('Form Submitted successfully');

    setFormData({
      mobile: '',
      expiryDate: '',
      agreeToTerms: false,
    });
  };

  return (
    <section className="backgroundReminder">
      <div className="container py-5">
        <div className="mobile-card">
          <div className="row d-flex justify-content-between">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div className="silo_page_section-heading">
                <small>EXCLUSIVE</small>
                <h2 className="pad-top3">Policy expiry too far away?</h2>
                <p>Set up a reminder and get a renewal offer from us.</p>
              </div>
            </div>
            <div className="track_ins col-xs-12 col-sm-12 col-md-12 col-lg-7 ">
              <div className="row d-flex reminderForm">
                <div className="col-md-5">
                  <p>Please enter the below details</p>
                  <input
                    type="text"
                    placeholder="Mobile No."
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className={`form-control mb-0 ${
                      formErrors.mobile ? 'is-invalid' : ''
                    }`}
                  />
                  {formErrors.mobile && (
                    <div className="text-danger">{formErrors.mobile}</div>
                  )}
                </div>
                <div className="col-md-5">
                  <p>Policy expiry date</p>
                  <input
                    type="date"
                    name="expiryDate"
                    value={formData.expiryDate}
                    onChange={handleChange}
                    className={`form-control mb-0 ${
                      formErrors.expiryDate ? 'is-invalid' : ''
                    }`}
                  />
                  {formErrors.expiryDate && (
                    <div className="text-danger">{formErrors.expiryDate}</div>
                  )}
                  <br />
                  <span className="text-secondary">
                    <span>
                      &nbsp;&nbsp;
                      <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                      />{' '}
                      I agree to the Terms and Conditions
                    </span>
                  </span>
                  {formErrors.agreeToTerms && (
                    <div className="text-danger">{formErrors.agreeToTerms}</div>
                  )}
                </div>
                <div className="col-md-2 mt-4">
                  <button
                    onClick={handleSubmit}
                    className="btn silo-btn silo-btn__blue"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reminder;
