import React from 'react'
import oops from '../../assets/images/404.jpg';
import '../404/notFound.scss'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='not-found-parent'>
      <div className='img-parent'>
        <img src={oops} alt="404" />
      </div>
      <Link to="/" className='btn btn silo-btn silo-btn__blue'>Go Back</Link>
    </div>
  )
}

export default NotFound
