import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../utils/Context';
import { useLocation } from 'react-router-dom';
import PaymentFailed from './Section/PaymentFailed/PaymentFailed';
import PaymentSuccess from './Section/PaymentSuccess/PaymentSuccess';
import { getRequest } from '../../../../api/api';
import { GetGuestUserToken, SetGuestUserToken } from '../guestUserTokenUtils';
import {
  REGENERATE_DOCUSIGN_AGREEMENT,
  GET_VEHICLE_DETAILS,
  REGENERATE_AGREEMENT_MAIL,
} from '../../../../api/endpoint';
import { ToastManager } from '../../../../ToastContainer/ToastContainer';
import './payment.scss';
import {
  GetLoggedInUserToken,
  SetLoggedInUserToken,
} from '../../LoggedIn-Area/loggedInUserTokenUtils';
import { PaymentCards } from './Section/PaymentCards';
import { DocusignMobileScreen } from './Section/DocusignMobileScreen';
import { DocusignDesktopScreen } from './Section/DocusignDesktopScreen';

const Payment = ({ saveProfile }) => {
  const { profileObject, docuSignDone, setDocuSignDone, setProfileObject } =
    useContext(Context);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const paymentStatus = urlParams.get('status');
  const securityKey = urlParams.get('security_key');
  const token = urlParams.get('token');
  const profile_id = urlParams.get('profile_id');
  const program_id = urlParams.get('program_id');
  const [displayPaymentFailed, setDisplayPaymentFailed] = useState(false);
  const [displayPaymentSuccess, setDisplayPaymentSuccess] = useState(false);
  const [partial_payment, setPartial_payment] = useState(
    profileObject?.programs[0]?.payment_options?.partial_payment
  );
  const [showFullPayment, setShowFullPayment] = useState(
    profileObject?.programs[0]?.payment_options?.full_payment[0]?.enabled
  );
  const isPaid = profileObject?.programs[0]?.is_fully_paid;
  const isMobileScreen = window.innerWidth < 768;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [mobileLoader, setMobileLoader] = useState(true);
  const [docusignFocusedURL, setDocusignFocusedURL] = useState(null);
  const [countdown, setCountdown] = useState(30);
  // const [agreementMailSent, setAgreementMailSent] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        clearInterval(timer); // Stop the countdown when it reaches 1
      } else {
        setCountdown(countdown - 1); // Decrease the countdown by 1 second
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts
    };
  }, [countdown]);

  useEffect(() => {
    if (profileObject && profileObject?.programs[0]?.docusign_agreed_at) {
      setDocuSignDone(true);
    }
    setPartial_payment(
      profileObject &&
        profileObject?.programs[0]?.payment_options?.partial_payment
    );
    setShowFullPayment(
      profileObject &&
        profileObject?.programs[0]?.payment_options?.full_payment[0]?.enabled
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject]);

  const getVehicleDetails = async () => {
    try {
      let guestUserToken = GetGuestUserToken();
      let loggedInUserToken = GetLoggedInUserToken();
      if (!guestUserToken) {
        SetGuestUserToken(token);
        guestUserToken = token;
        const response = await getRequest(GET_VEHICLE_DETAILS);
        setProfileObject(response.result.profiles[0]);
      }
      if (!loggedInUserToken && paymentStatus === 'success') {
        SetLoggedInUserToken(token);
        const response = await getRequest(GET_VEHICLE_DETAILS);
        setProfileObject(response.result.profiles[0]);
      }
    } catch (error) {
      console.log('API error:', error);
    }
  };
  //Un-comment this code if you want to send docusign email once user lands on Payment page from any route and
  //he has not signed docusign
  // useEffect(() => {
  //   if (
  //     profileObject &&
  //     isMobileScreen &&
  //     !docuSignDone &&
  //     !agreementMailSent
  //   ) {
  //     setAgreementMailSent(true);
  //     regenerateAgreementMail();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [profileObject, agreementMailSent]);

  useEffect(() => {
    getVehicleDetails();
    if (!isMobileScreen && !docuSignDone) {
      regenerateDocusignAgreement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Delay the toast for 5 seconds
    if (
      profileObject &&
      !profileObject?.programs[0]?.docusign_agreed_at &&
      !docuSignDone &&
      !isMobileScreen &&
      docusignFocusedURL
    ) {
      const delay = 5000;
      const timeoutId = setTimeout(() => {
        ToastManager.infoCustomDuration(
          'Please wait, Your DocuSign agreement is loading...',
          6000
        );
      }, delay);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      profileObject &&
      !isMobileScreen &&
      docusignFocusedURL &&
      !docuSignDone
    ) {
      // Create a script element and add the DocuSign bundle script
      const script = document.createElement('script');
      script.src = 'https://js-d.docusign.com/bundle.js';
      script.async = true;

      script.onload = () => {
        window.DocuSign.loadDocuSign(
          profileObject?.programs[0]?.docusign_integration_key
        )
          .then((docusign) => {
            const signing = docusign.signing({
              url: docusignFocusedURL,
              displayFormat: 'focused',
              style: {
                branding: {
                  primaryButton: {
                    backgroundColor: '#333',
                    color: '#fff',
                  },
                },
                signingNavigationButton: {
                  finishText: 'Finish',
                  position: 'bottom-center',
                },
              },
            });

            signing.on('ready', (event) => {
              console.log('UI is rendered');
            });
            signing.on('sessionEnd', (event) => {
              console.log('sessionend', event);
              handleDocusignCheckout();
            });
            signing.mount('#agreement');
          })
          .catch((ex) => {
            console.error('DocuSign integration error:', ex);
          });
      };

      // Append the script to the document
      document.body.appendChild(script);

      return () => {
        // Clean up the script when the component unmounts
        document.body.removeChild(script);
      };
    } else {
      setMobileLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject, docusignFocusedURL]);

  useEffect(() => {
    if (paymentStatus === 'failure') {
      setDisplayPaymentFailed(true);
      // Automatically hide the payment failed message after 3 seconds
    } else if (paymentStatus === 'success') {
      setDisplayPaymentSuccess(true);
    }
  }, [paymentStatus]);

  const regenerateDocusignAgreement = async () => {
    try {
      const params = {
        device_type: isMobileScreen ? 'mobile' : 'desktop',
      }
      const response = await getRequest(REGENERATE_DOCUSIGN_AGREEMENT, params);
      setDocusignFocusedURL(response?.result?.docusign_focused_url);
      //Below code need to be fixed for mobile -> dekstop
      // if (
      //   response?.result?.docusign_focused_url === null &&
      //   !docuSignDone &&
      //   response?.result?.message !== 'Document is already signed.'
      // ) {
      //   ToastManager.error(
      //     'It seems that there is an issue loading Docusign. Please attempt to refresh the page. If the problem persists, kindly contact us at support@silomembers.com for further assistance.'
      //   );
      // }
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const regenerateAgreementMail = async () => {
    try {
      const params = {
        device_type: isMobileScreen ? 'mobile' : 'desktop',
      }
      const response = await getRequest(REGENERATE_AGREEMENT_MAIL, params);
      console.log('response', response);
      ToastManager.success('Email Sent!');
      // setAgreementMailSent(true);
    } catch (error) {
      console.log('API error:', error);
    }
  };
  const handleResendDocusignAgreementMail = () => {
    setCountdown(30);
    regenerateAgreementMail();
  };

  const saveDocusignData = () => {
    if (!docuSignDone) {
      const currentDate = new Date();
      const data = {
        programs_attributes: [
          {
            id: profileObject?.programs[0]?.id,
            docusign_agreed_at: currentDate.toISOString(),
          },
        ],
        vehicle_details_attributes: [{}],
      };
      saveProfile(data).then((success) => {
        if (success) {
          ToastManager.success(
            'Signing Process has been completed. Please proceed to Payments!'
          );
          setDocuSignDone(true);
        } else {
          setDocuSignDone(false);
        }
        setButtonLoading(false);
      });
    }
  };

  const handleDocusignCheckout = () => {
    setButtonLoading(true);
    saveDocusignData();
  };

  return (
    <div>
      {isPaid && <h3>Your Payment is Completed</h3>}
      {!isMobileScreen && !docuSignDone && <DocusignDesktopScreen />}
      {!mobileLoader && !docuSignDone && isMobileScreen && (
        <DocusignMobileScreen
          countdown={countdown}
          handleResendDocusignAgreementMail={handleResendDocusignAgreementMail}
          handleDocusignCheckout={handleDocusignCheckout}
          buttonLoading={buttonLoading}
        />
      )}
      {docuSignDone && !isPaid && (
        <>
          {displayPaymentFailed ? (
            <PaymentFailed
              securityKey={securityKey}
              token={token}
              profile_id={profile_id}
              program_id={program_id}
              setDisplayPaymentFailed={setDisplayPaymentFailed}
            />
          ) : displayPaymentSuccess ? (
            <PaymentSuccess
              securityKey={securityKey}
              token={token}
              profile_id={profile_id}
              program_id={program_id}
              setDisplayPaymentSuccess={setDisplayPaymentSuccess}
            />
          ) : (
            <PaymentCards
              partial_payment={partial_payment}
              showFullPayment={showFullPayment}
              saveProfile={saveProfile}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Payment;
